/*******
Version: 1.0
********/
/* CSS Document */
/* @import url(jquery-ui.min.css);
@import url(font-awesome.min.css);
@import url(font-awesome-animation.min.css);
@import url(menu.css);
@import url(ace-responsive-menu.css);
@import url(megadropdown.css);
@import url(bootstrap-select.min.css);
@import url(simplebar.min.css);
@import url(progressbar.css);
@import url(flaticon.css);
@import url(animate.css);
@import url(slider.css);
@import url(magnific-popup.css);
@import url(timecounter.css);
@import url(
  https://fonts.googleapis.com/css?family=Nunito:400,400i,600,700&amp;display=swap
); */
/* Table of contents */

/*== Typography ==*/
/* Search Overlay */
/*== Header Top Style ==*/
/* Header Top Search Field css home2 */
/*== Menu Style For Home V1 & V2 ==*/
/*== Menu Style For Home V2 ==*/
/*== Menu Style For Home V3 ==*/
/*== Menu Style For Home V4 ==*/
/*== Menu Style For Home V5 ==*/
/*== Menu Style For Home V6 ==*/
/*== Menu Style For Home V7 ==*/
/*== Mobile Menu Css ==*/
/*== Home Css ==*/
/* ==== Home 2 Main Sliders === */
/*== Home Icon Box ==*/
/*== Home Five Overlay Slider ==*/
/*== School Category Courses ==*/
/*== Feature Property ==*/
/*== Property Cities ==*/
/*== Search Smart Property ==*/
/*== Single Property ==*/
/*== Listing Single V5 Page Sticky Nav ==*/
/*== Why Choose Us ==*/
/*== Popular Job Categories => Icon Hover Image Box ==*/
/*== Funfact Counter ==*/
/*== Footer Social Widget ==*/
/*== 1st Layout Footer ==*/
/*== Footer Widget ==*/
/*== Inner Pages Breadcrumb ==*/
/*== About Page About Content ==*/
/*== About home3 ==*/
/*== Testimonials Grid ==*/
/*== Blog Post Home V1 ==*/
/*== Team Slider Code ==*/
/*== Pricing Table Four ==*/
/*== Our Partners ==*/
/*== Error/404 Page ==*/
/*== Invoice Page ==*/
/*== Service Pages Style Code ==*/
/*== LogIn Register Page ==*/
/* Create a custom checkbox */
/*== Search Widget Faq Page ==*/
/*== Question Widget Faq Page ==*/
/*== Terms & Condition Page ==*/
/*== Tab Widget UI Page ==*/
/*== Table Widget UI Page ==*/
/*== Message Box Widget UI Page ==*/
/*== Form Input Widget UI Page ==*/
/*== Form Textarea Widget UI Page ==*/
/*== Form Tooltip Widget UI Page ==*/
/*== Select Search Box Widget UI Page ==*/
/*== Radiobox, Checkbox, Switch Widget UI Page ==*/
/*== CheckBox Widget UI Page ==*/
/*== SwithcBox Widget UI Page ==*/
/*== Select Box Widget UI Page ==*/
/*== MultiSelect CheckBox Widget UI Page ==*/
/*== Blog Post Main Page ==*/
/*== Blog Grid Post ==*/
/*== Search Widget Blog Page ==*/
/*== Category Widget Blog Page ==*/
/*== Recent Post Widget Blog Page ==*/
/*== Tag Widget Blog Page ==*/
/*== BlokQuote Blog Sinlge Page ==*/
/*== Next Prev Pagination Blog Sinlge Page ==*/
/*== Commenting Blog Sinlge Page ==*/
/*== Commenting Form Blog Sinlge Page ==*/
/*== Dashbord details Page ==*/
/*== Header User Notification & User ID ==*/
/*== Header User Setting ID ==*/
/*== Candidate Job Review Page Table ==*/
/*== My Resume Page ==*/
/*== Message Page ==*/
/*== DashBoard Pages ==*/
/*== sidebar about widget ==*/
/*== Pricing SLider ==*/
/*== Profile Avatar ==*/
/*== UI Page Elements ==*/
/*== Alpha v2 Page ==*/
/*== Testimonials Home V3 ==*/
/*== Contact Page ==*/
/*== Maximage_slider ==*/
/*== Color Area ==*/
/*== Button Area ==*/
/*== Fonts Size, Font Weights, Height, Display & Position ==*/
/*== Custome Margin Padding ==*/
/*== BackGround Images ==*/
/*== BackGround Color White Overlay ==*/
/*== Parallax Css ==*/
/*== Divider Css ==*/
/*== Chart Js Css ==*/
/*== Tags Bar Css ==*/
/*== arrow fontawesome icon to the right */
/*== Shop Page Style ==*/
/*== Shop Order Page Style ==*/
/*== Shop Chaeckout Page Style ==*/
/*== Shop Single Page Style ==*/
/*== New Pricing Range Smooth Slider ==*/
/*== Custom CSS By Tawhid ==*/

/*== Typography ==*/
html {
  font-size: 100%;
}

body {
  background-attachment: fixed;
  background-color: #ffffff;
  color: #777777;
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  line-height: 1.642;
  overflow-x: hidden;
  transition: inherit;
  -webkit-font-smoothing: antialiased;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
body.body_overlay {
  position: fixed;
  transform: translate(0, 0);
  width: 100%;
}
body.body_overlay:before {
  background-color: rgba(0, 108, 112, 0.5);
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  -webkit-transition: all 0.8s ease;
  -moz-transition: all 0.8s ease;
  -ms-transition: all 0.8s ease;
  -o-transition: all 0.8s ease;
  transition: all 0.8s ease;
  transform: translate(360px, 0);
}
img {
  max-width: 100%;
}

p {
  font-size: 14px;
  color: #484848;
  font-weight: 400;
}

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

iframe {
  border: none;
  width: 100%;
}

a {
  color: #555555;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
}
a:hover,
a:focus {
  color: #333333;
  text-decoration: none;
  outline: none;
}
a img {
  border: none;
}
.form-control {
  height: 50px;
}
.form-control:active,
.form-control:focus {
  box-shadow: none;
  outline: none;
}

iframe {
  border: none;
}

::selection {
  background: #222222;
  color: #ffffff;
  text-shadow: none;
}

::-moz-selection {
  /* Firefox */
  background: #222222;
  color: #ffffff;
  text-shadow: none;
}

::-webkit-selection {
  /* Safari */
  background: #222222;
  color: #ffffff;
  text-shadow: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: 'Nunito', sans-serif;
  line-height: 1.2;
  color: #484848;
}
h1 small,
h1 .small,
h2 small,
h2 .small,
h3 small,
h3 .small,
h4 small,
h4 .small,
h5 small,
h5 .small,
h6 small,
h6 .small,
.h1 small,
.h1 .small,
.h2 small,
.h2 .small,
.h3 small,
.h3 .small,
.h4 small,
.h4 .small,
.h5 small,
.h5 .small,
.h6 small,
.h6 .small {
  font-weight: normal;
  line-height: 1;
  color: #484848;
  font-weight: 400;
}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a,
.h1 a,
.h2 a,
.h3 a,
.h4 a,
.h5 a,
.h6 a {
  color: #484848;
  font-weight: 400;
}

h1,
h2,
h3,
h4 .h1,
.h2,
.h3,
.h4 {
  font-weight: bold;
}

h5,
h6,
.h5,
.h6 {
  font-weight: bold;
}

h1,
.h1 {
  font-size: 36px;
}

h2,
.h2 {
  font-size: 30px;
}

h3,
.h3 {
  font-size: 24px;
}

h4,
.h4 {
  font-size: 18px;
}

h5,
.h5 {
  font-size: 15px;
}

h6,
.h6 {
  font-size: 12px;
}
p,
ul,
ol,
dl,
dt,
dd,
blockquote,
address {
  margin: 0 0 10px;
}

.ff-nunito {
  font-family: 'Nunito', sans-serif;
}

.ff-fontawesome {
  font-family: FontAwesome !important;
}

.sign_up_modal:before {
  background-color: rgba(0, 0, 0, 0.9);
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.sign_up_modal.modal .close:focus {
  outline: none;
}
.sign_up_modal .tab-content .tab-pane.active {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
}
.sign_up_modal .modal-header {
  background-color: #ff5a5f;
  border-radius: 50%;
  border: none;
  height: 50px;
  position: absolute;
  right: -20px;
  text-align: center;
  top: -20px;
  width: 50px;
  z-index: 9;
}
.sign_up_modal .modal-header button.close {
  margin-top: -5px;
  margin-right: 0;
  margin-left: 4px;
  opacity: 1;
  padding: 0;
}
.sign_up_modal .modal-header button.close span {
  color: #ffffff;
}
.sign_up_modal .modal-header button.close span:focus {
  border: none;
  outline: none;
  text-shadow: none;
}
.sign_up_tab li.nav-item {
  text-align: center;
  width: 50%;
}
.sign_up_tab li.nav-item .nav-link {
  background-color: #f5f5f5;
  height: 70px;
  line-height: 65px;
  margin: 0;
  padding: 0;
}
.sign_up_tab li.nav-item .nav-link {
  border: none;
  color: #484848;
  font-family: 'Nunito', sans-serif;
  font-size: 18px;
  font-weight: bold;
}
.sign_up_tab li.nav-item .nav-link.active {
  background-color: #ffffff;
  border: none;
}
.modal-backdrop {
  display: none;
}
.cart {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  padding: 0;
  position: relative;
}
.cart > li {
  cursor: pointer;
  font-size: 14px;
  flex-grow: 1;
  flex-shrink: 0;
  position: relative;
}
.cart > li:hover .dropdown_content {
  opacity: 1;
  visibility: visible;
  z-index: 0;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}
.cart > li:hover .dropdown_content:before {
  background-color: #ffffff;
  content: '';
  height: 20px;
  position: absolute;
  top: -10px;
  width: 20px;
  right: 15px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.dropdown_content {
  background: #ffffff;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15); /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15); /* Firefox 3.5 - 3.6 */
  box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
  display: flex;
  height: 425px;
  flex-direction: column;
  margin: 0;
  opacity: 0;
  padding: 30px;
  position: absolute;
  right: 0;
  top: 120%;
  -webkit-transform: translate(0, -60px);
  -moz-transform: translate(0, -60px);
  -o-transform: translate(0, -60px);
  transform: translate(0, -60px);
  transition: opacity 0.2s, z-index 0s 0.2s, -webkit-transform 0.2s ease-out;
  transition: transform 0.2s ease-out, opacity 0.2s, z-index 0s 0.2s;
  transition: transform 0.2s ease-out, opacity 0.2s, z-index 0s 0.2s,
    -webkit-transform 0.2s ease-out;
  visibility: hidden;
  width: 414px;
  z-index: -1;
}
.dropdown_content > li:first-child {
  padding-top: 0;
}
.dropdown_content > li {
  border-bottom: 1px solid #dedede;
  cursor: pointer;
  font-size: 14px;
  padding: 20px 0;
}
.dropdown_content > li:last-child {
  border-bottom: none;
}
.dropdown_content .list_content a img {
  border-radius: 50%;
  margin-right: 20px;
}
.dropdown_content .list_content a p {
  color: #484848;
  font-family: 'Nunito', sans-serif;
  margin: 0;
}
.dropdown_content .list_content a small {
  font-size: 14px;
  margin: 0;
}
.dropdown_content .list_content a .close_icon {
  font-size: 16px;
  margin: -10px 0;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.dropdown_content .list_content h5 {
  color: #212121;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 15px;
}
.dropdown_content .list_content .cart_btns {
  border: 1px solid transparent;
  margin-right: 10px;
}
.dropdown_content .list_content .cart_btns:hover {
  background-color: #2ac4ea;
  border: 1px solid #2ac4ea;
}
.dropdown_content .list_content .cart_btns,
.dropdown_content .list_content .checkout_btns {
  border-radius: 25px;
  color: #ffffff !important;
  height: 50px;
  line-height: 40px;
  width: 170px;
}
.dropdown_content .list_content .checkout_btns:hover {
  background-color: #fbbc05;
}
/* Search Overlay */
.search_overlay {
  height: 100%;
  width: 100%;
}
.mk-search-trigger {
  position: absolute;
}
.mk-search-trigger.style2 {
  position: absolute;
  right: 72px;
  top: 26px;
}
.mk-search-trigger.style2:before {
  background-color: rgb(232, 238, 242);
  content: '';
  height: 38px;
  position: absolute;
  right: -15px;
  top: 0;
  width: 1px;
}
.search_overlay.home3 .mk-search-trigger,
.search_overlay.home5 .mk-search-trigger {
  position: relative;
  top: 0;
}
.search_overlay.home5 .mk-search-trigger {
  margin-left: 0;
}
.mk-fullscreen-search-overlay
  #mk-fullscreen-search-wrapper
  #mk-fullscreen-searchform
  input[type='submit'],
.mk-fullscreen-search-overlay
  #mk-fullscreen-search-wrapper2
  #mk-fullscreen-searchform2
  input[type='submit'] {
  position: absolute;
  width: 100px;
  height: 100%;
  background-color: transparent;
  border: 0;
  right: 0;
  top: 0;
}
#mk-fullscreen-searchform:focus,
#mk-fullscreen-searchform2:focus {
  outline: none;
  border: none;
}
.mk-fullscreen-search-overlay
  #mk-fullscreen-search-wrapper
  #mk-fullscreen-searchform
  .fullscreen-search-icon,
.mk-fullscreen-search-overlay
  #mk-fullscreen-search-wrapper2
  #mk-fullscreen-searchform2
  .fullscreen-search-icon {
  font-size: 25px;
  position: absolute;
  right: 15px;
  width: 25px;
  top: 15px;
  color: #484848;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}
.fullscreen-search-icon:hover,
.fullscreen-search-icon:focus {
  border: none;
  outline: none;
  -webkit-transition: transform ease-out 0.2s;
  -moz-transition: transform ease-out 0.2s;
  -ms-transition: transform ease-out 0.2s;
  -o-transition: transform ease-out 0.2s;
  transition: transform ease-out 0.2s;
}
#mk-fullscreen-searchform,
#mk-fullscreen-searchform2 {
  position: relative;
  vertical-align: middle;
}
.mk-fullscreen-search-overlay
  #mk-fullscreen-search-wrapper
  #mk-fullscreen-searchform
  #mk-fullscreen-search-input,
.mk-fullscreen-search-overlay
  #mk-fullscreen-search-wrapper2
  #mk-fullscreen-searchform2
  #mk-fullscreen-search-input2 {
  background-color: #ffffff;
  border: 0;
  border-radius: 5px;
  color: #262626;
  font-size: 17px;
  padding: 20px;
  width: 800px;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.mk-fullscreen-search-overlay
  #mk-fullscreen-search-wrapper
  #mk-fullscreen-searchform
  #mk-fullscreen-search-input:focus,
.mk-fullscreen-search-overlay
  #mk-fullscreen-search-wrapper2
  #mk-fullscreen-searchform2
  #mk-fullscreen-search-input2:focus {
  border: none;
  outline: none;
}
.mk-fullscreen-search-overlay.mk-fullscreen-search-overlay-show {
  visibility: visible;
  opacity: 1;
}
.mk-fullscreen-search-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.94);
  text-align: center;
  -webkit-transition: all ease-in-out 0.25s;
  -moz-transition: all ease-in-out 0.25s;
  -ms-transition: all ease-in-out 0.25s;
  -o-transition: all ease-in-out 0.25s;
  transition: all ease-in-out 0.25s;
}
.mk-animate-element,
.mk-effect-bg-layer,
.mk-fullscreen-search-overlay {
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.mk-fullscreen-search-overlay {
  z-index: 9999;
}
.mk-fullscreen-search-overlay .mk-fullscreen-close:link,
.mk-fullscreen-search-overlay .mk-fullscreen-close:visited {
  border: none;
  color: #fff;
}
.mk-fullscreen-search-overlay .mk-fullscreen-close {
  background-color: transparent;
  border: none;
  color: #fff;
  position: absolute;
  right: 50px;
  top: 70px;
  font-size: 26px;
  -webkit-transition: transform ease-out 0.2s;
  -moz-transition: transform ease-out 0.2s;
  -ms-transition: transform ease-out 0.2s;
  -o-transition: transform ease-out 0.2s;
  transition: transform ease-out 0.2s;
}
.mk-fullscreen-search-overlay.mk-fullscreen-search-overlay-show
  #mk-fullscreen-search-wrapper,
.mk-fullscreen-search-overlay.mk-fullscreen-search-overlay-show
  #mk-fullscreen-search-wrapper2 {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  ms-transform: scale(1);
}
.mk-fullscreen-search-overlay #mk-fullscreen-search-wrapper,
.mk-fullscreen-search-overlay #mk-fullscreen-search-wrapper2 {
  display: inline-block;
  max-width: 1000px;
  vertical-align: middle;
  text-align: center;
  font-size: 18px;
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  -ms-transform: scale(0.9);
  -o-transform: scale(0.9);
  transform: scale(0.9);
  ms-transform: scale(0.9);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}
.mk-search-trigger {
  -webkit-transition: color 0.2s ease-in-out;
  -moz-transition: color 0.2s ease-in-out;
  -ms-transition: color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
}
#search-button,
#search-button2 {
  cursor: pointer;
  opacity: 1;
  position: relative;
  vertical-align: middle;
  -webkit-transition: all 400ms;
  -moz-transition: all 400ms;
  -ms-transition: all 400ms;
  -o-transition: all 400ms;
}
#search-button i,
#search-button2 i {
  color: #484848;
  font-size: 22px;
}
.search_overlay.home5 #search-button i,
.search_overlay.home5 #search-button2 i {
  color: #484848;
}
/*== Header Top Style ==*/
.header_top.home2 {
  background-color: #000000;
  height: 80px;
  position: relative;
  z-index: 9;
}
.header_top.home3 {
  height: 70px;
}
.header_top.home3 .container-fluid {
  padding: 0 70px;
  position: relative;
  z-index: 9;
}
.home3_header_top_contact {
  margin-bottom: 0;
  margin-top: 25px;
}
.home3_header_top_contact li:first-child,
.home4_header_top_contact li:first-child {
  margin-right: 35px;
}
.home3_header_top_contact li a,
.home4_header_top_contact li a {
  color: #ffffff;
}
.home4_header_top_contact li a,
ul.sign_up_btn.home4 li a,
ul.sign_up_btn.home6 a {
  font-family: 'Nunito', sans-serif;
  font-size: 14px;
  line-height: initial;
}
.header_top.home6 {
  background-color: #ffffff;
  height: 90px;
  position: relative;
}
.home4_header_top_contact {
  margin-bottom: 0;
  margin-top: 5px;
}
.sign_up_btn.home4 .btn {
  height: auto;
  line-height: inherit;
}
.header_top.home7 {
  background-color: transparent;
  height: 40px;
  padding: 10px 0;
  position: relative;
  z-index: 9;
}
.header_top.home7 .header_top_lang_widget_home6 button.btn.dropdown-toggle {
  font-size: 14px;
  font-family: 'Nunito';
  color: rgba(255, 255, 255, 0.502);
  height: auto;
  line-height: 1.2;
}
.header_top.home7
  .header_top_lang_widget_home6
  button.btn.dropdown-toggle::after {
  margin-top: 5px;
}
ul.sign_up_btn.home7 a {
  font-size: 14px;
  font-family: 'Nunito';
}
.light_white,
.light_white:hover {
  color: rgba(255, 255, 255, 0.5);
}
/* Header Top Search Field css home2 */
.header_search_widget .mailchimp_form .form-control {
  background-color: #f7f7f7;
  border: none;
  border-radius: 25px;
  height: 50px;
  padding-left: 30px;
  width: 350px;
}
.header_search_widget
  .mailchimp_form
  input[type='text'].form-control::-webkit-input-placeholder {
  font-size: 14px;
  font-family: 'Nunito';
  color: #484848;
  line-height: 1.2;
}
.header_search_widget .mailchimp_form button {
  background-color: transparent;
  border: none;
  border-radius: 50%;
  color: #484848;
  font-size: 22px;
  height: 45px;
  margin-left: -70px;
  line-height: 30px;
}
.header_search_widget .mailchimp_form button:active,
.header_search_widget .mailchimp_form button:focus {
  color: #484848 !important;
  border: none;
  box-shadow: none !important;
  outline: none;
}
.ht_left_widget,
.ht_right_widget {
  margin-top: 19px;
}
.ht_left_widget ul,
.ht_right_widget ul {
  margin-bottom: 0;
}
.ht_left_widget ul li {
  position: relative;
  vertical-align: middle;
}
.ht_right_widget ul li.list-inline-item:nth-child(2) {
  border-left: 1px solid #333333;
  border-right: 1px solid #333333;
  margin-left: 10px;
  padding-right: 15px;
  text-align: center;
}
.ht_left_widget ul li.list-inline-item:first-child {
  margin-right: 25px;
}
.ht_right_widget ul {
  margin-bottom: 0;
}
.ht_right_widget ul li a,
.ht_right_widget a.btn.cart_btn,
.header_top_lang_widget ul li .btn.cart_btn {
  color: #a4a4a4;
  font-size: 14px;
}
.ht_right_widget ul li a,
.home3_header_top_contact li a {
  font-family: 'Nunito';
}
header.header-nav.menu_style_home_three.navbar-scrolltofixed
  .ht_left_widget.home3
  .header_top_lang_widget {
  margin-top: 5px;
}
.ht_left_widget.home3 .cd-dropdown-trigger,
.ht_left_widget.home3 .no-touch .cd-dropdown-trigger.dropdown-is-active:hover,
.ht_left_widget.home3 .no-touch .cd-dropdown-trigger:hover {
  background-color: #ffffff !important;
  color: #484848;
}
.ht_left_widget.home3 .cd-dropdown-trigger {
  text-transform: capitalize;
}
.ht_left_widget.home3 .cd-dropdown-trigger::before,
.ht_left_widget.home3 .cd-dropdown-trigger::after {
  background-color: #484848;
}
.ht_right_widget a.btn.cart_btn span {
  background-color: #ffffff;
}
.header_top_lang_widget ul li a {
  color: #a4a4a4;
  font-size: 16px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.header_top_lang_widget ul li a.dropdown-item.active,
.header_top_lang_widget ul li a.dropdown-item:active {
  background-color: #000000;
}
.header_top_lang_widget button.btn.dropdown-toggle {
  background-color: transparent;
  border: none;
  bottom: -5px;
  color: #a4a4a4;
  float: right;
  margin-top: 10px;
  padding: 0 8px;
  width: 90px;
}
.header_top_lang_widget_home4 button.btn.dropdown-toggle,
.header_top_lang_widget_home6 button.btn.dropdown-toggle {
  background-color: transparent;
  border: none;
  color: #ffffff;
  line-height: inherit;
  width: 90px;
  font-size: 14px;
}
.header_top_lang_widget_home6
  .dropdown.bootstrap-select.show-tick.show
  .dropdown-menu {
  display: inline;
  margin-top: -17px;
}
.header_top_lang_widget_home6.home7
  .dropdown.bootstrap-select.show-tick.show
  .dropdown-menu {
  margin-top: 0;
}
.header_top_lang_widget_home6
  .dropdown.bootstrap-select.show-tick.show
  .dropdown-menu
  .dropdown-item.active,
.header_top_lang_widget_home6
  .dropdown.bootstrap-select.show-tick.show
  .dropdown-menu
  .dropdown-item:active {
  background-color: #192675;
}
.menu6_search.home6 .mk-search-trigger,
.menu7_search.home7 .mk-search-trigger {
  margin-left: -25px;
  top: 22px;
}
.menu6_search.home6 .mk-search-trigger {
  top: 17px;
}
.header_top_lang_widget_home6 button.btn.dropdown-toggle::after {
  vertical-align: middle;
}
.header_top_lang_widget_home6.home7 button.btn.dropdown-toggle::after {
  vertical-align: top;
}
.header_top_lang_widget_home4
  .bootstrap-select:not([class*='col-']):not([class*='form-control']):not(.input-group-btn),
.header_top_lang_widget_home6
  .bootstrap-select:not([class*='col-']):not([class*='form-control']):not(.input-group-btn) {
  width: auto;
}
.header_top_lang_widget_home4
  button
  .btn-light:not(:disabled):not(.disabled).active:focus,
.header_top_lang_widget_home4
  button
  .btn-light:not(:disabled):not(.disabled):active:focus,
.header_top_lang_widget_home4 button .show > .btn-light.dropdown-toggle:focus,
.header_top_lang_widget_home4 button.btn.dropdown-toggle:active,
.header_top_lang_widget_home4 button.btn.dropdown-toggle:focus {
  background-color: transparent !important;
  outline: none;
  box-shadow: none;
}
.header_top_lang_widget_home4 button.btn.dropdown-toggle:active,
.header_top_lang_widget_home4 button.btn.dropdown-toggle:focus,
.header_top_lang_widget_home6 button.btn.dropdown-toggle:active,
.header_top_lang_widget_home6 button.btn.dropdown-toggle:focus,
.header_top_lang_widget .btn-light:not(:disabled):not(.disabled).active:focus,
.header_top_lang_widget .btn-light:not(:disabled):not(.disabled):active:focus,
.header_top_lang_widget .show > .btn-light.dropdown-toggle:focus,
.header_top_lang_widget button.btn:active,
.header_top_lang_widget button.btn:focus,
.header_top_lang_widget button.btn.dropdown-toggle:focus,
.header_top_lang_widget button.btn.dropdown-toggle .filter-option:focus,
.header_top_lang_widget .dropdown.bootstrap-select.show-tick:focus {
  box-shadow: none !important;
  outline: none !important;
}
.header_top_lang_widget .dropdown.bootstrap-select.show-tick {
  width: auto;
}
.cd-dropdown-content .cd-secondary-dropdown a {
  font-family: 'Nunito', sans-serif;
}
li.has-children a {
  line-height: 51px;
}
/*== Menu Style For Home V1 & V2 ==*/
.menu-style-one nav.transparent,
.menu-style-one nav.transparent .wrap-core-nav-list,
.menu-style-one nav.transparent .nav-header {
  background-color: transparent;
}
nav .wrap-core-nav-list,
nav .nav-header {
  background: #fff;
}
nav .brand {
  display: block;
  position: relative;
  width: 170px;
  top: 20px;
}
nav .brand img {
  width: 100%;
}
nav .menu li a {
  display: block;
  padding: 20px 25px;
  text-decoration: none;
  color: #3a3a3a;
}
nav .menu li.active > a,
nav .menu li a:hover {
  color: #e74c3c;
}
nav button {
  background: transparent;
  border: none;
  outline: none;
}
header.header-nav {
  padding: 0 30px;
}
header.header-nav.dashbord_pages {
  padding: 0 5px 0 15px;
}
header.header-nav a.navbar_brand {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  margin-right: 17%;
  margin-top: 20px;
  position: relative;
}
.menu_style_home_one.stricky-fixed {
  background-color: #ffffff;
  -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  -o-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
}
header.header-nav a.navbar_brand .logo2 {
  display: none;
}
header.header-nav.menu_style_home_one ul.ace-responsive-menu li.add_listing {
  background-color: #ffffff;
  border-radius: 25px;
  height: 40px;
  position: relative;
  text-align: center;
  vertical-align: middle;
  width: 180px;
}
header.header-nav.menu_style_home_one
  ul.ace-responsive-menu
  li.add_listing.home5,
header.header-nav.menu_style_home_one
  ul.ace-responsive-menu
  li.add_listing.home2.style2,
header.header-nav.menu_style_home_one
  ul.ace-responsive-menu
  li.add_listing.home2.style10 {
  top: 10px;
}
header.header-nav.menu_style_home_one.home9
  ul.ace-responsive-menu
  li.add_listing {
  background-color: #5f1e8f;
}
header.header-nav.menu_style_home_one.home9
  ul.ace-responsive-menu
  li.add_listing
  a {
  color: #ffffff;
}
header.header-nav.menu_style_home_one ul.ace-responsive-menu li.add_listing a {
  font-family: 'Nunito';
  color: #ff5a5f;
  line-height: 1.2;
  /* top: -20px; */
}
header.header-nav.menu_style_home_one.stricky-fixed
  .ace-responsive-menu
  li.add_listing {
  background-color: #ff5a5f;
  box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
}
header.header-nav.menu_style_home_one.stricky-fixed
  .ace-responsive-menu
  li.add_listing
  a {
  color: #ffffff;
  line-height: 1.2;
}
header.header-nav.menu_style_home_one.stricky-fixed li.list_s:before {
  background-color: #e8eef2;
  opacity: 1;
}
header.header-nav.menu_style_home_one.dashbord_pages {
  background-image: -moz-linear-gradient(
    0deg,
    rgb(52, 82, 255) 0%,
    rgb(255, 16, 83) 100%
  );
  background-image: -webkit-linear-gradient(
    0deg,
    rgb(52, 82, 255) 0%,
    rgb(255, 16, 83) 100%
  );
  background-image: -ms-linear-gradient(
    0deg,
    rgb(52, 82, 255) 0%,
    rgb(255, 16, 83) 100%
  );
}
header.header-nav.menu_style_home_one.navbar-scrolltofixed.stricky.animated,
header.header-nav.home2.style_one.navbar-scrolltofixed.stricky.animated,
header.header-nav.menu_style_home_eight.navbar-scrolltofixed.stricky.animated {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-delay: 500ms;
  animation-delay: 500ms;
}
header.header-nav.menu_style_home_one a.navbar_brand {
  margin-top: 25px;
}
header.header-nav.menu_style_home_one.stricky-fixed a.navbar_brand .logo1,
header.header-nav.menu_style_home_four.stricky-fixed a.navbar_brand .logo1 {
  display: none;
}
header.header-nav.menu_style_home_one.stricky-fixed a.navbar_brand .logo2,
header.header-nav.menu_style_home_four.stricky-fixed a.navbar_brand .logo2 {
  display: inline-block;
}
header.header-nav.menu_style_home_one a.navbar_brand span,
header.header-nav.menu_style_home_three a.navbar_brand span,
header.header-nav.menu_style_home_five a.navbar_brand span {
  color: #ffffff;
  font-weight: bold;
  font-size: 22px;
  margin-top: 10px;
  padding-left: 15px;
  text-transform: capitalize;
  vertical-align: middle;
}
header.header-nav.menu_style_home_one.stricky-fixed a.navbar_brand:before {
  background-color: #dadada;
}
header.header-nav.menu_style_home_one.stricky-fixed
  .ace-responsive-menu
  > li
  > a.active
  span,
header.header-nav.menu_style_home_one.stricky-fixed
  .ace-responsive-menu
  li
  a.active
  span,
header.header-nav.menu_style_home_one.stricky-fixed .ace-responsive-menu li a,
header.header-nav.menu_style_home_one.stricky-fixed a.navbar_brand span,
header.header-nav.menu_style_home_one.stricky-fixed .sign_up_btn .btn,
header.header-nav.menu_style_home_one.stricky-fixed #search-button i {
  color: #484848;
}
header.header-nav.menu_style_home_one.dashbord_pages.stricky-fixed
  a.navbar_brand
  span,
header.header-nav.menu_style_home_one.dashbord_pages.stricky-fixed li a {
  color: #ffffff;
}
header.header-nav.menu_style_home_one .ace-responsive-menu li a {
  color: #ffffff;
}
header.header-nav.menu_style_home_one .ace-responsive-menu li ul.sub-menu,
header.header-nav.menu_style_home_three .ace-responsive-menu li ul.sub-menu,
header.header-nav.menu_style_home_four .ace-responsive-menu li ul.sub-menu,
header.header-nav.menu_style_home_five .ace-responsive-menu li ul.sub-menu,
header.header-nav.menu_style_home_six .ace-responsive-menu li ul.sub-menu,
header.header-nav.menu_style_home_seven .ace-responsive-menu li ul.sub-menu,
header.header-nav.menu_style_home_eight .ace-responsive-menu li ul.sub-menu {
  background-color: #ffffff;
  border-radius: 5px;
  padding: 15px 0;
  -webkit-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
  -moz-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
  -o-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
  box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
}
header.header-nav.menu_style_home_one
  .ace-responsive-menu
  > li:first-child
  ul.sub-menu,
header.header-nav.menu_style_home_one
  .ace-responsive-menu
  > li:nth-child(2)
  ul.sub-menu,
header.header-nav.home2.style_one
  .ace-responsive-menu
  > li:first-child
  ul.sub-menu,
header.header-nav.home2.style_one
  .ace-responsive-menu
  > li:nth-child(2)
  ul.sub-menu {
  margin-left: 0;
}
header.header-nav.menu_style_home_one
  .ace-responsive-menu
  > li:first-child
  ul.sub-menu:before,
header.header-nav.menu_style_home_one
  .ace-responsive-menu
  > li:nth-child(2)
  ul.sub-menu:before,
header.header-nav.home2.style_one
  .ace-responsive-menu
  > li:first-child
  ul.sub-menu:before,
header.header-nav.home2.style_one
  .ace-responsive-menu
  > li:nth-child(2)
  ul.sub-menu:before {
  left: 25px;
}
header.header-nav.menu_style_home_one
  .ace-responsive-menu
  li:nth-child(2)
  ul.sub-menu
  li
  ul.sub-menu,
header.header-nav.menu_style_home_one
  .ace-responsive-menu
  li:nth-child(3)
  ul.sub-menu
  li
  ul.sub-menu,
header.header-nav.menu_style_home_one
  .ace-responsive-menu
  li:nth-child(4)
  ul.sub-menu
  li
  ul.sub-menu,
header.header-nav.menu_style_home_one
  .ace-responsive-menu
  li:nth-child(5)
  ul.sub-menu
  li
  ul.sub-menu,
header.header-nav.home2.style_one
  .ace-responsive-menu
  li:nth-child(2)
  ul.sub-menu
  li
  ul.sub-menu,
header.header-nav.home2.style_one
  .ace-responsive-menu
  li:nth-child(3)
  ul.sub-menu
  li
  ul.sub-menu,
header.header-nav.home2.style_one
  .ace-responsive-menu
  li:nth-child(4)
  ul.sub-menu
  li
  ul.sub-menu,
header.header-nav.home2.style_one
  .ace-responsive-menu
  li:nth-child(5)
  ul.sub-menu
  li
  ul.sub-menu,
header.header-nav.menu_style_home_three
  .ace-responsive-menu
  li:nth-child(4)
  ul.sub-menu
  li
  ul.sub-menu,
header.header-nav.menu_style_home_three
  .ace-responsive-menu
  li:nth-child(5)
  ul.sub-menu
  li
  ul.sub-menu,
header.header-nav.menu_style_home_three
  .ace-responsive-menu
  li:nth-child(6)
  ul.sub-menu
  li
  ul.sub-menu,
header.header-nav.menu_style_home_four
  .ace-responsive-menu
  li:nth-child(4)
  ul.sub-menu
  li
  ul.sub-menu,
header.header-nav.menu_style_home_four
  .ace-responsive-menu
  li:nth-child(5)
  ul.sub-menu
  li
  ul.sub-menu,
header.header-nav.menu_style_home_four
  .ace-responsive-menu
  li:nth-child(6)
  ul.sub-menu
  li
  ul.sub-menu,
header.header-nav.menu_style_home_five
  .ace-responsive-menu
  li:nth-child(4)
  ul.sub-menu
  li
  ul.sub-menu,
header.header-nav.menu_style_home_five
  .ace-responsive-menu
  li:nth-child(5)
  ul.sub-menu
  li
  ul.sub-menu,
header.header-nav.menu_style_home_five
  .ace-responsive-menu
  li:nth-child(6)
  ul.sub-menu
  li
  ul.sub-menu,
header.header-nav.menu_style_home_six
  .ace-responsive-menu
  li:nth-child(4)
  ul.sub-menu
  li
  ul.sub-menu,
header.header-nav.menu_style_home_six
  .ace-responsive-menu
  li:nth-child(5)
  ul.sub-menu
  li
  ul.sub-menu,
header.header-nav.menu_style_home_six
  .ace-responsive-menu
  li:nth-child(6)
  ul.sub-menu
  li
  ul.sub-menu,
header.header-nav.menu_style_home_seven
  .ace-responsive-menu
  li:nth-child(4)
  ul.sub-menu
  li
  ul.sub-menu,
header.header-nav.menu_style_home_seven
  .ace-responsive-menu
  li:nth-child(5)
  ul.sub-menu
  li
  ul.sub-menu,
header.header-nav.menu_style_home_seven
  .ace-responsive-menu
  li:nth-child(6)
  ul.sub-menu
  li
  ul.sub-menu,
header.header-nav.menu_style_home_eight
  .ace-responsive-menu
  li:nth-child(4)
  ul.sub-menu
  li
  ul.sub-menu,
header.header-nav.menu_style_home_eight
  .ace-responsive-menu
  li:nth-child(5)
  ul.sub-menu
  li
  ul.sub-menu,
header.header-nav.menu_style_home_eight
  .ace-responsive-menu
  li:nth-child(6)
  ul.sub-menu
  li
  ul.sub-menu {
  left: 260px;
}
header.header-nav.menu_style_home_one .ace-responsive-menu > li > ul.sub-menu,
header.header-nav.home2.style_one .ace-responsive-menu > li > ul.sub-menu {
  margin-left: -180px;
}
header.header-nav.menu_style_home_one .ace-responsive-menu li ul.sub-menu,
header.header-nav.home2.style_one .ace-responsive-menu li ul.sub-menu {
  padding-left: 0 !important;
}
header.header-nav.menu_style_home_one
  .ace-responsive-menu
  li
  ul.sub-menu:before,
header.header-nav.home2.style_one .ace-responsive-menu li ul.sub-menu:before,
header.header-nav.menu_style_home_three
  .ace-responsive-menu
  li
  ul.sub-menu:before,
header.header-nav.menu_style_home_four
  .ace-responsive-menu
  li
  ul.sub-menu:before,
header.header-nav.menu_style_home_five
  .ace-responsive-menu
  li
  ul.sub-menu:before,
header.header-nav.menu_style_home_six
  .ace-responsive-menu
  li
  ul.sub-menu:before,
header.header-nav.menu_style_home_seven
  .ace-responsive-menu
  li
  ul.sub-menu:before,
header.header-nav.menu_style_home_eight
  .ace-responsive-menu
  li
  ul.sub-menu:before {
  background-color: #ffffff;
  content: '';
  height: 20px;
  right: 25px;
  position: absolute;
  top: -10px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 20px;
  -webkit-transition: all 0.1s ease 0s;
  -moz-transition: all 0.1s ease 0s;
  -o-transition: all 0.1s ease 0s;
  transition: all 0.1s ease 0s;
}
header.header-nav.home2.style_one .ace-responsive-menu li ul.sub-menu:before {
  background-color: #191919;
}
header.header-nav.menu_style_home_three
  .ace-responsive-menu
  li
  ul.sub-menu:before {
  background-color: #051925;
}
header.header-nav.menu_style_home_four
  .ace-responsive-menu
  li
  ul.sub-menu:before {
  background-color: #ffffff;
}
header.header-nav.menu_style_home_five
  .ace-responsive-menu
  li
  ul.sub-menu:before {
  left: 25px;
}
header.header-nav.menu_style_home_three
  .ace-responsive-menu
  li:nth-child(2)
  ul.sub-menu
  li
  ul.sub-menu,
header.header-nav.menu_style_home_three
  .ace-responsive-menu
  li:nth-child(3)
  ul.sub-menu
  li
  ul.sub-menu,
header.header-nav.menu_style_home_four
  .ace-responsive-menu
  li:nth-child(2)
  ul.sub-menu
  li
  ul.sub-menu,
header.header-nav.menu_style_home_four
  .ace-responsive-menu
  li:nth-child(3)
  ul.sub-menu
  li
  ul.sub-menu,
header.header-nav.menu_style_home_five
  .ace-responsive-menu
  li:nth-child(2)
  ul.sub-menu
  li
  ul.sub-menu,
header.header-nav.menu_style_home_five
  .ace-responsive-menu
  li:nth-child(3)
  ul.sub-menu
  li
  ul.sub-menu,
header.header-nav.menu_style_home_six
  .ace-responsive-menu
  li:nth-child(2)
  ul.sub-menu
  li
  ul.sub-menu,
header.header-nav.menu_style_home_six
  .ace-responsive-menu
  li:nth-child(3)
  ul.sub-menu
  li
  ul.sub-menu,
header.header-nav.menu_style_home_seven
  .ace-responsive-menu
  li:nth-child(2)
  ul.sub-menu
  li
  ul.sub-menu,
header.header-nav.menu_style_home_seven
  .ace-responsive-menu
  li:nth-child(3)
  ul.sub-menu
  li
  ul.sub-menu,
header.header-nav.menu_style_home_eight
  .ace-responsive-menu
  li:nth-child(2)
  ul.sub-menu
  li
  ul.sub-menu,
header.header-nav.menu_style_home_eight
  .ace-responsive-menu
  li:nth-child(3)
  ul.sub-menu
  li
  ul.sub-menu {
  margin-left: 0;
}
header.header-nav.menu_style_home_three
  .ace-responsive-menu
  li:nth-child(2)
  ul.sub-menu:before,
header.header-nav.menu_style_home_three
  .ace-responsive-menu
  li:nth-child(3)
  ul.sub-menu:before,
header.header-nav.menu_style_home_three
  .ace-responsive-menu
  li:nth-child(4)
  ul.sub-menu:before,
header.header-nav.menu_style_home_three
  .ace-responsive-menu
  li:nth-child(5)
  ul.sub-menu:before,
header.header-nav.menu_style_home_three
  .ace-responsive-menu
  li:nth-child(6)
  ul.sub-menu:before,
header.header-nav.menu_style_home_four
  .ace-responsive-menu
  li:nth-child(2)
  ul.sub-menu:before,
header.header-nav.menu_style_home_four
  .ace-responsive-menu
  li:nth-child(3)
  ul.sub-menu:before,
header.header-nav.menu_style_home_four
  .ace-responsive-menu
  li:nth-child(4)
  ul.sub-menu:before,
header.header-nav.menu_style_home_four
  .ace-responsive-menu
  li:nth-child(5)
  ul.sub-menu:before,
header.header-nav.menu_style_home_four
  .ace-responsive-menu
  li:nth-child(6)
  ul.sub-menu:before,
header.header-nav.menu_style_home_five
  .ace-responsive-menu
  li:nth-child(2)
  ul.sub-menu:before,
header.header-nav.menu_style_home_five
  .ace-responsive-menu
  li:nth-child(3)
  ul.sub-menu:before,
header.header-nav.menu_style_home_five
  .ace-responsive-menu
  li:nth-child(4)
  ul.sub-menu:before,
header.header-nav.menu_style_home_five
  .ace-responsive-menu
  li:nth-child(5)
  ul.sub-menu:before,
header.header-nav.menu_style_home_five
  .ace-responsive-menu
  li:nth-child(6)
  ul.sub-menu:before,
header.header-nav.menu_style_home_six
  .ace-responsive-menu
  li:nth-child(2)
  ul.sub-menu:before,
header.header-nav.menu_style_home_six
  .ace-responsive-menu
  li:nth-child(3)
  ul.sub-menu:before,
header.header-nav.menu_style_home_six
  .ace-responsive-menu
  li:nth-child(4)
  ul.sub-menu:before,
header.header-nav.menu_style_home_six
  .ace-responsive-menu
  li:nth-child(5)
  ul.sub-menu:before,
header.header-nav.menu_style_home_six
  .ace-responsive-menu
  li:nth-child(6)
  ul.sub-menu:before,
header.header-nav.menu_style_home_seven
  .ace-responsive-menu
  li:nth-child(2)
  ul.sub-menu:before,
header.header-nav.menu_style_home_seven
  .ace-responsive-menu
  li:nth-child(3)
  ul.sub-menu:before,
header.header-nav.menu_style_home_seven
  .ace-responsive-menu
  li:nth-child(4)
  ul.sub-menu:before,
header.header-nav.menu_style_home_seven
  .ace-responsive-menu
  li:nth-child(5)
  ul.sub-menu:before,
header.header-nav.menu_style_home_seven
  .ace-responsive-menu
  li:nth-child(6)
  ul.sub-menu:before,
header.header-nav.menu_style_home_eight
  .ace-responsive-menu
  li:nth-child(2)
  ul.sub-menu:before,
header.header-nav.menu_style_home_eight
  .ace-responsive-menu
  li:nth-child(3)
  ul.sub-menu:before,
header.header-nav.menu_style_home_eight
  .ace-responsive-menu
  li:nth-child(4)
  ul.sub-menu:before,
header.header-nav.menu_style_home_eight
  .ace-responsive-menu
  li:nth-child(5)
  ul.sub-menu:before,
header.header-nav.menu_style_home_eight
  .ace-responsive-menu
  li:nth-child(6)
  ul.sub-menu:before {
  left: 25px;
}
header.header-nav.menu_style_home_three
  .ace-responsive-menu
  li:nth-child(2)
  ul.sub-menu
  li
  ul.sub-menu,
header.header-nav.menu_style_home_three
  .ace-responsive-menu
  li:nth-child(3)
  ul.sub-menu
  li
  ul.sub-menu,
header.header-nav.menu_style_home_four
  .ace-responsive-menu
  li:nth-child(2)
  ul.sub-menu
  li
  ul.sub-menu,
header.header-nav.menu_style_home_four
  .ace-responsive-menu
  li:nth-child(3)
  ul.sub-menu
  li
  ul.sub-menu,
header.header-nav.menu_style_home_five
  .ace-responsive-menu
  li:nth-child(2)
  ul.sub-menu
  li
  ul.sub-menu,
header.header-nav.menu_style_home_five
  .ace-responsive-menu
  li:nth-child(3)
  ul.sub-menu
  li
  ul.sub-menu,
header.header-nav.menu_style_home_six
  .ace-responsive-menu
  li:nth-child(2)
  ul.sub-menu
  li
  ul.sub-menu,
header.header-nav.menu_style_home_six
  .ace-responsive-menu
  li:nth-child(3)
  ul.sub-menu
  li
  ul.sub-menu,
header.header-nav.menu_style_home_seven
  .ace-responsive-menu
  li:nth-child(2)
  ul.sub-menu
  li
  ul.sub-menu,
header.header-nav.menu_style_home_seven
  .ace-responsive-menu
  li:nth-child(3)
  ul.sub-menu
  li
  ul.sub-menu,
header.header-nav.menu_style_home_eight
  .ace-responsive-menu
  li:nth-child(2)
  ul.sub-menu
  li
  ul.sub-menu,
header.header-nav.menu_style_home_eight
  .ace-responsive-menu
  li:nth-child(3)
  ul.sub-menu
  li
  ul.sub-menu {
  margin-left: -445px;
}
header.header-nav.menu_style_home_three
  .ace-responsive-menu
  li
  ul.sub-menu
  li
  ul.sub-menu
  li:hover
  a,
header.header-nav.menu_style_home_four
  .ace-responsive-menu
  li
  ul.sub-menu
  li
  ul.sub-menu
  li:hover
  a,
header.header-nav.menu_style_home_five
  .ace-responsive-menu
  li
  ul.sub-menu
  li
  ul.sub-menu
  li:hover
  a,
header.header-nav.menu_style_home_six
  .ace-responsive-menu
  li
  ul.sub-menu
  li
  ul.sub-menu
  li:hover
  a,
header.header-nav.menu_style_home_seven
  .ace-responsive-menu
  li
  ul.sub-menu
  li
  ul.sub-menu
  li:hover
  a,
header.header-nav.menu_style_home_eight
  .ace-responsive-menu
  li
  ul.sub-menu
  li
  ul.sub-menu
  li:hover
  a {
  padding-left: 30px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
header.header-nav.menu_style_home_one .ace-responsive-menu li ul.sub-menu li,
header.header-nav.home2.style_one .ace-responsive-menu li ul.sub-menu li,
header.header-nav.menu_style_home_three .ace-responsive-menu li ul.sub-menu li,
header.header-nav.menu_style_home_four .ace-responsive-menu li ul.sub-menu li,
header.header-nav.menu_style_home_five .ace-responsive-menu li ul.sub-menu li,
header.header-nav.menu_style_home_six .ace-responsive-menu li ul.sub-menu li,
header.header-nav.menu_style_home_seven .ace-responsive-menu li ul.sub-menu li,
header.header-nav.menu_style_home_eight .ace-responsive-menu li ul.sub-menu li {
  background-color: #ffffff;
  border-radius: 5px;
  padding: 0 30px;
  width: 300px;
}
header.header-nav.menu_style_home_four .ace-responsive-menu li ul.sub-menu li,
header.header-nav.menu_style_home_five .ace-responsive-menu li ul.sub-menu li,
header.header-nav.menu_style_home_six .ace-responsive-menu li ul.sub-menu li,
header.header-nav.menu_style_home_seven .ace-responsive-menu li ul.sub-menu li,
header.header-nav.menu_style_home_eight .ace-responsive-menu li ul.sub-menu li {
  background-color: transparent;
}
header.header-nav.home2.style_one .ace-responsive-menu li ul.sub-menu li,
header.header-nav.home2.style_one .ace-responsive-menu li ul.sub-menu {
  background-color: #191919;
}
header.header-nav.menu_style_home_three .ace-responsive-menu li ul.sub-menu li,
header.header-nav.menu_style_home_three .ace-responsive-menu li ul.sub-menu {
  background-color: #051925;
}
header.header-nav.menu_style_home_one
  .ace-responsive-menu
  li
  ul.sub-menu
  li
  ul.sub-menu:before,
header.header-nav.menu_style_home_three
  .ace-responsive-menu
  li
  ul.sub-menu
  li
  ul.sub-menu:before,
header.header-nav.menu_style_home_four
  .ace-responsive-menu
  li
  ul.sub-menu
  li
  ul.sub-menu:before,
header.header-nav.menu_style_home_five
  .ace-responsive-menu
  li
  ul.sub-menu
  li
  ul.sub-menu:before,
header.header-nav.menu_style_home_six
  .ace-responsive-menu
  li
  ul.sub-menu
  li
  ul.sub-menu:before,
header.header-nav.menu_style_home_seven
  .ace-responsive-menu
  li
  ul.sub-menu
  li
  ul.sub-menu:before,
header.header-nav.menu_style_home_eight
  .ace-responsive-menu
  li
  ul.sub-menu
  li
  ul.sub-menu:before {
  display: none;
}
header.header-nav.menu_style_home_one .ace-responsive-menu li ul.sub-menu li a,
header.header-nav.menu_style_home_four .ace-responsive-menu li ul.sub-menu li a,
header.header-nav.menu_style_home_five .ace-responsive-menu li ul.sub-menu li a,
header.header-nav.menu_style_home_six .ace-responsive-menu li ul.sub-menu li a,
header.header-nav.menu_style_home_seven
  .ace-responsive-menu
  li
  ul.sub-menu
  li
  a,
header.header-nav.menu_style_home_eight
  .ace-responsive-menu
  li
  ul.sub-menu
  li
  a {
  border-bottom: 1px solid #dedede;
  color: #484848;
  font-size: 16px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
header.header-nav.menu_style_home_one
  .ace-responsive-menu
  li
  ul.sub-menu
  li:last-child
  a,
header.header-nav.menu_style_home_four
  .ace-responsive-menu
  li
  ul.sub-menu
  li:last-child
  a,
header.header-nav.menu_style_home_five
  .ace-responsive-menu
  li
  ul.sub-menu
  li:last-child
  a,
header.header-nav.menu_style_home_six
  .ace-responsive-menu
  li
  ul.sub-menu
  li:last-child
  a,
header.header-nav.menu_style_home_seven
  .ace-responsive-menu
  li
  ul.sub-menu
  li:last-child
  a,
header.header-nav.menu_style_home_eight
  .ace-responsive-menu
  li
  ul.sub-menu
  li:last-child
  a {
  border-bottom: none;
}
header.header-nav.menu_style_home_one
  .ace-responsive-menu
  li
  ul.sub-menu
  li
  a:hover,
header.header-nav.menu_style_home_one
  .ace-responsive-menu
  li
  ul.sub-menu
  li
  ul.sub-menu {
  color: #c75533;
  padding-left: 20px;
  box-shadow: none !important;
}
header.header-nav.menu_style_home_one ul.sign_up_btn {
  margin-right: 35px;
}
header.header-nav.menu_style_home_one .ace-responsive-menu > li > a,
header.header-nav.menu_style_home_three .ace-responsive-menu > li > a,
header.header-nav.menu_style_home_four .ace-responsive-menu > li > a,
header.header-nav.menu_style_home_five .ace-responsive-menu > li > a,
header.header-nav.menu_style_home_six .ace-responsive-menu > li > a,
header.header-nav.menu_style_home_seven .ace-responsive-menu > li > a,
header.header-nav.menu_style_home_eight .ace-responsive-menu > li > a {
  font-family: 'Nunito', sans-serif;
  padding: 20px 16px 25px 10px;
}
header.header-nav.menu_style_home_one.style2.home10
  .ace-responsive-menu
  > li
  > a {
  padding: 35px 10px 30px 20px;
}
header.header-nav.menu_style_home_three .ace-responsive-menu > li > a,
header.header-nav.menu_style_home_four .ace-responsive-menu > li > a,
header.header-nav.menu_style_home_five .ace-responsive-menu > li > a,
header.header-nav.menu_style_home_six .ace-responsive-menu > li > a,
header.header-nav.menu_style_home_seven .ace-responsive-menu > li > a,
header.header-nav.menu_style_home_eight .ace-responsive-menu > li > a {
  padding: 27px 35px 30px 10px;
}
header.header-nav.menu_style_home_five .ace-responsive-menu > li > a {
  padding: 27px 20px 30px 10px;
}
header.header-nav.menu_style_home_one.style2 {
  background-color: #ffffff;
  -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  -o-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
}
header.header-nav.menu_style_home_one.style2.home3 {
  background-color: #ffffff;
  box-shadow: none;
}
header.header-nav.menu_style_home_one.style2 a.navbar_brand {
  margin-top: 20px;
}
header.header-nav.menu_style_home_one.style2 a.navbar_brand span {
  color: #484848;
  font-family: 'Nunito';
  font-size: 18px !important;
  font-weight: bold;
  line-height: 1.2;
}
header.header-nav.menu_style_home_one.style2.home10,
header.header-nav.menu_style_home_one.style2.home10
  .ace-responsive-menu
  li
  ul.sub-menu,
header.header-nav.menu_style_home_one.style2.home10
  .ace-responsive-menu
  li
  ul.sub-menu:before,
header.header-nav.menu_style_home_one.style2.home10
  .ace-responsive-menu
  > li:first-child
  ul.sub-menu,
header.header-nav.menu_style_home_one.style2.home10
  .ace-responsive-menu
  li
  ul.sub-menu
  li,
header.header-nav.menu_style_home_one.style2.home10
  .ace-responsive-menu
  > li:first-child
  ul.sub-menu:before,
header.header-nav.menu_style_home_one.style2.home10
  .ace-responsive-menu
  > li:first-child
  ul.sub-menu,
header.header-nav.menu_style_home_one.style2.home10
  .ace-responsive-menu
  > li:nth-child(2)
  ul.sub-menu,
header.header-nav.menu_style_home_one.style2.home10
  .ace-responsive-menu
  > li:nth-child(2)
  ul.sub-menu:before {
  background-color: #1d293e;
}
header.header-nav.menu_style_home_one.style2.home10 a.navbar_brand span,
header.header-nav.menu_style_home_one.style2.home10 .ace-responsive-menu li a,
header.header-nav.menu_style_home_one.style2.home10
  .ace-responsive-menu
  li
  ul.sub-menu
  li
  a,
header.header-nav.menu_style_home_one.style2.home10
  .ace-responsive-menu
  > li
  > a {
  color: #ffffff;
}
header.header-nav.menu_style_home_one.style2 .ace-responsive-menu li a {
  font-size: 16px;
  font-family: 'Nunito';
  color: #484848;
  line-height: 1.2;
}
header.header-nav.menu_style_home_one.style2 ul.ace-responsive-menu li.list_s {
  position: relative;
}
header.header-nav.menu_style_home_one.style2
  ul.ace-responsive-menu
  li.list_s:before {
  background-color: #e8eef2;
  content: '';
  height: 38px;
  left: -5px;
  position: absolute;
  right: 0;
  top: 13px;
  width: 1px;
}
header.header-nav.menu_style_home_one.style2.home10
  ul.ace-responsive-menu
  li.list_s:before {
  display: none;
}
header.header-nav.menu_style_home_one.style2
  ul.ace-responsive-menu
  li.add_listing {
  background-color: #ffc107 !important;
  -webkit-box-shadow: 0px 1px 4px 0px rgb(255 235 90 / 30%);
  -moz-box-shadow: 0px 1px 4px 0px rgba(255 235 90 / 30%);
  -o-box-shadow: 0px 1px 4px 0px rgba(255 235 90 / 30%);
  box-shadow: 0px 1px 4px 0px rgb(255 204 90 / 30%);
}
header.header-nav.menu_style_home_one.style2
  ul.ace-responsive-menu
  li.add_listing.home2 {
  background-color: #ffc107;
  -webkit-box-shadow: 0px 1px 4px 0px rgb(255 235 90 / 30%);
  -moz-box-shadow: 0px 1px 4px 0px rgba(255 235 90 / 30%);
  -o-box-shadow: 0px 1px 4px 0px rgba(255 235 90 / 30%);
  box-shadow: 0px 1px 4px 0px rgb(255 204 90 / 30%);
}

li.add_listing.home2 {
  background-color: #ffc107;
  -webkit-box-shadow: 0px 1px 4px 0px rgb(255 235 90 / 30%);
  -moz-box-shadow: 0px 1px 4px 0px rgba(255 235 90 / 30%);
  -o-box-shadow: 0px 1px 4px 0px rgba(255 235 90 / 30%);
  box-shadow: 0px 1px 4px 0px rgb(255 204 90 / 30%);
}
header.header-nav.menu_style_home_one.style3
  ul.ace-responsive-menu
  li.add_listing.home6 {
  background-color: #ff5a5f;
}
header.header-nav.menu_style_home_one.style2
  ul.ace-responsive-menu
  li.add_listing
  a {
  font-size: 16px;
  font-family: 'Nunito';
  color: #000;
  line-height: 1;
  padding: 12px 17px;
}
header.header-nav.menu_style_home_one.style2.home10
  ul.ace-responsive-menu
  li.add_listing.home2 {
  border-radius: 8px;
  background-color: rgb(53, 71, 101);
  box-shadow: none;
}
header.header-nav.menu_style_home_one.style2.listing_single_page5 {
  position: absolute;
  width: 100%;
  z-index: 9;
}
header.header-nav.menu_style_home_one.home4 {
  border-bottom: 1px solid rgba(255, 255, 255, 0.102);
}
.menu_style_home_one.style2.menu-fixed {
  position: fixed;
  width: 100%;
  z-index: 9;
}
/*== Menu Style For Home V2 ==*/
header.header-nav.home2.style_one {
  display: -webkit-box;
  background-color: #141414;
  position: relative;
  z-index: 1 !important;
}
header.header-nav.home2.style_one .ace-responsive-menu > li > a {
  padding: 27px 40px 27px 5px;
}
header.header-nav.home2.style_one ul.sign_up_btn li {
  position: relative;
}
header.header-nav.home2.style_one ul.sign_up_btn li:first-child:before {
  background-color: #434343;
  content: '';
  height: 30px;
  right: -5px;
  position: absolute;
  top: 5px;
  width: 1px;
}
header.header-nav.home2.style_one ul.sign_up_btn li a {
  color: #a4a4a4;
  font-family: 'Nunito';
  font-size: 14px;
  line-height: 1.2;
}
header.header-nav.home2.style_one ul.sign_up_btn li a:hover {
  color: #ffffff;
}
header.header-nav.home2.style_one .ace-responsive-menu li ul.sub-menu {
  border-radius: 5px;
  padding: 15px 0;
  -webkit-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
  -moz-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
  -o-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
  box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
}
header.header-nav.home2.style_one .ace-responsive-menu li ul.sub-menu li a {
  border-bottom: 1px solid #dedede;
  color: #a4a4a4;
  font-size: 16px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
header.header-nav.home2.style_one
  .ace-responsive-menu
  li
  ul.sub-menu
  li
  a:hover,
header.header-nav.home2.style_one
  .ace-responsive-menu
  li
  ul.sub-menu
  li
  ul.sub-menu {
  color: #ffffff;
  padding-left: 20px;
  box-shadow: none !important;
}
header.header-nav.home2.style_one
  .ace-responsive-menu
  li
  ul.sub-menu
  li
  ul.sub-menu:before {
  display: none;
}
header.header-nav.home2.style_one .ace-responsive-menu li ul.sub-menu li a {
  border-bottom: 1px solid #555;
  color: #a4a4a4;
  font-size: 16px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
header.header-nav.home2.style_one
  .ace-responsive-menu
  li
  ul.sub-menu
  li:last-child
  a,
header.header-nav.home2.style_one
  .ace-responsive-menu
  li
  ul.sub-menu
  li:last-child
  a {
  border-bottom: none;
}
header.header-nav.home2.style_one
  .ace-responsive-menu
  li
  ul.sub-menu
  li
  a:hover,
header.header-nav.home2.style_one
  .ace-responsive-menu
  li
  ul.sub-menu
  li
  ul.sub-menu {
  box-shadow: none !important;
  color: #ffffff;
  padding-left: 20px;
}
header.header-nav.menu_style_home_three a.navbar_brand {
  margin-top: 10px;
}
header.header-nav.menu_style_home_three .ht_left_widget.home3 ul li:last-child {
  margin-top: 5px;
}
header.header-nav.menu_style_home_three
  .header_search_widget
  .mailchimp_form
  .form-control {
  margin-top: 10px;
}
header.header-nav.menu_style_home_three
  .header_search_widget
  .mailchimp_form
  button {
  background-color: transparent;
  margin-top: 10px;
}
/*== Menu Style For Home V3 ==*/
header.header-nav.menu_style_home_three {
  background-color: #051925;
  padding: 0;
}
header.header-nav.menu_style_home_three a.navbar_brand:before {
  background-color: #6077dd;
  height: 80px;
}
header.header-nav.menu_style_home_three .ace-responsive-menu > li {
  float: right;
}
header.header-nav.menu_style_home_three .ace-responsive-menu li ul.sub-menu {
  background-color: #051925;
}
header.header-nav.menu_style_home_three
  .ace-responsive-menu
  li
  ul.sub-menu:nth-child(3) {
  left: 0;
  margin-left: 0;
}
header.header-nav.menu_style_home_three .ace-responsive-menu li a,
header.header-nav.menu_style_home_three
  .ace-responsive-menu
  li
  ul.sub-menu
  li
  a {
  color: #ffffff;
  font-family: 'Nunito', sans-serif;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
header.header-nav.menu_style_home_three
  .ace-responsive-menu
  li
  ul.sub-menu
  li
  a {
  border-bottom: 1px solid #2e2e2e;
}
header.header-nav.menu_style_home_three
  .ace-responsive-menu
  li
  ul.sub-menu
  li:last-child
  a {
  border-bottom: none;
}
header.header-nav.menu_style_home_three .ace-responsive-menu > li.last a {
  padding-right: 5px;
}
header.header-nav.menu_style_home_three
  .ace-responsive-menu
  > li
  .sub-menu
  li:hover
  a,
header.header-nav.menu_style_home_four
  .ace-responsive-menu
  > li
  .sub-menu
  li:hover
  a,
header.header-nav.menu_style_home_five
  .ace-responsive-menu
  > li
  .sub-menu
  li:hover
  a,
header.header-nav.menu_style_home_six
  .ace-responsive-menu
  > li
  .sub-menu
  li:hover
  a {
  padding-left: 20px;
}
header.header-nav.menu_style_home_three .container-fluid {
  padding: 0 70px;
}
header.header-nav.menu_style_home_three a.navbar_brand:before {
  display: none;
}
.sign_up_btn .btn {
  box-shadow: none;
  border-radius: 4px;
  color: #ffffff;
  height: auto;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.sign_up_btn.home3 .btn {
  font-family: 'Nunito';
  font-size: 14px;
  line-height: 1.2;
}
.sign_up_btn.home3 .btn i {
  font-size: 18px;
  margin-right: 5px;
}
.sign_up_btn li:first-child .btn span {
  padding-left: 10px;
}
.sign_up_btn a.btn.cart_btn,
.sign_up_btn a.btn.search_btn,
.cart_btnes.home4 a.btn.cart_btn {
  font-size: 24px;
  line-height: normal;
}
.cart_btnes.home4 a.btn.cart_btn {
  color: #ffffff;
  margin-top: 15px;
}
.sign_up_btn a.btn.cart_btn span,
.cart_btnes.home4 a.btn.cart_btn span,
.cart_btn.home5 a.btn.cart_btn span {
  background-color: #192675;
  margin: 0 auto 0 -10px;
}
.sign_up_btn a.btn.cart_btn span sup,
.cart_btnes.home4 a.btn.cart_btn span sup {
  color: #ffffff;
}
.sign_up_btn a.btn.cart_btn span,
.ht_right_widget .cart_btn a.btn span,
.cart_btnes.home4 a.btn.cart_btn span,
.cart_btnes.home4 a.btn.cart_btn span,
.cart_btn.home5 a.btn.cart_btn span {
  border-radius: 50%;
  height: 17px;
  line-height: 15px;
  position: absolute;
  text-align: center;
  width: 17px;
}
.sign_up_btn.home3 a.btn.cart_btn span {
  line-height: 17px;
}
.ht_right_widget .cart_btn a.btn span {
  line-height: 20px;
}
.cart_btn.home5 a.btn.cart_btn span {
  color: #ffffff;
  margin-top: -5px;
  z-index: 9;
}
.cart_btn.home5 a.btn.cart_btn span sup {
  font-size: 10px;
  line-height: 1.7rem;
  margin-left: 0;
}
.ht_right_widget .cart_btn a.btn span {
  margin: -5px auto 0 -5px;
}
.sign_up_btn a.btn.cart_btn span sup,
.cart_btnes.home4 a.btn.cart_btn span sup {
  font-size: 10px;
  margin-left: -10px;
  vertical-align: baseline;
}
.cart_btnes.home4 a.btn.cart_btn span sup {
  margin-left: 0;
}
.ht_right_widget .cart_btn a.btn span sup {
  color: #141414;
  font-size: 10px;
  margin-left: 0;
  vertical-align: sub;
}
header.header-nav.menu_style_home_one .sign_up_btn li.list_s {
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  margin-right: 0 !important;
}
header.header-nav.menu_style_home_one
  .sign_up_btn
  li.list_s
  a.btn.flaticon-user {
  line-height: 30px;
}
header.header-nav.menu_style_home_one.stricky-fixed .sign_up_btn li.list_s {
  border-right: 1px solid #dadada;
}
.sign_up_btn li.list_s:last-child {
  border: none;
}
.sign_up_btn li .btn .cart_btn {
  background-color: #192675;
  font-size: 10px;
  height: 17px;
  width: 17px;
  text-align: center;
}
/*== Menu Style For Home V4 ==*/
header.header-nav.menu_style_home_four {
  background: transparent;
  border-bottom: none;
  padding: 10px 0;
}
header.header-nav.menu_style_home_four a.navbar_brand {
  margin-top: 10px;
}
header.header-nav.menu_style_home_four.stricky-fixed
  .cart_btnes.home4
  a.btn.cart_btn {
  color: #484848;
}
header.header-nav.menu_style_home_four a.navbar_brand span {
  color: #ffffff;
  font-weight: bold;
  font-size: 22px;
  margin-top: 10px;
  padding-left: 15px;
  text-transform: uppercase;
  vertical-align: middle;
}
header.header-nav.menu_style_home_four.stricky-fixed {
  background-color: #ffffff;
  border-bottom: none;
  padding: 10px 0;
}
header.header-nav.menu_style_home_four.stricky-fixed a.navbar_brand span {
  color: #484848;
}
header.header-nav.menu_style_home_four a.navbar_brand:before {
  background-color: transparent !important;
}
header.header-nav.menu_style_home_four .ace-responsive-menu > li > a {
  color: #ffffff;
}
header.header-nav.menu_style_home_four .ace-responsive-menu li ul.sub-menu,
header.header-nav.menu_style_home_four
  .ace-responsive-menu
  li
  ul.sub-menu
  li
  a {
  color: #484848;
  background-color: #ffffff;
}
header.header-nav.menu_style_home_four .ace-responsive-menu > li {
  float: right;
}
header.header-nav.menu_style_home_four .ace-responsive-menu li a {
  color: #ffffff;
  font-family: 'Nunito', sans-serif;
}
header.header-nav.menu_style_home_four .sign_up_btn .btn {
  background-color: transparent;
  border: 1px solid #ffffff;
  border-radius: 20px;
  color: #ffffff;
  height: 45px;
  line-height: 30px;
  margin-top: 10px;
}
header.header-nav.menu_style_home_four .sign_up_btn .btn:hover,
.sign_up_btn .btn:hover {
  color: #ffffff;
}
header.header-nav.menu_style_home_four.stricky-fixed .ace-responsive-menu li a {
  color: #484848;
}
/*== Menu Style For Home V5 ==*/
header.header-nav.menu_style_home_five {
  background-color: #ffffff;
  height: 98px;
  padding: 10px 0;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}
header.header-nav.menu_style_home_five a.navbar_brand {
  margin-top: 8px;
}
header.header-nav.menu_style_home_five a.navbar_brand:before {
  background-color: #6077dd;
  height: 80px;
}
header.header-nav.menu_style_home_five
  .ace-responsive-menu
  li
  ul.cart.sub-menu {
  background-color: transparent;
  box-shadow: none;
  padding: 0;
}
header.header-nav.menu_style_home_five .ace-responsive-menu li ul.sub-menu {
  background-color: #ffffff;
}
header.header-nav.menu_style_home_five
  .ace-responsive-menu
  li
  ul.cart.sub-menu {
  display: block;
}
header.header-nav.menu_style_home_five
  .ace-responsive-menu
  li
  ul.cart.sub-menu
  li {
  width: auto;
}
header.header-nav.menu_style_home_five
  .ace-responsive-menu
  li
  ul.cart.sub-menu
  .dropdown_content {
  height: auto;
  right: 10px;
  top: 110%;
}
header.header-nav.menu_style_home_five
  .ace-responsive-menu
  li
  ul.cart.sub-menu
  .dropdown_content
  .list_content
  .cart_btns {
  background-color: #ff5a5f;
  border: 1px solid #ff5a5f;
  float: left;
  line-height: 20px;
}
header.header-nav.menu_style_home_five
  .ace-responsive-menu
  li
  ul.cart.sub-menu
  .dropdown_content
  .list_content
  .checkout_btns {
  background-color: #ff1053;
  border: 1px solid #ff1053;
  float: right;
  line-height: 0;
  line-height: 20px;
}
header.header-nav.menu_style_home_five
  .ace-responsive-menu
  li
  ul.cart
  a.btn.cart_btn {
  font-size: 22px;
  padding-top: 21px;
}
header.header-nav.menu_style_home_five
  .ace-responsive-menu
  li
  ul.cart
  a.btn.cart_btn
  span {
  background-color: #192675;
  border-radius: 50%;
  height: 17px;
  line-height: 17px;
  margin: -2px auto 0 -10px;
  position: absolute;
  text-align: center;
  width: 17px;
}
header.header-nav.menu_style_home_five
  .ace-responsive-menu
  li
  ul.cart
  a.btn.cart_btn
  span:before {
  display: none;
}
header.header-nav.menu_style_home_five
  .ace-responsive-menu
  li
  ul.cart
  a.btn.cart_btn
  span
  sup {
  color: #ffffff;
  font-size: 10px;
  z-index: 9;
}
header.header-nav.menu_style_home_five a.navbar_brand span,
header.header-nav.menu_style_home_five .ace-responsive-menu li a {
  color: #484848;
}
header.header-nav.menu_style_home_five .ace-responsive-menu li a {
  padding-top: 27px;
  font-family: 'Nunito', sans-serif;
  text-transform: capitalize;
}
header.header-nav.menu_style_home_five
  .ace-responsive-menu
  li
  ul.sub-menu
  li
  a {
  padding: 12px 20px 12px 15px;
}
header.header-nav.menu_style_home_five .ace-responsive-menu > li {
  float: right;
}
header.header-nav.menu_style_home_five .ace-responsive-menu > li:first-child {
  padding-left: 10px;
}
header.header-nav.menu_style_home_five .container-fluid {
  padding: 0 70px;
}
header.header-nav.menu_style_home_five a.navbar_brand:before {
  display: none;
}
header.header-nav.menu_style_home_five
  .header_search_widget
  .mailchimp_form
  button {
  background-color: transparent;
  border: none;
  border-radius: 50%;
  color: #484848;
  font-size: 24px;
  height: 45px;
  margin-left: -65px;
}
header.header-nav.menu_style_home_five
  .header_search_widget
  .mailchimp_form
  button:active,
header.header-nav.menu_style_home_five
  .header_search_widget
  .mailchimp_form
  button:focus {
  color: #000000 !important;
  border: none;
  box-shadow: none !important;
  outline: none;
}
header.header-nav.menu_style_home_five .sign_up_btn {
  margin-bottom: 0;
}
header.header-nav.menu_style_home_five .sign_up_btn .btn {
  color: #484848;
  margin-top: 10px;
}
.ht_left_widget.home5 .cd-dropdown-trigger::before,
.ht_left_widget.home5 .cd-dropdown-trigger::after {
  background-color: #ffffff;
}
header.header-nav.menu_style_home_five
  .header_search_widget
  .mailchimp_form
  .form-control {
  background-color: #edeff7;
  border: 1px solid #edeff7;
  border-radius: 25px;
  color: #555555;
  font-size: 14px;
  height: 45px;
  margin-top: 5px;
  padding-left: 30px;
  width: 450px;
}
header.header-nav.menu_style_home_five
  .header_search_widget
  .mailchimp_form
  .form-control:focus {
  border: 1px solid #edeff7;
  box-shadow: none;
  outline: none;
}
header.header-nav.menu_style_home_five
  .ht_left_widget.home5
  .cd-dropdown-trigger {
  background-color: #484848;
  color: #ffffff;
}
.home5_shop_reg_widget li:first-child {
  margin-right: 0;
}
/*== Menu Style For Home V4 ==*/
header.header-nav.menu_style_home_six {
  display: -webkit-box;
  background: #ffffff;
  height: 80px;
  padding: 10px 0;
  position: relative;
  z-index: 1 !important;
}
header.header-nav.menu_style_home_six a.navbar_brand {
  margin-top: 10px;
}
header.header-nav.menu_style_home_six.stricky-fixed
  .cart_btnes.home4
  a.btn.cart_btn {
  color: #484848;
}
header.header-nav.menu_style_home_six a.navbar_brand span {
  color: #484848;
  font-weight: bold;
  font-size: 22px;
  margin-top: 10px;
  padding-left: 15px;
  text-transform: uppercase;
  vertical-align: middle;
}
header.header-nav.menu_style_home_six.stricky-fixed {
  background-color: #ffffff;
  border-bottom: none;
  padding: 10px 0;
}
header.header-nav.menu_style_home_six.stricky-fixed a.navbar_brand span {
  color: #484848;
}
header.header-nav.menu_style_home_six a.navbar_brand:before {
  background-color: transparent !important;
}
header.header-nav.menu_style_home_six .ace-responsive-menu > li > a {
  color: #ffffff;
}
header.header-nav.menu_style_home_six .ace-responsive-menu li ul.sub-menu,
header.header-nav.menu_style_home_six .ace-responsive-menu li ul.sub-menu li a {
  color: #484848;
  background-color: #ffffff;
}
header.header-nav.menu_style_home_six .ace-responsive-menu > li {
  float: right;
}
header.header-nav.menu_style_home_six .ace-responsive-menu li a {
  color: #ffffff;
  font-family: 'Nunito', sans-serif;
}
header.header-nav.menu_style_home_six .sign_up_btn .btn {
  background-color: transparent;
  border: 1px solid #ffffff;
  border-radius: 20px;
  color: #ffffff;
  height: 45px;
  line-height: 30px;
  margin-top: 10px;
}
header.header-nav.menu_style_home_six .sign_up_btn .btn:hover,
.sign_up_btn .btn:hover {
  color: #ffffff;
}
header.header-nav.menu_style_home_six .ace-responsive-menu > li.last {
  margin-right: 40px;
}
header.header-nav.menu_style_home_six.stricky-fixed .ace-responsive-menu li a {
  color: #484848;
}
.menu6_search.home6 #search-button i {
  color: #484848;
}
.home7_search.home7 #search-button i {
  color: #ffffff;
}
header.header-nav.menu_style_home_four.home8 .container-fluid {
  padding: 0 70px;
}
header.header-nav.menu_style_home_four.home8.stricky-fixed a.navbar_brand span {
  background: linear-gradient(to right, #835bb4 0%, #d7cf96 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
/*== Menu Style For Home V6 ==*/
header.header-nav.menu_style_home_six {
  background-color: #ffffff;
  border-bottom: none;
  padding: 0;
}
header.header-nav.menu_style_home_six .ace-responsive-menu > li > a {
  padding: 25px 25px 25px 5px;
}
header.header-nav.menu_style_home_six .ace-responsive-menu li ul.sub-menu {
  background-color: #ffffff;
}
header.header-nav.menu_style_home_six .ace-responsive-menu li a {
  color: #251730;
}
header.header-nav.menu_style_home_six a.navbar_brand:before {
  background-color: transparent;
}
header.header-nav.menu_style_home_six .sign_up_btn .btn {
  border: none;
  color: #ffffff;
  margin-top: 10px;
}
/*== Menu Style For Home V7 ==*/
header.header-nav.menu_style_home_seven {
  display: -webkit-box;
  background-color: transparent;
  height: 80px;
  padding: 10px 0;
  position: relative;
  z-index: 1 !important;
}
header.header-nav.menu_style_home_seven.navbar-scrolltofixed.main-menu.scroll-to-fixed-fixed {
  background-color: #ffffff;
}
header.header-nav.menu_style_home_seven a.navbar_brand {
  margin-top: 0;
  position: relative;
}
header.header-nav.menu_style_home_seven a.navbar_brand:before {
  display: none;
}
header.header-nav.menu_style_home_seven a.navbar_brand .logo1.home7 {
  border-radius: 0 0 5px 5px;
  margin-top: -50px;
}
header.header-nav.menu_style_home_seven.stricky-fixed
  .cart_btnes.home4
  a.btn.cart_btn {
  color: #484848;
}
header.header-nav.menu_style_home_seven .ace-responsive-menu > li > a {
  color: #ffffff;
}
header.header-nav.menu_style_home_seven .ace-responsive-menu li ul.sub-menu,
header.header-nav.menu_style_home_seven
  .ace-responsive-menu
  li
  ul.sub-menu
  li
  a {
  color: #484848;
  background-color: #ffffff;
}
header.header-nav.menu_style_home_seven .ace-responsive-menu > li {
  float: right;
}
header.header-nav.menu_style_home_seven .ace-responsive-menu li a {
  color: #ffffff;
  font-family: 'Nunito', sans-serif;
  padding: 18px 20px 18px 15px;
}
header.header-nav.menu_style_home_seven
  .ace-responsive-menu
  li
  ul.sub-menu
  li
  a:hover {
  padding-left: 20px;
}
header.header-nav.menu_style_home_seven .sign_up_btn .btn {
  background-color: transparent;
  border: 1px solid #ffffff;
  border-radius: 20px;
  color: #ffffff;
  height: 45px;
  line-height: 30px;
  margin-top: 10px;
}
header.header-nav.menu_style_home_seven .sign_up_btn .btn:hover,
.sign_up_btn .btn:hover {
  color: #ffffff;
}
header.header-nav.menu_style_home_seven .ace-responsive-menu > li.last {
  margin-right: 40px;
}
/*== Mobile Menu Css ==*/
.stylehome1 .mm-navbar {
  color: #484848;
  height: 51px;
  line-height: 2;
}
.stylehome1 .mm-panel {
  background-color: #f7f7f7;
}
.stylehome1 .mm-panel ul.mm-listview li:first-child.mm-listitem {
  margin-top: 30px;
}
.stylehome1 .mm-panel ul.mm-listview li.mm-listitem.cl_btn {
  border-bottom: none;
  margin: 50px auto;
  max-width: 90%;
}
.stylehome1 .mm-panel ul.mm-listview li.mm-listitem.cl_btn a.btn {
  color: #ffffff;
  font-size: 16px;
}
.stylehome1 a.mm-listitem__text,
.stylehome1 a.mm-btn.mm-btn_next.mm-listitem__btn.mm-listitem__text {
  color: #484848;
}
.stylehome1 a.mm-listitem__text:hover,
.stylehome1 a.mm-btn.mm-btn_next.mm-listitem__btn.mm-listitem__text:hover {
  background-color: #e5e5e5;
  color: #484848;
}
.stylehome1.home3 a.mm-listitem__text,
.stylehome1.home3 a.mm-btn.mm-btn_next.mm-listitem__btn.mm-listitem__text {
  color: #cecff9;
}
.stylehome1.home3 a.mm-listitem__text:hover,
.stylehome1.home3
  a.mm-btn.mm-btn_next.mm-listitem__btn.mm-listitem__text:hover {
  background-color: #e5e5e5;
  color: #484848;
}
.stylehome1.home4 .mm-navbar,
.stylehome1.home4 .mm-panel {
  background-color: #201b72;
}
.stylehome1.home4 a.mm-listitem__text,
.stylehome1.home4 a.mm-btn.mm-btn_next.mm-listitem__btn.mm-listitem__text {
  color: #cecff9;
}
.stylehome1.home4 a.mm-listitem__text:hover,
.stylehome1.home4
  a.mm-btn.mm-btn_next.mm-listitem__btn.mm-listitem__text:hover {
  background-color: #151156;
  color: #ffffff;
}
.stylehome1.home6 .mm-navbar {
  background-color: #ffffff;
}
.stylehome1.home6 .mm-panel {
  background-color: #ebf9f4;
}
.stylehome1.home6 a.mm-listitem__text,
.stylehome1.home6 a.mm-btn.mm-btn_next.mm-listitem__btn.mm-listitem__text {
  color: #252834;
}
.stylehome1.home6 a.mm-listitem__text:hover,
.stylehome1.home6
  a.mm-btn.mm-btn_next.mm-listitem__btn.mm-listitem__text:hover {
  background-color: #43d4b6;
  color: #ffffff;
}
.stylehome1.home6 .mm-navbar {
  color: #252834;
}
.stylehome1.home6 .mm-btn_next::after {
  border-color: #252834;
}
.stylehome1 li.mm-listitem:hover,
.stylehome1 li.mm-listitem:active,
.stylehome1 li.mm-listitem:focus {
  color: #ffffff;
}
.stylehome1 .mm-navbar,
.stylehome1 .mm-btn_prev:before {
  background-color: #ffffff;
}
/*== Home Css ==*/
.home_content {
  padding: 380px 0 400px;
}
.home_content .home-text p {
  font-weight: 600;
}
.home_content.home4 {
  padding: 0;
}
.home_content.home5 {
  padding: 330px 0 240px;
  position: relative;
}
.home_content.home5.style2 {
  padding-top: 290px;
}
.home-text.home5 .discounts_para {
  margin-bottom: 100px;
}
.home_content.home6 {
  padding: 350px 0 100px;
  position: relative;
}
.home_content.home7 {
  padding: 350px 0 90px;
  position: relative;
}
.home_content .home-text.home5 h4 {
  font-family: 'Nunito';
  color: #ffffff;
  font-style: italic;
  line-height: 1.2;
}
.icon_home5 {
  background-color: rgba(255, 255, 255, 0.149);
  border-radius: 3px;
  cursor: pointer;
  height: 120px;
  padding: 10px 0;
  position: relative;
  text-align: center;
  width: 130px;
}
.icon_home5 span {
  color: #ffffff;
  font-size: 40px;
}
.icon_home5 p {
  font-size: 16px;
  font-family: 'Nunito';
  color: #ffffff;
  line-height: 1.2;
  margin-bottom: 0;
}
.home1_adsrchfrm {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 30px 20px;
  position: relative;
  z-index: 9;
}
.home1_adsrchfrm:before {
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 8px;
  bottom: -10px;
  content: '';
  left: -10px;
  position: absolute;
  right: -10px;
  top: -10px;
  z-index: -1;
}
.home_adv_srch_opt {
  margin-top: 145px;
  position: relative;
}
.home_adv_srch_opt .nav-pills {
  border-bottom: none;
  margin: 0 auto;
  text-align: center;
  width: 375px;
  z-index: 9;
  position: relative;
}
.home_adv_srch_opt.home3 {
  margin-top: -17px;
  -webkit-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
  -moz-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
  -o-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
  box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
}
.home-four .home_adv_srch_opt.home4 {
  margin-top: 110px;
}
.home-four4 .home_adv_srch_opt.home4 {
  margin-top: 40px;
}
.home_adv_srch_opt.home3 .nav-pills,
.home_adv_srch_opt.home6 .nav-pills {
  margin: 0;
}
.home_adv_srch_opt .nav-pills li.nav-item a.nav-link:first-child {
  margin-right: 15px;
}
.home_adv_srch_opt .nav-pills li.nav-item a.nav-link {
  background-color: #ffffff;
  border-radius: 8px;
  color: #1b2032;
  font-size: 16px;
  height: 50px;
  width: 100px;
  text-align: center;
  line-height: 35px;
}
.home_adv_srch_opt .nav-pills li.nav-item a.nav-link.active {
  background-color: #ff5a5f;
  border-color: #ff5a5f;
  color: #ffffff;
}
.home_adv_srch_opt .nav-pills li.nav-item a.nav-link.active:before {
  background-color: #ff5a5f;
  content: '';
  height: 20px;
  margin-left: 5px;
  position: absolute;
  top: 35px;
  width: 20px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.home_adv_srch_opt.home4 .nav-pills li.nav-item a.nav-link {
  border-radius: 25px;
  cursor: pointer;
}
.home_adv_srch_opt.home4 .home1_adsrchfrm {
  border-radius: 10px;
  height: 80px;
  padding: 10px 10px;
}
.home_adv_srch_opt.home4 .home1_adsrchfrm:before {
  border-radius: 15px;
}
.home_adv_srch_opt.home6 {
  margin-top: 100px;
}
.home_adv_srch_opt.home6 .nav-pills li.nav-item a.nav-link.active {
  background-color: #3e4c66;
  border-color: #3e4c66;
  color: #ffffff;
}
.home_adv_srch_opt.home6 .nav-pills li.nav-item a.nav-link.active:before {
  background-color: #3e4c66;
}
.home_adv_srch_opt .tab-content {
  margin-top: 25px;
}
.home_adv_srch_opt.home7,
.home_adv_srch_opt.home9 {
  margin-top: 25px;
}
.home1-advnc-search ul li {
  display: inline-block;
  line-height: 3;
  margin-right: 15px !important;
  vertical-align: text-top;
  width: 165px;
}
.home1-advnc-search.home2 {
  padding-left: 8px;
}
.home1-advnc-search.home2 ul li {
  margin-right: 15px !important;
}
.home1-advnc-search.home9 ul li {
  line-height: normal;
  vertical-align: middle;
}
.home1-advnc-search.home10 .small_dropdown2 {
  margin-right: 10px;
}
.home1-advnc-search.home10 .mega-dropdown .dropbtn i {
  padding-left: 10px;
}
.home1-advnc-search.home5 ul li {
  margin-right: 5px !important;
}
.home1-advnc-search.home10 ul li {
  margin-right: 18px !important;
}
.home1-advnc-search.home10 ul li:last-child {
  margin-right: 0 !important;
}
.home1-advnc-search.home10 ul li .search_option_button button {
  width: 180px;
}
.home1-advnc-search.home4 ul li .search_option_button .btn {
  padding: 8px 15px;
  font-weight: 700;
}
.home1-advnc-search ul li:first-child .form-control {
  background-color: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  font-size: 14px;
  color: #484848;
  line-height: 1.2;
  height: 50px;
  width: 215px;
}
.home1-advnc-search.home2.style2 ul li:first-child .form-control::placeholder,
.home1-advnc-search.home2.style2
  ul
  li:nth-child(3)
  .form-group
  .form-control::placeholder,
.home1-advnc-search
  .search_option_two.home2.style2
  .dropdown.bootstrap-select
  .dropdown-toggle
  .filter-option,
.small_dropdown2.home2.style2 .dd_btn,
.mega-dropdown.home2.style2 .dropbtn {
  color: #006c70;
}
.home1-advnc-search.home4 ul li:first-child .form-control {
  border-radius: 25px;
  padding-left: 20px;
}
.home1-advnc-search.home4 ul li:nth-child(3) .form-group label {
  border-radius: 0 25px 25px 0;
  right: 5px;
}
.home1-advnc-search
  ul
  li:first-child
  input[type='text'].form-control::-webkit-input-placeholder {
  background-color: #ffffff;
  color: #484848;
  font-size: 14px;
  font-family: 'Nunito';
  line-height: 1.2;
}
.home1-advnc-search.home3
  ul
  li:first-child
  input[type='text'].form-control::-webkit-input-placeholder,
.home1-advnc-search.home3
  ul
  li:nth-child(3)
  .form-group
  input[type='text'].form-control::-webkit-input-placeholder,
.home1-advnc-search.home4
  ul
  li:first-child
  input[type='text'].form-control::-webkit-input-placeholder,
.home1-advnc-search.home4
  ul
  li:nth-child(3)
  .form-group
  input[type='text'].form-control::-webkit-input-placeholder,
.home1-advnc-search.home6
  ul
  li:first-child
  input[type='text'].form-control::-webkit-input-placeholder,
.home1-advnc-search.home6
  ul
  li:nth-child(3)
  .form-group
  input[type='text'].form-control::-webkit-input-placeholder,
.home1-advnc-search.home9
  ul
  li:first-child
  input[type='text'].form-control::-webkit-input-placeholder,
.home5_advanced_search_form
  .form-group
  input[type='text'].form-control::-webkit-input-placeholder {
  color: #006c70;
  font-size: 14px;
  font-family: 'Nunito';
  line-height: 1.2;
}
.home1-advnc-search.home7 {
  background-color: rgba(255, 255, 255, 0.102);
  border-radius: 8px;
  height: 91px;
  line-height: 90px;
}
.home1-advnc-search.home9 {
  background-color: #ffffff;
  border-radius: 8px;
  height: 91px;
  line-height: 90px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  width: 632px;
}
.h1ads_1st_list {
  display: flex;
  align-items: center;
}
.home1-advnc-search.home9 ul.h1ads_1st_list {
  padding: 0 0 0 15px;
}
.home1-advnc-search.home9 ul li:first-child .form-control {
  border: none;
  width: 400px;
}
.header_search_widget
  .mailchimp_form
  input[type='text'].form-control::-webkit-input-placeholder,
.home1-advnc-search ul li .form-group {
  margin-bottom: 0;
}
.home1-advnc-search ul li:nth-child(2) {
  margin-right: 0;
}
.home1-advnc-search ul li:nth-child(3) .form-group {
  position: relative;
}
.home1-advnc-search ul li:nth-child(3) .form-group .form-control {
  border: 1px solid #ebebeb;
  border-radius: 8px;
  background-color: #ffffff;
  font-size: 14px;
  height: 50px;
  width: 190px;
}
.home1-advnc-search ul li:nth-child(3) .form-group label {
  background-color: #ffffff;
  font-size: 16px;
  height: 40px;
  line-height: 40px;
  position: absolute;
  right: 1px;
  text-align: center;
  top: 5px;
  width: 40px;
}
.home1-advnc-search ul li:nth-child(3) .form-group label span {
  font-size: 18px;
}
.home1-advnc-search ul li:last-child {
  margin-right: 0 !important;
}
.home1-advnc-search ul li .search_option_button button {
  border-radius: 8px;
  background-color: #ff5a5f;
  -webkit-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  -moz-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  -o-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  font-size: 16px;
  color: #ffffff;
  font-weight: bold;
  line-height: 1.2;
  height: 40px;
  width: 80px;
  margin-right: 10px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.home1-advnc-search ul li .search_option_button button:hover {
  background-color: #ffffff;
  border: 2px solid #ffc107;
  color: #ffc107;
}
.home1-advnc-search.home3 ul li .search_option_button button {
  background-color: #3e4c66;
}
.home1-advnc-search.home6 ul li .search_option_button button {
  background-color: #3e4c66;
  border: 1px solid #3e4c66;
  -webkit-box-shadow: 0px 1px 4px 0px rgba(29, 41, 62, 0.3);
  -moz-box-shadow: 0px 1px 4px 0px rgba(29, 41, 62, 0.3);
  -o-box-shadow: 0px 1px 4px 0px rgba(29, 41, 62, 0.3);
  box-shadow: 0px 1px 4px 0px rgba(29, 41, 62, 0.3);
}
.home1-advnc-search.home6 ul li .search_option_button.style2 button {
  background-color: #ffc107;
  border: 1px solid #ffc107;
}
.home1-advnc-search.home3 ul li .search_option_button.style2 button:hover {
  background-color: #ffffff;
  border: 1px solid #ffc107;
  color: #ffc107;
}
.search_option_button.home5 button {
  background-image: -moz-linear-gradient(
    0deg,
    rgb(250, 124, 65) 0%,
    rgb(255, 101, 101) 100%
  );
  background-image: -webkit-linear-gradient(
    0deg,
    rgb(250, 124, 65) 0%,
    rgb(255, 101, 101) 100%
  );
  background-image: -ms-linear-gradient(
    0deg,
    rgb(250, 124, 65) 0%,
    rgb(255, 101, 101) 100%
  );
  border-radius: 8px;
  border: 1px solid rgb(255, 101, 101);
  color: #ffffff;
  height: 50px;
}
.home1-advnc-search.home7 ul li .search_option_button button {
  background-image: -moz-linear-gradient(
    120deg,
    rgb(253, 85, 89) 0%,
    rgb(255, 51, 125) 100%
  );
  background-image: -webkit-linear-gradient(
    120deg,
    rgb(253, 85, 89) 0%,
    rgb(255, 51, 125) 100%
  );
  background-image: -ms-linear-gradient(
    120deg,
    rgb(253, 85, 89) 0%,
    rgb(255, 51, 125) 100%
  );
}
.home1-advnc-search ul.apeartment_area_list {
  text-align: left;
}
.home1-advnc-search ul.apeartment_area_list li,
.home1-advnc-search ul.apeartment_area_list li:nth-child(2) {
  margin-right: 15px;
}
input.amount2,
input.amount4 {
  text-align: right;
}
input.amount,
input.amount2,
input.amount3,
input.amount4 {
  background-color: transparent;
  border: none;
  box-shadow: none;
  color: #006c70;
  font-size: 16px;
  font-family: 'Nunito';
  font-weight: bold;
  line-height: 1.2;
  width: 48%;
}
input.amount::placeholder,
input.amount2::placeholder,
input.amount3::placeholder,
input.amount4::placeholder {
  color: #006c70;
}
input.amount:hover,
input.amount:active,
input.amount:focus,
input.amount2:hover,
input.amount2:active,
input.amount2:focus,
input.amount3:hover,
input.amount3:active,
input.amount3:focus,
input.amount4:hover,
input.amount4:active,
input.amount4:focus {
  border: none;
  outline: none;
}
.home1-advnc-search .dropdown-menu.pricing .dropdown-item:hover {
  background-color: transparent;
}
.home1-advnc-search
  .slider-range.ui-slider.ui-corner-all.ui-slider-horizontal.ui-widget.ui-widget-content,
.sasw_list
  .slider-range.ui-slider.ui-corner-all.ui-slider-horizontal.ui-widget.ui-widget-content,
.noUi-target {
  background-color: #f7f7f7;
  border: none;
  border-radius: 3.5px;
  height: 7px;
  margin-top: 25px;
  width: 240px;
}
.home1-advnc-search.home5
  .slider-range.ui-slider.ui-corner-all.ui-slider-horizontal.ui-widget.ui-widget-content {
  width: 100%;
}
.home1-advnc-search .ui-slider-range.ui-corner-all.ui-widget-header,
.sasw_list .ui-slider-range.ui-corner-all.ui-widget-header {
  background-color: #ff5a5f;
}
.home1-advnc-search span.ui-slider-handle.ui-corner-all.ui-state-default,
.sasw_list span.ui-slider-handle.ui-corner-all.ui-state-default {
  background-color: #ffffff;
  border-radius: 50%;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(19, 19, 28, 0.2);
  -moz-box-shadow: 0px 0px 5px 0px rgba(19, 19, 28, 0.2);
  -o-box-shadow: 0px 0px 5px 0px rgba(19, 19, 28, 0.2);
  box-shadow: 0px 0px 5px 0px rgba(19, 19, 28, 0.2);
  height: 25px;
  top: -10px;
  width: 25px;
}
.home1-advnc-search span.ui-slider-handle.ui-corner-all.ui-state-default:before,
.sasw_list span.ui-slider-handle.ui-corner-all.ui-state-default:before {
  background-color: #ff5a5f;
  border-radius: 10px;
  content: '';
  height: 11px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  top: 6px;
  width: 11px;
}
.home1-advnc-search span.ui-slider-handle.ui-corner-all.ui-state-default:hover,
.sasw_list span.ui-slider-handle.ui-corner-all.ui-state-default:hover {
  background-color: #ffffff;
  cursor: pointer;
}
.home1-advnc-search span.ui-slider-handle.ui-corner-all.ui-state-default:active,
.home1-advnc-search span.ui-slider-handle.ui-corner-all.ui-state-default:focus,
.sasw_list span.ui-slider-handle.ui-corner-all.ui-state-default:active,
.sasw_list span.ui-slider-handle.ui-corner-all.ui-state-default:focus {
  background-color: #ffffff;
  border: none;
  outline: none;
}
.home1-advnc-search
  .search_option_two
  .dropdown.bootstrap-select
  > .dropdown-toggle {
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid #ebebeb;
  height: 50px;
  line-height: 35px;
  width: 150px;
}
.home1-advnc-search.home5
  .search_option_two
  .dropdown.bootstrap-select
  > .dropdown-toggle {
  width: 100%;
}
.home1-advnc-search.home4
  .search_option_two
  .dropdown.bootstrap-select
  > .dropdown-toggle,
.home1-advnc-search.home4 ul li:nth-child(3) .form-group .form-control,
.small_dropdown2.home4 .dd_btn,
.home1-advnc-search.home4 ul li .search_option_button button {
  border-radius: 10px;
  padding-left: 10px;
}
.home1-advnc-search.home3
  .search_option_two
  .dropdown.bootstrap-select
  > .dropdown-toggle
  .filter-option-inner-inner,
.home1-advnc-search.home4
  .search_option_two
  .dropdown.bootstrap-select
  > .dropdown-toggle
  .filter-option-inner-inner,
.home1-advnc-search.home6
  .search_option_two
  .dropdown.bootstrap-select
  > .dropdown-toggle
  .filter-option-inner-inner,
.home5_advanced_search_form
  .dropdown.bootstrap-select
  > .dropdown-toggle
  .filter-option-inner-inner,
.home5_advanced_search_form
  .dropdown.bootstrap-select
  > .dropdown-toggle
  .filter-option-inner-inner,
.small_dropdown2.home3 .dd_btn,
.mega-dropdown.home3 .dropbtn,
.small_dropdown2.home4 .dd_btn,
.small_dropdown2.home5 .dd_btn,
.small_dropdown2.home6 .dd_btn,
.mega-dropdown.home4 .dropbtn,
.mega-dropdown.home5 .dropbtn,
.mega-dropdown.home6 .dropbtn {
  color: #006c70;
}
.home1-advnc-search .dropdown.bootstrap-select > .dropdown-toggle {
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid #ebebeb;
  height: 50px;
  line-height: 40px;
  font-size: 14px;
  width: 190px;
}
.home1-advnc-search
  .search_option_two
  .dropdown.bootstrap-select
  .dropdown-toggle
  .filter-option {
  font-size: 14px;
  color: #484848;
}
.home1-advnc-search
  .search_option_two
  .dropdown.bootstrap-select
  > .dropdown-toggle:focus,
.home1-advnc-search
  .search_option_two
  .dropdown.bootstrap-select
  > .dropdown-toggle:active {
  box-shadow: none;
  outline: none;
}
.home1-advnc-search.home7
  .search_option_two
  .dropdown.bootstrap-select
  > .dropdown-toggle {
  width: 194px;
  height: 50px;
}
.home1-advnc-search.home7 ul li {
  display: inline-block;
  vertical-align: middle;
}
.home1-advnc-search.home7 ul li:nth-child(2) {
  vertical-align: middle;
  width: 500px;
}
.home1-advnc-search.home7 ul.dropdown-menu.inner.show li {
  display: block;
}
.home1-advnc-search
  .search_option_two
  .dropdown.bootstrap-select
  .dropdown-menu.show,
.home1-advnc-search .dropdown.bootstrap-select .dropdown-menu.show {
  min-width: 250px !important;
}
.home1-advnc-search.home5
  .search_option_two
  .dropdown.bootstrap-select
  .dropdown-menu.show,
.home1-advnc-search.home5 .dropdown.bootstrap-select .dropdown-menu.show {
  min-width: 100% !important;
}
.home1-advnc-search .search_option_two ul.dropdown-menu.inner.show li,
.home1-advnc-search ul.dropdown-menu.inner.show li {
  font-size: 14px;
  margin-right: 0 !important;
}
.home1-advnc-search .search_option_two .dropdown-menu {
  top: 50px !important;
}
form.home5_advanced_search_form .search_option_two .dropdown-menu {
  top: 10px !important;
}
.home1-advnc-search .search_option_two.home2 .dropdown-menu {
  top: 35px !important;
}
.home1-advnc-search .search_option_two .dropdown-menu button.btn {
  font-size: 14px;
  font-family: 'Nunito';
  color: #484848;
  line-height: 1.2;
}
.home1-advnc-search .search_option_two .dropdown-menu.show:before {
  background-color: #ffffff;
  content: '';
  height: 20px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  top: -5px;
  width: 20px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.home1-advnc-search .bootstrap-select .bs-ok-default:after {
  color: #ff5a5f;
}
.home1-advnc-search .bootstrap-select .dropdown-menu li a {
  color: #484848;
  font-size: 14px;
  line-height: 2;
  width: 250px;
}
.home1-advnc-search.home5 .form-group.df label {
  font-size: 24px;
  line-height: 2;
  position: absolute;
  right: 10%;
}
.home1-advnc-search.home5 {
  padding: 0 15px;
}
.home5_advanced_search_form {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 30px;
  position: relative;
  z-index: 9;
}
.home5_advanced_search_form:before {
  background-color: rgba(255, 255, 255, 0.149);
  border-radius: 8px;
  content: '';
  bottom: -10px;
  left: -10px;
  position: absolute;
  right: -10px;
  top: -10px;
  z-index: -1;
}
.home5_advanced_search_form .form-group input.form-control {
  border: 1px solid #ebebeb;
  border-radius: 8px;
}
form.home5_advanced_search_form .form-group input.form-control::placeholder,
form.home5_advanced_search_form
  .form-group
  input.form-control:-ms-input-placeholder,
form.home5_advanced_search_form
  .form-group
  input.form-control::-ms-input-placeholder {
  color: #006c70;
}
.small_dropdown .dd_btn {
  background-color: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  color: #484848;
  font-size: 14px;
  font-family: 'Nunito';
  height: 50px;
  line-height: 2.5;
  width: 180px;
  text-align: left;
}
.small_dropdown2 .dd_btn {
  background-color: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  color: #484848;
  height: 50px;
  line-height: 2.5;
  margin: 0;
  outline: none;
  text-align: left;
  width: 150px;
}
.small_dropdown .dd_btn label,
.small_dropdown2 .dd_btn label {
  float: right;
}
.small_dropdown2.home5 {
  position: relative;
}
.home1-advnc-search.home5 .bootstrap-select .dropdown-menu li a,
.small_dropdown2.home5 .dd_btn {
  width: 100%;
}
.small_dropdown .dd_btn:hover {
  cursor: pointer;
}
.small_dropdown:hover .dd_content {
  display: block;
}
.dd_content {
  background-color: #ffffff;
  border-radius: 8px;
  -webkit-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
  -moz-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
  -o-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
  box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
  display: none;
  height: 240px !important;
  left: -40% !important;
  margin: 0 auto;
  padding: 40px 30px;
  position: absolute;
  right: 0 !important;
  top: 140px;
  width: 250px;
}
.dd_content2 {
  background-color: #ffffff;
  border-radius: 8px;
  -webkit-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
  -moz-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
  -o-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
  box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
  display: none;
  height: 147px;
  left: auto;
  margin: 0 auto;
  max-width: 304px;
  padding: 25px 30px;
  position: absolute;
  right: 26%;
  top: 140px;
}
.dd_content:before,
.dd_content2:before {
  background-color: #ffffff;
  content: '';
  height: 20px;
  left: auto;
  margin: 0 auto;
  position: absolute;
  right: 25%;
  top: -5px;
  width: 20px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.dd_content:after {
  background-color: transparent;
  content: '';
  height: 42px;
  left: auto;
  margin: 0 auto;
  right: 0 !important;
  top: -25% !important;
  width: 200px !important;
  position: absolute;
}
.dd_content2:after {
  background-color: transparent;
  content: '';
  height: 90px;
  left: auto;
  margin: 0 auto;
  position: absolute;
  right: 15%;
  top: -29%;
  width: 100px;
  z-index: -1;
}
.dd_content2.home2 {
  top: 110px;
  z-index: 9;
}
.dd_content2.home5 {
  max-width: 100%;
  right: 0;
  top: 70px;
  z-index: 9;
}
/* MegaDropDown Content Style/Css Code */
.mega-dropdown .dropbtn {
  color: #484848;
  border: none;
  font-size: 14px;
  margin-left: 30px;
  outline: none;
  position: relative;
}
.mega-dropdown .dropbtn:hover {
  cursor: pointer;
}
.dropdown-content {
  background-color: #ffffff;
  border-radius: 8px;
  -webkit-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
  -moz-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
  -o-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
  box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
  display: none;
  min-height: 254px;
  left: 0;
  position: absolute;
  top: 140px;
  width: 100%;
  z-index: 9;
  padding: 15px;
}
.dropdown-content:before {
  background-color: #ffffff;
  content: '';
  height: 20px;
  left: auto;
  margin: 0 auto;
  position: absolute;
  right: 30%;
  top: -5px;
  width: 20px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.dropdown-content.home2 {
  top: 110px;
}
.mega-dropdown.home5 .dropdown-content {
  top: 0;
}
.mega-dropdown.home5 .dropdown-content:before {
  display: none;
}
.mega-dropdown.home5 span.dropbtn {
  border: 1px solid #ebebeb;
  border-radius: 8px;
  display: block;
  height: 50px;
  line-height: 50px;
  padding-left: 15px;
  width: 100%;
}
.mega-dropdown.home5 span.dropbtn i {
  float: right;
  margin-right: 10px;
}
/* Home Two Featured Row */
.featured_row {
  border-radius: 8px;
  background-color: #ffffff;
  -webkit-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
  -moz-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
  -o-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
  box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
  height: 90px;
  margin-bottom: 60px;
  margin-left: 0;
  margin-right: 0;
  margin-top: -100px;
}
.home1-advnc-search .dropdown-menu.show {
  -webkit-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
  -moz-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
  -o-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
  box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
  border-radius: 8px;
  overflow: visible !important;
}
.home1-advnc-search .bootstrap-select .bs-ok-default:after {
  color: #ff5a5f;
}
.home10-mainslider {
  margin-top: 0;
}
.home10-mainslider .banner-style-one .slide:before {
  background-color: rgba(29, 41, 62, 0.6);
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.home10-mainslider .banner-style-one .banner-title {
  font-size: 45px;
  font-family: 'Nunito';
  color: #ffffff;
  font-weight: bold;
  line-height: 1.2;
  text-transform: capitalize;
}
.home10-mainslider .home-content {
  padding-top: 15px;
}
.home_iconbox_container {
  left: 0;
  margin: 0 auto;
  position: sticky;
  height: 450px;
  right: 0;
  margin-top: -25%;
  z-index: 1;
}
.animated {
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-delay: 500ms;
  animation-delay: 500ms;
}
.animate-out {
  -webkit-animation-delay: 0ms;
  animation-delay: 0ms;
}
.search_box_home4 form.form-inline.mailchimp_form {
  display: inline-block;
  margin-top: 25px;
  position: relative;
}
.search_box_home4 .header_search_widget .mailchimp_form .form-control {
  border-radius: 30px;
  border: none;
  height: 60px;
  padding-left: 30px;
  width: 450px;
}
.search_box_home4 .header_search_widget .mailchimp_form button {
  position: absolute;
  right: 20px;
  top: 7px;
}
.home-text h1,
.home-text h2,
.home-text h3 {
  color: #ffffff;
  margin-top: 0;
}
.home-text.home6 h2 {
  font-family: 'Nunito';
  color: #484848;
  font-weight: bold;
  line-height: 1.2;
}
.home-text.home6 p {
  font-family: 'Nunito';
  color: #484848;
  line-height: 1.2;
}
.mouse_scroll {
  position: absolute;
  right: -350px;
  top: -130px;
}
.mouse_scroll.home5,
.mouse_scroll.home8 {
  z-index: 9;
}
.mouse_scroll .icon {
  cursor: pointer;
  display: inline-block;
  padding-right: 14px;
  text-align: right;
}
.mouse_scroll .icon h4 {
  color: #ffffff;
  line-height: 1.2;
  margin: 0;
}
.mouse_scroll .icon p {
  color: rgba(255, 255, 255, 0.42);
  line-height: 1.2;
  margin: 0;
}
.mouse_scroll .thumb {
  float: right;
}
/* ==== Home 2 Main Sliders === */
.main-banner-wrapper {
  position: relative;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .main-banner-wrapper {
    overflow: hidden;
  }
}

.main-banner-wrapper .carousel-btn-block,
.listing_single_property_slider .owl-controls .owl-nav {
  cursor: pointer;
  position: absolute;
  top: 45%;
  width: 100%;
  z-index: 9;
}
.main-banner-wrapper .carousel-btn-block .carousel-btn,
.listing_single_property_slider .owl-controls .owl-nav .owl-prev,
.listing_single_property_slider .owl-controls .owl-nav .owl-next {
  border: 1px solid #ffffff;
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  height: 82px;
  width: 42px;
  line-height: 82px;
  text-align: center;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  vertical-align: middle;
  z-index: 9;
}
.listing_single_property_slider .owl-controls .owl-nav {
  top: 41%;
}
.listing_single_property_slider .owl-controls .owl-nav .owl-prev {
  background-color: #ffffff;
  color: #006c70;
  left: 30px;
  position: absolute;
}
.listing_single_property_slider .owl-controls .owl-nav .owl-next {
  background-color: #ffffff;
  color: #006c70;
  right: 30px;
  position: absolute;
}
.main-banner-wrapper .carousel-btn-block .carousel-btn:hover {
  background-color: #ffffff;
  color: #006c70;
  opacity: 1;
}
.main-banner-wrapper .carousel-btn-block .carousel-btn.left-btn {
  position: absolute;
  left: 30px;
}
.main-banner-wrapper .carousel-btn-block .carousel-btn.right-btn {
  position: absolute;
  right: 30px;
}
.banner-style-one .slide {
  padding: 165px 0;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.banner-style-one .slide:before {
  background-color: rgba(0, 0, 0, 0.7);
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.banner-style-one .owl-nav {
  display: none;
}
.banner-style-one h3,
.banner-style-one p {
  margin: 0;
}
.banner-style-one .banner-title,
.banner-style-one .banner_top_title,
.banner-style-one .banner_top_title small {
  color: #ffffff;
  font-size: 50px;
  font-weight: 700;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}
.banner-style-one .banner_top_title {
  font-size: 28px;
  color: #ffffff;
  font-weight: bold;
  line-height: 1.2;
}
.banner-style-one .banner_top_title small {
  font-size: 14px;
  color: #ffffff;
}
.banner-style-one .banner-title {
  font-weight: normal;
  text-transform: uppercase;
}
.main-banner-wrapper.home7 .carousel-btn-block .carousel-btn {
  top: 70px;
}
.banner-style-one p {
  font-size: 18px;
  line-height: 35px;
  color: #ffffff;
  font-weight: 300;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}
.banner-style-one .banner-btn {
  display: inline-block;
  vertical-align: middle;
  color: #ffffff;
  font-size: 18px;
  padding: 19.5px 50px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  margin-top: 20px;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}
.banner-style-one .banner-btn:hover {
  background-color: #ffffff;
  color: #2d174f;
}
.banner-style-one .active .banner-title,
.banner-style-one .active .banner_top_title {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}
.banner-style-one .active p {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
.banner-style-one .active .banner-btn {
  border: 2px solid #ffffff;
  border-radius: 8px;
  color: #ffffff;
  font-size: 18px;
  padding: 12px 50px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
}
.banner-style-one .active .banner-btn:hover,
.banner-style-one .active .banner-btn:active,
.banner-style-one .active .banner-btn:focus {
  background-color: #ffffff;
  color: #ff5a5f;
}
.banner-style-one .owl-dots {
  display: none !important;
  margin: 0;
  position: absolute;
  bottom: 110px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 100%;
  max-width: 1170px;
  text-align: center;
}
.banner-style-one .owl-dots .owl-dot span {
  background-color: #eeeeee;
  width: 8px;
  height: 8px;
  margin: 0;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.banner-style-one .owl-dots .owl-dot + .owl-dot {
  margin-left: 7px;
}
.banner-style-one .owl-dots .owl-dot.active span {
  height: 13px;
  width: 13px;
  background-color: #ffffff;
}
.banner-style-one.no-dots .owl-dots {
  display: none !important;
}
.home-one,
.home-four,
.home-five,
.home-seven,
.home-nine {
  /* margin-top: -96px; */
  padding: 0;
}
.home-six {
  margin-top: -90px;
  padding: 0;
}
.home-seven {
  background-image: url(../images/background/10.jpg);
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
  height: 800px;
}
.home-nine {
  background-image: url(../images/background/12.jpg);
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
  height: 960px;
  z-index: 0 !important;
}
.home-nine:before {
  background-image: -moz-linear-gradient(
    120deg,
    rgb(100, 17, 147) 0%,
    rgb(35, 0, 90) 100%
  );
  background-image: -webkit-linear-gradient(
    120deg,
    rgb(100, 17, 147) 0%,
    rgb(35, 0, 90) 100%
  );
  background-image: -ms-linear-gradient(
    120deg,
    rgb(100, 17, 147) 0%,
    rgb(35, 0, 90) 100%
  );
  bottom: 0;
  content: '';
  left: 0;
  opacity: 0.6;
  position: absolute;
  right: 0;
  top: 0;
}
.home-one .home-text {
  margin: 0 auto;
  left: 0;
  position: absolute;
  right: 0;
}
.home-one .home-text a.btn {
  border: 2px solid #ffffff;
  border-radius: 27px;
  color: #ffffff;
  font-size: 18px;
  margin-top: 15px;
  padding: 10px 50px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.home-one .home-text a.btn:hover {
  background-color: #ffffff;
  color: #2d174f;
}
.home-three {
  margin-top: 0;
}
.home-three:before {
  background-color: rgba(29, 41, 62, 0.702);
  bottom: 0;
  border-radius: 8px;
  content: '';
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
}
.home3_home_content {
  padding: 160px 0;
  position: relative;
}
.home3_home_content h1 {
  font-size: 55px;
  font-family: 'Nunito';
  color: #ffffff;
  font-weight: bold;
  line-height: 1.2;
}
.home3_home_content h4 {
  font-size: 18px;
  font-family: 'Nunito';
  color: #ffffff;
  line-height: 1.2;
}
.home3_home_content a.popup_video_btn {
  background: rgb(255, 90, 95);
  border-radius: 50%;
  color: #ffffff;
  font-size: 30px;
  height: 74px;
  line-height: 74px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 56%;
  width: 74px;
  z-index: 9;
}
.home3_home_content a.popup_video_btn:after {
  background-color: rgba(255, 90, 95, 0.5);
  border-radius: 100%;
  content: '';
  height: 89px;
  left: -7px;
  position: absolute;
  top: -7px;
  width: 89px;
  z-index: -1;
}
.row_style.home8 {
  bottom: -8px;
  left: -15px;
  width: 105%;
}
.home-four .banner-style-one .slide:before {
  background-image: -moz-linear-gradient(
    -36deg,
    rgb(62, 62, 165) 0%,
    rgb(255, 90, 95) 100%
  );
  background-image: -webkit-linear-gradient(
    -36deg,
    rgb(62, 62, 165) 0%,
    rgb(255, 90, 95) 100%
  );
  background-image: -ms-linear-gradient(
    -36deg,
    rgb(62, 62, 165) 0%,
    rgb(255, 90, 95) 100%
  );
  left: 0;
  opacity: 0.902;
  position: absolute;
  right: 0;
  content: '';
  top: 0;
  bottom: 0;
}
.home4_overlay:before {
  background-color: rgba(10, 10, 10, 0.5);
  bottom: 97px;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.home-five {
  margin-top: 0;
}
.home-five .home-text {
  padding: 230px 0 210px;
}
.home-five .home-text h2 {
  color: #484848;
  font-size: 45px;
}
.home-five .home-text p {
  font-family: 'Nunito', sans-serif;
  font-size: 18px;
  line-height: 30px;
  max-width: 495px;
}
.home-five .home-text a.btn {
  border: 2px solid #484848;
  border-radius: 27px;
  color: #484848;
  font-size: 18px;
  margin-top: 15px;
  padding: 10px 50px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.home-five .home-text a.btn:hover {
  background-color: #ffffff;
  border: 2px solid #ffffff;
  color: #2d174f;
}
.slide.slide-one.home6 {
  height: 730px;
}
.banner-style-one .slide.slide-one.home6 .banner-title {
  font-size: 50px;
  font-weight: normal;
  line-height: initial;
  text-transform: uppercase;
}
.banner-style-one .slide.slide-one.home6 p {
  margin: 0 auto;
  max-width: 450px;
}
.banner-style-one .slide.home6 {
  padding: 295px 0;
}
.banner-style-one .slide.home6:before {
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.home1_bgi1 {
  background-image: url(../images/background/1.jpg);
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
  height: 960px;
}
.home1-overlay {
  position: relative;
}
.home1-overlay:before {
  content: '';
  background-color: rgb(29, 41, 62);
  opacity: 0.6;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
}
.home5_bgi5 {
  background-image: url(../images/background/5.jpg);
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
  height: 960px;
}
.home5-overlay:before {
  content: '';
  background-color: rgb(35, 39, 51);
  opacity: 0.6;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
}
.home-six {
  background-color: #ffffff;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
  height: 860px;
}
.home6-overlay:before {
  background-image: url(../images/background/7.jpg);
  bottom: 0;
  content: '';
  opacity: 0.7;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
.home8-overlay {
  position: relative;
}
.home8-overlay:before {
  content: '';
  background-color: rgb(29, 41, 62);
  opacity: 0.6;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
}
.listing-home-bg {
  background-image: url(../images/background/13.jpg);
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
  height: 600px;
}
.listing-home-bg:before {
  background-color: rgba(29, 41, 62, 0.6);
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
.home_content.listing {
  padding: 90px 0;
}
.home_content.listing.slider_style {
  padding: 120px 0 90px;
}
.home_adv_srch_opt.listing {
  margin-top: 45px;
}
.home-listing-slider {
  margin-top: 0;
  position: relative;
}
.home-listing-slider .banner-style-one .slide:before {
  background-color: rgba(29, 41, 62, 0.6);
  left: 0;
  position: absolute;
  right: 0;
  content: '';
  top: 0;
  bottom: 0;
}

/*== Home Icon Box ==*/
.home4_iconbox {
  cursor: pointer;
  position: relative;
  text-align: center;
}
.home4_iconbox li {
  background-color: rgba(255, 255, 255, 0.149);
  border-radius: 22.5px;
  height: 45px;
  padding: 0 20px;
  text-align: center;
  width: 180px;
}

.home4_iconbox .icon {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
}
.home4_iconbox .icon span,
.home4_iconbox .icon p {
  color: #ffffff;
}
.home4_iconbox .icon span {
  font-size: 25px;
  padding-right: 10px;
}
.home4_iconbox .icon p {
  font-size: 16px;
  font-family: 'Nunito';
  color: #ffffff;
  line-height: 3;
  margin-bottom: 0;
}

/*== Home Five Overlay Slider ==*/
.divider.home5 {
  padding: 170px 135px;
}
.divider-btn {
  line-height: 40px;
}
.home1-divider2 {
  background-image: url(../images/background/3.jpg);
  -webkit-background-size: cover;
  background-position: center center;
  background-size: cover;
  overflow: hidden;
  padding: 145px 0 145px;
  position: relative;
}
.home1-divider2:before {
  background-color: rgba(36, 65, 231, 0.7);
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.app_grid h1 {
  margin-bottom: 5px;
}
.app_grid h1,
.app_grid p {
  color: #ffffff;
}
.app_grid p {
  margin-bottom: 40px;
}
.app_grid button:last-child {
  margin-right: 0;
}
.app_grid .apple_btn,
.app_grid .play_store_btn {
  background-color: transparent;
  border-radius: 5px;
  border: 2px solid rgba(255, 255, 255, 0.4);
  color: #ffffff;
  height: 75px;
  line-height: normal;
  margin-right: 30px;
  position: relative;
  -ms-text-align-last: auto;
  text-align: left;
  width: 210px;
}
.app_grid .apple_btn {
  width: 230px;
}
.app_grid .apple_btn:hover,
.app_grid .play_store_btn:hover {
  border: 2px solid transparent;
  cursor: pointer;
}
.app_grid .apple_btn .icon,
.app_grid .play_store_btn .icon {
  font-size: 36px;
  float: left;
  margin-right: 20px;
  margin-left: 15px;
}
.app_grid .apple_btn .title,
.play_store_btn .title {
  display: block;
  font-size: 16px;
}
.app_grid .apple_btn .subtitle,
.play_store_btn .subtitle {
  font-size: 13px;
}
.phone_img {
  position: absolute;
  right: 0;
  top: -80px;
}
/*== School Category Courses ==*/
.img_hvr_box {
  -webkit-background-size: cover;
  background-size: cover;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 30px;
  padding: 60px 30px 55px;
  position: relative;
  text-align: center;
}
.img_hvr_box:before {
  background-color: rgba(10, 10, 10, 0.5);
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.img_hvr_box:hover {
  cursor: pointer;
}
.img_hvr_box:hover:before {
  background-color: rgba(36, 65, 231, 0.8);
}
.img_hvr_box.home3:hover:before {
  background-color: rgba(54, 159, 219, 0.8);
}
.img_hvr_box .overlay .details {
  display: block;
  position: relative;
}
.img_hvr_box .overlay .details h5,
.img_hvr_box .overlay .details p {
  color: #ffffff;
  margin-bottom: 0;
}
.img_hvr_box .overlay .details h5 {
  font-size: 18px;
}
.img_hvr_box .overlay .details p {
  font-size: 15px;
}
.home7_row {
  margin-top: -105px;
}
.img_hvr_box.home7 {
  height: 200px;
  -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  -o-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  width: 307px;
}
.img_hvr_box.home7 .overlay {
  margin-top: 30px;
}
.img_hvr_box.home7 .overlay .details h4 {
  color: #ffffff;
  font-family: 'Nunito SemiBold';
  font-size: 20px;
  line-height: 1.2;
  text-transform: uppercase;
}
.img_hvr_box.home7:before {
  background-color: rgba(236, 208, 111, 0.9);
}
.img_hvr_box.home7.two:before {
  background-color: rgba(255, 16, 83, 0.9);
}
.img_hvr_box.home7.three:before {
  background-color: rgba(0, 167, 142, 0.9);
}
.img_hvr_box.home7.four:before {
  background-color: rgba(0, 188, 228, 0.9);
}
.courses_all_btn .btn {
  border: 2px solid;
  border-radius: 25px;
  font-size: 15px;
  height: 50px;
  line-height: 35px;
  margin-top: 30px;
  width: 235px;
}
.courses_all_btn.home3 .btn {
  border-color: #369fdb;
  color: #369fdb;
}
.courses_all_btn.home3 .btn:hover,
.courses_all_btn.home3 .btn:active,
.courses_all_btn.home3 .btn:focus {
  background-color: #369fdb;
  color: #ffffff;
}
.course-single2 {
  background-color: #f9fafc;
}
.courses_single_container {
  position: relative;
  width: 100%;
}
.cs_row_one .cs_instructor {
  clear: both;
  display: inline-block;
  position: relative;
  width: 100%;
}
.cs_row_one .cs_instructor .cs_instrct_list li {
  margin-right: 15px;
}
.cs_row_one .cs_instructor .cs_instrct_list li .thumb {
  height: 50px;
  width: 50px;
}
.cs_row_one .cs_instructor .cs_instrct_list li a {
  font-size: 15px;
  font-family: 'Open Sans';
  color: rgb(126, 126, 126);
  line-height: 1.2;
}
.cs_row_one .cs_instructor .cs_instrct_list li a {
  font-size: 15px;
  font-family: 'Open Sans';
  color: rgb(126, 126, 126);
  line-height: 1.2;
}
.cs_row_one .cs_instructor .cs_watch_list li a,
.cs_row_one.csv3 .cs_watch_list li a {
  font-size: 15px;
  font-family: 'Open Sans';
  color: #484848;
  line-height: 1.2;
}
.cs_row_one .cs_instructor .cs_watch_list li:first-child a {
  font-size: 20px;
}
.cs_row_one .cs_instructor .cs_watch_list li:last-child a,
.cs_row_one.csv3 .cs_watch_list li:last-child a {
  border-radius: 5px;
  background-color: rgb(36, 65, 231);
  color: #ffffff;
  display: block;
  height: 30px;
  text-align: center;
  width: 90px;
}
.cs_row_one .cs_instructor .cs_watch_list li:last-child a span,
.cs_row_one.csv3 .cs_watch_list li:last-child a span {
  font-size: 13px;
  font-family: 'Open Sans';
  color: #ffffff;
  line-height: 2.2;
}
.cs_row_one .cs_title {
  font-size: 26px;
  font-family: 'Nunito SemiBold';
  color: #484848;
  line-height: 1.2;
}
.cs_row_one .cs_review_seller {
  margin-bottom: 15px;
}
.cs_row_one .cs_review_seller li {
  margin-right: 4px;
}
.cs_row_one .cs_review_seller li a {
  font-size: 13px;
  font-family: 'FontAwesome';
  color: #bcc52a;
  line-height: 1.2;
}
.cs_row_one .cs_review_seller li:first-child {
  margin-right: 15px;
}
.cs_row_one .cs_review_seller li:first-child a {
  border-radius: 5px;
  background-color: rgb(255, 16, 83);
  color: #ffffff;
  display: block;
  height: 30px;
  text-align: center;
  width: 90px;
}
.cs_row_one .cs_review_seller li:first-child a span {
  font-size: 13px;
  font-family: 'Open Sans';
  color: #ffffff;
  line-height: 2.2;
}
.cs_row_one .cs_review_seller li:last-child a {
  font-size: 14px;
  font-family: 'Open Sans';
  color: rgb(126, 126, 126);
  line-height: 1.2;
}
.cs_row_one .cs_review_enroll {
  margin-bottom: 30px;
}
.cs_row_one .cs_review_enroll li {
  margin-right: 15px;
}
.cs_row_one .cs_review_enroll li a {
  font-size: 14px;
  font-family: 'Open Sans';
  color: rgb(126, 126, 126);
  line-height: 1.2;
}
.cs_row_one .cs_review_enroll li a span {
  padding-right: 6px;
}
.cs_row_one .courses_big_thumb {
  margin-bottom: 30px;
}
.cs_row_one .courses_big_thumb .thumb {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  overflow: hidden;
  position: relative;
}
.cs_ins_container .courses_big_thumb .iframe_video {
  border-radius: 5px;
  min-height: 540px;
  max-width: 960px;
}
.cs_row_one .courses_big_thumb .thumb img {
  border-radius: 5px;
}
.cs_row_one .courses_big_thumb .thumb .overlay_icon:before {
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
}
.cs_row_one .courses_big_thumb .thumb .overlay_icon a,
.cs_row_one .courses_big_thumb .thumb .overlay_icon .title {
  color: #ffffff;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  text-align: center;
  top: 40%;
}
.cs_row_one .courses_big_thumb .thumb .overlay_icon .title {
  top: 58%;
}
.cs_row_one.csv3 .cs_review_enroll {
  display: inline;
}
.cs_row_two .cs_overview {
  border-style: solid;
  border-width: 2px;
  border-color: rgb(237, 239, 247);
  border-radius: 5px;
  background-color: rgba(42, 53, 59, 0);
  margin-bottom: 30px;
  padding: 30px;
}
.cs_row_two.csv2 .cs_overview,
.cs_row_three.csv2 .course_content,
.cs_row_four.csv2 .about_ins_container,
.cs_row_five.csv2 .student_feedback_container,
.cs_row_six.csv2 .sfeedbacks,
.cs_row_seven.csv2 .sfeedbacks {
  border-radius: 5px;
  background-color: #ffffff;
  -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  -o-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  margin-top: 30px;
}
.cs_row_two .cs_overview .title {
  font-family: 'Nunito SemiBold';
  font-size: 20px;
  color: #484848;
  line-height: 1.2;
  margin-bottom: 22px;
}
.cs_row_two .cs_overview h4 {
  font-family: 'Nunito';
  font-size: 18px;
  color: rgb(59, 59, 59);
  line-height: 1.333;
  margin-bottom: 10px;
}
.cs_row_two .cs_overview p {
  font-family: 'Open Sans';
  font-size: 15px;
  color: rgb(126, 126, 126);
  line-height: 1.6;
}
.cs_row_two .cs_overview ul.cs_course_syslebus {
  margin-bottom: 17px;
  margin-right: 100px;
}
.cs_row_two .cs_overview ul.cs_course_syslebus,
.cs_row_two .cs_overview ul.cs_course_syslebus2 {
  display: inline-block;
}
.cs_row_two .cs_overview ul.cs_course_syslebus li p,
.cs_row_two .cs_overview ul.cs_course_syslebus2 li p {
  font-family: 'Open Sans';
  font-size: 15px;
  color: rgb(126, 126, 126);
  display: inline;
  line-height: 2.8;
  margin-bottom: 0;
  padding-left: 10px;
}
.cs_row_two .cs_overview ul.list_requiremetn li i {
  font-size: 10px;
  color: rgb(126, 126, 126);
}
.cs_row_two .cs_overview ul.list_requiremetn li p {
  color: rgb(126, 126, 126);
  font-family: 'Open Sans';
  font-size: 15px;
  display: inline;
  line-height: 2.667;
  margin-bottom: 0;
  padding-left: 13px;
}
.cs_row_three .course_content {
  border-style: solid;
  border-width: 2px;
  border-color: rgb(237, 239, 247);
  border-radius: 5px;
  background-color: rgba(42, 53, 59, 0);
  margin-bottom: 30px;
  position: relative;
  padding: 30px;
}
.cs_row_three .course_content .cc_headers {
  position: relative;
}
.cs_row_three .course_content h4.title {
  font-size: 20px;
  font-family: 'Nunito SemiBold';
  color: #484848;
  float: left;
  line-height: 1.2;
}
.cs_row_three .course_content .details {
  display: inline-block;
  position: relative;
  width: 100%;
}
.cs_row_three .course_content .details .cc_tab {
  margin-top: 30px;
}
.cs_row_three .course_content .details .cc_tab h4.panel-title {
  font-size: 18px;
  font-family: 'Nunito';
  color: rgb(59, 59, 59);
  line-height: 1.7;
  border-radius: 5px;
  background-color: rgb(237, 239, 247);
  height: 50px;
  padding: 10px 20px;
}
.cs_row_three .course_content .details .cc_tab .panel-body .cs_list li {
  border: 1px solid rgb(235, 235, 235);
  padding: 20px 25px;
}
.cs_row_three
  .course_content
  .details
  .cc_tab
  .panel-body
  .cs_list
  li:last-child {
  border-bottom: none;
}
.cs_row_three .course_content .details .cc_tab .panel-body .cs_list li a {
  font-size: 15px;
  font-family: 'Open Sans';
  color: rgb(79, 79, 79);
  line-height: 1.6;
}
.cs_row_three .course_content .details .cc_tab .panel-body .cs_list li a span {
  padding-right: 4px;
}
.cs_row_three
  .course_content
  .details
  .cc_tab
  .panel-body
  .cs_list
  li
  a
  span.cs_preiew {
  float: right;
  margin-right: 50px;
}
.cs_row_three .course_content .details .cc_tab .panel-body .cs_list li:hover a {
  color: #ff5a5f;
}
.cs_row_three
  .course_content
  .details
  .cc_tab
  .panel-body
  .cs_list
  li
  a
  span.cs_time {
  float: right;
}
.cs_row_four .about_ins_container,
.cs_row_five .student_feedback_container,
.cs_row_six .sfeedbacks,
.cs_row_seven .sfeedbacks {
  border-radius: 5px;
  margin-bottom: 30px;
  position: relative;
}
.cs_row_six .sfeedbacks .sspd_review_liked {
  background-color: rgb(237, 239, 247);
  border-radius: 20px;
  height: 40px;
  text-align: center;
  max-width: 200px;
}
.cs_row_six .sfeedbacks .sspd_review_liked a {
  font-size: 14px;
  font-family: 'Open Sans';
  line-height: 2.714;
}
.cs_row_six.csv2.style2 {
  overflow: hidden;
}
.cs_row_four .about_ins_container .aii_title,
.cs_row_five .student_feedback_container .aii_title {
  font-size: 20px;
  font-family: 'Nunito SemiBold';
  color: #484848;
  margin-bottom: 25px;
  line-height: 1.2;
}
.cs_row_four .about_ins_container .about_ins_info {
  float: left;
}
.cs_row_four .about_ins_container .details {
  padding-left: 160px;
}
.cs_row_four .about_ins_container .details h4 {
  font-size: 20px;
  font-family: 'Nunito';
  color: #484848;
  margin-bottom: 0;
  line-height: 1.5;
}
.cs_row_four .about_ins_container .details .subtitle {
  font-size: 14px;
  font-family: 'Open Sans';
  color: rgb(137, 137, 137);
  margin-bottom: 15px;
  line-height: 1.714;
}
.cs_row_four .about_ins_container .details p {
  font-size: 15px;
  font-family: 'Open Sans';
  color: rgb(79, 79, 79);
  line-height: 1.6;
}
.cs_row_four .about_ins_container .details .about_ins_list li:first-child p {
  font-size: 15px;
  font-family: 'Open Sans';
  color: rgb(79, 79, 79);
  margin-bottom: 0;
  line-height: 1.6;
}
.cs_row_four .about_ins_container .details .about_ins_list li a {
  font-size: 15px;
  font-family: 'Open Sans';
  color: rgb(79, 79, 79);
  line-height: 1.6;
}
.cs_row_four .about_ins_container .details .review_list {
  float: left;
  margin-right: 80px;
}
.cs_row_four.csv2 .about_ins_container .details .review_list {
  margin-right: 50px;
}
.cs_row_four.csv2 .about_ins_container .details .about_info_list li {
  margin-right: 15px;
}
.cs_row_four .about_ins_container .details .review_list li {
  margin-right: 4px;
}
.cs_row_four .about_ins_container .details .review_list li i {
  font-size: 13px;
  color: #bcc52a;
  line-height: 1.2;
}
.cs_row_four .about_ins_container .details .about_info_list li {
  font-size: 14px;
  font-family: 'Open Sans';
  color: rgb(126, 126, 126);
  line-height: 1.2;
  margin-right: 30px;
}
.cs_row_four .about_ins_container .details .about_info_list li:last-child {
  margin-right: 0;
}
.cs_row_four .about_ins_container .details .about_info_list li span {
  padding-right: 10px;
}
.cs_row_five .student_feedback_container .s_feeback_content .sonny_progressbar {
  border-radius: 5px;
  max-width: 470px;
  padding-left: 100px;
}
.cs_row_five .student_feedback_container .s_feeback_content ul.skills {
  margin-bottom: 30px;
}
.cs_row_five .student_feedback_container .s_feeback_content ul.skills li {
  color: rgb(79, 79, 79);
  font-size: 14px;
  font-family: 'Open Sans';
  float: left;
  line-height: 1.2;
}
.cs_row_five
  .student_feedback_container
  .s_feeback_content
  .sonny_progressbar
  .bar {
  background-color: #d0d23c !important;
}
.cs_row_five
  .student_feedback_container
  .s_feeback_content
  .sonny_progressbar
  .bar-container {
  border-radius: 5px;
  height: 10px !important;
  overflow: hidden;
}
.cs_row_five
  .student_feedback_container
  .s_feeback_content
  .sonny_progressbar
  p.title {
  float: right;
  margin-right: -50px;
  margin-top: -10px;
}
.my_course_content_list .mc_content_list .s_feeback_content .sonny_progressbar {
  border-radius: 5px;
  max-width: 170px;
  padding-left: 100px;
}
.my_course_content_list .mc_content_list .sonny_progressbar .bar-container {
  margin-left: -190px;
  margin-right: 130px;
  margin-top: 10px;
}
.my_course_content_list .mc_content_list .sonny_progressbar p.title {
  float: right;
  margin-bottom: 0;
  margin-right: 0;
  margin-top: -10px;
}
.cs_row_five .student_feedback_container .aii_average_review {
  border-radius: 5px;
  background-color: rgb(237, 239, 247);
  height: 180px;
  position: absolute;
  right: 30px;
  top: 75px;
  width: 230px;
}
.cs_row_five .student_feedback_container .aii_average_review .av_content {
  margin-top: 45px;
}
.cs_row_five .student_feedback_container .aii_average_review .av_content h2 {
  font-size: 50px;
  font-family: 'Nunito';
  color: #484848;
  line-height: 0.6;
}
.cs_row_five
  .student_feedback_container
  .aii_average_review
  .av_content
  .aii_rive_list
  li {
  font-size: 13px;
  font-family: 'FontAwesome5FreeSolid';
  color: #bcc52a;
  margin-right: 3px;
  line-height: 1.2;
}
.cs_row_five .student_feedback_container .aii_average_review .av_content p {
  font-size: 15px;
  font-family: 'Open Sans';
  color: rgb(79, 79, 79);
  line-height: 1.2;
}
.r_course_title {
  font-size: 26px;
  font-family: 'Nunito SemiBold';
  color: #484848;
  line-height: 1.2;
  margin-bottom: 30px;
}
.mbp_first.media.csv1.style2 {
  position: relative;
}
.mbp_first.media.csv1.style2:before {
  bottom: -30px;
  background-color: #dedede;
  content: '';
  position: absolute;
  height: 1px;
  left: -30px;
  right: -30px;
  width: 105%;
}
/*== Feature Property ==*/
.latest-property {
  border-bottom: 1px solid #ebebeb;
}
.best-property .container {
  max-width: 1560px;
}
.feat_property {
  background-color: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  margin-bottom: 30px;
  overflow: hidden;
  position: relative;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.feat_property:hover {
  cursor: pointer;
  -webkit-box-shadow: 0px 0px 30px 0px rgba(19, 19, 28, 0.12);
  -moz-box-shadow: 0px 0px 30px 0px rgba(19, 19, 28, 0.12);
  -o-box-shadow: 0px 0px 30px 0px rgba(19, 19, 28, 0.12);
  box-shadow: 0px 0px 30px 0px rgba(19, 19, 28, 0.12);
}
.feat_property .thumb {
  background-color: #1d293e;
  border-radius: 8px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  overflow: hidden;
  margin: 10px 10px 0 10px;
  position: relative;
}
.feat_property .thumb img {
  opacity: 0.6;
  object-fit: cover;
  height: 100%;
}
.feat_property .thumb .blog_tag {
  background-color: #ff5a5f;
  border-radius: 3px;
  color: rgb(254, 254, 254);
  font-family: 'Nunito';
  font-size: 14px;
  height: 25px;
  line-height: 25px;
  left: 10px;
  position: absolute;
  text-align: center;
  top: 10px;
  width: 110px;
}
.for_blog.feat_property.home7 .thumb {
  border-radius: 8px 8px 0 0;
  margin: 0;
}
.for_blog.feat_property .thumb img {
  opacity: 1;
}
.feat_property .thumb .thmb_cntnt,
.properti_city.home6 .thumb .thmb_cntnt {
  bottom: 0;
  left: 10px;
  position: absolute;
  right: 10px;
  top: 10px;
}
.feat_property .thumb .thmb_cntnt ul.tag,
.properti_city.home6 .thumb .thmb_cntnt ul.tag {
  position: absolute;
  left: 12px;
  top: 10px;
}
.feat_property .thumb .thmb_cntnt ul.tag li,
.feat_property.home8 ul.tag li,
.properti_city.home6 .thumb .thmb_cntnt ul.tag li,
.feat_property.list .dtls_headr ul.tag li {
  border-radius: 3px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  width: 75px;
}
.feat_property.home8 ul.tag {
  margin-bottom: 10px;
}
.feat_property .thumb .thmb_cntnt ul.tag li:first-child,
.feat_property.home8 ul.tag li:first-child,
.properti_city.home6 .thumb .thmb_cntnt ul.tag li:first-child,
.feat_property.list .dtls_headr ul.tag li:first-child {
  background-color: rgb(62, 76, 102);
  color: white;
}
.feat_property .thumb .thmb_cntnt ul.tag li:last-child,
.feat_property.home8 ul.tag li:last-child,
.properti_city.home6 .thumb .thmb_cntnt ul.tag li:last-child,
.feat_property.list .dtls_headr ul.tag li:last-child {
  background-color: #ff5a5f;
  margin-right: 0;
}
.feat_property .thumb .thmb_cntnt ul.tag li a,
.properti_city.home6 .thumb .thmb_cntnt ul.tag li a,
.feat_property.home8 ul.tag li a,
.feat_property.list ul.tag li a {
  font-size: 14px;
  font-family: 'Nunito';
  color: #fefefe;
  line-height: 1.2;
}
.feat_property .thumb .thmb_cntnt .icon {
  bottom: 15px;
  position: absolute;
  right: 20px;
}
.feat_property .thumb .thmb_cntnt .icon li {
  border-radius: 8px;
  background-color: rgb(15, 21, 31);
  height: 35px;
  line-height: 35px;
  margin-right: 5px;
  opacity: 0.502;
  text-align: center;
  width: 35px;
}
.feat_property .thumb .thmb_cntnt .icon li.list-inline-item:hover {
  background-color: #ffc107;
}
.feat_property.home8 .icon li {
  border-radius: 8px;
  background-color: rgb(247, 247, 247);
  height: 35px;
  line-height: 35px;
  margin-right: 5px;
  opacity: 1;
  text-align: center;
  width: 35px;
}
.feat_property.home8 .icon li a {
  color: #484848;
}
.feat_property.home8 .details .tc_content {
  display: inline-block;
}
.feat_property .details .tc_content ul.icon {
  float: right;
  margin-bottom: 0;
}
.feat_property .thumb .thmb_cntnt .icon li a {
  color: #ffffff;
}
.feat_property .thumb .thmb_cntnt .icon li:last-child,
.feat_property.home8 .icon li:last-child {
  margin-right: 0;
}
.feat_property .thumb .thmb_cntnt a.fp_price {
  bottom: 15px;
  font-size: 22px;
  font-family: 'Nunito';
  color: #ffffff;
  font-weight: bold;
  left: 20px;
  line-height: 1.2;
  position: absolute;
}
.feat_property.home8 {
  overflow: visible;
  position: relative;
}
.feat_property.list {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
}
.feat_property.list .thumb {
  width: 45%;
  margin: 10px;
  position: relative;
}
.feat_property.list.style2 .thumb {
  height: auto;
  max-width: -webkit-fill-available;
}
.feat_property.list .details {
  width: 70%;
}
.feat_property.list .details .fp_footer {
  border: none;
  padding: 0 20px 10px;
}
.feat_property.list .details .tc_content {
  padding: 5px 10px 10px 20px !important;
  position: relative;
  min-width: 300px;
  line-height: 3;
}
.feat_property.list .dtls_headr {
  display: -webkit-inline-flex;
  display: -moz-inline-flex;
  display: -ms-inline-flex;
  display: -o-inline-flex;
  display: inline-flex;
}
.feat_property.list .dtls_headr a.fp_price {
  font-size: 22px;
  font-family: 'Nunito';
  color: #484848;
  font-weight: bold;
  line-height: 1.2;
  position: absolute;
  right: 20px;
}
.feat_property.list.favorite_page {
  border: none;
  border-radius: 0;
  box-shadow: none;
  border-bottom: 1px solid #ebebeb;
  padding: 0 0 30px;
}
.feat_property.list.favorite_page .thumb {
  height: 126px;
  max-width: 150px;
  margin: 0;
  position: relative;
}
.feat_property.list.favorite_page .view_edit_delete_list {
  position: absolute;
  right: 0;
}
.feat_property.list.favorite_page.style2 {
  border-bottom: none;
  margin: 10px 0;
  margin-bottom: 0;
  padding-bottom: 0;
}
.feat_property.list.favorite_page.style2 .details .tc_content p {
  margin-bottom: 0;
}
.status_tag.badge {
  border-radius: 3px;
  background-color: rgb(255, 90, 95);
  font-size: 14px;
  font-family: 'Nunito';
  color: rgb(254, 254, 254);
  height: 25px;
  line-height: 1.2;
  padding: 5px 8px;
  text-align: center;
}
.status_tag.badge2 {
  border-radius: 3px;
  background-color: rgb(57, 218, 138);
  font-size: 14px;
  font-family: 'Nunito';
  color: rgb(254, 254, 254);
  height: 25px;
  line-height: 20px;
  padding: 5px 8px;
  text-align: center;
}
.status_tag.badge3 {
  border-radius: 3px;
  background-color: rgb(253, 57, 122);
  font-size: 14px;
  font-family: 'Nunito';
  color: rgb(254, 254, 254);
  height: 25px;
  line-height: 20px;
  padding: 5px 8px;
  text-align: center;
}
.bs_carousel .feat_property.home8 {
  opacity: 0;
  -webkit-transform: translateY(20px);
  -moz-transform: translateY(20px);
  -o-transform: translateY(20px);
  transform: translateY(20px);
  -webkit-transition: all 0.6s ease-in-out;
  -moz-transition: all 0.6s ease-in-out;
  -o-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
}
.bs_carousel .carousel-item.active .feat_property.home8 {
  opacity: 1;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}
.feat_property.home8:before {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  bottom: -10px;
  content: '';
  position: absolute;
  left: -10px;
  right: -10px;
  top: -10px;
  z-index: -1;
}
.feat_property.home8 a.fp_price {
  color: #ff5a5f;
  float: left;
  font-size: 22px;
  font-family: 'Nunito';
  font-weight: bold;
  line-height: 1.2;
  margin-top: 0;
  position: relative;
}
.feat_property .thumb .thmb_cntnt a.fp_price small {
  font-size: 14px;
}
.feat_property .details {
  position: relative;
}
.feat_property .details .tc_content {
  padding: 20px;
}
.feat_property .details .tc_content p {
  font-size: 14px;
  font-family: 'Nunito';
  color: #484848;
  line-height: 1.2;
}
.feat_property .details .tc_content h4 {
  font-size: 18px;
  font-family: 'Nunito';
  color: #484848;
  font-weight: bold;
  line-height: 1.2;
  height: 40px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.feat_property .details .tc_content .prop_details {
  margin-bottom: 0;
}
.feat_property.home8 .details .tc_content .prop_details {
  margin-bottom: 10px;
}
.feat_property .details .tc_content .prop_details li {
  margin-right: 30px;
}
.feat_property .details .tc_content .prop_details li a {
  font-size: 14px;
  font-family: 'Nunito';
  color: #484848;
  line-height: 1.2;
}
.feat_property .details .tc_content .prop_details li:last-child {
  margin-right: 0;
}
.feat_property.home2 .details .tc_content h5 {
  color: #484848;
  font-family: 'Nunito';
  line-height: 1.333;
}
.feat_property .details .fp_footer {
  border-top: 1px solid #eeeeee;
  display: inline-block;
  padding: 20px;
  position: relative;
  width: 100%;
}
.feat_property .details .fp_footer .fp_meta {
  margin-bottom: 0;
}
.feat_property .details .fp_footer .fp_meta li {
  margin-right: 10px;
  vertical-align: middle;
}
.feat_property .details .fp_footer .fp_meta li:last-child {
  margin-right: 0;
}
.feat_property .details .fp_footer .fp_meta li a {
  font-size: 14px;
  font-family: 'Nunito';
  color: #777777;
  line-height: 1.2;
}
.feat_property .details .fp_footer .fp_meta li:nth-child(2) {
  margin-right: 10px;
}
.for_blog.feat_property .details .fp_footer .fp_meta li:nth-child(2) {
  margin-right: 30px;
}
.feat_property .details .fp_footer .fp_pdate {
  font-size: 14px;
  font-family: 'Nunito';
  color: #777777;
  line-height: 1.2;
  margin-top: 12px;
}
.feat_property.home2 .details .fp_footer .fp_pdate {
  color: #192675;
}
.feat_property.home8:hover .thumb .overlay:before {
  background-color: rgba(25, 38, 117, 0.7);
}
.feat_property.home8 .wc_age_seat li a {
  color: #7e7e7e;
  font-size: 13px;
}
.feat_property.home3 {
  border: none;
}
.feat_property.home3 .thumb {
  padding: 0;
}
.feat_property.home3 .details .fp_footer {
  border-top: none;
}
.feat_property.home7 {
  -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  -o-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
}
.feat_property.home7:hover {
  -webkit-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
  -moz-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
  -o-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
  box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
}
.feat_property.home7.style2 {
  border: none;
  overflow: visible;
}
.feat_property.home7.style3 {
  box-shadow: none;
}
.feat_property.home7.style2:before {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  bottom: -5px;
  content: '';
  left: -5px;
  position: absolute;
  right: -5px;
  top: -5px;
}
.feat_property.home7.style3 .details .tc_content {
  padding: 20px 0;
}
.feat_property.home7.style3 .thumb,
.feat_property.home7.style4 .thumb {
  border-radius: 8px;
}
.feat_property.home7.style4 .thumb {
  margin: 10px;
  /* max-height: 215px; */
  max-height: 180px;
}
.feat_property.home7.style4 .thumb .thmb_cntnt.style2 {
  bottom: auto;
}
.feat_property.home7.style4 .thumb .thmb_cntnt.style3 {
  top: auto;
}
.feat_property.home7 .thumb {
  border-radius: 8px 8px 0 0;
  margin: 0;
}
.feat_property.home7.agent .thumb {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 10px;
}
.feat_property.home7.agent .thumb img {
  border-radius: 8px;
}
.feat_property.home7 .details {
  background-color: #ffffff;
  border-radius: 0 0 8px 8px;
}
.feat_property.agent .thumb img,
.feat_property.agency .thumb img {
  opacity: 1;
  object-fit: cover;
}
.feat_property.agent .details .fp_footer .fp_pdate,
.feat_property.agency .details .fp_footer .fp_pdate {
  margin-top: 6px;
}
.feat_property.agency .thumb {
  background-color: #ffffff;
  border-bottom: 1px solid #ebebeb;
}
.feat_property.agency .thumb img {
  margin: 0 auto;
}
.feat_property.list.agency .thumb {
  border-bottom: none;
  height: 235px;
  width: 40%;
}
.feat_property.list.agency .details {
  border-left: 1px solid #ebebeb;
  padding-left: 10px;
}
.feat_property.home7.style4:hover
  .fp_single_item_slider.owl-carousel.owl-theme.owl-loaded
  .owl-prev {
  left: 20px;
}
.feat_property.home7.style4:hover
  .fp_single_item_slider.owl-carousel.owl-theme.owl-loaded
  .owl-next {
  right: 20px;
}
.fp_single_item_slider.owl-carousel.owl-theme.owl-loaded .owl-prev {
  background-color: transparent;
  color: #ffffff;
  font-size: 16px;
  left: -30px;
  position: absolute;
  top: 40%;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.fp_single_item_slider.owl-carousel.owl-theme.owl-loaded .owl-next {
  background-color: transparent;
  color: #ffffff;
  font-size: 16px;
  right: -30px;
  position: absolute;
  top: 40%;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.fp_single_item_slider.owl-theme .owl-nav {
  margin: 0;
}
.properti_city_home8 {
  position: relative;
  margin-bottom: 30px;
}
.properti_city_home8 .details p {
  margin-bottom: 0;
}
.properti_city_home8 .thumb {
  background-color: #f7f7f7;
  border-radius: 50%;
  height: 200px;
  margin: 0 auto 20px;
  position: relative;
  width: 200px;
}
.properti_city_home8 .thumb img {
  bottom: 0;
  left: 0;
  margin: 0 auto;
  right: 0;
  position: absolute;
}
.properti_city.home6 .details .tc_content h4,
.properti_city.home6 .details a.fp_price,
.properti_city.home6 ul.prop_details li a {
  color: #ffffff;
  font-weight: 600;
}
.properti_city.home6 .overlay .details h4 {
  font-size: 16px;
  font-family: 'Nunito';
  color: #ffffff;
  font-weight: bold;
  line-height: 1.2;
}
.properti_city.home6 .details a.fp_price {
  font-size: 22px;
  font-family: 'Nunito';
  color: #ffffff;
  font-weight: bold;
  line-height: 2;
}
.feature_property_slider.owl-carousel.owl-theme .owl-nav,
.sidebar_feature_property_slider.owl-carousel.owl-theme .owl-nav {
  margin: 0;
}
.feature_property_slider.owl-carousel.owl-theme .owl-dots .owl-dot,
.sidebar_feature_property_slider.owl-carousel.owl-theme .owl-dots .owl-dot,
.best_property_slider.owl-carousel.owl-theme .owl-dots .owl-dot {
  background-color: transparent;
  margin-right: 15px;
  vertical-align: middle;
}
.best_property_slider.style2.owl-carousel.owl-theme .owl-dots .owl-dot span,
.feature_property_slider.owl-carousel.owl-theme .owl-dots .owl-dot span,
.sidebar_feature_property_slider.owl-carousel.owl-theme
  .owl-dots
  .owl-dot
  span {
  background-color: #cccccc;
  border-radius: 50%;
  margin: 3px;
  width: 6px;
  height: 6px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.best_property_slider.style2.owl-carousel.owl-theme
  .owl-dots
  .owl-dot.active
  span,
.best_property_slider.style2.owl-carousel.owl-theme
  .owl-dots
  .owl-dot:hover
  span,
.feature_property_slider.owl-carousel.owl-theme .owl-dots .owl-dot.active span,
.feature_property_slider.owl-carousel.owl-theme .owl-dots .owl-dot:hover span,
.sidebar_feature_property_slider.owl-carousel.owl-theme
  .owl-dots
  .owl-dot.active
  span,
.sidebar_feature_property_slider.owl-carousel.owl-theme
  .owl-dots
  .owl-dot:hover
  span {
  background-color: #202020 !important;
  border: 5px solid #e0e2e3;
  height: 15px;
  width: 15px;
}
.best_property_slider.owl-carousel.owl-theme .owl-dots .owl-dot span {
  background-color: rgb(206, 52, 56);
  border-radius: 50%;
  margin: 3px;
  width: 6px;
  height: 6px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.best_property_slider.owl-carousel.owl-theme .owl-dots .owl-dot.active span,
.best_property_slider.owl-carousel.owl-theme .owl-dots .owl-dot:hover span {
  background-color: rgb(154, 34, 38) !important;
  border: 5px solid rgb(255, 155, 158);
  width: 15px;
  height: 15px;
}
.for_blog.feat_property.home9 {
  min-height: 283px;
  margin-top: 140px;
  overflow: visible;
  position: relative;
}
.for_blog.feat_property.home9 .thumb {
  position: absolute;
  top: -130px;
}
.for_blog.feat_property.home9 .details {
  bottom: 0;
  position: absolute;
}
.for_blog.feat_property.home9 .details .tc_content h4 {
  margin-bottom: 0;
}
.for_blog.feat_property .details .tc_content .bpg_meta li a {
  font-size: 14px;
  font-family: 'Nunito';
  color: rgb(119, 119, 119);
  line-height: 1.2;
}
.for_blog.feat_property .details .tc_content p {
  font-size: 14px;
  font-family: 'Nunito';
  color: #484848;
  line-height: 1.714;
  margin-bottom: 0;
}
/*== Property Cities ==*/
.properti_city {
  margin-bottom: 0px;
  position: relative;
}
.properti_city:hover {
  cursor: pointer;
}
.properti_city .thumb {
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}
.properti_city .thumb img {
  -webkit-transition: all 0.6s ease;
  -moz-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
  transition: all 0.6s ease;
}
.properti_city:hover .thumb img {
  -webkit-transform: scale(1.1) rotate(-1deg);
  -moz-transform: scale(1.1) rotate(-1deg);
  -o-transform: scale(1.1) rotate(-1deg);
  -ms-transform: scale(1.1) rotate(-1deg);
  transform: scale(1.1) rotate(0deg);
}
.properti_city .overlay {
  background: -moz-linear-gradient(
    270deg,
    rgba(255, 255, 255, 0.01) 0%,
    rgba(29, 41, 62, 1) 100%
  ); /* ff3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, rgba(255, 255, 255, 0.01)),
    color-stop(100%, rgba(29, 41, 62, 1))
  ); /* safari4+,chrome */
  background: -webkit-linear-gradient(
    270deg,
    rgba(255, 255, 255, 0.01) 0%,
    rgba(29, 41, 62, 1) 100%
  ); /* safari5.1+,chrome10+ */
  background: -o-linear-gradient(
    270deg,
    rgba(255, 255, 255, 0.01) 0%,
    rgba(29, 41, 62, 1) 100%
  ); /* opera 11.10+ */
  background: -ms-linear-gradient(
    270deg,
    rgba(255, 255, 255, 0.01) 0%,
    rgba(29, 41, 62, 1) 100%
  ); /* ie10+ */
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.01) 0%,
    rgba(29, 41, 62, 1) 100%
  ); /* w3c */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#1d293e',GradientType=0 ); /* ie6-9 */
  border-radius: 0px 0px 10px 10px;
  bottom: 0;
  height: 244px;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
}
.properti_city .overlay .details {
  bottom: 20px;
  left: 0;
  position: absolute;
  right: 0;
}
.properti_city .overlay .details h4 {
  color: #ffffff;
  font-size: 18px;
  font-family: 'Nunito';
  font-weight: bold;
  line-height: 1.2;
}
.properti_city.home5 .overlay .details .left {
  background-color: #ffffff;
  border-radius: 17.5px;
  -webkit-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
  -moz-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
  -o-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
  box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
  float: left;
  height: 35px;
  margin-left: 30px;
  padding: 0 30px;
  position: relative;
  text-align: center;
}
.properti_city.home5 .overlay .details .left:before {
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 17.5px;
  bottom: -5px;
  content: '';
  left: -5px;
  position: absolute;
  right: -5px;
  top: -5px;
}
.properti_city.home5 .overlay .details .left h4 {
  font-size: 16px;
  font-family: 'Nunito';
  color: #484848;
  font-weight: bold;
  line-height: 2.3;
  margin: 0;
}
.properti_city.home5 .overlay .details p {
  float: right;
  margin-bottom: 0;
  margin-right: 30px;
  margin-top: 10px;
}
.properti_city.home5 .overlay .details {
  bottom: 30px;
}
.properti_city.home6 .thumb .thmb_cntnt {
  left: 8px;
  position: absolute;
  top: 20px;
}
.properti_city.home6 .overlay .details {
  padding-left: 20px;
  text-align: left;
}
.properti_city .overlay .details p {
  font-size: 14px;
  font-family: 'Nunito';
  color: #ffffff;
  line-height: 1.2;
}
.properti_city.style2 {
  background-color: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  padding: 10px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.properti_city.style2:hover {
  -webkit-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
  -moz-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
  -o-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
  box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
}
.properti_city.style2 .details {
  padding: 20px 20px 10px;
  text-align: center;
}
.properti_city.style2 .details p {
  margin-bottom: 0;
}
.property_city_home6 {
  border: 1px solid #ebebeb;
  border-radius: 8px;
  background-color: #ffffff;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  margin-bottom: 30px;
  overflow: hidden;
  position: relative;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.property_city_home6:hover {
  cursor: pointer;
  -webkit-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
  -moz-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
  -o-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
  box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
}
.property_city_home6 .thumb {
  position: relative;
}
.property_city_home6 .details {
  padding: 50px 25px 0;
}
.modern_apertment {
  border-radius: 3px;
  background-color: #ffffff;
  padding: 55px 15px 50px 45px;
  position: relative;
}
.modern_apertment .title {
  font-family: 'Nunito';
  color: #484848;
  font-weight: bold;
  line-height: 1.2;
}
.modern_apertment .subtitle {
  font-family: 'Nunito';
  color: #484848;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 15px;
}
.modern_apertment p {
  font-size: 14px;
  font-family: 'Nunito';
  color: #484848;
  line-height: 1.429;
  margin-bottom: 30px;
}
.modern_apertment .booking_btn {
  border-radius: 8px;
  background-color: #ff5a5f;
  -webkit-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  -moz-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  -o-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  font-size: 16px;
  font-family: 'Nunito';
  color: #ffffff;
  font-weight: bold;
  line-height: 35px;
  height: 50px;
  width: 170px;
}
.properti_city.home7 .details {
  padding-top: 20px;
}
.properti_city.home7 .details p {
  font-size: 14px;
  font-family: 'Nunito';
  color: rgba(119, 119, 119, 0.8);
  line-height: 1.2;
}
/*== Search Smart Property ==*/
.search_smart_property {
  padding: 75px 0;
  position: relative;
}
.search_smart_property h2 {
  font-size: 30px;
  font-family: 'Nunito';
  color: #484848;
  font-weight: bold;
  line-height: 1.2;
}
.search_smart_property p {
  font-size: 16px;
  font-family: 'Nunito';
  color: #484848;
  line-height: 1.875;
  margin-bottom: 30px;
}
.search_smart_property button.ssp_btn {
  background-color: #ffffff;
  -webkit-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
  -moz-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
  -o-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
  box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
  border-radius: 8px;
  color: #484848;
  font-size: 16px;
  font-family: 'Nunito';
  font-weight: bold;
  height: 60px;
  line-height: 1.2;
  width: 200px;
}
.search_smart_property button.ssp_btn:hover {
  background-color: #3e4c66;
  color: #ffffff;
}
.icon_hvr_img_box {
  background-size: cover;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  cursor: pointer;
  height: auto;
  margin-bottom: 30px;
  overflow: hidden;
  padding: 30px 0;
  position: relative;
  text-align: center;
  min-height: 300px;
}
.icon_hvr_img_box:before {
  background-color: #ffffff;
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.icon_hvr_img_box:hover:before {
  background-color: rgba(29, 41, 62, 0.8);
}
.icon_hvr_img_box .icon {
  color: #ff5a5f;
  font-size: 50px;
  margin-bottom: 5px;
  position: relative;
  text-align: center;
}
.icon_hvr_img_box .details {
  position: relative;
}
.icon_hvr_img_box:hover .details h4,
.icon_hvr_img_box:hover .details p,
.icon_hvr_img_box:hover .overlay .icon {
  color: #ffffff;
}
/*== Single Property ==*/
.single_property_title {
  position: relative;
}
.listing_single_row {
  margin-bottom: 25px;
  margin-top: -100px;
}
.single_property_title .upload_btn {
  border-radius: 8px;
  background-color: rgb(255, 255, 255);
  -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  -o-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  color: rgb(72, 72, 72);
  cursor: pointer;
  display: block;
  font-size: 14px;
  font-family: 'Nunito';
  height: 45px;
  line-height: 2.1;
  outline: none;
  position: relative;
  text-align: center;
  width: 150px;
}
.single_property_title .upload_btn span {
  font-size: 20px;
  padding-right: 5px;
}
.single_property_title.multicolor_style h2,
.single_property_title.multicolor_style p,
.single_property_social_share.multicolor_style h2,
.single_property_social_share.multicolor_style h2 small {
  color: #ffffff;
}
.single_property_social_share h2 {
  font-size: 30px;
  font-family: 'Nunito';
  color: rgb(50 108 161);
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 0;
}
.single_property_social_share h2 small {
  font-size: 18px;
}
.single_property_social_share.style2 {
  position: absolute;
  right: 30px;
}
.spss ul li {
  border-radius: 8px;
  background-color: rgb(0, 106, 112);
  height: 35px;
  line-height: 35px;
  text-align: center;
  width: 35px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.spss ul li:hover {
  background-color: rgb(62, 76, 102);
  cursor: pointer;
}
.spss ul li a {
  font-size: 14px;
  color: #ffffff;
}
.spss.style2 ul li {
  background-color: #ffffff;
}
.spss.style2 ul li a {
  color: #484848;
}
.spls_style_two {
  position: relative;
}
.spls_style_two .overlay {
  bottom: 0;
  border-radius: 8px;
  background-color: rgba(29, 41, 62, 0.8);
  cursor: pointer;
  left: 0;
  margin-bottom: 0;
  padding-top: 35%;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
}
.spls_style_two .overlay .title {
  font-size: 26px;
  font-family: 'Nunito';
  color: rgb(255, 255, 255);
  font-weight: bold;
  line-height: 1;
}
.spls_style_two img {
  border-radius: 8px;
}
.faq_according .accordion .card.floor_plan {
  outline: none;
}
.faq_according .accordion .card.floor_plan .card-header.active .btn-link {
  color: #484848;
}
.faq_according .accordion .card.floor_plan .card-header.active {
  border: 1px solid #ebebeb;
}
.faq_according .accordion .card.floor_plan .card-header .btn-link ul li {
  display: -webkit-inline-flex;
  display: -moz-inline-flex;
  display: -ms-inline-flex;
  display: -o-inline-flex;
  display: inline-flex;
}
.single_page_listing_tab {
  position: relative;
}
.single_page_listing_tab .nav-tabs {
  border: none;
  margin-top: 45px;
  position: absolute;
  z-index: 9;
}
.single_page_listing_tab .nav-tabs .nav-item {
  border: none;
  margin-right: 10px;
}
.single_page_listing_tab .nav-tabs .nav-item .nav-link {
  border-radius: 8px;
  background-color: rgba(15, 21, 31, 0.502);
  border: 1px solid transparent;
  font-size: 20px;
}
.single_page_listing_tab .nav-tabs .nav-link.active {
  border: none;
}
.single_page_listing_style {
  position: relative;
}
.property_video {
  padding: 30px;
  position: relative;
}
.property_video .thumb {
  border-radius: 8px;
  overflow: hidden;
}
.property_video .thumb .pro_img {
  min-height: 390px;
}
.property_video .thumb .overlay_icon a {
  background-color: rgb(255, 90, 95);
  border-radius: 50%;
  color: #ffffff;
  font-size: 22px;
  height: 70px;
  left: 0;
  line-height: 70px;
  margin: 0 auto;
  position: absolute;
  right: 0;
  text-align: center;
  top: 40%;
  width: 70px;
  z-index: 99;
}
.property_video .thumb .overlay_icon a:after {
  background-color: rgb(255, 90, 95, 0.2);
  border-radius: 100%;
  content: '';
  height: 90px;
  left: -10px;
  position: absolute;
  top: -10px;
  width: 90px;
  z-index: -1;
}
.property_video .thumb .overlay_icon a .map_img_icon {
  width: 23px;
}
.video_popup_btn {
  border-radius: 50%;
  display: block;
  height: 70px;
  line-height: 70px;
  margin: 0 auto;
  text-align: center;
  width: 70px;
  animation: pulse-black 2s infinite;
}
.video_popup_btn span {
  color: #ffffff;
  font-size: 22px;
}
.video_popup_btn.red {
  background: rgba(255, 90, 95, 1);
  box-shadow: 0 0 0 0 rgba(255, 90, 95, 1);
  animation: pulse-red 2s infinite;
}
@keyframes pulse-red {
  0% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(255, 90, 95, 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 15px rgba(255, 90, 95, 0);
  }
  100% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(255, 90, 95, 0);
  }
}
.lsd_list {
  margin-bottom: 40px;
  position: relative;
}
.lsd_list li {
  border-radius: 8px;
  background-color: rgb(247, 247, 247);
  height: 40px;
  padding: 6px 25px;
  text-align: center;
}
.lsd_list li a {
  color: #484848;
  font-size: 14px;
  font-family: 'Nunito';
  color: rgb(72, 72, 72);
  line-height: 1.2;
}
.lsd_list li:hover a {
  color: #ff5a5f;
}
.listing_single_description {
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(235, 235, 235);
  border-radius: 8px 8px 0 0;
  padding: 30px;
}
.listing_single_description.style2 {
  border-radius: 0;
}
.listing_single_description2 {
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(235, 235, 235);
  border-bottom: none;
  border-radius: 8px 8px 0 0;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  position: relative;
  padding: 30px;
}
.listing_single_description .card.card-body {
  border: none;
  padding: 0;
}
.white_goverlay {
  position: relative;
}
.white_goverlay:before {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.2) 20%,
    rgba(255, 255, 255, 0.3) 30%,
    rgba(255, 255, 255, 0.8) 20%
  );
  bottom: 0;
  content: '';
  height: 90px;
  left: 0;
  position: absolute;
  right: 0;
  width: 100%;
  z-index: 1;
}
.white_goverlay.z-1:before {
  z-index: -1;
}
.additional_details {
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(235, 235, 235);
  padding: 30px;
  position: relative;
}
.additional_details ul li p {
  font-size: 14px;
  font-family: 'Nunito';
  color: rgb(72, 72, 72);
  line-height: 2.857;
  margin-bottom: 0;
}
.additional_details ul li p span {
  font-size: 14px;
  font-family: 'Nunito';
  color: rgb(72, 72, 72);
  font-weight: bold;
  line-height: 2.857;
}
.property_attachment_area {
  border-radius: 0 0 8px 8px;
}
.property_attachment_area,
.walkscore_area {
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(235, 235, 235);
  border-radius: 8px;
  padding: 30px;
  position: relative;
}
.iba_container {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  margin-bottom: 25px;
}
.icon_box_area {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
}
.icon_box_area .score {
  border-radius: 8px;
  background-color: rgb(247, 247, 247);
  height: 80px;
  line-height: 80px;
  width: 80px;
  text-align: center;
}
.icon_box_area.style2 .details {
  padding: 25px 20px 0;
}
.icon_box_area .details {
  padding: 15px 20px;
}
.icon_box_area .details h5 {
  font-size: 16px;
  font-family: 'Nunito';
  color: rgb(72, 72, 72);
  font-weight: bold;
  line-height: 1.5;
  margin-bottom: 0;
}
.icon_box_area .details p {
  font-size: 14px;
  font-family: 'Nunito';
  color: rgb(72, 72, 72);
  margin-bottom: 0;
}
.walkscore_area .more_info {
  color: rgb(72, 72, 72);
  font-size: 14px;
  font-family: 'Nunito';
  line-height: 1.714;
  text-decoration: underline;
}
.whats_nearby {
  border: 1px solid rgb(235, 235, 235);
  border-radius: 8px;
  background-color: rgb(255, 255, 255);
  padding: 30px;
  position: relative;
}
.education_distance {
  display: grid;
  position: relative;
}
.education_distance h5 {
  font-size: 16px;
  font-family: 'Nunito';
  color: rgb(72, 72, 72);
  font-weight: bold;
  line-height: 2;
  margin-bottom: 0;
}
.education_distance h5 span {
  color: #4585ff;
  font-size: 24px;
  margin-right: 5px;
}
.education_distance.style2 h5 span {
  color: #fb8855;
}
.education_distance.style3 h5 span {
  color: #92d060;
}
.single_line .para {
  color: rgb(72, 72, 72);
  font-size: 14px;
  font-family: 'Nunito';
  float: left;
  line-height: 2.143;
  margin-bottom: 0;
}
.single_line .para span {
  color: #767676;
}
.single_line .review {
  float: right;
  margin-bottom: 0;
}
.single_line .review li {
  margin-right: 3px;
}
.single_line .review li:nth-child(5) {
  margin-right: 25px;
}
.single_line .review li:nth-child(5) a {
  color: #e1e1e1;
}
.single_line .review span.total_rive_count {
  font-size: 14px;
  font-family: 'Nunito';
  color: rgb(118, 118, 118);
  line-height: 2.143;
}
.single_line .review li a {
  font-size: 14px;
  color: rgb(188, 197, 42);
  line-height: 2.143;
}
/*== Listing Single V5 Page Sticky Nav ==*/
.sticky_heading {
  -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  -o-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
}
.sticky-nav-tabs {
  -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  -o-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
}
.sticky-ad-tabs {
  -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  -o-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
}
.sticky-nav-tabs-container {
  background-color: #ffffff;
  display: inline-block;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  z-index: 1;
  -webkit-transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}
.sticky-nav-tabs-container--top-first {
  padding-top: 13px;
  position: fixed;
  top: 0;
  -webkit-transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  text-align: center;
  -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  -o-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
}
.sticky-nav-tabs-container--top-second {
  background-color: #ffffff;
  padding-top: 13px;
  height: 70px;
  position: fixed;
  text-align: center;
  top: 0;
  z-index: 9;
  -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  -o-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
}
.sticky-nav-tabs-container li:active,
.sticky-nav-tabs-container li:hover,
.sticky-nav-tabs-container li:visited,
.sticky-nav-tabs-container li.active {
  border-bottom: 1px solid #ff5a5f;
}
.sticky-nav-tabs-container li.active a {
  color: #ff5a5f;
}
.sticky-nav-tabs-container li:visited {
  border-bottom: 1px solid #ff5a5f;
}
.sticky-nav-tabs-container li:visited a {
  color: #ff5a5f;
}
.sticky-nav-tabs-container li {
  background-color: #fff;
}
.sticky-nav-tabs-container li a {
  background-color: #fff;
  font-size: 16px;
  font-family: 'Nunito';
  color: rgb(72, 72, 72);
  font-weight: bold;
}
.sticky-ad-tabs-container {
  background-color: #ffffff;
  display: inline-block;
  /* left: 0; */
  margin: 0 auto;
  position: absolute;
  /* right: 0; */
  text-align: center;
  top: 0;
  z-index: 0;
  -webkit-transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}
.sticky-ad-tabs-container--top-first {
  padding-top: 13px;
  position: fixed;
  top: 65px;
  -webkit-transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  text-align: center;
  -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  -o-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
}
.sticky-ad-tabs-container--top-second {
  background-color: #ffffff;
  padding-top: 0px;
  height: 70px;
  position: fixed;
  text-align: center;
  top: 65px;
  z-index: 9;
  -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  -o-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
}
.sticky-ad-tabs-container li:active,
.sticky-ad-tabs-container li:hover,
.sticky-ad-tabs-container li:visited,
.sticky-ad-tabs-container li.active {
  border-bottom: 1px solid #ff5a5f;
}
.sticky-ad-tabs-container li.active a {
  color: #ff5a5f;
}
.sticky-ad-tabs-container li:visited {
  border-bottom: 1px solid #ff5a5f;
}
.sticky-ad-tabs-container li:visited a {
  color: #ff5a5f;
}
.sticky-ad-tabs-container li {
  background-color: #fff;
}
.sticky-ad-tabs-container li a {
  background-color: #fff;
  font-size: 16px;
  font-family: 'Nunito';
  color: rgb(72, 72, 72);
  font-weight: bold;
}
.sticky-nav-tab {
  color: #221f1f;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding: 15px 25px 18px;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.sticky-nav-tab-slider {
  position: absolute;
  bottom: 0;
  width: 0;
  height: 6px;
  background-color: #66b1f1;
  -webkit-transition: left 0.3s ease;
  -moz-transition: left 0.3s ease;
  -o-transition: left 0.3s ease;
  transition: left 0.3s ease;
}
.listing_single_property_compare {
  position: relative;
  text-align: center;
}
.listing_single_property_compare ul li.list-inline-item {
  margin-right: 25px;
  text-align: center;
}
.listing_single_property_compare ul li .icon {
  background-color: #e3e3e3;
  border-radius: 50%;
  height: 50px;
  line-height: 50px;
  margin: 0 auto 15px;
  width: 50px;
}
.listing_single_property_compare ul li .icon span {
  color: #484848;
}
.listing_single_property_compare ul li p {
  font-size: 14px;
  font-family: 'Nunito';
  color: rgb(72, 72, 72);
  line-height: 1.857;
  margin-bottom: 0;
}
/*== Why Choose Us ==*/
.why_chose_us {
  background-color: #ffffff;
  border-radius: 8px;
  margin-bottom: 30px;
  padding: 50px 41px 30px 41px;
  position: relative;
  text-align: center;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.why_chose_us:hover {
  cursor: pointer;
  -webkit-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
  -moz-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
  -o-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
  box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
}
.why_chose_us .icon {
  background-color: #ffe8e9;
  border-radius: 50%;
  height: 130px;
  line-height: 130px;
  margin: 0 auto;
  width: 130px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.why_chose_us:hover .icon {
  background-color: #ff5a5f;
}
.why_chose_us:hover .icon span {
  color: #ffffff;
}
.why_chose_us .icon span {
  color: #ff5a5f;
  font-size: 68px;
  line-height: 130px;
}
.why_chose_us .details {
  margin-top: 50px;
}
.why_chose_us .details h4 {
  font-family: 'Nunito';
  color: #484848;
  font-weight: bold;
}
.why_chose_us .details p {
  font-size: 14px;
  font-family: 'Nunito';
  line-height: 1.714;
}
.why_chose_us.style2 {
  border: 1px solid #ebebeb;
  overflow: hidden;
}
.why_chose_us.style2 .icon {
  background-color: #f7f7f7;
}
.why_chose_us.style2:hover:before {
  background-color: #ff5a5f;
  bottom: 0;
  content: '';
  height: 5px;
  left: 0;
  position: absolute;
  right: 0;
  width: 100%;
}
.why_chose_us.style2:hover .icon {
  background-image: -moz-linear-gradient(
    0deg,
    rgb(250, 124, 65) 0%,
    rgb(255, 101, 101) 100%
  );
  background-image: -webkit-linear-gradient(
    0deg,
    rgb(250, 124, 65) 0%,
    rgb(255, 101, 101) 100%
  );
  background-image: -ms-linear-gradient(
    0deg,
    rgb(250, 124, 65) 0%,
    rgb(255, 101, 101) 100%
  );
}
.features_row {
  border-radius: 8px;
  background-color: #ffffff;
  -webkit-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
  -moz-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
  -o-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
  box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
  margin-bottom: 70px;
  margin-top: -220px;
}
.why_chose_us.home6 {
  border-radius: 0;
  border-right: 1px solid #ebebeb;
  margin-bottom: 0;
  overflow: hidden;
  padding: 30px 20px;
}
.why_chose_us.home6.bdrrn {
  border-right: none;
}
.why_chose_us.home6,
.why_chose_us.home6:hover {
  box-shadow: none;
}
.why_chose_us.home6 .icon {
  background-color: #3e4c66;
  border-radius: 50%;
  height: 100px;
  margin: 0 auto;
  width: 100px;
}
.why_chose_us.home6:hover .icon {
  background-color: #ff5a5f;
}
.why_chose_us.home6:hover .icon span {
  color: #ffffff;
}
.why_chose_us.home6 .icon span {
  color: #ffffff;
  font-size: 50px;
  line-height: 100px;
}
.why_chose_us.home6 .details {
  margin-top: 30px;
}
.why_chose_us.home6:hover .details h4 {
  color: #ff5a5f;
}
.why_chose_us.home7 {
  padding: 25px 50px 0 41px;
}
.why_chose_us.home7:hover {
  box-shadow: none;
}
.why_chose_us.home7 .icon {
  background-image: -moz-linear-gradient(
    148deg,
    rgb(253, 85, 89) 0%,
    rgb(255, 51, 125) 100%
  );
  background-image: -webkit-linear-gradient(
    148deg,
    rgb(253, 85, 89) 0%,
    rgb(255, 51, 125) 100%
  );
  background-image: -ms-linear-gradient(
    148deg,
    rgb(253, 85, 89) 0%,
    rgb(255, 51, 125) 100%
  );
  height: 110px;
  margin: 0 auto;
  width: 110px;
  position: relative;
}
.why_chose_us.home7 .icon:before {
  background-color: rgba(254, 59, 117, 0.1);
  border-radius: 50%;
  bottom: -10px;
  content: '';
  left: -10px;
  position: absolute;
  right: -10px;
  top: -10px;
}
.why_chose_us.home7 .icon span {
  color: #ffffff;
  font-size: 50px;
  line-height: 110px;
}
.why_chose_us.home10:hover {
  background-color: #ff5a5f;
}
.why_chose_us.home10:hover .icon {
  background-color: #ffffff;
}
.why_chose_us.home10:hover .icon span {
  color: #ff5a5f;
}
.why_chose_us.home10:hover .details h4,
.why_chose_us.home10:hover .details p {
  color: #ffffff;
}
/*== Why Choose Us ==*/
.our-testimonial {
  background-color: #1d293e;
}
.our-testimonial:before {
  background-image: url('../images/background/2.jpg');
  bottom: 0;
  content: '';
  left: 0;
  opacity: 0.5;
  position: absolute;
  right: 0;
  top: 0;
}
.our-testimonial.home5 {
  background-color: #232733;
}
.our-testimonial.home5:before {
  background-image: url('../images/background/6.jpg');
  bottom: 0;
  content: '';
  left: 0;
  opacity: 0.149;
  position: absolute;
  right: 0;
  top: 0;
}
.you-looking-for {
  background-color: rgb(8, 15, 29);
  height: 365px;
}
.you-looking-for:before {
  background-image: url('../images/background/8.jpg');
  bottom: 0;
  content: '';
  left: 0;
  opacity: 1;
  position: absolute;
  right: 0;
  top: 0;
}
.modern-apertment {
  background-color: rgb(29, 41, 62);
  height: 549px;
}
.modern-apertment:before {
  background-image: url('../images/background/9.jpg');
  bottom: 0;
  content: '';
  left: 0;
  opacity: 1;
  position: absolute;
  right: 0;
  top: 0;
}
.our-hot-offer {
  background-image: url('../images/background/11.jpg');
  height: 581px;
}
.our-hot-offer:before {
  background-image: -moz-linear-gradient(
    0deg,
    rgb(60, 4, 135) 0%,
    rgb(76, 21, 148) 100%
  );
  background-image: -webkit-linear-gradient(
    0deg,
    rgb(60, 4, 135) 0%,
    rgb(76, 21, 148) 100%
  );
  background-image: -ms-linear-gradient(
    0deg,
    rgb(60, 4, 135) 0%,
    rgb(76, 21, 148) 100%
  );
  bottom: 0;
  content: '';
  left: 0;
  opacity: 0.851;
  position: absolute;
  right: 0;
  top: 0;
}
.our_hotoffer {
  position: relative;
  margin-top: 120px;
}
.our_hotoffer h2 {
  font-size: 30px;
  font-family: 'Nunito';
  color: #ffffff;
  font-weight: bold;
  line-height: 1.2;
}
.our_hotoffer p {
  font-size: 16px;
  font-family: 'Nunito';
  color: #ffffff;
  line-height: 1.2;
  margin-bottom: 25px;
}
.our_hotoffer button.btn.ht_offer {
  border-radius: 8px;
  background-image: -moz-linear-gradient(
    148deg,
    rgb(253, 85, 89) 0%,
    rgb(255, 51, 125) 100%
  );
  background-image: -webkit-linear-gradient(
    148deg,
    rgb(253, 85, 89) 0%,
    rgb(255, 51, 125) 100%
  );
  background-image: -ms-linear-gradient(
    148deg,
    rgb(253, 85, 89) 0%,
    rgb(255, 51, 125) 100%
  );
  box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
  color: #ffffff;
  font-size: 16px;
  font-family: 'Nunito';
  font-weight: bold;
  height: 50px;
  line-height: 1.2;
  width: 170px;
}
.View_All {
  position: absolute;
  left: 443.484px;
  top: 3835.4px;
  z-index: 419;
}

.our-testimonials {
  border-bottom: 1px solid #ebebeb;
}
.testimonial_grid {
  position: relative;
  text-align: center;
}
.testimonial_grid .thumb {
  position: relative;
}
.testimonial_grid .thumb img {
  border: 10px solid rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  display: inline;
  height: 114px;
  width: 114px;
}
.testimonial_grid .details {
  padding: 20px 40px;
}
.testimonial_grid .details h4 {
  color: #ffffff;
  margin-bottom: 0;
}
.testimonial_grid .details p {
  font-size: 14px;
  font-family: 'Nunito';
  color: #ffffff;
  line-height: 1.429;
  margin-bottom: 0;
}
.testimonial_grid.style2 {
  padding: 40px 0 0;
}
.testimonial_grid.style2 .details h4,
.testimonial_grid.style2 .details p {
  color: #484848;
}
.testimonial_grid.style2 .thumb {
  position: relative;
}
.testimonial_grid.style2 .thumb .tg_quote {
  border-radius: 50%;
  background-image: -moz-linear-gradient(
    148deg,
    rgb(253, 85, 89) 0%,
    rgb(255, 51, 125) 100%
  );
  background-image: -webkit-linear-gradient(
    148deg,
    rgb(253, 85, 89) 0%,
    rgb(255, 51, 125) 100%
  );
  background-image: -ms-linear-gradient(
    148deg,
    rgb(253, 85, 89) 0%,
    rgb(255, 51, 125) 100%
  );
  -webkit-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
  -moz-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
  -o-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
  box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
  height: 43px;
  left: 0;
  line-height: 43px;
  margin: 0 auto;
  position: absolute;
  right: -100px;
  top: 70px;
  width: 43px;
}
.testimonial_grid.style2 .thumb .tg_quote span {
  color: #ffffff;
}
.testimonial_grid.style2 .thumb img {
  border: 5px solid #ffffff;
  -webkit-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
  -moz-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
  -o-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
  box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
}
.testimonial_grid_slider.owl-carousel.owl-theme .owl-nav {
  margin: 0;
}
.testimonial_grid_slider.owl-carousel.owl-theme .owl-dots .owl-dot {
  background-color: transparent;
  margin-right: 15px;
  vertical-align: middle;
}
.testimonial_grid_slider.owl-carousel.owl-theme .owl-dots .owl-dot span {
  background-color: #ffffff;
  border-radius: 50%;
  margin: 3px;
  width: 6px;
  height: 6px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.testimonial_grid_slider.owl-carousel.owl-theme .owl-dots .owl-dot.active span,
.testimonial_grid_slider.owl-carousel.owl-theme .owl-dots .owl-dot:hover span {
  background-color: #ffffff !important;
  border: 5px solid rgb(92, 106, 124);
  height: 15px;
  width: 15px;
}
.testimonial_grid_slider.style2.owl-carousel.owl-theme .owl-dots .owl-dot span {
  background-color: #dedede;
}
.testimonial_grid_slider.style2.owl-carousel.owl-theme
  .owl-dots
  .owl-dot.active
  span,
.testimonial_grid_slider.style2.owl-carousel.owl-theme
  .owl-dots
  .owl-dot:hover
  span {
  background-color: #24324a !important;
  border: 5px solid #dedede;
}
.start_partner h2 {
  color: #ffffff;
  line-height: 1.2;
}
.start_partner p {
  color: #ffffff;
  margin-bottom: 0;
  line-height: 1.2;
}
.parner_reg_btn a.btn {
  border-radius: 8px;
  background-color: rgb(255, 120, 124);
  -webkit-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  -moz-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  -o-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  height: 60px;
  line-height: 50px;
  width: 200px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.parner_reg_btn a.btn:hover,
.parner_reg_btn a.btn:active,
.parner_reg_btn a.btn:focus {
  background-color: #ffffff;
  color: #ff5a5f;
}
.parner_reg_btn.home5 a.btn {
  background-color: #ffffff;
  color: #fa7a44;
}
.parner_reg_btn.home5 a.btn:hover,
.parner_reg_btn.home5 a.btn:active,
.parner_reg_btn.home5 a.btn:focus {
  background-color: #fa7a44;
  border: 1px solid #ffffff;
  color: #ffffff;
}
.parner_reg_btn.home7 a.btn {
  background-image: -moz-linear-gradient(
    148deg,
    rgb(253, 85, 89) 0%,
    rgb(255, 51, 125) 100%
  );
  background-image: -webkit-linear-gradient(
    148deg,
    rgb(253, 85, 89) 0%,
    rgb(255, 51, 125) 100%
  );
  background-image: -ms-linear-gradient(
    148deg,
    rgb(253, 85, 89) 0%,
    rgb(255, 51, 125) 100%
  );
  -webkit-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
  -moz-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
  -o-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
  box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
  color: #ffffff;
}

.container-fluid.style2 {
  max-width: 1720px;
  width: 90%;
}
.courses-list {
  background-color: #f9fafc;
}
.my_course_content {
  border-radius: 5px;
  background-color: #ffffff;
  -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  -o-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  position: relative;
  padding: 20px 0 0;
}
.courses_list_heading,
.my_course_content_header {
  background-color: #ffffff;
  border: 1px solid rgb(235, 235, 235);
  border-radius: 5px 5px 0 0;
  -webkit-box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.09);
  -o-box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.09);
  box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.09);
  padding: 20px;
}
.my_course_content_header {
  box-shadow: none;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  padding: 20px 20px 30px;
}
.my_course_content_list {
  position: relative;
}
.my_course_content_list .mc_content_list {
  border: 1px solid rgb(235, 235, 235);
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  padding: 30px;
}
.my_course_content_list .mc_content_list:hover {
  background-color: #f9fafc;
}
.my_course_content_list .mc_content_list:last-child {
  border-bottom: none;
}
.my_course_content_list .mc_content_list .thumb {
  display: -webkit-inline-flex;
  display: -moz-inline-flex;
  display: -ms-inline-flex;
  display: -o-inline-flex;
  display: inline-flex;
  margin-right: 20px;
  min-height: 150px;
  min-width: 250px;
  overflow: hidden;
  position: relative;
}
.my_course_content_list .mc_content_list .thumb .overlay {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  bottom: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.my_course_content_list .mc_content_list:hover .thumb .overlay {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  text-align: center;
}
.my_course_content_list .mc_content_list .thumb .overlay ul {
  margin-top: 110px;
}
.my_course_content_list .mc_content_list .thumb .overlay ul li {
  border-radius: 17.5em;
  border-style: solid;
  border-width: 2px;
  border-color: #ffffff;
  background-color: transparent;
  height: 35px;
  text-align: center;
  width: 100px;
}
.my_course_content_list .mc_content_list .thumb .overlay ul li a {
  color: #ffffff;
  line-height: 2;
}
.my_course_content_list .mc_content_list .thumb img {
  border-radius: 5px;
}
.my_course_content_list .mc_content_list .details .mc_footer ul {
  display: inline;
}
.my_course_content_list .mc_content_list .details .mc_footer ul:first-child {
  margin-right: 25px;
}
.my_course_content_list .mc_content_list .details .mc_footer ul.mc_review li {
  margin-right: 3px;
}
.my_course_content_list
  .mc_content_list
  .details
  .mc_footer
  ul.mc_review
  li.fp_pdate {
  font-size: 18px;
  font-family: 'Nunito';
  color: rgb(199, 85, 51);
  font-weight: bold;
  line-height: 1.2;
  margin-left: 15px;
}
.my_course_content_list .mc_content_list .details .mc_footer {
  margin-top: 23px;
}
.my_course_content_list
  .mc_content_list
  .details
  .mc_footer
  ul.mc_review
  li.fp_pdate
  a {
  color: rgb(199, 85, 51);
}
.my_course_content_list
  .mc_content_list
  .details
  .mc_footer
  ul.mc_review
  li:nth-child(6)
  a {
  font-size: 14px;
  font-family: 'Open Sans';
  color: rgb(126, 126, 126);
  line-height: 1.2;
}
.my_course_content_list
  .mc_content_list
  .details
  .mc_footer
  ul.mc_review
  li
  a
  i {
  color: #bcc52a;
  line-height: 1.2;
}
.my_course_content_list .mc_content_list .details .mc_content .subtitle {
  font-size: 15px;
  font-family: 'Open Sans';
  color: rgb(126, 126, 126);
  line-height: 1.2;
  margin-bottom: 5px;
}
.my_course_content_list .mc_content_list .details .mc_content .subtitle .title {
  font-size: 18px;
  font-family: 'Nunito';
  color: rgb(10, 10, 10);
  line-height: 1.333;
}
.my_course_content_list .mc_content_list .details .mc_content h5.title span {
  border-radius: 5px;
  background-color: rgb(27, 213, 75);
  display: inline-block;
  height: 30px;
  margin-left: 10px;
  text-align: center;
  width: 90px;
}
.my_course_content_list
  .mc_content_list
  .details
  .mc_content
  h5.title
  span.style2 {
  background-color: #ff1053;
}
.my_course_content_list
  .mc_content_list
  .details
  .mc_content
  h5.title
  span
  .tag {
  color: #ffffff;
  font-size: 13px;
  font-family: 'Open Sans';
  line-height: 2.2;
}
.my_course_content_list .mc_content_list .details .mc_content p {
  font-size: 14px;
  font-family: 'Open Sans';
  color: rgb(126, 126, 126);
  line-height: 1.714;
}
.my_setting_content {
  border-radius: 5px;
  background-color: #ffffff;
  -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  position: relative;
}
.my_setting_content_header {
  border: 1px solid rgb(235, 235, 235);
  padding: 30px;
}
.my_profile_setting_input.form-group {
  margin-bottom: 30px;
}
.my_profile_setting_input label,
.my_profile_setting_textarea label {
  font-size: 16px;
  font-family: 'Nunito';
  color: rgb(72, 72, 72);
  font-weight: bold;
  line-height: 1.5;
}
.my_profile_setting_input .form-control {
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(235, 235, 235);
  border-radius: 8px;
  height: 52px;
  padding-left: 20px;
}
.my_profile_setting_input .form-control .disabled {
  background-color: #dddddd !important;
  border: 1px solid rgb(235, 235, 235);
  border-radius: 8px;
  height: 52px;
  padding-left: 20px;
}
.my_profile_setting_input .form-control::placeholder {
  font-size: 14px;
  font-family: 'Nunito';
  color: rgb(72, 72, 72);
  line-height: 1.2;
}
.my_profile_setting_textarea {
  margin-bottom: 25px;
}
.my_profile_setting_input .btn1 {
  background-color: rgba(255, 90, 95, 0);
  border: 2px solid rgb(255, 90, 95);
  border-radius: 8px;
  -webkit-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  -moz-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  -o-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  font-size: 16px;
  font-family: 'Nunito';
  color: rgb(255, 90, 95);
  font-weight: bold;
  line-height: 1.2;
  height: 50px;
  margin-right: 30px;
  width: 200px;
}
.my_profile_setting_input .btn1:hover {
  background-color: rgb(255, 90, 95);
  color: #ffffff;
}
.my_profile_setting_input .btn2 {
  background-color: #ffc107 !important;
  border: 2px solid #ffc107 !important;
  border-radius: 8px;
  -webkit-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  -moz-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  -o-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  font-size: 16px;
  font-family: 'Nunito';
  color: #000 !important;
  font-weight: bold;
  line-height: 1.2;
  height: 50px;
  width: 200px;
}
.my_profile_setting_input .btn2:hover {
  color: rgb(255, 90, 95);
  background-color: #ffffff;
  border: 2px solid rgb(255, 90, 95);
}
.my_profile_setting_input .btn3 {
  background-color: rgb(29, 41, 62);
  border: 2px solid rgb(29, 41, 62);
  border-radius: 8px;
  -webkit-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  -moz-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  -o-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  font-size: 16px;
  font-family: 'Nunito';
  color: rgb(255, 255, 255);
  font-weight: bold;
  line-height: 1.2;
  height: 50px;
  width: 200px;
}
.my_profile_setting_input .btn3:hover {
  border: 2px solid rgb(29, 41, 62);
  color: rgb(29, 41, 62);
  background-color: #ffffff;
}
.my_profile_setting_input2 label {
  font-size: 14px;
  font-family: 'Open Sans';
  color: rgb(10, 10, 10);
  line-height: 1.2;
}
.my_profile_setting_input .form-control:focus {
  outline: none;
}
.admore_btn {
  border-radius: 8px;
  background-color: rgb(236, 236, 236);
  font-size: 16px;
  font-family: 'Nunito';
  color: rgb(72, 72, 72);
  font-weight: bold;
  line-height: 1.2;
  height: 50px;
  width: 160px;
}
.my_setting_savechange_btn {
  border-radius: 5px;
  background-color: rgb(36, 65, 231);
  font-size: 15px;
  font-family: 'Open Sans';
  color: #ffffff;
  line-height: 1.2;
  height: 55px;
  width: 150px;
}
.view_edit_delete_list li:hover {
  cursor: pointer;
}
.view_edit_delete_list li {
  background-color: rgb(247, 247, 247);
  border-radius: 8px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  width: 45px;
}
.view_edit_delete_list li a span {
  color: #ff5a5f;
  font-size: 20px;
}
.courses_list_heading.style2 {
  box-shadow: none;
}
.courses_container {
  position: relative;
  border-radius: 0 0 5px 5px;
  background-color: #ffffff;
  -webkit-box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.09);
  box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.09);
}
.courses_container.style2 {
  box-shadow: none;
}
.courses_container .courses_list_content {
  border: 1px solid rgb(235, 235, 235);
  padding: 20px;
  position: relative;
}
.courses_container .courses_list_content:hover {
  background-color: #f9fafc;
}
.courses_list_content .feat_property.list {
  border: none;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  margin-bottom: 0;
}
.courses_list_content .feat_property.list {
  background-color: transparent;
}
.courses_list_content .feat_property.list:hover {
  box-shadow: none;
}
.courses_list_content .feat_property.list .details .tc_content p {
  font-size: 14px;
  font-family: 'Open Sans';
  color: rgb(126, 126, 126);
  line-height: 1.714;
}
.courses_list_content .feat_property.list:hover .details .tc_content h5 {
  color: #ff5a5f;
}
.original_price {
  font-size: 13px;
  font-family: 'Nunito';
  color: rgb(126, 126, 126);
  line-height: 1.2;
}
.courses_list_content .feat_property.list .details .fp_footer ul.tc_review {
  margin-bottom: 0;
  margin-right: 20px;
}
.courses_list_content .feat_property.list .details .fp_footer ul.tc_review li {
  margin-right: 4px;
}
.courses_list_content
  .feat_property.list
  .details
  .fp_footer
  ul.tc_review
  li
  a {
  font-size: 13px;
  font-family: 'FontAwesome';
  color: #bcc52a;
  line-height: 1.2;
}
.courses_list_content
  .feat_property.list
  .details
  .fp_footer
  ul.tc_review
  li:last-child
  a {
  color: #7e7e7e;
}
.courses_list_content .feat_property.list .details .tc_content {
  padding: 0px 0px 20px 15px;
}
.courses_list_content .feat_property.list .details .fp_footer {
  padding: 0 20px;
}
.courses_list_content .feat_property.list .thumb {
  border-radius: 5px;
  overflow: hidden;
  width: 450px;
}
.courses_list_content .feat_property.list .details .fp_footer {
  border: none;
}
/*== Popular Job Categories => Icon Hover Image Box ==*/
.hvr_img_box_container {
  -webkit-background-size: cover;
  background-size: cover;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 35px;
  overflow: hidden;
  position: relative;
  -webkit-box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.09);
  -o-box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.09);
  box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.09);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  z-index: 0;
}
.hvr_img_box_container:before {
  background-color: #ffffff;
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  z-index: 1;
}
.hvr_img_box_container .hvr_img_box {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.hvr_img_box_container:hover .hvr_img_box {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
}
.hvr_img_box {
  position: relative;
  padding: 60px 50px;
}
.hvr_img_box.imgs {
  height: 100%;
  position: absolute;
  width: 100%;
}
.hvr_img_box_container:hover:before {
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.hvr_img_box_container .overlay .details {
  display: block;
  height: 250px;
  padding: 60px 50px;
  position: relative;
  width: 300px;
  z-index: 9;
}
.hvr_img_box_container .overlay .details h5 {
  color: #484848;
  font-family: 'Nunito SemiBold';
  font-size: 18px;
  margin-bottom: 20px;
  text-transform: uppercase;
}
.hvr_img_box_container .overlay .details p {
  color: #7e7e7e;
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 20px;
}
.hvr_img_box_container .overlay .details a {
  color: #192675;
  font-size: 14px;
  line-height: 24px;
}
.hvr_img_box_container .overlay .details a span.span {
  padding-left: 15px;
}
.hvr_img_box_container:hover .overlay .details h5,
.hvr_img_box_container:hover .overlay .details p,
.hvr_img_box_container:hover .overlay .details a {
  color: #ffffff;
}
.pjc_all_btn {
  margin-top: 25px;
}
.pjc_all_btn a.btn {
  border-radius: 4px;
  line-height: 45px;
  padding: 5px 40px;
}
.pjc_all_btn.home5 a.btn {
  border: 1px solid #262626;
  color: #262626;
}
.pjc_all_btn.home5 a.btn:hover {
  border: 1px solid #ff5a5f;
  color: #ffffff;
}
/*== Funfact Counter ==*/
.funfact_one .icon {
  color: #ffffff;
  font-size: 70px;
  line-height: initial;
}
.funfact_one .details ul {
  margin-bottom: 0;
}
.funfact_one .details .timer,
.funfact_one .details ul li {
  color: #ffffff;
  font-family: 'Nunito', sans-serif;
  font-size: 30px;
  font-weight: bold;
  margin-right: -2px;
}
.funfact_one .details ul li:last-child {
  margin: 0;
}
.funfact_one .details h5 {
  color: #ffffff;
  font-size: 18px;
  margin-bottom: 0;
}
.funfact_one .timer {
  color: #ffffff;
  font-size: 25px;
}
.funfact_one p {
  margin-bottom: 0;
}
.funfact_one.home6 {
  text-align: center;
}
.funfact_one.home6 .icon {
  color: #1fbda5;
  font-size: 60px;
  line-height: normal;
  margin-bottom: 30px;
  margin-top: 6px;
}
.funfact_one.home6 .timer {
  color: #252834;
  font-size: 50px;
  font-weight: bold;
  line-height: normal;
}
.funfact_one.home6:hover .icon {
  color: #252834;
}
.brdr_left_right {
  position: relative;
}
.brdr_left_right:before {
  background-color: #383838;
  bottom: 0;
  content: '';
  height: 40px;
  left: -30px;
  position: absolute;
  top: 25px;
  width: 1px;
}
.brdr_left_right.home6:before {
  left: -15px;
}
.brdr_left_right.home3:before {
  background-color: #d5d6d8;
}
.footer_middle_area.home5 {
  background-color: #232733;
}
.footer_middle_area.home6 {
  background-color: #f0f0f0;
  position: relative;
}
/*== Footer Social Widget ==*/
.footer_social_widget {
  position: relative;
}
.footer_social_widget li {
  padding-right: 10px;
}
.footer_social_widget li a {
  color: #8a99b3;
  font-size: 16px;
  font-family: 'FontAwesome';
  line-height: 1.2;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.footer_social_widget.home3 li a {
  color: #777777;
}
.footer_social_widget li:hover a {
  color: #ffffff;
}
.footer_social_widget.home3 li:hover a {
  color: #ff5a5f;
}
.footer_mailchimp_form {
  position: relative;
}
.footer_mailchimp_form .col-auto input.form-control {
  background-color: #354765;
  border: 1px solid #354765;
  border-radius: 25px;
  color: #ffffff;
  height: 50px;
  padding-left: 30px;
  width: 195px;
}
.footer_mailchimp_form.home3 .col-auto input.form-control {
  background-color: #e8eef2;
  border: 1px solid #e8eef2;
  color: #777777;
}
.footer_mailchimp_form.home5 .col-auto input.form-control {
  background-color: #292d39;
  border: 1px solid #292d39;
}
.footer_mailchimp_form.home6 .col-auto input.form-control {
  background-color: #ffffff;
  -webkit-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
  -moz-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
  -o-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
  box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
  border: 1px solid #ffffff;
  color: #484848;
}
.footer_mailchimp_form.home6 .col-auto input.form-control:hover,
.footer_mailchimp_form.home6 .col-auto input.form-control:focus,
.footer_mailchimp_form.home6 .col-auto input.form-control:active {
  -webkit-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
  -moz-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
  -o-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
  box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
}
.footer_mailchimp_form .col-auto input.form-control:hover,
.footer_mailchimp_form .col-auto input.form-control:focus,
.footer_mailchimp_form .col-auto input.form-control:active {
  box-shadow: none;
  outline: none;
}
.footer_mailchimp_form .col-auto input.form-control::placeholder,
.footer_mailchimp_form .col-auto input.form-control:-ms-input-placeholder,
.footer_mailchimp_form .col-auto input.form-control::-ms-input-placeholder {
  color: #8a99b3;
}
.footer_mailchimp_form .col-auto button {
  background-color: #354765;
  border: 1px solid #354765;
  border-radius: 50%;
  height: 50px;
  width: 50px;
}
.footer_mailchimp_form.home3 .col-auto button {
  background-color: #3e4c66;
  border: 1px solid #3e4c66;
  color: #ffffff;
}
.footer_mailchimp_form.home5 .col-auto button {
  background-image: -moz-linear-gradient(
    0deg,
    rgb(250, 124, 65) 0%,
    rgb(255, 101, 101) 100%
  );
  background-image: -webkit-linear-gradient(
    0deg,
    rgb(250, 124, 65) 0%,
    rgb(255, 101, 101) 100%
  );
  background-image: -ms-linear-gradient(
    0deg,
    rgb(250, 124, 65) 0%,
    rgb(255, 101, 101) 100%
  );
  border-radius: 50%;
  height: 50px;
  width: 50px;
}
.footer_mailchimp_form.home6 .col-auto button {
  background-color: #ffffff;
  border: 1px solid #ffffff;
  -webkit-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
  -moz-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
  -o-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
  box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
  color: #484848;
}
.footer_middle_area.home3 .footer_social_widget p {
  color: #969ca9;
}
.footer_middle_area.home3 .footer_social_widget li a {
  color: #969696;
}
.footer_middle_area.home3 .footer_social_widget li:hover a,
.footer_middle_area.home4 .footer_social_widget li:hover a {
  color: #484848;
}
.footer_social_widget.home8 li a {
  color: #969696;
}
.footer_social_widget.home8 li:hover a {
  color: #484848;
}
/*== 1st Layout Footer ==*/
.footer_one {
  padding: 80px 0 70px;
}
.footer_one {
  background-color: #24324a;
}
.footer_middle_area {
  background-color: #1d293e;
}
.footer_bottom_area {
  background-color: #484848;
}
.copyright-widget p {
  font-size: 14px;
  font-family: 'Nunito';
  color: #8a99b3;
  line-height: 2.143;
  margin-bottom: 0;
}
.copyright-widget.home3 p,
.copyright-widget.home6 p {
  color: #777777;
}
.footer_one.home3 {
  background-color: #ffffff;
  border-bottom: 1px solid #e8eef2;
}
.footer_middle_area.home3 {
  background-color: #ffffff;
}
.footer_bottom_area.home3 {
  background-color: #ebeef4;
}
.footer_one.home4 {
  background-color: #f9fafc;
}
.footer_bottom_area.home3 .copyright-widget p,
.footer_bottom_area.home4 .copyright-widget p {
  color: #969696;
}
.footer_bottom_area.home4 {
  background-color: #fafafa;
}
.footer_middle_area.home4 {
  border-top: 1px solid #f0f0f0;
  background-color: #ffffff;
}
.footer_one.home5 {
  background-color: #232733;
  border-bottom: 1px solid rgba(255, 255, 255, 0.102);
}
.footer_one.home8 {
  background-color: transparent;
}
.footer_one.home8:before {
  background-image: url(../images/background/3.html);
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: -75px;
  z-index: -1;
}
/*== Footer Widget ==*/
.footer_about_widget p {
  font-size: 14px;
  font-family: 'Nunito';
  color: #8a99b3;
  line-height: 2.143;
  margin-bottom: 0;
}
.footer_about_widget.home5 p,
.footer_qlink_widget.home5 li a,
.footer_contact_widget.home5 li a,
.footer_social_widget.home5 li a {
  color: #98a2aa;
}
.footer_about_widget.home6 p,
.footer_qlink_widget.home6 li a,
.footer_contact_widget.home6 li a {
  color: #777777;
}
.footer_social_widget.home6 li a {
  color: #484848;
}
.footer_about_widget.home3 p {
  color: #777777;
}
.footer_contact_widget li {
  padding-bottom: 0;
}
.footer_qlink_widget {
  padding-left: 70px;
}
.footer_about_widget h4,
.footer_qlink_widget h4,
.footer_contact_widget h4,
.footer_social_widget h4,
.footer_apps_widget h4 {
  color: #ffffff;
  font-weight: bold;
  margin-bottom: 25px;
}
.footer_about_widget.home3 h4,
.footer_qlink_widget.home3 h4,
.footer_contact_widget.home3 h4,
.footer_social_widget.home3 h4,
.footer_apps_widget.home3 h4 {
  color: #484848;
  font-weight: bold;
  margin-bottom: 25px;
}
.footer_about_widget.home3 li a,
.footer_qlink_widget.home3 li a,
.footer_contact_widget.home3 li a {
  color: #777777;
}
.footer_about_widget.home6 h4,
.footer_qlink_widget.home6 h4,
.footer_contact_widget.home6 h4,
.footer_social_widget.home6 h4,
.footer_apps_widget.home6 h4 {
  font-family: 'Nunito';
  color: #484848;
  font-weight: bold;
  line-height: 1.333;
}
.footer_qlink_widget.home6 li:hover a {
  color: #484848;
}
.footer_about_widget li a,
.footer_qlink_widget li a,
.footer_contact_widget li a {
  font-size: 14px;
  font-family: 'Nunito';
  color: #8a99b3;
  line-height: 2.429;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.footer_about_widget li:hover a,
.footer_qlink_widget li:hover a,
.footer_contact_widget li:hover a {
  color: #ffffff;
  padding-left: 8px;
}
.footer_apps_widget .app_grid .apple_btn,
.footer_apps_widget .app_grid .play_store_btn {
  background-color: #484848;
  border: none;
  border-radius: 4px;
  height: 75px;
  margin-bottom: 15px;
  margin-right: 0;
  width: 220px;
}
.footer_apps_widget .app_grid .apple_btn:hover,
.footer_apps_widget .app_grid .play_store_btn:hover {
  background-color: #ff5a5f;
}
.footer_apps_widget .app_grid .play_store_btn {
  margin-bottom: 0;
}
.footer_apps_widget .app_grid .apple_btn span,
.footer_apps_widget .app_grid .play_store_btn span {
  margin-right: 5px;
}
.footer_apps_widget .app_grid .apple_btn span.title,
.footer_apps_widget .app_grid .play_store_btn span.title {
  color: #ffffff;
}
.footer_apps_widget .app_grid .apple_btn span,
.footer_apps_widget .app_grid .apple_btn span.subtitle,
.footer_apps_widget .app_grid .play_store_btn span,
.footer_apps_widget .app_grid .play_store_btn span.subtitle {
  color: #969696;
}
.footer_apps_widget .app_grid .apple_btn:hover span,
.footer_apps_widget .app_grid .apple_btn:hover span.subtitle,
.footer_apps_widget .app_grid .play_store_btn:hover span,
.footer_apps_widget .app_grid .play_store_btn:hover span.subtitle {
  color: #ffffff;
}
.footer_apps_widget.home3 .app_grid .apple_btn:hover,
.footer_apps_widget.home3 .app_grid .play_store_btn:hover {
  background-color: #369fdb;
}
.footer_apps_widget .app_grid .apple_btn:focus,
.footer_apps_widget.home3 .app_grid .apple_btn:active,
.footer_apps_widget.home3 .app_grid .apple_btn:focus,
.footer_apps_widget.home3 .app_grid .play_store_btn:active,
.footer_apps_widget.home3 .app_grid .play_store_btn:focus {
  box-shadow: none;
  outline: none;
}
.logo-widget {
  position: relative;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
}
.logo-widget span {
  color: #ffffff;
  font-family: 'Nunito', sans-serif;
  font-size: 22px;
  font-weight: bold;
  padding-left: 15px;
  margin-top: 10px;
  text-transform: uppercase;
  vertical-align: middle;
}
.logo-widget.home3 span {
  color: #051925;
}
.logo-widget.home8 span {
  background: linear-gradient(to right, #835bb4 0%, #d7cf96 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.footer_menu_widget ul {
  margin-bottom: 0;
}
.footer_menu_widget ul li {
  margin-right: 0 !important;
  padding-right: 25px;
  vertical-align: middle;
}
.footer_menu_widget ul li a {
  font-size: 14px;
  font-family: 'Nunito';
  color: #8a99b3;
  line-height: 2.143;
}
.footer_menu_widget ul li a:hover {
  color: #ffffff;
}
.footer_menu_widget.home3 ul li a,
.footer_menu_widget.home6 ul li a {
  color: #777777;
}
.footer_menu_widget.home5 ul li a,
.copyright-widget.home5 p {
  color: #98a2aa;
}
.footer_menu_widget.home3 ul li a:hover,
.footer_menu_widget.home6 ul li a:hover {
  color: #484848;
}
.footer_apps_widget.home3 h4,
.footer_about_widget.home4 h4,
.footer_qlink_widget.home4 h4,
.footer_contact_widget.home4 h4,
.footer_apps_widget.home4 h4,
.footer_one.home3 .footer_qlink_widget li:hover a,
.footer_one.home3 .footer_about_widget li:hover a,
.footer_one.home3 .footer_contact_widget li:hover a,
.footer_one.home4 .footer_qlink_widget li:hover a,
.footer_one.home4 .footer_about_widget li:hover a,
.footer_one.home4 .footer_contact_widget li:hover a {
  color: #051925;
}
.footer_about_widget.home8 p {
  line-height: 30px;
}
.footer_one.home8:after {
  background-color: #e2e9f0;
  bottom: 80px;
  content: '';
  height: 1px;
  left: 0;
  position: absolute;
  right: 0;
  width: 100%;
}
.logo-widget.home8 {
  margin-left: 150px;
}
.footer_apps_widget .mailchimp_form .form-control {
  background-color: #1a1a1a;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: none;
  color: #707070;
  height: 52px;
  width: 80%;
}
.footer_apps_widget .mailchimp_form button {
  background-color: transparent;
  border: transparent;
  color: #484848;
  height: 45px;
  margin-left: -62px;
  width: 45px;
}
.footer_qlink_widget.home5 h4,
.footer_apps_widget.home5 h4 {
  color: #ffffff;
}
.footer_one.home3 .footer_apps_widget .mailchimp_form button {
  background-color: #1c327e;
}
.mailchimp_form.home5 .form-control {
  background-color: #fafafa;
}
.footer_apps_widget.home6 .mailchimp_form .form-control {
  background-color: #101219;
}
.footer_menu {
  padding-left: 60px;
}
.footer_menu ul li:last-child {
  border-radius: 4px;
}
.footer_menu ul li:last-child a {
  background-color: transparent !important;
  color: #7c7c7c;
  padding-left: 20px;
}
.footer_menu ul li:last-child:hover a {
  color: #7c7c7c;
}
.footer_menu ul li .bootstrap-select > .dropdown-toggle {
  background-color: #2f2b2b;
  box-shadow: none !important;
  border: transparent;
  color: #ffffff;
  height: 45px;
  line-height: 35px;
  margin-left: 15px;
  outline: none !important;
  padding-left: 25px;
  padding-right: 20px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: 130px;
  z-index: 1;
}
.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show > .btn-light.dropdown-toggle {
  background-color: transparent;
  box-shadow: none;
}
.footer_menu ul li .bootstrap-select .dropdown-toggle .filter-option {
  outline: none;
}
.footer_menu ul li .bootstrap-select {
  width: auto !important;
}
.footer_bottom_area.home3
  .footer_menu
  ul
  li
  .bootstrap-select
  > .dropdown-toggle {
  background-color: #1f2532;
}
.footer_bottom_area.home4
  .footer_menu
  ul
  li
  .bootstrap-select
  > .dropdown-toggle {
  background-color: #ededed;
  border-radius: 22.5px;
  color: #8b8b8b;
}
.footer_bottom_area.home5 {
  background-color: #ffffff;
}
.footer_bottom_area.home5
  .footer_menu
  ul
  li
  .bootstrap-select
  > .dropdown-toggle {
  background-color: #e6e6e6;
  color: #8b8b8b;
}
/*== Inner Pages Breadcrumb ==*/
.inner_page_breadcrumbSearch {
  background-image: url(../images/background/inner-pagebg.jpg);
  background-repeat: no-repeat;
  /* height: 150px; */
  position: relative;
  padding: 45px;
}
.inner_page_breadcrumbSearch:before {
  background-color: rgb(0, 0, 0);
  bottom: 0;
  content: '';
  left: 0;
  opacity: 0.8;
  position: absolute;
  right: 0;
  top: 0;
}
.inner_page_breadcrumb {
  background-image: url(../images/background/inner-pagebg.jpg);
  background-repeat: no-repeat;
  height: 150px;
  position: relative;
  padding: 45px;
}
.inner_page_breadcrumb:before {
  background-color: rgb(0, 0, 0);
  bottom: 0;
  content: '';
  left: 0;
  opacity: 0.8;
  position: absolute;
  right: 0;
  top: 0;
}
.inner_page_breadcrumb .breadcrumb_content {
  margin-top: 120px;
}
.inner_page_breadcrumb.csv2 .breadcrumb_content,
.inner_page_breadcrumb.csv3 .breadcrumb_content {
  margin-top: 100px;
}
.inner_page_breadcrumb .breadcrumb_content .breadcrumb {
  background-color: transparent;
  display: -webkit-inline-flex;
  display: -moz-inline-flex;
  display: -ms-inline-flex;
  display: -o-inline-flex;
  display: inline-flex;
  margin-bottom: 0;
  padding-left: 0;
}
.inner_page_breadcrumb .breadcrumb_title {
  font-size: 40px;
  font-family: 'Nunito';
  color: #ffffff;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 0;
}
.inner_page_breadcrumb .breadcrumb_title li:first-child {
  color: #7f7f7f;
}
.inner_page_breadcrumb .breadcrumb_title li:last-child {
  color: #221f1f;
}
.inner_page_breadcrumb .breadcrumb-item a,
.inner_page_breadcrumb .breadcrumb-item.active,
.inner_page_breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  color: #ffffff;
  text-transform: capitalize;
}
.breadcrumb_content.style2 {
  margin-bottom: 25px !important;
}
.breadcrumb_content.style2 ol.breadcrumb {
  background-color: transparent;
  margin-bottom: 0;
  padding-left: 0;
  padding-top: 0;
}
/*== About Page About Content ==*/
.about_content p.large {
  color: #484848;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  margin-bottom: 45px;
}
.about_content p {
  font-size: 14px;
  color: #484848;
  line-height: 1.714;
  margin-bottom: 40px;
}
ul.ab_counting li {
  margin-right: 30px !important;
}
ul.ab_counting li:last-child {
  margin-right: 0;
}
.about_counting {
  display: -webkit-inline-flex;
  display: -moz-inline-flex;
  display: -ms-inline-flex;
  display: -o-inline-flex;
  display: inline-flex;
}
.about_counting .icon span {
  color: #ff5a5f;
  font-size: 40px;
  line-height: 1.2;
}
.about_counting .details {
  padding-left: 12px;
}
.about_counting .details h3 {
  font-size: 22px;
  font-family: 'Nunito';
  color: #484848;
  font-weight: bold;
  margin-bottom: 0;
}
.about_counting .details p {
  font-size: 14px;
  font-family: 'Nunito';
  color: #484848;
}
.about_thumb {
  position: relative;
  overflow: hidden;
}
.about_thumb img {
  border-radius: 8px;
}
.about_thumb a {
  background: #ff5a5f;
  border-radius: 100%;
  font-size: 22px;
  height: 70px;
  left: 0;
  line-height: 70px;
  margin: 0 auto;
  position: absolute;
  right: 0;
  text-align: center;
  top: 43%;
  width: 70px;
  z-index: 99;
}
.about_thumb a:after {
  background-color: rgba(255, 90, 95, 0.2);
  border-radius: 100%;
  content: '';
  height: 87px;
  left: -7px;
  position: absolute;
  top: -7px;
  width: 87px;
  z-index: -1;
}

/*== About home3 ==*/
.home3_about,
.home3_about2 {
  overflow: hidden;
}
.about_home3 {
  margin-top: 100px;
}
.about_home3 h3 {
  font-size: 26px;
  margin-bottom: 10px;
}
.about_home3 h5 {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 40px;
}
.about_home3 p {
  margin-bottom: 0;
  max-width: 560px;
}
.about_home3 a.about_btn_home3 {
  background-color: #ffffff;
  border: 2px solid #369fdb;
  border-radius: 25px;
  color: #369fdb;
  height: 50px;
  line-height: 35px;
  margin-top: 40px;
  width: 180px;
}
.about_home3 a.about_btn_home3:hover,
.about_home3 a.about_btn_home3:active,
.about_home3 a.about_btn_home3:focus {
  background-color: #369fdb;
  color: #ffffff;
}
.about_home3 .partners_thumb_list {
  margin-bottom: 0;
  margin-top: 50px;
}
.about_home3 .partners_thumb_list li {
  margin-right: 55px;
}
.about_home3 .partners_thumb_list li:last-child {
  margin-right: 0;
}
.home3_about_icon_box {
  border-radius: 5px;
  display: block;
  margin-bottom: 45px;
  padding: 30px 60px 35px 40px;
  position: relative;
  z-index: 1;
}
.home3_about_icon_box.home8 {
  padding: 70px 60px 60px 45px;
}
.home3_about_icon_box .icon {
  font-size: 70px;
}
.home3_about_icon_box .icon span {
  color: #ffffff;
}
.home3_about_icon_box h4 {
  font-size: 18px;
  margin-bottom: 10px;
}
.home3_about_icon_box h4,
.home3_about_icon_box p {
  color: #ffffff;
}
.home3_about_icon_box p {
  font-size: 15px;
  line-height: 30px;
}
.home3_about_icon_box.one {
  background-color: #ecd06f;
  margin-top: -50px;
}
.home3_about_icon_box.two {
  background-color: #ff1053;
  margin-top: -80px;
}
.home3_about_icon_box.three {
  background-color: #00a78e;
  margin-bottom: 0;
}
.home3_about_icon_box.four {
  background-color: #00bce4;
  margin-bottom: 0;
  margin-top: -25px;
}
.home3_about_icon_box.five {
  background-color: #2ac4ea;
  margin-top: -50px;
}
.home3_about_icon_box.six {
  background-color: #ff5a5f;
  margin-bottom: 0;
}
.home3_about_icon_box.seven {
  background-color: #fbbc05;
  margin-bottom: 0;
  margin-top: -25px;
}
.about_home4_shape4 {
  bottom: 550px;
  position: absolute;
  left: -225px;
  z-index: -1;
}
.about2_home3 {
  margin-top: 100px;
}
.about2_home3 h3 {
  font-size: 30px;
  margin-bottom: 10px;
}
.about2_home3 p {
  font-size: 18px;
  margin-bottom: 0;
}
.about2_home3 a.about_btn_home3 {
  background-color: #ffffff;
  border: 2px solid #051925;
  border-radius: 25px;
  color: #051925;
  height: 50px;
  line-height: 35px;
  margin-top: 30px;
  width: 180px;
}
.about2_home3 a.about_btn_home3:hover,
.about2_home3 a.about_btn_home3:active,
.about2_home3 a.about_btn_home3:focus {
  background-color: #051925;
  color: #ffffff;
}
.instructor_col {
  text-align: center;
  margin-bottom: 40px;
}
.instructor_col .thumb img {
  height: 150px;
  margin: 0 auto;
  width: 150px;
}
.instructor_col .details p {
  font-size: 14px;
  margin-bottom: 0;
}
.instructor_col .details ul {
  margin-top: 10px;
}
.instructor_col .details ul li {
  margin-right: 4px;
}
.instructor_col .details ul li a {
  color: #d0d23c;
}
.instructor_col .details ul li:last-child a {
  color: #7e7e7e;
}
.about_box_home6 .details {
  position: relative;
}
.about_box_home6 .details h3 {
  font-size: 26px;
  font-family: 'Nunito SemiBold';
  margin-bottom: 20px;
}
.about_box_home6 .details p {
  font-size: 15px;
  line-height: 2;
  margin-bottom: 35px;
  max-width: 540px;
}
.about_box_home6 .details a {
  border-radius: 5px;
  box-shadow: none;
  font-size: 15px;
  height: 50px;
  line-height: 30px;
  width: 200px;
}
.about_box_home7 .thumb {
  margin-bottom: 20px;
  position: relative;
  z-index: 9;
}
.about_box_home7 .thumb:before {
  background-color: #ffffff;
  border-radius: 5px;
  content: '';
  height: 410px;
  left: 20px;
  position: absolute;
  top: 22px;
  width: 630px;
  z-index: -1;
}
.about_box_home7 .thumb:before {
  background-color: #f9f9f9;
}
.smp_icon_box {
  position: relative;
}
.smp_icon_box .icon {
  margin-bottom: 15px;
}
.smp_icon_box .details h4 {
  font-size: 18px;
  font-family: 'Nunito';
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 10px;
}
.smp_icon_box .details p {
  color: #131313;
  font-size: 15px;
  line-height: 30px;
}
.smp_icon_box.box1 .details h4 {
  color: #f25141;
}
.smp_icon_box.box2 .details h4 {
  color: #93c524;
}
.smp_icon_box.box3 .details h4 {
  color: #28a8e3;
}
.smp_icon_box.box4 .details h4 {
  color: #00a78e;
}
.about_home3.home8 {
  margin-top: 30px;
}
.our-team.instructor-page {
  background-color: #f9fafc;
}
.team_member {
  background-color: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  padding: 10px;
  position: relative;
  text-align: center;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.team_member:hover {
  cursor: pointer;
}
.team_member .thumb {
  border-radius: 8px;
  overflow: hidden;
  position: relative;
}
.team_member .thumb .overylay {
  background-color: rgba(255, 90, 95, 0.8);
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.team_member.home4 .thumb .overylay {
  opacity: 0;
}
.team_member.home4:hover .thumb .overylay {
  background-image: -moz-linear-gradient(
    -36deg,
    rgb(62, 62, 165) 0%,
    rgb(255, 90, 95) 100%
  );
  background-image: -webkit-linear-gradient(
    -36deg,
    rgb(62, 62, 165) 0%,
    rgb(255, 90, 95) 100%
  );
  background-image: -ms-linear-gradient(
    -36deg,
    rgb(62, 62, 165) 0%,
    rgb(255, 90, 95) 100%
  );
  opacity: 0.902;
}
.team_member:hover .thumb .overylay {
  opacity: 1;
}
.team_member .thumb .overylay .social_icon,
.our_agent .thumb .overylay .social_icon {
  bottom: 15px;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
}
.team_member .thumb .overylay .social_icon li,
.our_agent .thumb .overylay .social_icon li {
  margin-right: 20px;
}
.team_member .thumb .overylay .social_icon li:last-child,
.our_agent .thumb .overylay .social_icon li:last-child {
  margin-right: 0;
}
.team_member .thumb .overylay .social_icon li a i,
.our_agent .thumb .overylay .social_icon li a i {
  color: #ffffff;
  font-size: 13px;
}
.team_member .details {
  padding: 20px 0 5px;
}
.team_member .details p {
  font-size: 14px;
  color: rgba(72, 72, 72, 0.8);
  line-height: 1.2;
  margin-bottom: 0;
}
.team_member.style2 {
  -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  -o-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
}
.team_member.style3:hover {
  -webkit-box-shadow: 0px 0px 30px 0px rgba(32, 32, 32, 0.15);
  -moz-box-shadow: 0px 0px 30px 0px rgba(32, 32, 32, 0.15);
  -o-box-shadow: 0px 0px 30px 0px rgba(32, 32, 32, 0.15);
  box-shadow: 0px 0px 30px 0px rgba(32, 32, 32, 0.15);
}
.team_member .instructor_col {
  margin-bottom: 15px;
}
.team_member .instructor_col .thumb img {
  height: 120px;
  margin: 0 auto 20px;
  width: 120px;
}
.team_member .instructor_col .details p {
  font-size: 15px;
}
.team_member .tm_footer {
  border-top: 1px solid #eeeeee;
}
.team_member .tm_footer ul {
  margin-bottom: 0;
  padding: 10px 0 10px;
}
.team_member .tm_footer ul li:first-child {
  padding-right: 10px;
}
.team_member .tm_footer ul li a {
  font-size: 14px;
}
.our-agents {
  border-bottom: 1px solid #e8eef2;
  padding-bottom: 90px;
}
.our_agent {
  margin-bottom: 30px;
  position: relative;
}
.our_agent .thumb {
  margin-bottom: 15px;
  position: relative;
}
.our_agent .details p {
  margin-bottom: 0;
}
.our_agent .thumb .overylay {
  background-color: rgba(8, 15, 29, 0.8);
  border-radius: 8px;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.our_agent:hover .thumb .overylay {
  cursor: pointer;
  opacity: 1;
}
/*== Testimonials Grid ==*/
.testimonial_item .thumb {
  margin-bottom: 30px;
  text-align: center;
}
.testimonial_item .thumb img {
  height: 60px;
  width: 60px;
}
.testimonial_item .details {
  margin: 0 0 0 6%;
  padding: 0 35px;
  position: relative;
  text-align: center;
}
.testimonial_item .details h4 {
  font-family: 'Nunito';
  color: #484848;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 0;
}
.testimonial_item .details .small {
  font-size: 16px;
  font-family: 'Nunito';
  line-height: 1.5;
}
.testimonial_item .details p {
  font-size: 14px;
  font-family: 'Nunito';
  color: rgb(119, 119, 119);
  font-style: italic;
  line-height: 1.714;
  margin-bottom: 0;
  margin-top: 30px;
}
.testimonialsec {
  float: left;
  position: relative;
  width: 100%;
}
.testimonialsec:before {
  bottom: -50px;
  color: #1b2032;
  content: '\f10d';
  font-size: 270px;
  font-family: fontawesome;
  left: 0;
  margin: 0 auto;
  opacity: 0.03;
  position: absolute;
  right: 0;
  text-align: center;
}
.tes-for {
  float: left;
  margin: 0;
  position: relative;
  text-align: center;
  width: 100%;
}
.tes-nav {
  float: left;
  width: 100%;
  text-align: center;
  padding: 0;
  margin: 0;
  position: relative;
  left: 0;
}
.tes-nav img {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  float: none;
  height: 60px;
  margin: 0 auto;
  opacity: 0.6;
  width: 60px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
li.slick-slide.slick-active:nth-child(6) img,
li.slick-slide.slick-active:nth-child(8) img {
  -webkit-transform: translate(5%, 0) scale(1.3);
  -moz-transform: translate(5%, 0) scale(1.3);
  -o-transform: translate(5%, 0) scale(1.3);
  transform: translate(5%, 0) scale(1.3);
}
.tes-nav .slick-list .slick-track li.slick-slide.slick-active {
  margin: 20px 0 0;
}
.tes-nav
  .slick-list
  .slick-track
  li.slick-slide.slick-current.slick-active.slick-center {
  margin: 0;
  width: 130px !important;
}
.tes-nav .slick-list .slick-track li.slick-slide.slick-active:first-child img,
.tes-nav .slick-list .slick-track li.slick-slide.slick-active:last-child img {
  height: 60px;
  width: 60px;
}
.tes-nav .slick-list .slick-track li.slick-slide.slick-active:nth-child(2) img,
.tes-nav .slick-list .slick-track li.slick-slide.slick-active:nth-child(4) img {
  height: 80px;
  width: 80px;
}
.tes-nav .slick-slide.slick-current.slick-active.slick-center img {
  height: 100px;
  margin: 0 auto;
  opacity: 1;
  width: 100px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-box-shadow: 0px 1px 4px 0px rgba(41, 39, 58, 0.3);
  -moz-box-shadow: 0px 1px 4px 0px rgba(41, 39, 58, 0.3);
  -o-box-shadow: 0px 1px 4px 0px rgba(41, 39, 58, 0.3);
  box-shadow: 0px 1px 4px 0px rgba(41, 39, 58, 0.3);
  -webkit-transform: translate(5%, 0) scale(0.9);
  -moz-transform: translate(5%, 0) scale(0.9);
  -o-transform: translate(5%, 0) scale(0.9);
  transform: translate(5%, 0) scale(0.9);
}
.testimonial_item.home2 {
  position: relative;
  text-align: center;
  opacity: 0.5;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.testimonial_item.home2 .details {
  border-radius: 5px;
  margin-bottom: 25px;
  padding: 13px 60px 35px;
  padding-left: 120px;
  text-align: left;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.testimonial_item.home2 .thumb .title {
  color: #060606;
  font-size: 18px;
}
.testimonial_item.home2 .thumb .subtitle {
  color: #ff5a5f;
  font-size: 13px;
  margin-bottom: 40px;
}
.testimonial_item.home2 .details .icon {
  font-size: 40px;
  left: 50px;
  line-height: normal;
  position: absolute;
  top: 70px;
}
.testimonial_item.home2 .thumb img {
  display: inline-block;
  margin-bottom: 25px;
}
.testimonial_post {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px 20px 30px 30px;
  -webkit-box-shadow: 0px 0px 30px 0px rgba(19, 19, 28, 0.12);
  -moz-box-shadow: 0px 0px 30px 0px rgba(19, 19, 28, 0.12);
  -o-box-shadow: 0px 0px 30px 0px rgba(19, 19, 28, 0.12);
  box-shadow: 0px 0px 30px 0px rgba(19, 19, 28, 0.12);
  position: relative;
}
.testimonial_post .details .icon {
  margin-bottom: 20px;
  font-size: 30px;
}
.testimonial_post .details p {
  font-size: 14px;
  font-family: 'Nunito';
  color: #484848;
  line-height: 1.714;
  margin-bottom: 22px;
}
.testimonial_post .thumb {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  position: relative;
}
.testimonial_post .thumb img {
  width: 90px;
}
.testimonial_post .thumb h4.title {
  margin-top: 25px;
  padding-left: 20px;
}
.testimonial_slider_home9 .testimonial_post {
  margin: 0px 10px 50px;
}
.feature_property_slider.owl-carousel.owl-theme,
.feature_property_slider.owl-carousel .owl-stage-outer,
.feature_property_home3_slider.owl-carousel.owl-theme,
.feature_property_home3_slider.owl-carousel .owl-stage-outer,
.best_property_slider.owl-carousel.owl-theme,
.best_property_slider.owl-carousel .owl-stage-outer,
.testimonial_slider_home9.owl-carousel.owl-theme,
.testimonial_slider_home9.owl-carousel .owl-stage-outer {
  overflow: visible;
}
.testimonial_slider_home9.owl-carousel.owl-theme .owl-dots .owl-dot {
  margin-right: 10px;
  vertical-align: middle;
}
.testimonial_slider_home9.owl-carousel.owl-theme .owl-dots .owl-dot span {
  background-color: #b9b9b9;
  border-radius: 50%;
  margin: 3px;
  width: 6px;
  height: 6px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.testimonial_slider_home9.owl-carousel.owl-theme
  .owl-dots
  .owl-dot.active
  span {
  border: 5px solid #ffffff;
  height: 15px;
  width: 15px;
}
.testimonial_slider_home9.owl-carousel.owl-theme .owl-nav {
  display: none;
}
.becomea_instructor {
  border-radius: 5px;
  background-color: #f9f9f9;
  padding: 65px 15px 55px 200px;
}
.becomea_instructor.style2 {
  background-color: #f9f9f9;
  padding: 65px 200px 55px 15px;
}
.becomea_instructor h3 {
  font-size: 28px;
  margin-bottom: 25px;
}
.becomea_instructor p {
  margin-bottom: 30px;
}
.becomea_instructor a {
  border-radius: 5px;
  font-size: 14px;
  padding: 20px 25px 20px 40px;
  -webkit-box-shadow: 0px 1px 4px 0px rgba(36, 65, 231, 0.3);
  -moz-box-shadow: 0px 1px 4px 0px rgba(36, 65, 231, 0.3);
  -o-box-shadow: 0px 1px 4px 0px rgba(36, 65, 231, 0.3);
  box-shadow: 0px 1px 4px 0px rgba(36, 65, 231, 0.3);
}
.becomea_instructor a span {
  padding-left: 15px;
}
.becomea_instructor_home3 {
  border-radius: 5px;
  padding: 65px 0 55px 35px;
}
.becomea_instructor_home3 h3 {
  color: #ffffff;
  font-size: 26px;
  margin-bottom: 15px;
}
.becomea_instructor_home3 p {
  color: #ffffff;
  font-size: 16px;
}
.becomea_instructor_home3.style1 {
  background-color: #369fdb;
}
.becomea_instructor_home3.style2 {
  background-color: #051925;
}
.becomea_instructor_home3 a {
  border: none;
  border-radius: 5px;
  font-size: 14px;
  height: 50px;
  line-height: 35px;
  margin-top: 20px;
  width: 198px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.becomea_instructor_home3.style1 a {
  color: #369fdb;
}
.becomea_instructor_home3.style1 a:hover,
.becomea_instructor_home3.style1 a:active,
.becomea_instructor_home3.style1 a:focus {
  background-color: #051925;
  color: #ffffff;
}
.becomea_instructor_home3.style2 a {
  color: #051925;
}
.becomea_instructor_home3.style2 a:hover,
.becomea_instructor_home3.style2 a:active,
.becomea_instructor_home3.style2 a:focus {
  background-color: #369fdb;
  color: #ffffff;
}
.becomea_instructor_home3 a span {
  padding-left: 15px;
}
/*== Blog Post Home V1 ==*/
.blog_post {
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}
.blog_post .thumb {
  display: grid;
  overflow: hidden;
  position: relative;
}
.blog_post .thumb img {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.blog_post:hover .thumb img {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
  z-index: 0;
}
.blog_post.one .thumb .post_title {
  font-size: 5vw;
  color: #ffffff;
  left: 20px;
  opacity: 0.15;
  position: absolute;
  top: 0;
}
.blog_post_home2 .bph2_header .bph2_date_meta,
.blog_post.one .thumb .post_date {
  background-color: #ffffff;
  border-radius: 5px;
  height: 64px;
  left: auto;
  padding-left: 15px;
  padding-top: 10px;
  right: 30px;
  text-align: center;
  width: 80px;
}
.blog_post_home2 .bph2_header .bph2_date_meta span,
.blog_post.one .thumb .post_date span {
  color: #484848;
  font-family: 'Nunito', sans-serif;
  font-size: 18px;
  font-weight: bold;
  display: table-caption;
  line-height: 22px;
}
.blog_post .thumb .post_date {
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  left: 30px;
  position: absolute;
  top: 30px;
  z-index: 9;
}
.blog_post.one .thumb:before {
  background-color: rgba(25, 38, 117, 0.7);
  bottom: 0;
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
}
.blog_post .thumb:before {
  background-color: rgba(0, 0, 0, 0.6);
  bottom: 0;
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  z-index: 1;
}
.blog_post.one .details .post_meta {
  position: relative;
}
.blog_post.one .details .post_meta ul li {
  margin-right: 30px;
}
.blog_post.one .details .post_meta ul li:last-child {
  margin-right: 0;
}
.blog_post.one .details .post_meta ul li a {
  color: #ffffff;
  font-size: 14px;
}
.blog_post.one .details .post_meta ul li a i {
  font-size: 16px;
  margin-right: 10px;
}
.blog_post .details {
  bottom: 20px;
  left: 30px;
  position: absolute;
  z-index: 9;
}
.blog_post .details h4 {
  font-size: 20px;
  margin-top: 8px;
  width: 90%;
}
.blog_post .details h4 {
  color: #ffffff;
}
.blog_post .details h5 {
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
}
.read_more_home a {
  color: #c75533;
}
.blog_post_home4 {
  position: relative;
}
.blog_post_home4 .bph4_thumb {
  border-radius: 4px;
  -webkit-box-shadow: 0px 3px 10px 0px rgba(0, 214, 100, 0.2);
  -moz-box-shadow: 0px 3px 10px 0px rgba(0, 214, 100, 0.2);
  -o-box-shadow: 0px 3px 10px 0px rgba(0, 214, 100, 0.2);
  box-shadow: 0px 3px 10px 0px rgba(0, 214, 100, 0.2);
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  overflow: hidden;
  position: relative;
}
.blog_post_home4 .bph4_thumb:before {
  background-color: rgba(0, 0, 0, 0.4);
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
.blog_post_home4 .bph4_thumb .bph4_date_meta {
  border-radius: 4px;
  left: 30px;
  overflow: hidden;
  position: absolute;
  top: 30px;
  text-align: center;
}
.blog_post_home4 .bph4_thumb .bph4_date_meta .year {
  background-color: #f8faf9;
  color: #221f1f;
  font-size: 17px;
  font-weight: bold;
  padding: 5px 20px 3px;
}
.blog_post_home4 .bph4_thumb .bph4_date_meta .year,
.blog_post_home4 .bph4_thumb .bph4_date_meta .date {
  margin-bottom: 0;
}
.blog_post_home4 .bph4_thumb .bph4_date_meta .date {
  background-color: #ffffff;
  color: #221f1f;
  font-size: 15px;
  font-weight: normal;
  padding: 3px 13px 5px;
}
.blog_post_home4.home8 .bph4_thumb {
  box-shadow: none;
}
.blog_post_home4.home8 .bph4_thumb:before {
  background-color: transparent;
}
.blog_post_home4.home8 .bph4_thumb .bph4_date_meta .year {
  background-color: #ff5a5f;
  color: #ffffff;
}
.blog_post_home4.home8 .bph4_thumb .bph4_date_meta .date {
  background-color: #192675;
  color: #ffffff;
}
.blog_post_home4 .details {
  padding-top: 25px;
}
.blog_post_home4 .details h4 {
  font-size: 18px;
  line-height: 30px;
}
.blog_post_home4 .details p {
  font-size: 15px;
  line-height: 28px;
  max-width: 342px;
}
.blog_post_home6 {
  position: relative;
}
.blog_post_home6 h3.title {
  font-size: 26px;
  margin-bottom: 50px;
}
.blog_post_home6 .thumb h4 {
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 5px;
  margin-top: 20px;
}
.blog_post_home6 .thumb a {
  font-size: 15px;
  line-height: 30px;
}
.blog_post_home6_media_objects .title {
  font-size: 15px;
  margin-bottom: 60px;
  padding-top: 5px;
  position: relative;
  text-align: right;
}
.blog_post_home6_media_objects .title a {
  color: #484848;
  font-size: 15px;
}
.blog_post_home6_date {
  cursor: pointer;
  position: relative;
}
.blog_post_home6_date .title {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
}
.blog_post_home6_date .post_grid {
  background-color: #f9f9f9;
  border-radius: 5px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  padding: 30px 15px 30px 15px;
  position: relative;
}
.blog_post_home6_date .post_grid.home7 {
  background-color: #ffffff;
}
.blog_post_home6_date .post_grid:hover .post_date {
  background-color: #192675;
}
.blog_post_home6_media_objects .title a span,
.blog_post_home6_date .title a span {
  padding-left: 15px;
}
.blog_post_home6_date .title h3 {
  float: left;
  font-size: 26px;
  margin-bottom: 50px;
}
.blog_post_home6_date .title a {
  font-size: 15px;
  position: absolute;
  right: 0;
  top: 5px;
}
.blog_post_home6_date .post_grid .post_date {
  border-radius: 5px;
  background-color: #ff1053;
  color: #ffffff;
  float: left;
  height: 60px;
  line-height: normal;
  margin-right: 15px;
  padding-top: 7px;
  position: relative;
  text-align: center;
  width: 70px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.blog_post_home6_date .post_grid .post_meta ul {
  margin-bottom: 0;
}
.blog_post_home6_date .post_grid .post_meta ul li a {
  font-size: 13px;
}
.blog_post_home6_date .post_grid .post_meta ul li a span {
  font-size: 16px;
  padding-right: 5px;
}
.about_home6 .partners_thumb_list {
  margin-bottom: 0;
  margin-top: 70px;
}
.about_home6 .partners_thumb_list li {
  margin-right: 55px;
}
.about_home6 .partners_thumb_list li:last-child {
  margin-right: 0;
}
.footer_apps_widget_home1.home6 input.form-control {
  margin: 0 45px 0 0;
}
.footer_apps_widget_home1.home6 button.btn,
.footer_apps_widget_home1.home7 button.btn {
  border-radius: 5px;
}
.footer_apps_widget_home1.home7 input.form-control {
  background-color: #f9f9f9;
}
/*== Team Slider Code ==*/
.team_slider.owl-carousel.owl-theme.owl-loaded .owl-prev,
.feature_post_slider.owl-carousel.owl-theme.owl-loaded .owl-prev,
.feature_property_home3_slider.owl-carousel.owl-theme.owl-loaded .owl-prev,
.feature_property_home6_slider.owl-carousel.owl-theme.owl-loaded .owl-prev,
.our_agents_home6_slider.owl-carousel.owl-theme.owl-loaded .owl-prev {
  left: -25px;
}
.team_slider.owl-carousel.owl-theme.owl-loaded .owl-next,
.feature_post_slider.owl-carousel.owl-theme.owl-loaded .owl-next,
.feature_property_home3_slider.owl-carousel.owl-theme.owl-loaded .owl-next {
  right: -25px;
}
.team_slider.owl-carousel.owl-theme.owl-loaded .owl-prev,
.team_slider.owl-carousel.owl-theme.owl-loaded .owl-next,
.feature_post_slider.owl-carousel.owl-theme.owl-loaded .owl-prev,
.feature_post_slider.owl-carousel.owl-theme.owl-loaded .owl-next,
.feature_property_home3_slider.owl-carousel.owl-theme.owl-loaded .owl-prev,
.feature_property_home3_slider.owl-carousel.owl-theme.owl-loaded .owl-next,
.feature_property_home6_slider.owl-carousel.owl-theme.owl-loaded .owl-prev,
.feature_property_home6_slider.owl-carousel.owl-theme.owl-loaded .owl-next,
.our_agents_home6_slider.owl-carousel.owl-theme.owl-loaded .owl-prev,
.our_agents_home6_slider.owl-carousel.owl-theme.owl-loaded .owl-next {
  background-color: #ffc107 !important;
  border-radius: 50%;
  color: #000;
  height: 40px;
  line-height: 35px;
  position: absolute;
  top: 40%;
  width: 40px;
  -webkit-box-shadow: 0px 0px 50px 5px rgb(32 32 32 / 35%);
  -moz-box-shadow: 0px 0px 50px 5px rgb(32 32 32 / 35%);
  -o-box-shadow: 0px 0px 50px 5px rgb(32 32 32 / 35%);
  box-shadow: 0px 0px 50px 5px rgb(32 32 32 / 35%);
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.team_slider.owl-carousel.owl-theme.owl-loaded .owl-prev:hover,
.team_slider.owl-carousel.owl-theme.owl-loaded .owl-next:hover,
.feature_post_slider.owl-carousel.owl-theme.owl-loaded .owl-prev:hover,
.feature_post_slider.owl-carousel.owl-theme.owl-loaded .owl-next:hover,
.feature_property_home3_slider.owl-carousel.owl-theme.owl-loaded
  .owl-prev:hover,
.feature_property_home3_slider.owl-carousel.owl-theme.owl-loaded
  .owl-next:hover,
.feature_property_home6_slider.owl-carousel.owl-theme.owl-loaded
  .owl-prev:hover,
.feature_property_home6_slider.owl-carousel.owl-theme.owl-loaded
  .owl-next:hover,
.our_agents_home6_slider.owl-carousel.owl-theme.owl-loaded .owl-prev:hover,
.our_agents_home6_slider.owl-carousel.owl-theme.owl-loaded .owl-next:hover {
  background-color: #4f83b2 !important;
  color: #000;
}
.team_slider.owl-carousel.owl-theme .owl-nav,
.feature_property_home3_slider.owl-carousel.owl-theme .owl-nav,
.feature_property_home6_slider.owl-carousel.owl-theme .owl-nav,
.our_agents_home6_slider.owl-carousel.owl-theme .owl-nav {
  margin: 0;
}
.feature_property_home6_slider.owl-carousel.owl-theme.owl-loaded .owl-next,
.our_agents_home6_slider.owl-carousel.owl-theme.owl-loaded .owl-next {
  right: -15%;
}
.feature_property_home6_slider.owl-carousel.owl-theme .owl-stage-outer,
.our_agents_home6_slider.owl-carousel.owl-theme .owl-stage-outer {
  display: inline-block;
}
.feature_post_slider .shop_grid .thumb img {
  margin: 0 auto;
  width: auto;
}
/*== Pricing Table Four ==*/
.membership_container {
  background-color: #ffffff;
  padding: 30px 0 0;
  position: relative;
}
ul.mc_parent_list .membership_header .thumb {
  border-radius: 8px;
  overflow: hidden;
  position: relative;
}
ul.mc_parent_list .membership_header .thumb:before {
  background-color: rgba(0, 0, 0, 0.6);
  bottom: 0;
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}
ul.mc_parent_list .membership_header .thumb a {
  background-color: #ffffff;
  border-radius: 8px;
  height: 30px;
  line-height: 25px;
  position: absolute;
  right: 10px;
  text-align: center;
  top: 10px;
  width: 30px;
}
ul.mc_parent_list .membership_header .thumb a span {
  font-size: 10px;
}
ul.mc_parent_list .membership_header .thumb .price {
  bottom: 15px;
  color: #ffffff;
  font-family: 'Nunito';
  font-size: 22px;
  font-weight: bold;
  left: 0;
  line-height: 1.2;
  margin: 0 auto;
  position: absolute;
  right: 0;
}
ul.mc_parent_list .membership_header .details {
  padding-top: 12px;
}
ul.mc_parent_list .membership_header .details p {
  font-size: 14px;
  font-family: 'Nunito';
  color: #ff5a5f;
  line-height: 1.2;
}
ul.mc_parent_list li {
  margin-right: -3px !important;
}
ul.mc_parent_list ul.mc_child_list.one li {
  font-weight: bold;
  padding: 10px 50px 10px 50px;
}
ul.mc_parent_list ul.mc_child_list.one li,
ul.mc_parent_list ul.mc_child_list.two li,
ul.mc_parent_list ul.mc_child_list.three li,
ul.mc_parent_list ul.mc_child_list.four li {
  border-bottom: 1px solid #ebebeb;
  color: #484848;
  font-size: 16px;
  font-family: 'Nunito';
  line-height: 3.333;
}
ul.mc_parent_list ul.mc_child_list.two li,
ul.mc_parent_list ul.mc_child_list.three li,
ul.mc_parent_list ul.mc_child_list.four li {
  padding: 10px 20px;
}
ul.mc_parent_list ul.mc_child_list.one li:first-child,
ul.mc_parent_list ul.mc_child_list.two li:first-child,
ul.mc_parent_list ul.mc_child_list.three li:first-child,
ul.mc_parent_list ul.mc_child_list.four li:first-child,
ul.mc_parent_list ul.mc_child_list.one li:last-child,
ul.mc_parent_list ul.mc_child_list.two li:last-child,
ul.mc_parent_list ul.mc_child_list.three li:last-child,
ul.mc_parent_list ul.mc_child_list.four li:last-child {
  padding-top: 25px;
}
ul.mc_parent_list ul.mc_child_list.one li:last-child,
ul.mc_parent_list ul.mc_child_list.two li:last-child,
ul.mc_parent_list ul.mc_child_list.three li:last-child,
ul.mc_parent_list ul.mc_child_list.four li:last-child {
  border-bottom: none;
}
ul.mc_parent_list ul.mc_child_list .membership_header h4 {
  font-size: 18px;
  font-family: 'Nunito';
  color: #484848;
  line-height: 1.333;
  margin: 0;
}

/*== Our Partners ==*/
.our-partner {
  padding: 40px 0;
}
.our_partner {
  text-align: center;
}
.start-partners.home5 {
  background-image: -moz-linear-gradient(
    0deg,
    rgb(250, 124, 65) 0%,
    rgb(255, 101, 101) 100%
  );
  background-image: -webkit-linear-gradient(
    0deg,
    rgb(250, 124, 65) 0%,
    rgb(255, 101, 101) 100%
  );
  background-image: -ms-linear-gradient(
    0deg,
    rgb(250, 124, 65) 0%,
    rgb(255, 101, 101) 100%
  );
}
.start-partners.home7 {
  background-image: -moz-linear-gradient(
    0deg,
    rgb(60, 4, 135) 0%,
    rgb(76, 21, 148) 100%
  );
  background-image: -webkit-linear-gradient(
    0deg,
    rgb(60, 4, 135) 0%,
    rgb(76, 21, 148) 100%
  );
  background-image: -ms-linear-gradient(
    0deg,
    rgb(60, 4, 135) 0%,
    rgb(76, 21, 148) 100%
  );
}

/*== Our Newslatters ==*/
.our-newslatters {
  background-color: #f9fafc;
}
.footer_apps_widget_home1 input.form-control {
  border: none;
  border-radius: 5px;
  height: 50px;
  margin: 0 auto;
  width: 65%;
  -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  -o-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
}
.footer_apps_widget_home1 input.form-control:focus {
  border: none;
  -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  -o-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
}
.footer_apps_widget_home1 button.btn {
  font-size: 14px;
  height: 50px;
  margin-left: -15px;
  width: 150px;
}
.footer_apps_widget_home1 button.btn span {
  margin-left: 10px;
}
.our-newslatters.home7 {
  background-color: #ffffff;
}
/*== Error/404 Page ==*/
.our-error {
  padding: 80px 0;
  position: relative;
}
.logo-widget.error_paged {
  padding-left: 30px;
}
.erro_code {
  margin-top: 50px;
}
.erro_code h1 {
  font-size: 45px;
  font-family: 'Nunito';
  color: #484848;
  font-weight: bold;
  line-height: 1.2;
}
.error_page.footer_apps_widget {
  position: relative;
  margin-bottom: 25px;
}
.error_page.footer_apps_widget p {
  font-size: 14px;
  font-family: 'Nunito';
  color: #484848;
  line-height: 1.714;
}
.error_page.footer_apps_widget .form-inline {
  display: inline-block;
  margin-top: 20px;
}
.error_page.footer_apps_widget .mailchimp_form .form-control {
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid #ebebeb;
  height: 52px;
  width: 600px;
}
a.btn.btn_error {
  background-color: #ff5a5f;
  border-radius: 8px;
  color: #ffffff;
  font-weight: bold;
  font-size: 16px;
  font-family: 'Nunito';
  height: 50px;
  line-height: 35px;
  width: 200px;
  -webkit-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  -moz-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  -o-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
}
/*== Service Pages Style Code ==*/
.service_grid {
  background-color: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  margin-bottom: 30px;
  padding: 10px;
  position: relative;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.service_grid:hover {
  cursor: pointer;
  -webkit-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
  -moz-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
  -o-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
  box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
}
.service_grid .details {
  padding: 20px 0 10px;
}
.service_grid .details h4 {
  line-height: 1.444;
  margin: 0;
}
/*== Pricing Table Pages Style Code ==*/
.pricing_table {
  background-color: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  margin-bottom: 30px;
  padding: 30px;
  position: relative;
  text-align: center;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.pricing_table:hover {
  cursor: pointer;
  -webkit-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
  -moz-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
  -o-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
  box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
}
.pricing_table .pricing_header {
  padding: 35px 0;
}
.pricing_table .pricing_header .price {
  font-size: 40px;
  color: #ff5a5f;
  font-weight: bold;
  line-height: 0.65;
  margin-bottom: 20px;
}
.pricing_table .pricing_header h4 {
  font-size: 18px;
  font-family: 'Nunito';
  color: #484848;
  font-weight: bold;
  line-height: 1.444;
}
.pricing_table .pricing_content ul li {
  font-size: 14px;
  font-family: 'Nunito';
  color: #484848;
  line-height: 3.571;
}
.pricing_table .pricing_footer {
  margin-top: 20px;
}
.pricing_table .pricing_footer a.btn.pricing_btn {
  background-color: #ffffff;
  border: 2px solid #ff5a5f;
  border-radius: 8px;
  color: #ff5a5f;
  font-size: 16px;
  font-family: 'Nunito';
  font-weight: bold;
  line-height: 30px;
  -webkit-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  -moz-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  -o-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  height: 46px;
}
.pricing_table .pricing_footer a.btn.pricing_btn:hover {
  background-color: #ff5a5f;
  color: #ffffff;
}

/*== LogIn Register Page ==*/
.our-log {
  background-color: #f9fafc;
}
.login_form {
  padding: 0;
}
.login_form hr,
.sign_up_form hr {
  margin-bottom: 25px;
  position: relative;
}
.login_form hr:before,
.sign_up_form hr:before {
  background-color: #ffffff;
  bottom: 0;
  color: #006c70;
  content: 'Or';
  font-size: 14px;
  position: absolute;
  left: 0;
  margin: 0 auto;
  right: 0;
  text-align: center;
  top: -12px;
  width: 10%;
}
.login_form .input-group-text,
.sign_up_form .input-group-text {
  background-color: transparent;
  border: none;
  color: #006c70;
  height: 40px;
  position: absolute;
  right: 2px;
  top: 6px;
}
.login_form .form-group .custom-control-label,
.sign_up_form .form-group .custom-control-label {
  font-size: 14px;
  padding-top: 2px;
}
.login_form .btn-fpswd {
  color: #8b91dd;
  font-size: 14px;
  font-family: 'Nunito';
  line-height: 2.214;
}
.sign_up_form .divide,
.login_form .divide {
  position: relative;
}
.sign_up_form .divide .lf_divider,
.login_form .divide .lf_divider {
  background-color: #f9fafc;
  height: 20px;
  left: 0;
  line-height: 20px;
  margin: 0 auto;
  position: absolute;
  right: 0;
  text-align: center;
  top: -10px;
  width: 62px;
}
.sign_up_form.inner_page,
.login_form.inner_page {
  padding: 0 80px 0;
}
.login_form.inner_page .heading h4 {
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 5px;
}
.login_form .heading,
.sign_up_form .heading {
  margin-bottom: 25px;
}
.login_form .heading h4,
.sign_up_form .heading h4 {
  color: #006c70;
}
.login_form button.btn-fb,
.sign_up_form button.btn-fb {
  background-color: #ffffff;
  border: 1px solid #506dab;
  border-radius: 8px;
  color: #506dab;
  font-size: 14px;
  height: 52px;
  margin-bottom: 20px;
  padding-left: 20px;
}
.login_form button.btn-fb:hover,
.sign_up_form button.btn-fb:hover {
  background-color: #506dab;
  border: 1px solid #506dab;
  color: #ffffff;
}
.login_form button.btn-googl,
.sign_up_form button.btn-googl {
  background-color: #ffffff;
  border: 1px solid #dd4b39;
  border-radius: 8px;
  color: #dd4b39;
  font-size: 14px;
  height: 52px;
  margin-bottom: 10px;
  padding-left: 20px;
}
.login_form button.btn-googl:hover,
.sign_up_form button.btn-googl:hover {
  background-color: #dd4b39;
  border: 1px solid #dd4b39;
  color: #ffffff;
}
.login_form button i,
.sign_up_form button i {
  font-size: 16px;
}
.login_form .form-control:focus,
.sign_up_form .form-control:focus {
  box-shadow: none;
  outline: none;
}
.sign_up_form .bootstrap-select .dropdown-toggle .filter-option-inner-inner {
  font-size: 14px;
  font-family: 'Nunito';
  color: #484848;
  line-height: 3;
}
.login_form .heading h4 {
  font-family: 'Nunito', sans-serif;
}
.login_form.inner_page .heading p {
  margin-bottom: 0;
}
.login_form.inner_page .form-control {
  border-style: solid;
  border-width: 1px;
  border-color: rgb(221, 221, 221);
  border-radius: 5px;
  background-color: #ffffff;
}
.login_form .form-control {
  margin-bottom: 20px;
}
.login_form .form-control,
.sign_up_form .form-control {
  background-color: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  color: #484848;
  font-size: 14px;
  font-family: 'Nunito';
  height: 52px;
  line-height: 1.2;
}
.login_form .form-check-input {
  height: 17px;
  width: 17px;
}
.login_form .form-check-label {
  color: #484848;
  font-size: 14px;
  padding-left: 8px;
}
.login_form .btn-log {
  border-radius: 8px;
  background-color: #ff5a5f;
  -webkit-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  -moz-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  -o-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  color: #ffffff;
  font-family: 'Nunito';
  font-size: 16px;
  font-weight: bold;
  height: 50px;
  line-height: 1.2;
  margin-bottom: 15px;
}
.sign_up_form {
  padding: 0;
}
.sign_up_form.inner_page .form-control {
  border-style: solid;
  border-width: 1px;
  border-color: rgb(221, 221, 221);
  border-radius: 5px;
  background-color: #ffffff;
}
.sign_up_form .form-check-input {
  border-radius: 2px;
  height: 15px;
  width: 15px;
}
/* Create a custom checkbox */
.sign_up_form .checkmark {
  background-color: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 2px;
  height: 15px;
  position: absolute;
  top: 0;
  left: 0;
  width: 15px;
  -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  -o-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
}
.sign_up_form .form-check-label {
  color: #484848;
  font-size: 14px;
  padding-left: 7px;
}
.sign_up_form button {
  margin-bottom: 10px;
}
.sign_up_form .btn-log {
  border-radius: 8px;
  background-color: #ff5a5f;
  -webkit-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  -moz-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  -o-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  color: #ffffff;
  font-size: 16px;
  font-family: 'Nunito';
  height: 50px;
}
/*== Question Widget Faq Page ==*/
.shortcode_widget_accprdons,
.shortcode_widget_tab,
.shortcode_widget_table,
.shortcode_widget_message_box,
.shortcode_widget_buttons {
  margin-bottom: 60px;
}
.shortcode_widget_buttons .ui_kit_button button {
  margin-right: 10px;
}
.shortcode_widget_buttons .ui_kit_button button:last-child {
  margin-right: 0;
}
.shortcode_widget_accprdons h4,
.shortcode_widget_tab h4,
.shortcode_widget_table h4,
.shortcode_widget_message_box h4,
.shortcode_widget_buttons h4,
.shortcode_widget_form h4,
.shortcode_widget_multiselect h4,
.ui_kit_select_box h4,
.shortcode_widget_radiobox h4,
.shortcode_widget_checkbox h4,
.shortcode_widget_switch h4,
.shortcode_widget_form h4,
.shortcode_widget_progressbar h4,
.shortcode_form_title h4 {
  font-size: 18px;
  font-family: 'Nunito';
  color: #484848;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 30px;
}
.faq_content {
  background-color: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  padding: 30px;
}
.faq_according .panel .panel-body {
  padding: 15px 0;
}
.faq_according .panel .panel-body p {
  font-size: 15px;
  font-family: 'Open Sans';
  color: rgb(126, 126, 126);
  line-height: 1.6;
}
.faq_according .panel .panel-body h4 {
  font-size: 18px;
  font-family: 'Nunito';
  color: rgb(59, 59, 59);
  line-height: 1.333;
}
.faq_according .panel-heading {
  background-color: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  height: 50px;
  margin-bottom: 20px;
}
.faq_according .panel-heading .panel-title {
  font-family: 'Nunito';
  font-size: 16px;
  font-weight: bold;
  line-height: 3;
}
.faq_according .panel-heading .panel-title a {
  padding: 0 20px;
}

.faq_according .accordion .card {
  border: none;
}
.faq_according .accordion .card .card-header {
  background-color: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 8px !important;
  height: 52px;
  margin-bottom: 20px;
  padding: 10px 15px;
}
.faq_according .accordion .card .card-header.active {
  background-color: #ffffff;
  border: 1px solid #ff5a5f;
}
.faq_according .accordion .card .card-header .btn-link {
  font-size: 16px;
  font-family: 'Nunito';
  color: #484848;
  display: block;
  font-weight: bold;
  padding: 0;
}
.faq_according .accordion .card .card-header .btn-link:active,
.faq_according .accordion .card .card-header .btn-link:focus,
.faq_according .accordion .card .card-header .btn-link:hover {
  text-decoration: none;
}
.faq_according .accordion .card .card-header.active .btn-link {
  color: #ff5a5f;
}
.faq_according .accordion .card .card-header::before {
  font-family: 'FontAwesome';
  color: #484848;
  content: '\f107';
  float: right;
  font-weight: lighter;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  margin-right: 10px;
  margin-top: 0;
}
.faq_according .accordion .card .card-header.active::before {
  color: #ff5a5f;
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}
.faq_according .accordion .card .card-body {
  font-size: 14px;
  font-family: 'Nunito';
  color: #484848;
  line-height: 1.714;
  padding: 10px 0 30px;
}
.faq_according .accordion .card .card-body p {
  font-size: 14px;
  font-family: 'Nunito';
  color: #484848;
  line-height: 1.714;
}
/*== Terms & Condition Page ==*/
.terms_condition_grid {
  background-color: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  padding: 30px;
  position: relative;
}
.terms_condition_grid .grids h4 {
  margin-bottom: 25px;
}
.terms_condition_grid .grids p {
  font-size: 14px;
  font-family: 'Nunito';
  color: #484848;
  line-height: 1.714;
}
.terms_condition_widget {
  background-color: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  margin-bottom: 0px;
  padding: 30px 30px 20px;
  position: relative;
}
.terms_condition_widget .title {
  margin-bottom: 20px;
}
.terms_condition_widget ul {
  margin-bottom: 0;
}
.terms_condition_widget ul li a {
  font-size: 14px;
  font-family: 'Nunito';
  color: #484848;
  line-height: 2.857;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.terms_condition_widget ul li:hover a,
.terms_condition_widget ul li a span:hover {
  color: #ff5a5f;
}
.terms_condition_widget ul li:hover a span {
  color: #484848;
}
.terms_condition_widget ul li a span {
  color: #484848;
}

/*== Tab Widget UI Page ==*/
.ui_kit_tab .nav.nav-tabs {
  /* background-color: #fff; */
  border-radius: 4px;
}
.ui_kit_tab .nav.nav-tabs {
  height: 60px;
}
.ui_kit_tab .nav.nav-tabs .nav-link {
  color: #212529;
  height: 43px;
  line-height: 27px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  width: auto;
  font-family: 'Nunito';
  font-size: 16px;
  font-weight: 700;
  padding: 0.5rem 0.2rem;
}
.ui_kit_tab .nav.nav-tabs .nav-link:hover {
  border: 1px solid transparent;
}
.ui_kit_tab .nav.nav-tabs .nav-link.active {
  border-radius: 9px;
  background-color: #ffc107;
  border: none;
  color: #484848;
  font-family: 'Nunito';
  font-size: 16px;
  font-weight: 700;
  /* margin-left: 10px; */
}
.Rounded_Rectangle_13 {
  position: absolute;
  left: 1076px;
  top: 618px;
  width: 95px;
  height: 43px;
  z-index: 63;
}
.ui_kit_tab .tab-content .tab-pane {
  padding: 35px 0;
}
.ui_kit_tab .tab-content .tab-pane p {
  font-size: 14px;
  font-family: 'Nunito';
  color: #484848;
  line-height: 1.857;
  margin-bottom: 0;
}
/*== Table Widget UI Page ==*/
.ui_kit_table .table {
  background-color: #ffffff;
  border-radius: 4px 4px 0 0;
  overflow: hidden;
}
.ui_kit_table .table th {
  background-color: #24324a;
  border-bottom: transparent;
  color: #ffffff;
  font-weight: normal;
}
.ui_kit_table .table th,
.ui_kit_table .table td {
  padding-left: 30px;
}
/*== Message Box Widget UI Page ==*/
.ui_kit_message_box .alert {
  border-radius: 8px;
  height: 70px;
  line-height: 50px;
}
.alert.alart_style_one {
  background-color: #4cbfd8;
  color: #ffffff;
}
.alert.alart_style_two {
  background-color: #8c689d;
  color: #ffffff;
}
.alert.alart_style_three {
  background-color: #35c2a5;
  color: #ffffff;
}
.alert.alart_style_four {
  background-color: #ff8480;
  color: #ffffff;
}
.ui_kit_button .btn {
  border-radius: 4px;
  height: 55px;
  width: 170px;
}
/*== Form Input Widget UI Page ==*/
.ui_kit_input .form-control {
  padding-left: 20px;
}
.ui_kit_input .form-control:focus {
  box-shadow: none;
  border-color: #dddddd;
}
/*== Form Textarea Widget UI Page ==*/
.ui_kit_textarea h5 {
  margin-bottom: 18px;
}
.ui_kit_textarea .form-control:focus {
  box-shadow: none;
  border-color: #dddddd;
}
/*== Form Tooltip Widget UI Page ==*/
.ui_kit_tooltip .btn {
  border-radius: 4px;
  color: #7f7f7f;
  height: 50px;
  margin-right: 5px;
  width: 100px;
}
.ui_kit_tooltip .btn:hover {
  color: ;
}
.ui_kit_tooltip .btn:focus {
  box-shadow: none;
  border-color: #dddddd;
}
/*== Select Search Box Widget UI Page ==*/
.ui_kit_select_search button.btn {
  background-color: #ffffff !important;
  border-color: #dddddd !important;
  color: #6d6d6d !important;
  height: 50px;
  line-height: 35px;
  padding-left: 20px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.ui_kit_select_search button.btn:focus {
  box-shadow: none !important;
  border-color: #dddddd;
  outline: none !important;
}
.ui_kit_select_search .bs-searchbox .form-control {
  height: 50px;
}
.ui_kit_select_search .bs-searchbox .form-control:focus {
  box-shadow: none;
  border-color: #dddddd;
}
.ui_kit_select_search .bs-searchbox:before {
  color: #d0d1d3;
  content: '\f002';
  font-family: FontAwesome;
  font-size: 20px;
  line-height: 45px;
  position: absolute;
  right: 25px;
  z-index: 999;
}
.ui_kit_select_search .dropdown-item {
  color: #484848;
}
.ui_kit_select_search .dropdown-item:hover {
  background-color: transparent;
  color: #221f1f;
}
.ui_kit_select_search .dropdown-item.active,
.ui_kit_select_search .dropdown-item:active {
  background-color: transparent;
  color: #7f7f7f;
}
/*== Radiobox, Checkbox, Switch Widget UI Page ==*/
.ui_kit_radiobox .radio {
  display: inline-block;
  vertical-align: top;
}
.ui_kit_radiobox .radio input[type='radio'] {
  position: absolute;
  opacity: 0;
}
.ui_kit_radiobox input[type='radio'] {
  box-shadow: none;
  cursor: pointer;
  width: 16px;
  height: 16px;
}
.ui_kit_radiobox .radio label {
  cursor: pointer;
  font-size: 14px;
  font-family: 'Nunito';
  color: #484848;
  line-height: 1.714;
  line-height: 25px;
  margin: 0px 0 10px;
  padding-left: 29px;
  position: relative;
}
.ui_kit_radiobox label,
legend {
  display: block;
  font-size: 16px;
  margin-bottom: 8px;
}
.ui_kit_radiobox .radio input[type='radio']:checked + label .radio-label {
  background-color: #ff5a5f;
  box-shadow: inset 0 0 0 5px #fff;
}
.ui_kit_radiobox .radio input[type='radio'] + label .radio-label {
  content: '';
  background-color: #fff;
  border-radius: 100%;
  border: 1px solid #dedede;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  vertical-align: top;
  cursor: pointer;
  text-align: center;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  box-shadow: inset 0 0 0 8px #fff;
  z-index: 100;
  position: absolute;
  top: 2px;
  left: 0;
}
.ui_kit_radiobox label span,
legend span {
  font-weight: 400;
  font-size: 14px;
  color: #444;
}
.ui_kit_radiobox .radio-label:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background-color: transparent;
  display: block;
}
.ui_kit_radiobox .radio input[type='radio'] + label .radio-label:after {
  background-color: #ff5a5f;
  box-sizing: border-box;
  border-radius: 50%;
  content: '';
  height: 100%;
  left: 0;
  opacity: 1;
  margin: 0 auto;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  width: 100%;
  z-index: 99;
}
/*== CheckBox Widget UI Page ==*/
.ui_kit_checkbox input[type='checkbox'],
.ui_kit_checkbox input[type='radio'] {
  height: 17px;
  width: 17px;
}
.ui_kit_checkbox.style2 .custom-control-label::before {
  border-radius: 8.5px;
}
.ui_kit_checkbox .custom-control-label::after {
  left: -1.4rem;
  top: 5px;
}
.custom-control-label:focus,
.custom-control:focus {
  outline: none;
}
.custom-control-label::before {
  background-color: #ffffff;
  border: 1px solid #dddddd;
  height: 18px;
  top: 0.25rem;
  width: 18px;
}
.ui_kit_checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #ff5a5f;
  border-color: #ff5a5f;
}
.ui_kit_checkbox .custom-control-label {
  color: #4f4f4f;
  font-size: 14px;
  justify-content: left;
  line-height: 28px;
  margin: 0 0 10px;
  outline: none;
  padding-left: 7px;
  padding-right: 15px;
  width: 100%;
}
.ui_kit_checkbox .custom-control-label span {
  color: #c1c1c1;
  font-size: 14px;
}
.ui_kit_checkbox .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}
/*== SwithcBox Widget UI Page ==*/
.ui_kit_whitchbox .custom-control-label {
  font-size: 14px;
  font-family: 'Nunito';
  color: #484848;
  line-height: 1.714;
  line-height: 2;
  margin: 0 0 10px;
  outline: none;
  padding-left: 25px;
}
.ui_kit_whitchbox
  .custom-control-input:checked
  ~ .custom-control-label::before {
  background-color: #ff5a5f;
}
.ui_kit_whitchbox .custom-switch .custom-control-label::before {
  height: 1.25rem;
  border: 1px solid #dddddd;
  width: 3.15rem;
}
.ui_kit_whitchbox .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}
.ui_kit_whitchbox
  .custom-switch
  .custom-control-input:checked
  ~ .custom-control-label::after {
  -webkit-transform: translateX(2rem);
  -moz-transform: translateX(2rem);
  -o-transform: translateX(2rem);
  transform: translateX(2rem);
}
.ui_kit_whitchbox .custom-switch .custom-control-label::after {
  height: 14px;
  top: 7px;
  width: 14px;
}
/*== Select Box Widget UI Page ==*/
.ui_kit_select_box .custom-select:focus {
  box-shadow: none;
  border-color: #dddddd;
}
.ui_kit_select_box .dropdown.bootstrap-select.custom-select-lg {
  padding-left: 0;
  padding-top: 0;
}
.ui_kit_progress_bar .skills li span {
  color: #262626;
}
.ui_kit_select_box .bootstrap-select .dropdown-menu {
  margin-top: 10px;
}
/*== BlockQueto UI Page ==*/
.ui_page_heading {
  position: relative;
}
.ui_page_heading .blockquote {
  background-color: #f7f7f7;
  border-left: 8px solid #ff5a5f;
  border-radius: 8px;
  margin-bottom: 25px;
  padding: 30px 30px 30px 50px;
}
.ui_page_heading .blockquote .blockquote-header {
  margin-bottom: 15px;
}
.ui_page_heading .blockquote .blockquote-header span {
  font-size: 30px;
  color: rgb(187, 187, 187);
  font-style: italic;
  line-height: 0.333;
}
.ui_page_heading .blockquote p {
  font-size: 14px;
  font-family: 'Nunito';
  color: #484848;
  font-style: italic;
  line-height: 1.714;
}
.order_list {
  margin-bottom: 0;
}
.order_list li {
  padding-left: 30px;
  position: relative;
}
.order_list li a span {
  color: #ff5a5f;
  left: 0;
  position: absolute;
}
.order_list li a {
  font-size: 14px;
  font-family: 'Nunito';
  color: #484848;
  line-height: 2.857;
}
.list-style-type-bullet li {
  color: #ff5a5f;
  list-style-type: disc;
  margin-left: 100px;
  padding-left: 0;
  position: relative;
}
.ui_page_headings {
  margin-bottom: 40px;
}
.ui_page_headings h1 {
  font-size: 45px;
  font-family: 'Nunito';
  color: #484848;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 35px;
}
.ui_page_headings h2 {
  font-size: 35px;
  font-family: 'Nunito';
  color: #484848;
  font-weight: bold;
  line-height: 0.686;
  margin-bottom: 35px;
}
.ui_page_headings h3 {
  font-size: 30px;
  font-family: 'Nunito';
  color: #484848;
  font-weight: bold;
  line-height: 0.8;
  margin-bottom: 35px;
}
.ui_page_headings h4 {
  font-size: 26px;
  font-family: 'Nunito';
  color: #484848;
  font-weight: bold;
  line-height: 0.923;
  margin-bottom: 35px;
}
.ui_page_headings h5 {
  font-size: 22px;
  font-family: 'Nunito';
  color: #484848;
  font-weight: bold;
  line-height: 1.091;
  margin-bottom: 35px;
}
.ui_page_headings h6 {
  font-size: 18px;
  font-family: 'Nunito';
  color: #484848;
  font-weight: bold;
  line-height: 1.333;
  margin-bottom: 35px;
}
/*== MultiSelect CheckBox Widget UI Page ==*/
.ui_kit_multi_select_box
  .dropdown.bootstrap-select.show-tick.show
  .dropdown-menu {
  border-radius: 8px;
  padding: 20px;
  -webkit-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
  -moz-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
  -o-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
  box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
}
.ui_kit_multi_select_box
  .bootstrap-select.show-tick
  .dropdown-menu
  li
  a
  span.text {
  font-size: 14px;
  font-family: 'Nunito';
  color: #484848;
  line-height: 2.5;
}
.ui_kit_select_search .dropdown-menu.show {
  border-radius: 8px;
  -webkit-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
  -moz-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
  -o-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
  box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
}
.ui_kit_select_search .bootstrap-select .dropdown-menu li a span.text {
  font-size: 14px;
  font-family: 'Nunito';
  color: #484848;
  line-height: 2.5;
}
.ui_kit_select_box
  .bootstrap-select:not([class*='col-']):not([class*='form-control']):not(.input-group-btn),
.ui_kit_multi_select_box
  .bootstrap-select:not([class*='col-']):not([class*='form-control']):not(.input-group-btn) {
  width: 100%;
}
.ui_kit_select_box .bootstrap-select > .dropdown-toggle,
.ui_kit_multi_select_box .bootstrap-select > .dropdown-toggle {
  background-color: #ffffff;
  border: 1px solid #dddddd;
  color: #7f7f7f;
  height: 50px;
  line-height: 40px;
  padding-left: 20px;
}
.ui_kit_select_box .bootstrap-select > .dropdown-toggle:focus,
.ui_kit_multi_select_box .bootstrap-select > .dropdown-toggle:focus {
  box-shadow: none;
  outline: none !important;
}
.ui_kit_select_box .filter-option-inner-inner:focus,
.ui_kit_multi_select_box .filter-option-inner-inner:focus {
  outline: none;
}
.ui_kit_select_box .btn-light:not(:disabled):not(.disabled).active,
.ui_kit_select_box .btn-light:not(:disabled):not(.disabled):active,
.ui_kit_select_box .show > .btn-light.dropdown-toggle,
.ui_kit_multi_select_box .btn-light:not(:disabled):not(.disabled).active,
.ui_kit_multi_select_box .btn-light:not(:disabled):not(.disabled):active,
.ui_kit_multi_select_box .show > .btn-light.dropdown-toggle {
  background-color: #ffffff;
  color: #7f7f7f;
}
/*== Blog Post Main Page ==*/
.mbp_pagination {
  display: inline-block;
  position: relative;
  width: 100%;
}
.mbp_thumb_post .thumb {
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}
.main_blog_post_content {
  position: relative;
}
.blog_sp_tag {
  background-color: #ff5a5f;
  border-radius: 3px;
  height: 25px;
  margin-bottom: 30px;
  text-align: center;
  width: 110px;
}
.blog_sp_tag a {
  font-size: 14px;
  font-family: 'Nunito';
  color: #fefefe;
  line-height: 1.2;
}
.blog_sp_title {
  font-size: 22px;
  font-family: 'Nunito';
  color: #484848;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 30px;
}
.blog_sp_post_meta {
  margin-bottom: 30px;
}
.blog_sp_post_meta li:nth-child(2),
.blog_sp_post_meta li:nth-child(4),
.blog_sp_post_meta li:nth-child(6) {
  margin-right: 25px;
}
.blog_sp_post_meta li:last-child {
  margin-right: 0;
}
.main_blog_post_content .mbp_thumb_post {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 30px;
}
.main_blog_post_content .mbp_thumb_post .details {
  padding-top: 30px;
}
.main_blog_post_content .mbp_thumb_post .details p {
  font-size: 14px;
  font-family: 'Nunito';
  color: #484848;
  line-height: 1.714;
}
.main_blog_post_content .mbp_thumb_post .details ul.post_meta {
  margin-bottom: 20px;
}
.mbp_thumb_post .details .post_meta li:nth-child(2) {
  margin-right: 20px;
  margin-left: 8px;
}
.mbp_thumb_post .details .post_meta li:nth-child(4) {
  margin-left: 8px;
}
.mbp_thumb_post li {
  display: inline-block;
}
.mbp_pagination ul.page_navigation {
  height: 70px;
  margin-bottom: 0;
  text-align: center;
  width: 100%;
}
.mbp_pagination ul.page_navigation li.page-item {
  display: inline-block;
  margin-right: 6px;
  margin-top: 12px;
}
.mbp_pagination ul.page_navigation li .page-link {
  background-color: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 50%;
  font-size: 14px;
  font-family: 'Nunito';
  color: #777777;
  line-height: 1.2;
  height: 45px;
  line-height: 30px;
  overflow: hidden;
  text-align: center;
  width: 45px;
}
.mbp_pagination ul.page_navigation li.active .page-link {
  background-color: #ff5a5f;
  color: #ffffff;
}
.event_counter_plugin_container {
  border-radius: 5px;
  background-image: -moz-linear-gradient(
    0deg,
    rgb(52, 82, 255) 0%,
    rgb(255, 16, 83) 100%
  );
  background-image: -webkit-linear-gradient(
    0deg,
    rgb(52, 82, 255) 0%,
    rgb(255, 16, 83) 100%
  );
  background-image: -ms-linear-gradient(
    0deg,
    rgb(52, 82, 255) 0%,
    rgb(255, 16, 83) 100%
  );
  height: 100px;
  margin-bottom: 15px;
  margin-top: 30px;
  position: relative;
}
.event_counter_plugin_content {
  text-align: center;
}
.event_counter_plugin_content ul {
  margin-bottom: 0;
  text-align: center;
}
.event_counter_plugin_content ul li {
  color: #ffffff;
  display: inline-block;
  font-size: 15px;
  font-family: 'Open Sans';
  color: #ffffff;
  line-height: 2.667;
  margin-top: 10px;
  text-align: left;
  text-transform: capitalize;
  width: 22%;
}
.event_counter_plugin_content ul li:last-child {
  width: auto;
}
.event_counter_plugin_content ul li span {
  display: block;
  font-size: 26px;
  font-family: 'Nunito';
  color: #ffffff;
  line-height: 1.2;
}
/*== Blog Grid Post ==*/
.blog_grid_post {
  border-style: solid;
  border-width: 2px;
  border-color: rgb(237, 239, 247);
  border-radius: 5px;
  background-color: #ffffff;
  overflow: hidden;
}
.blog_grid_post .thumb {
  position: relative;
  overflow: hidden;
}
.blog_grid_post .thumb:before {
  background-color: rgba(0, 0, 0, 0.3);
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
.blog_grid_post .thumb .tag {
  font-size: 14px;
  font-family: 'Open Sans';
  color: rgb(250, 250, 250);
  line-height: 1.2;
  left: 30px;
  line-height: 40px;
  position: absolute;
  top: 30px;
}
.blog_grid_post .thumb .post_date {
  bottom: 30px;
  font-weight: bold;
  max-width: 95px;
  position: absolute;
  right: 30px;
  text-align: center;
}
.blog_grid_post .thumb .post_date h2 {
  font-size: 50px;
  font-family: 'Nunito';
  color: #ffffff;
  margin-bottom: 0;
}
.blog_grid_post .thumb .post_date span {
  font-size: 18px;
  font-family: 'Nunito';
  color: #ffffff;
}
.blog_grid_post .details h3 {
  font-size: 26px;
  font-family: 'Nunito SemiBold';
  color: #484848;
  line-height: 1.2;
  margin-bottom: 15px;
}
.blog_grid_post .details {
  padding: 20px 25px 30px 30px;
}
.blog_grid_post .details ul.post_meta {
  margin-bottom: 18px;
}
.blog_grid_post .details ul.post_meta li a span {
  font-size: 15px;
  font-family: 'Open Sans';
  color: rgb(126, 126, 126);
  line-height: 1.6;
}
.blog_grid_post .details p {
  font-size: 15px;
  font-family: 'Open Sans';
  color: rgb(126, 126, 126);
  line-height: 1.6;
  margin-bottom: 0;
}
.blog_grid_post.style2 .details {
  border-radius: 0 5px 5px 0;
  padding: 60px 45px 55px 40px;
}
.blog_grid_post.style2 .details p {
  font-size: 15px;
  font-family: 'Open Sans';
  color: rgb(126, 126, 126);
  line-height: 1.6;
}
.blog_grid_post .details .post_meta li:nth-child(2) {
  margin-right: 20px;
  margin-left: 8px;
}
.blog_grid_post .details .post_meta li:nth-child(4) {
  margin-left: 8px;
}
.blog_grid_post li {
  display: inline-block;
}
.blog_grid_post.style2.event_lists {
  border-left: none;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.main_blog_post_content .event_lists:hover .blog_grid_post.event_lists {
  -webkit-box-shadow: 0px 0px 30px 0px rgba(32, 32, 32, 0.15);
  -moz-box-shadow: 0px 0px 30px 0px rgba(32, 32, 32, 0.15);
  -o-box-shadow: 0px 0px 30px 0px rgba(32, 32, 32, 0.15);
  box-shadow: 0px 0px 30px 0px rgba(32, 32, 32, 0.15);
}
.blog_grid_post.style2.event_lists .thumb img {
  max-width: 600px;
  max-height: 385px;
}
.blog_grid_post.style2.event_lists ul li {
  display: list-item;
}
.blog_grid_post.style2.event_lists .details {
  padding: 50px 75px 60px 50px;
}
.blog_grid_post.style2.event_lists .details h3 {
  font-size: 22px;
  font-family: 'Nunito';
  color: #484848;
  line-height: 1.091;
}
.blog_grid_post.style2.event_lists .details p {
  font-size: 15px;
  font-family: 'Open Sans';
  color: rgb(126, 126, 126);
  line-height: 1.6;
}
.blog_grid_post.style2.event_lists .details ul {
  margin-top: 25px;
}
.blog_grid_post.style2.event_lists .details ul li {
  margin-bottom: 20px;
}
.blog_grid_post.style2.event_lists .details ul li:last-child {
  margin-bottom: 0;
}
.blog_grid_post.style2.event_lists .details ul li a {
  font-size: 14px;
  font-family: 'Open Sans';
  color: rgb(126, 126, 126);
  line-height: 1.2;
}
.blog_grid_post.style2.event_lists .details ul li a span {
  font-size: 18px;
  padding-right: 10px;
}
.event_participator_tile {
  font-size: 20px;
  font-family: 'Nunito SemiBold';
  color: #484848;
  line-height: 1.2;
  margin-bottom: 50px;
}
.participator_team .thumb img {
  height: 100px;
  width: 100px;
}
.participator_team .details h4 {
  font-size: 18px;
  font-family: 'Nunito';
  color: #484848;
  line-height: 1.333;
  margin-bottom: 0;
  margin-top: 20px;
}
.participator_team .details p {
  font-size: 15px;
  font-family: 'Open Sans';
  color: rgb(126, 126, 126);
  line-height: 1.2;
}
/*== Search Widget Blog Page ==*/
.sidebar_recent_product,
.sidebar_feature_listing,
.sidebar_search_widget {
  border: 1px solid rgb(235, 235, 235);
  border-radius: 8px;
  background-color: #ffffff;
  margin-bottom: 30px;
  padding: 30px;
}
.sidebar_recent_product,
.sidebar_feature_listing {
  padding: 30px 30px 0;
}
.sidebar_recent_product .title,
.sidebar_feature_listing .title {
  font-size: 18px;
  font-family: 'Nunito';
  color: #484848;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 25px;
}
.sidebar_recent_product .media,
.sidebar_feature_listing .media {
  margin-bottom: 30px;
}
.sidebar_recent_product .media img {
  border-radius: 6px;
  height: 80px;
  width: 90px;
}
.sidebar_recent_product .media .media-body .post_title,
.sidebar_feature_listing .media .media-body .post_title {
  font-size: 16px;
  font-family: 'Nunito';
  color: #484848;
  font-weight: bold;
  line-height: 1.2;
}
.sidebar_recent_product .media .media-body a,
.sidebar_feature_listing .media .media-body a {
  font-size: 16px;
  font-family: 'Nunito';
  color: rgb(255, 90, 95);
  font-weight: bold;
  line-height: 1.2;
}
.sidebar_feature_listing .media .media-body ul li {
  font-size: 14px;
  font-family: 'Nunito';
  color: #484848;
  line-height: 1.2;
  margin-right: 0;
}
.blog_search_widget button.btn {
  height: 48px;
}
.blog_search_widget input.form-control {
  border: 1px solid #ebebeb;
  border-radius: 8px;
  background-color: #ffffff;
  flex: 1;
  height: 52px;
  padding-left: 20px;
  width: 100%;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.blog_search_widget input.form-control:focus {
  border-right: none;
}
.blog_search_widget button#button-addon2 {
  background-color: #fff;
  border-bottom: 1px solid #ebebeb;
  border-left: transparent !important;
  border-right: 1px solid #ebebeb;
  border-top: 1px solid #ebebeb;
  height: 52px;
}
.blog_search_widget button#button-addon2:focus {
  box-shadow: none;
}
.blog_search_widget button#button-addon2:hover {
  color: #6c757d;
}
/*== Category Widget Blog Page ==*/
.blog_category_widget {
  margin-bottom: 30px;
}
.blog_recent_post_widget .title,
.blog_category_widget .title {
  font-size: 20px;
  font-family: 'Nunito SemiBold';
  color: #484848;
  line-height: 1.2;
  margin-bottom: 0;
}
.blog_category_widget ul.list-group {
  border-style: solid;
  border-width: 2px;
  border-color: rgb(238, 238, 238);
  border-radius: 5px;
  background-color: rgba(243, 243, 246, 0);
  padding: 30px;
}
.blog_category_widget ul li.list-group-item {
  background-color: transparent;
  border: none;
  font-size: 15px;
  font-family: 'Open Sans';
  color: #484848;
  padding: 10px 0;
}
.blog_category_widget ul li.list-group-item span {
  font-size: 15px;
  font-family: 'Open Sans';
  color: rgb(126, 126, 126);
  font-weight: normal;
}
.event_details_widget {
  border-style: solid;
  border-width: 2px;
  border-color: rgb(238, 238, 238);
  border-radius: 5px;
  background-color: rgba(243, 243, 246, 0);
  margin-bottom: 30px;
  padding: 30px;
}
.event_details_widget .title {
  font-size: 20px;
  font-family: 'Nunito SemiBold';
  color: #484848;
  line-height: 1.2;
  margin-bottom: 25px;
}
.event_details_widget ul {
  margin-bottom: 0;
}
.event_details_widget ul li {
  font-size: 14px;
  font-family: 'Open Sans';
  color: rgb(126, 126, 126);
  line-height: 1.5;
  margin-bottom: 20px;
}
.event_details_widget ul li:last-child {
  margin-bottom: 0;
}
.event_details_widget ul li span {
  font-size: 18px;
  padding-right: 10px;
}
.category_sidebar_widget {
  position: relative;
}
.category_sidebar_widget .category_list {
  margin-bottom: 0;
}
.category_sidebar_widget .category_list li a {
  font-family: 'Open Sans';
  font-size: 15px;
  color: rgb(79, 79, 79);
  line-height: 2.667;
}
.category_sidebar_widget .category_list li a span {
  font-family: 'Open Sans';
  font-size: 14px;
  color: rgb(193, 193, 193);
  line-height: 2.857;
}
.feature_course_widget {
  border-style: solid;
  border-width: 2px;
  border-color: rgb(238, 238, 238);
  border-radius: 5px;
  background-color: rgba(243, 243, 246, 0);
  padding: 30px 0 15px;
  margin-bottom: 30px;
}
.feature_course_widget h4 {
  font-family: 'Nunito SemiBold';
  font-size: 20px;
  color: #484848;
  line-height: 1.2;
  padding-left: 30px;
}
.feature_course_widget ul li {
  border: 1px solid rgb(235, 235, 235);
  color: #484848;
  font-family: 'Open Sans';
  font-size: 15px;
  line-height: 2.667;
  padding: 0 30px;
}
.feature_course_widget ul li span {
  font-size: 15px;
  font-family: 'Open Sans';
  color: rgb(126, 126, 126);
  line-height: 2.667;
}
.feature_course_widget ul li:last-child {
  border-bottom: none;
  padding: 0 30px;
}
.instructor_pricing_widget {
  border-style: solid;
  border-width: 2px;
  border-color: rgb(237, 239, 247);
  border-radius: 5px;
  background-color: #ffffff;
  margin-bottom: 30px;
  padding: 30px;
  text-align: center;
}
.instructor_pricing_widget.csv2 {
  margin-top: -300px;
}
.instructor_pricing_widget .price {
  font-size: 30px;
  font-family: 'Nunito';
  color: #484848;
  font-weight: bold;
  line-height: 1.2;
}
.instructor_pricing_widget .price span {
  font-size: 15px;
  font-family: 'Open Sans';
  color: rgb(79, 79, 79);
  line-height: 2.667;
}
.instructor_pricing_widget .price small {
  font-size: 14px;
  font-family: 'Open Sans';
  color: rgb(193, 193, 193);
  line-height: 2.857;
}
.instructor_pricing_widget h5 {
  font-size: 15px;
  font-family: 'Open Sans';
  color: rgb(79, 79, 79);
  line-height: 2.667;
}
.instructor_pricing_widget .price_quere_list li a {
  font-size: 15px;
  font-family: 'Open Sans';
  color: rgb(126, 126, 126);
  line-height: 2.667;
}
.instructor_pricing_widget .price_quere_list li a span {
  color: #484848;
  font-size: 18px;
  padding-right: 5px;
}
.cart_btnss {
  border-radius: 27px;
  background-color: rgb(255, 16, 83);
  display: block;
  font-size: 15px;
  font-family: 'Open Sans';
  color: #ffffff;
  height: 50px;
  line-height: 3.2;
  margin-bottom: 23px;
  margin-top: 23px;
  max-width: 234px;
}
.cart_btnss:hover {
  background-color: #ffffff;
  border: 1px solid #ff1053;
  color: #ff1053;
}
.cart_btnss_white {
  background-color: #ffffff;
  border: 1px solid #ff1053;
  border-radius: 27px;
  color: #ff1053;
  display: block;
  font-size: 15px;
  font-family: 'Open Sans';
  line-height: 3.2;
  margin-bottom: 23px;
  height: 50px;
  max-width: 234px;
}
.cart_btnss_white:hover {
  background-color: #ff1053;
  color: #ffffff;
}
/*== Recent Post Widget Blog Page ==*/
.blog_recent_post_widget {
  margin-bottom: 30px;
}
.blog_recent_post_widget .title {
  font-size: 20px;
  margin-bottom: 20px;
}
.blog_recent_post_widget.media_widget {
  border-style: solid;
  border-width: 2px;
  border-color: rgb(238, 238, 238);
  border-radius: 5px;
  background-color: rgba(243, 243, 246, 0);
  padding: 30px 20px 30px 30px;
}
.blog_recent_post_widget.media_widget .media {
  margin-bottom: 30px;
}
.blog_recent_post_widget.media_widget .media:last-child {
  margin-bottom: 0;
}
.blog_recent_post_widget.media_widget .media img {
  border-radius: 8px;
  max-width: 90px;
}
.blog_recent_post_widget .media-body .post_title {
  font-size: 16px;
  font-family: 'Nunito';
  color: #212121;
  line-height: 1.5;
  margin-bottom: 0;
}
.blog_recent_post_widget .media-body a {
  font-size: 14px;
  font-family: 'Open Sans';
  color: rgb(123, 123, 123);
  line-height: 1.2;
}
/*== Tag Widget Blog Page ==*/
.blog_tag_widget {
  border: 1px solid rgb(235, 235, 235);
  border-radius: 8px;
  background-color: #ffffff;
  padding: 30px 30px 20px;
}
.blog_tag_widget .title {
  font-size: 18px;
  font-family: 'Nunito';
  color: #484848;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 20px;
}
.blog_tag_widget .tag_list li {
  border-radius: 6px;
  background-color: rgb(227, 227, 227);
  height: 40px;
  line-height: 40px;
  margin-bottom: 10px;
  padding: 0px 25px;
}
.blog_tag_widget .tag_list li:hover {
  cursor: pointer;
}
.blog_tag_widget .tag_list li a {
  font-size: 14px;
  font-family: 'Nunito';
  color: #484848;
  line-height: 1.2;
}
/*== BlokQuote Blog Sinlge Page ==*/
.mbp_blockquote {
  background-color: rgb(247, 247, 247);
  border-left: 8px solid #ff5a5f;
  border-radius: 8px;
  height: 147px;
  margin-bottom: 30px;
  margin-top: 25px;
  padding: 25px 30px 30px 40px;
}
.mbp_blockquote .blockquote {
  position: relative;
}
.mbp_blockquote .blockquote:before {
  background-color: transparent;
}
.mbp_blockquote .blockquote span {
  font-size: 16px;
  font-family: 'Nunito';
  color: rgb(187, 187, 187);
  font-style: italic;
  line-height: 0.333;
}
.mbp_blockquote .blockquote em {
  font-size: 14px;
  font-family: 'Nunito';
  color: #484848;
  font-style: italic;
  line-height: 1.714;
}
.blog_post_share li {
  background-color: #f7f7f7;
  border-radius: 8px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  width: 45px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.blog_post_share li:first-child {
  background-color: transparent;
  margin-right: 25px;
  width: auto;
}
.blog_post_share li:first-child a {
  font-size: 16px;
  font-family: 'Nunito';
  color: #484848;
  font-weight: bold;
  line-height: 2.5;
}
.blog_post_share li:first-child:hover {
  background-color: transparent;
}
.blog_post_share li:hover {
  cursor: pointer;
}
.blog_post_share li a {
  font-size: 14px;
  color: #484848;
  line-height: 1.2;
}
.blog_post_share li:hover a {
  color: rgb(72, 73, 77);
}
.blog_post_share li p {
  font-size: 18px;
  font-family: 'Nunito';
  color: rgb(59, 59, 59);
  line-height: 1.333;
}
/*== Next Prev Pagination Blog Sinlge Page ==*/
.mbp_pagination_tab {
  padding: 35px 0;
  position: relative;
}
.mbp_pagination_tab .pag_prev {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
}
.mbp_pagination_tab .pag_prev a,
.mbp_pagination_tab .pag_next a {
  background-color: rgb(208, 208, 208);
  border-radius: 50%;
  height: 45px;
  line-height: 45px;
  padding-left: 0;
  text-align: center;
  width: 45px;
}
.mbp_pagination_tab .pag_prev:hover,
.mbp_pagination_tab .pag_next:hover {
  cursor: pointer;
}
.mbp_pagination_tab .pag_next a {
  display: inline-block;
  float: right;
}
.mbp_pagination_tab .pag_prev a:hover,
.mbp_pagination_tab .pag_next a:hover {
  background-color: #ff5a5f;
}
.mbp_pagination_tab .pag_prev a span,
.mbp_pagination_tab .pag_next a span {
  color: #ffffff;
  font-size: 14px;
  line-height: 1.2;
  -moz-transform: matrix(0, -1, 1, 0, 0, 0);
  -webkit-transform: matrix(0, -1, 1, 0, 0, 0);
  -ms-transform: matrix(0, -1, 1, 0, 0, 0);
}
.mbp_pagination_tab .pag_prev .detls {
  padding-left: 15px;
}
.mbp_pagination_tab .pag_next a {
  padding-right: 0;
}
.mbp_pagination_tab .pag_next .detls {
  padding-right: 15px;
}
.mbp_pagination_tab .pag_prev .detls,
.mbp_pagination_tab .pag_next .detls {
  display: inline-block;
  font-size: 16px;
  font-family: 'Nunito';
  color: #484848;
  line-height: 1.75;
  margin-bottom: 0;
}
.mbp_pagination_tab .pag_prev .detls h5,
.mbp_pagination_tab .pag_next .detls h5 {
  font-size: 16px;
  font-family: 'Nunito';
  font-weight: bold;
  line-height: 1.5;
  margin-bottom: 0;
}
.mbp_pagination_tab .pag_prev .detls p,
.mbp_pagination_tab .pag_next .detls p {
  font-size: 14px;
  font-family: 'Nunito';
  color: #484848;
  line-height: 1.714;
  margin-bottom: 0;
}
.mbp_pagination_tab .pag_prev .detls h5:hover,
.mbp_pagination_tab .pag_next .detls h5:hover,
.mbp_pagination_tab .pag_prev .detls p:hover,
.mbp_pagination_tab .pag_next .detls p:hover,
.mbp_pagination_tab .pag_prev a:hover,
.mbp_pagination_tab .pag_next a:hover {
  color: #ff5a5f;
}
/*== Commenting Blog Sinlge Page ==*/
.mbp_pagination_comments {
  background-color: #ffffff;
  border: 1px solid rgb(235, 235, 235);
  border-radius: 8px;
  position: relative;
}
.mbp_pagination_comments .total_review {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  padding: 30px 30px 0;
  position: relative;
}
.mbp_pagination_comments .total_review h4 {
  margin-bottom: 0;
}
.mbp_pagination_comments .total_review ul.review_star_list li {
  margin-right: 3px;
}
.mbp_pagination_comments .title {
  font-size: 20px;
  margin-bottom: 30px;
}
.mbp_pagination_comments .mbp_first.media {
  padding: 30px 30px;
}
.mbp_pagination_comments .mbp_first.media .media-body p {
  font-size: 14px;
  font-family: 'Nunito';
  color: #484848;
  line-height: 1.714;
  margin-bottom: 0;
}

.mbp_pagination_comments .mbp_first img {
  margin-right: 1.6rem !important;
}
.mbp_pagination_comments .mbp_first .media-body .sub_title {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  font-size: 16px;
  font-family: 'Nunito';
  color: #484848;
  font-weight: bold;
  line-height: 1.5;
  margin-bottom: 0;
}
.mbp_pagination_comments .review_star_list li i {
  font-size: 14px;
  color: #bcc52a;
}
.mbp_pagination_comments a.write_review {
  font-size: 14px;
  font-family: 'Nunito';
  color: rgb(255, 90, 95);
  position: absolute;
  right: 30px;
}
.mbp_pagination_comments .tr_outoff {
  font-size: 14px;
  font-family: 'Nunito';
  color: rgb(118, 118, 118);
}
/*== Commenting Form Blog Sinlge Page ==*/
.mbp_comment_form {
  margin-top: 20px;
}
.mbp_comment_form h4 {
  font-size: 20px;
  margin-bottom: 30px;
}
.mbp_comment_form .comments_form {
  background-color: #f1f1f1;
  padding: 30px 50px 50px;
}
.mbp_comment_form .comments_form input {
  border: 1px solid #dddddd;
  height: 50px;
}
.mbp_comment_form .comments_form input:focus,
.mbp_comment_form .comments_form button.btn:focus,
.mbp_comment_form .comments_form textarea:focus {
  box-shadow: none;
}
.mbp_comment_form .comments_form textarea {
  border: 1px solid #dddddd;
  height: auto;
}
.mbp_comment_form .comments_form .form-group label {
  color: #221f1f;
}
.mbp_comment_form .comments_form button.btn {
  border-radius: 4px;
  font-size: 16px;
  height: 55px;
  margin-top: 10px;
}
.mbp_comment_form .comments_form button span {
  padding-left: 10px;
}
/*== Dashbord details Page ==*/
.our-dashbord {
  position: relative;
}
.our-dashbord.dashbord {
  padding-top: 90px !important;
}
.our-dashbord.dashbord .container-fluid {
  padding-left: 3%;
  padding-right: 15px !important;
}
.breadcrumb_widgets {
  border-radius: 5px;
  background-color: rgb(225, 230, 239);
  height: 70px;
  margin-bottom: 30px;
  padding: 20px 30px;
  position: relative;
}
.breadcrumb_widgets .title {
  font-size: 22px;
  font-family: 'Nunito SemiBold';
  color: rgb(10, 10, 10);
  line-height: 1.2;
  margin-bottom: 0;
}
.breadcrumb_widgets .breadcrumb {
  background-color: transparent;
  padding: 0;
  position: relative;
}
.breadcrumb_widgets .breadcrumb .breadcrumb-item {
  font-family: 'Open Sans';
  font-size: 15px;
  color: #7e7e7e;
}
.breadcrumb_widgets .breadcrumb .breadcrumb-item.active {
  color: rgb(126, 126, 126);
  line-height: 1.6;
}
.user_board {
  padding: 0 15px 0 0;
  position: relative;
}
.user_profile {
  margin-bottom: 10px;
  position: relative;
}
.user_profile .media .media-body h4 {
  font-size: 18px;
  font-family: 'Nunito';
  color: #484848;
  line-height: 1.2;
  padding-left: 30px;
}
.user_profile .media .media-body h5,
.user_profile .media .media-body p {
  margin-bottom: 0;
}
.user_profile .media img {
  width: 65px;
}
.dashbord_nav_list h4 {
  position: relative;
  padding-left: 30px;
}
.dashbord_nav_list li {
  height: 50px;
  line-height: 45px;
  padding-left: 30px;
  position: relative;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.dashbord_nav_list li a {
  color: #7f7f7f;
  font-size: 16px;
}
.dashbord_nav_list li:hover a,
.dashbord_nav_list li:hover a span {
  color: #ff5a5f;
}
.dashbord_nav_list li.active {
  background-color: #f9fafc;
}
.dashbord_nav_list li.active a {
  color: #ff5a5f;
}
.dashbord_nav_list li.active:before {
  background-color: #ff5a5f;
  bottom: 0;
  content: '';
  height: 50px;
  position: absolute;
  right: 0px;
  top: 0;
  width: 2px;
}
.dashbord_nav_list li a span {
  font-size: 23px;
  margin-right: 15px;
}
.my_dashboard_review,
.my_listing_single {
  background-color: #ffffff;
  border: 1px solid rgb(235, 235, 235);
  border-radius: 8px;
  /* padding: 10px 0px 30px 0px; */
}
.my_dashboard_review .review_content .review_title {
  font-size: 18px;
  font-family: 'Nunito';
  color: #484848;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 0;
}
.my_dashboard_review .review_content .review_date {
  font-size: 14px;
  font-family: 'Nunito';
  color: rgb(118, 118, 118);
  line-height: 2.143;
}
.my_dashboard_review .review_content .para {
  margin-top: 10px;
}
.my_dashboard_review .review_content .sspd_review ul li {
  margin-right: 3px;
}
.my_dashboard_review .review_content .sspd_review ul li a {
  font-size: 14px;
  font-family: 'FontAwesome';
  color: rgb(188, 197, 42);
  line-height: 2.143;
}
.packages_table .table,
.savesearched_table .table,
.property_table .table {
  border: 1px solid #ebebeb;
  border-radius: 8px;
  margin-bottom: 30px;
}
.packages_table .table .thead-light th,
.savesearched_table .table .thead-light th,
.property_table .table .thead-light th {
  background-color: rgb(36, 50, 74);
  border: none;
  font-size: 16px;
  font-family: 'Nunito';
  color: #ffffff;
  font-weight: bold;
  line-height: 2.2;
}
.packages_table .table td,
.packages_table .table th,
.savesearched_table .table td,
.savesearched_table .table th,
.property_table .table td,
.property_table .table th {
  font-size: 14px !important;
  font-family: 'Nunito';
  color: #484848;
  font-weight: 600;
  line-height: 1.5 !important;
  padding-left: 15px !important;
}
.property_table .table td,
.property_table .table th {
  vertical-align: middle;
}
.savesearched_table td.para,
.property_table td.para {
  font-size: 14px;
  font-family: 'Nunito';
  color: #484848;
}
.pck_chng_btn .btn {
  border-radius: 8px;
  background-color: rgb(255, 90, 95);
  -webkit-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  -moz-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  -o-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  font-size: 16px;
  font-family: 'Nunito';
  color: #ffffff;
  font-weight: bold;
  line-height: 1.2;
  height: 50px;
  width: 200px;
}
.skill_sidebar_widget {
  margin-top: 40px;
  margin-left: 15px;
  padding-right: 50px;
}
.skill_sidebar_widget h4 {
  font-size: 17px;
  margin-bottom: 20px;
}
.skill_sidebar_widget .sonny_progressbar .bar {
  border-radius: 5px;
  height: 10px;
}
.skill_sidebar_widget .sonny_progressbar .bar-container {
  border-radius: 5px;
  height: 10px !important;
  overflow: hidden;
}
.password_change_form form input {
  border-style: solid;
  border-width: 1px;
  border-color: rgb(221, 221, 221);
  border-radius: 5px;
  background-color: rgba(196, 14, 117, 0);
  height: 48px;
  margin-bottom: 30px;
  padding-left: 20px;
  -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  -o-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
}
.password_change_form form input:focus {
  -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  -o-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  outline: none;
}
.password_change_form form button {
  border-radius: 4px;
  height: 55px;
  padding: 0 35px;
}
.password_change_form form button:focus {
  box-shadow: none;
  outline: none;
}
/*== Header User Notification & User ID ==*/
.header_user_notif {
  margin-bottom: 0;
  margin-top: 10px;
  position: relative;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.header_user_notif li {
  display: inline-block;
  vertical-align: middle;
}
.header_user_notif li:first-child {
  margin-right: 20px;
}
.header_user_notif li:nth-child(2) {
  margin-right: 15px;
}
.header_user_notif li span {
  color: #ffffff;
  font-size: 20px;
  line-height: 2;
}
.header_user_notif li .dropdown .notification_icon {
  border-radius: 50%;
  background-color: #e35a9a;
  display: block;
  height: 43px;
  line-height: 40px;
  text-align: center;
  width: 43px;
}
.header_user_notif li img {
  width: 50px;
}
.header_user_notif .user_notif {
  position: relative;
}
.header_user_notif
  .user_notif
  .dropdown-menu.notification_dropdown_content.show {
  border: none;
  border-radius: 5px;
  margin-top: 25px;
  margin-left: -270px !important;
  padding: 30px 10px 25px 30px;
  -webkit-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
  -moz-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
  -o-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
  box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
  max-width: 340px;
}
.header_user_notif
  .user_setting
  .dropdown-menu.notification_dropdown_content.show:before {
  background-color: #ffffff;
  content: '';
  height: 37px;
  position: absolute;
  right: 40px;
  width: 37px;
  top: -7px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.header_user_notif
  .user_notif
  .dropdown-menu.notification_dropdown_content.show
  .view_all_noti {
  display: block;
  padding: 20px 0 0;
}
.header_user_notif .user_notif .so_heading:before {
  background-color: #ffffff;
  content: '';
  height: 37px;
  position: absolute;
  right: 30px;
  width: 37px;
  top: -7px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.header_user_notif .user_notif .so_heading p {
  border-bottom: 1px solid #dedede;
  color: #484848;
  font-size: 18px;
  font-family: 'Nunito';
  line-height: 1.333;
  margin-bottom: 0;
  padding-bottom: 20px;
}
.header_user_notif .user_notif .so_content {
  max-height: 200px;
}
.header_user_notif .user_notif .so_content .simplebar-offset {
  width: auto;
}
.header_user_notif .user_notif .so_content ul {
  margin-bottom: 0;
}
.header_user_notif .user_notif .so_content ul li {
  border-bottom: 1px solid #dedede;
  padding: 20px 0 20px;
}
.header_user_notif .user_notif .so_content ul li h5,
.header_user_notif .user_notif .so_content ul li p {
  font-size: 15px;
  margin-bottom: 0;
}
.header_user_notif .user_notif .so_content ul li p {
  color: #7f7f7f;
}
.header_user_notif .user_notif .so_content ul li:first-child {
  margin-right: 0;
}
.header_user_notif .user_notif .so_content ul li:last-child {
  border-bottom: none;
}
.header_user_notif .user_notif {
  padding: 0;
}
.header_user_notif .user_notif .simplebar-offset:focus {
  outline: none;
}
.header_user_notif .user_notif .simplebar-offset::-webkit-scrollbar {
  margin-top: 15px;
  margin-right: 10px;
  width: 6px;
  z-index: 3;
}
.header_user_notif .user_notif .simplebar-offset::-webkit-scrollbar * {
  background: transparent;
}
.header_user_notif .user_notif .simplebar-offset::-webkit-scrollbar-track {
  display: none;
}
.header_user_notif .user_notif .simplebar-offset::-webkit-scrollbar-thumb {
  background-color: #dfdfdf !important;
  border-radius: 6px;
}
.simplebar-placeholder {
  max-height: 355px !important;
  width: 325px !important;
}
/*== Header User Setting ID ==*/
.user_setting {
  position: relative;
}
.user_setting:before {
  content: '';
  background-color: #e8eef2;
  height: 38px;
  position: absolute;
  top: 10px;
  width: 1px;
}
.user_setting .btn {
  border: 1px solid transparent;
  color: #ffffff;
}
.user_setting a.btn.dropdown-toggle:after {
  display: none;
}
.user_setting .btn:focus {
  border: 1px solid transparent;
  box-shadow: none;
}
.user_setting .dropdown-menu.show {
  border: none;
  border-radius: 5px;
  margin-top: 19px;
  margin-left: -200px !important;
  padding: 20px 30px 10px;
  -webkit-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
  -moz-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
  -o-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
  box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
  width: 300px;
}
.user_setting .dropdown-menu.show:before {
  background-color: #ffffff;
  content: '';
  height: 37px;
  position: absolute;
  right: 40px;
  width: 37px;
  top: -7px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.user_setting .dropdown-menu.show .user_set_header {
  padding: 10px 0;
}
.user_setting .dropdown-menu.show .user_set_header img {
  margin-right: 15px;
}
.user_setting .dropdown-menu .user_set_header p {
  font-size: 16px;
  font-family: 'Nunito';
  color: #484848;
  line-height: 1.2;
}
.user_setting .dropdown-menu .user_set_header p span {
  font-size: 14px;
  font-family: 'Open Sans';
  color: rgb(126, 126, 126);
  line-height: 1.2;
}
.user_setting .dropdown-menu .user_setting_content {
  padding: 15px 0 0;
}
.user_setting .dropdown-menu .user_setting_content a {
  color: #484848;
  font-size: 16px;
  font-family: 'Nunito';
  line-height: 2.125 !important;
  padding-left: 0;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.user_setting .dropdown-menu .user_setting_content a:hover {
  padding-left: 12px;
}
.user_setting .dropdown-menu .user_setting_content a.active,
.user_setting .dropdown-menu .user_setting_content a.active span,
.user_setting .dropdown-menu .user_setting_content a:hover,
.user_setting .dropdown-menu .user_setting_content a:hover span {
  background-color: transparent;
}
.user_setting .dropdown-menu .user_setting_content a span {
  margin-right: 10px;
}
/*== Candidate Job Review Page Table ==*/
.candidate_revew_search_box input.form-control {
  background-color: #ffffff;
  border: 1px solid rgb(235, 235, 235);
  border-right: none;
  border-radius: 8px 0px 0px 8px;
  height: 52px;
  margin-right: 0 !important;
  padding-left: 20px;
  padding-right: 0;
  width: 200px !important;
}
.candidate_revew_search_box button {
  background-color: #ffffff;
  border: 1px solid rgb(235, 235, 235);
  border-left: none;
  border-radius: 0 8px 8px 0;
  color: #484848;
  height: 52px;
  width: 70px;
}
.candidate_revew_search_box button:hover {
  color: #484848;
}
.candidate_revew_search_box input.form-control:focus,
.candidate_revew_search_box button:focus {
  box-shadow: none;
  outline: none;
}
.candidate_revew_select .bootstrap-select > .dropdown-toggle,
.grid_list_search_result .bootstrap-select > .dropdown-toggle {
  background-color: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 4px;
  color: #7f7f7f;
  height: 50px;
  line-height: 35px;
}
.candidate_revew_select .bootstrap-select > .dropdown-toggle:hover,
.candidate_revew_select .bootstrap-select > .dropdown-toggle:focus,
.candidate_revew_select .bootstrap-select > .dropdown-toggle:active,
.grid_list_search_result .bootstrap-select > .dropdown-toggle:hover,
.grid_list_search_result .bootstrap-select > .dropdown-toggle:focus,
.grid_list_search_result .bootstrap-select > .dropdown-toggle:active {
  background-color: #ffffff;
  color: #7f7f7f;
  box-shadow: none;
  outline: none !important;
}
.candidate_revew_select .dropdown-item.active,
.candidate_revew_select .dropdown-item:active,
.grid_list_search_result .dropdown-item.active,
.grid_list_search_result .dropdown-item:active {
  background-color: #f7f7f7;
  color: #484848;
}
.grid_list_search_result {
  background-color: #ffffff;
  border: 1px solid rgb(235, 235, 235);
  border-radius: 8px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  height: 62px;
  margin: 0 auto 30px;
  padding: 0 15px;
  position: relative;
  width: 96%;
}
.grid_list_search_result.style2 {
  width: 98%;
}
.grid_list_search_result .left_area p {
  margin-bottom: 0;
  margin-top: 20px;
}
.grid_list_search_result .right_area ul {
  margin-bottom: 0;
}
.grid_list_search_result .right_area li {
  margin-top: 6px;
}
.grid_list_search_result .right_area ul li:first-child {
  margin-right: 40px;
  position: relative;
}
.grid_list_search_result .right_area.style2 ul li:first-child {
  margin-right: 0;
}
.grid_list_search_result .right_area.style2 ul li:first-child:before {
  display: none;
}
.grid_list_search_result .right_area ul li:first-child:before {
  background-color: rgb(232, 238, 242);
  content: '';
  height: 35px;
  position: absolute;
  right: -22px;
  top: 8px;
  width: 1px;
}
.grid_list_search_result
  .right_area
  ul
  li:first-child
  .dropdown.bootstrap-select.show-tick,
.grid_list_search_result
  .right_area
  ul
  li:first-child
  button.btn.dropdown-toggle {
  border: none;
  box-shadow: none;
  padding-left: 0;
  padding-right: 0;
  width: 80px;
}
.grid_list_search_result
  .right_area.style2
  ul
  li:first-child
  button.btn.dropdown-toggle {
  border: none;
  box-shadow: none;
  padding-left: 0;
  padding-right: 0;
  width: 120px;
}
.grid_list_search_result
  .right_area
  ul
  li:last-child
  .dropdown.bootstrap-select.show-tick,
.grid_list_search_result
  .right_area
  ul
  li:last-child
  button.btn.dropdown-toggle {
  border: none;
  box-shadow: none;
  padding-left: 0;
  padding-right: 0;
  width: 105px;
}
.grid_list_search_result .right_area ul.dropdown-menu.inner li {
  margin-right: 0;
  overflow: hidden;
}
.grid_list_search_result .right_area ul li .filter-option-inner-inner {
  font-size: 14px;
  font-family: 'Nunito';
  color: #484848;
}
.grid_list_search_result .right_area ul li span.stts,
.grid_list_search_result .right_area ul li span.shrtby {
  font-size: 14px;
  font-family: 'Nunito';
  color: #484848;
  font-weight: bold;
}
.grid_list_search_result
  .right_area
  .bootstrap-select.show-tick
  .dropdown-menu
  li
  a
  span.text {
  color: #777777;
  font-weight: 600;
}
.grid_list_search_result .half_map_advsrch_navg ul li:first-child:before {
  background-color: rgb(232, 238, 242);
  content: '';
  height: 35px;
  position: absolute;
  right: -20px;
  top: 7px;
  width: 1px;
}
.grid_list_search_result .half_map_advsrch_navg ul li:first-child {
  margin-right: 35px;
  position: relative;
}
.grid_list_search_result .half_map_advsrch_navg ul li {
  margin-right: 15px;
  margin-top: 6px;
}
.half_map_advsrch_navg.style2 ul li {
  margin-right: 10px;
}
.half_map_advsrch_navg.style3 ul li:first-child {
  margin-right: 15px;
}
.half_map_advsrch_navg.style3 ul li:first-child:before {
  display: none;
}
.half_map_advsrch_navg.style3 ul li {
  margin-right: 10px;
}
.half_map_area_content {
  height: 850px;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 0 15px;
}
.half_map_area.version4 {
  bottom: 0;
  width: 100%;
  top: 0;
}
.our-listing-list-v4::-webkit-scrollbar,
.half_map_area_content::-webkit-scrollbar {
  background: transparent;
  border-radius: 3px;
  padding-right: 10px;
  width: 4px;
}
.our-listing-list-v4::-webkit-scrollbar-thumb {
  background-color: #f7f7f7;
  border-radius: 3px;
}
.half_map_area_content::-webkit-scrollbar-thumb {
  background-color: #f7f7f7;
  border-radius: 3px;
  height: 250px;
}
.half_map_area_content .search_option_two.home2 .dropdown-menu {
  top: 0 !important;
}
.grid_list_search_result
  .half_map_advsrch_navg
  .dropdown.bootstrap-select.show-tick.show
  .dropdown-menu.show
  .inner.show {
  overflow-y: initial !important;
}
.grid_list_search_result
  .half_map_advsrch_navg
  ul
  li
  ul.dropdown-menu.inner.show
  li {
  margin-right: 0;
}
.grid_list_search_result .half_map_advsrch_navg ul li:last-child {
  margin-right: 0;
}
.grid_list_search_result
  .half_map_advsrch_navg
  ul
  li
  .dropdown.bootstrap-select.show-tick {
  border: none;
  box-shadow: none;
  padding-left: 0;
  padding-right: 0;
  width: 120px;
}
.grid_list_search_result
  .half_map_advsrch_navg
  ul
  li
  button.btn.dropdown-toggle {
  border: none;
  box-shadow: none;
  padding-left: 0;
  padding-right: 0;
  width: 120px;
}
.half_map_area_content .grid_list_search_result {
  width: 97%;
}
.half_style .search_option_two .dropdown.bootstrap-select > .dropdown-toggle {
  background-color: #ffffff;
}
.h1ads_1st_list.half_style input.form-control {
  border: 1px solid rgb(235, 235, 235);
  border-radius: 8px;
  background-color: #ffffff;
  height: 52px;
  line-height: 50px;
  padding-left: 20px;
}
.h1ads_1st_list.half_style input.form-control::placeholder {
  font-size: 14px;
  font-family: 'Nunito';
  color: #484848;
  line-height: 1.2;
}
.h1ads_1st_list.half_style li:last-child {
  margin-right: 0 !important;
}
.h1ads_1st_list.half_style .navbered {
  background-color: #ffffff;
  border: 1px solid rgb(235, 235, 235);
  border-radius: 8px;
  height: 52px;
  line-height: 50px;
  text-align: center;
  width: 142px;
}
.h1ads_1st_list.half_style .navbered .dropbtn i {
  color: #bdc6ce;
  float: right;
  margin-left: -15px;
  margin-right: 15px;
}
.h1ads_1st_list.half_style .navbered .dropdown-content:before {
  right: 5%;
}
.h1ads_1st_list.half_style li.custome_fields_half:nth-child(2) {
  width: 69%;
}
.h1ads_1st_list.half_style li.custome_fields_half:nth-child(3) {
  width: 12%;
}
.h1ads_1st_list.half_style .apeartment_area_list li:nth-child(2) {
  width: auto;
}
.feature_property_half_clist li.extrawide {
  margin-left: 10px;
  margin-right: 0;
  width: 32%;
}
.feature_property_half_clist.style2 li.extrawide {
  width: 48%;
}
.listing_list_style {
  margin-top: 18px;
  position: relative;
  text-align: right;
}
.listing_list_style ul li {
  background-color: #ffffff;
  border: 1px solid rgb(235, 235, 235);
  border-radius: 8px;
  height: 44px;
  line-height: 44px;
  text-align: center;
  width: 49px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.listing_list_style ul li:hover {
  background-color: rgb(227, 227, 227);
  cursor: pointer;
}
.listing_list_style ul li a {
  color: #484848;
}
.sidebar_listing_list {
  border: 1px solid rgb(235, 235, 235);
  border-radius: 8px;
  background-color: #ffffff;
  margin-bottom: 30px;
  padding: 30px;
}
.sidebar_listing_list.style2 {
  border: none;
  max-height: 960px;
  overflow-y: scroll;
  padding: 30px 25px;
}
.sidebar_listing_list.style2::-webkit-scrollbar {
  background: transparent;
  border-radius: 3px;
  padding-right: 0;
  width: 4px;
}
.sidebar_listing_list.style2::-webkit-scrollbar-thumb {
  background-color: transparent;
}
.sl_creator {
  margin-bottom: 30px;
}
.sl_creator .media-body h5 {
  font-size: 16px;
  font-family: 'Nunito';
  color: rgb(72, 72, 72);
  font-weight: bold;
  line-height: 1.2;
}
.sl_creator .media-body p {
  font-size: 14px;
  font-family: 'Nunito';
  color: rgb(72, 72, 72);
  line-height: 1.5;
  margin-bottom: 0;
}
.sl_creator .media-body a {
  font-size: 14px;
  font-family: 'Nunito';
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 0;
}
.sasw_list .search_area {
  position: relative;
}
.sasw_list .search_area input {
  background-color: #ffffff;
  border: 1px solid rgb(235, 235, 235);
  border-radius: 8px;
  font-size: 14px;
  font-family: 'Nunito';
  color: #484848;
  line-height: 1.2;
  height: 52px;
  padding-left: 20px;
}
.sasw_list.style2 .search_area input.form-control::placeholder,
.sasw_list.style2 .search_option_two .filter-option-inner-inner,
.sasw_list.style2 .small_dropdown2 .dd_btn span,
.sasw_list.style2 .min_area.style2 input.form-control::placeholder,
.sasw_list.style2 .max_area input.form-control::placeholder,
.sasw_list.style2 .panel-heading h4.panel-title a.accordion-toggle.link {
  color: #006c70 !important;
}
.sasw_list .search_area textarea {
  border: 1px solid rgb(235, 235, 235);
  border-radius: 8px;
  box-shadow: none;
  font-size: 14px;
  line-height: 2.2;
}
.sasw_list .min_area .form-control::placeholder,
.sasw_list .max_area .form-control::placeholder,
.sasw_list .search_area input::placeholder,
.sasw_list .search_area textarea::placeholder {
  color: #484848;
}
.sasw_list .search_area input:focus,
.sasw_list button.btn.dropdown-toggle {
  outline: none;
  box-shadow: none;
}
.sasw_list .search_area label {
  font-size: 18px;
  position: absolute;
  right: 20px;
  top: 10px;
}
.sasw_list button.btn.dropdown-toggle {
  border: 1px solid rgb(235, 235, 235);
  border-radius: 8px;
  color: #484848;
  font-size: 14px;
  font-family: 'Nunito';
  height: 52px;
  line-height: 42px;
  padding-left: 20px;
}
.sasw_list .search_option_two {
  margin-bottom: 20px;
}
.sasw_list .small_dropdown2 {
  position: relative;
}
.sasw_list .small_dropdown2 .dd_content2 {
  right: 0;
  top: 65px;
  z-index: 9;
}
.sasw_list .small_dropdown2 .dd_btn {
  margin-bottom: 20px;
  padding-left: 20px;
  width: 100%;
}
.sasw_list .search_option_two .selectpicker {
  width: 100%;
}
.sasw_list .panel-heading h4.panel-title {
  font-size: 14px;
  font-family: 'Nunito';
  color: #484848;
  line-height: 1.2;
  margin-bottom: 25px;
}
.sasw_list .panel-heading h4.panel-title i {
  color: #bdc6ce;
}
.sasw_list #accordion .panel-heading a.accordion-toggle:before {
  display: none;
}
.sasw_list .ui_kit_checkbox .custom-control-label {
  padding-right: 0;
}
.sasw_list #accordion .panel {
  margin-bottom: 10px;
}
.sasw_list .min_area {
  margin-right: 20px;
}
.sasw_list .min_area,
.sasw_list .max_area {
  width: 135px;
}
.sasw_list .max_area {
  float: right;
  margin-right: 0;
}
.sasw_list .min_area .form-control,
.sasw_list .max_area .form-control {
  background-color: #ffffff;
  border: 1px solid rgb(235, 235, 235);
  border-radius: 8px;
  font-size: 14px;
  height: 52px;
  padding-left: 20px;
}
.sasw_list .search_option_button button.btn {
  height: 50px;
}
.sidebar_feature_property_slider .owl-prev,
.sidebar_feature_property_slider .owl-next {
  background-color: transparent !important;
  left: 0;
  position: absolute;
  top: 33%;
}
.sidebar_feature_property_slider .owl-next {
  left: auto;
  right: 0;
}
.sidebar_feature_property_slider .feat_property {
  margin-bottom: 0px;
}
.sidebar_feature_property_slider .feat_property .thumb {
  height: 220px;
}
.sidebar_feature_property_slider .feat_property .thumb .thmb_cntnt a.fp_price {
  bottom: 50px;
  left: 10px;
}
.sidebar_feature_property_slider .feat_property .thumb .thmb_cntnt h4 {
  bottom: 10px;
  left: 10px;
  position: absolute;
}
/*== My Resume Page ==*/
.portfolio_upload {
  background-color: rgb(247, 247, 247);
  border: 1px solid rgb(235, 235, 235);
  border-radius: 8px;
  height: 223px;
  margin-bottom: 30px;
  text-align: center;
}
.portfolio_upload .btn {
  color: #ffffff;
  cursor: pointer;
  padding: 8px 20px;
  font-size: 46px;
  font-weight: normal;
}
.portfolio_upload .icon {
  font-size: 48px;
  margin-top: 50px;
}
.portfolio_upload .icon {
  color: #ff5a5f;
  font-size: 48px;
  margin-top: 50px;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.portfolio_upload p {
  font-size: 22px;
  font-family: 'Nunito';
  color: rgb(72, 72, 72);
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 0;
  position: relative;
}
.portfolio_upload input[type='file'] {
  font-size: 100px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
/*== Message Page ==*/
.message_container {
  background-color: #ffffff;
  border: 1px solid rgb(235, 235, 235);
  border-radius: 8px;
  height: 100%;
  position: relative;
}
.inbox_user_list {
  display: inline-block;
  padding: 30px 30px 40px;
  width: 100%;
}
.inbox_user_list ul {
  height: 625px;
  overflow-y: scroll;
}
.inbox_user_list ul::-webkit-scrollbar {
  background: transparent;
  border-radius: 3px;
  padding-right: 10px;
  width: 4px;
}
.inbox_user_list ul::-webkit-scrollbar-thumb {
  background-color: #f7f7f7;
  border-radius: 3px;
  height: 250px;
}
.inbox_user_list .wrap {
  position: relative;
}
.inbox_user_list .wrap img {
  border-radius: 50%;
  float: left;
  margin-right: 15px;
}
.inbox_user_list .wrap .meta h5.name {
  font-size: 16px;
  font-family: 'Nunito';
  color: #484848;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 0;
}
.inbox_user_list .wrap .meta p.preview {
  font-size: 14px;
  font-family: 'Nunito';
  color: rgb(119, 119, 119);
  line-height: 2;
  margin-bottom: 0;
}
.inbox_user_list .wrap .m_notif {
  border: 2px solid #ffffff;
  border-radius: 50%;
  background-color: rgb(252, 68, 90);
  color: #ffffff;
  font-size: 10px;
  font-family: 'Lato';
  line-height: 17px;
  height: 21px;
  position: absolute;
  right: 5%;
  text-align: center;
  top: 10px;
  width: 21px;
}
.inbox_user_list ul li {
  padding: 10px 0;
}
.inbox_user_list .iu_heading {
  padding-bottom: 25px;
}
.inbox_user_list ul li.contact .wrap .meta {
  display: inline-block;
  margin-top: 10px;
}
.iu_heading .candidate_revew_search_box input.form-control {
  width: 75%;
}
.iu_heading .candidate_revew_search_box .candidate_revew_search_box button {
  background-color: transparent;
}
.inbox_user_list ul li.contact .wrap span {
  background-color: #95a5a6;
  border: 1px solid #ffffff;
  border-radius: 50%;
  height: 13px;
  left: 50px;
  margin: 0px 0 0 1px;
  position: absolute;
  width: 13px;
}
.inbox_user_list ul li.contact .wrap span.online {
  background-color: #2ecc71;
}
.inbox_user_list ul li.contact .wrap span.away {
  background-color: #f1c40f;
}
.inbox_user_list ul li.contact .wrap span.busy {
  background-color: #e74c3c;
}
.inbox_user_list ul li.contact .wrap img {
  border-radius: 50%;
  float: left;
  margin-right: 10px;
  width: 50px;
}
.message_container .inbox_chatting_box {
  position: relative;
  max-height: 575px !important;
  height: auto;
  margin-right: 10px;
  max-height: calc(100% - 93px);
  overflow-y: scroll;
  overflow-x: hidden;
}
.message_container .inbox_chatting_box::-webkit-scrollbar {
  background: transparent;
  border-radius: 3px;
  padding-right: 10px;
  width: 4px;
}
.message_container .inbox_chatting_box::-webkit-scrollbar-thumb {
  background-color: #ededed;
  border-radius: 3px;
}
.message_container .user_heading {
  background-color: #ffffff;
  border-bottom: 1px solid rgb(235, 235, 235);
  border-radius: 8px 8px 0 0;
  padding: 30px;
  position: relative;
}
.inbox_chatting_box .chatting_content {
  display: inline-block;
  padding: 15px 30px 0 30px;
  position: relative;
  width: 100%;
}
.message_container .user_heading .wrap img {
  float: left;
  margin-right: 10px;
  border-radius: 50%;
}
.message_container .user_heading .wrap h5.name {
  font-size: 16px;
  font-family: 'Nunito';
  color: rgb(10, 10, 10);
  line-height: 1.2;
  margin-bottom: 0;
}
.message_container .user_heading .wrap p.preview {
  font-size: 15px;
  font-family: 'Open Sans';
  color: rgb(127, 127, 127);
  line-height: 1.867;
  margin-bottom: 0;
}
.inbox_chatting_box .chatting_content li {
  padding: 20px 0;
  margin-bottom: 0;
}
.inbox_chatting_box .chatting_content li.media.sent {
  float: left;
  clear: both;
}
.inbox_chatting_box .chatting_content li.media img {
  border-radius: 50px;
  margin-bottom: 10px;
}
.inbox_chatting_box .chatting_content li.media .media-body {
  display: block;
  flex: auto;
}
.inbox_chatting_box .chatting_content li.media span {
  background-color: #95a5a6;
  border: 1px solid #ffffff;
  border-radius: 50%;
  height: 13px;
  left: 80px;
  margin: 2px 0 0 -2px;
  position: absolute;
  width: 13px;
}
.inbox_chatting_box .chatting_content li.media span.busy {
  background-color: #e74c3c;
}
.inbox_chatting_box .chatting_content li.media .media-body .date_time {
  font-size: 14px;
}
.inbox_chatting_box .chatting_content li.media .media-body p {
  background-color: #f7f7f7;
  border: 1px solid #f7f7f7;
  border-radius: 3px;
  color: #484848;
  font-size: 14px;
  font-family: 'Open Sans';
  margin-bottom: 0;
  padding: 10px 15px;
  max-width: 290px;
}
.inbox_chatting_box .chatting_content li.media.reply {
  float: right;
  clear: both;
}
.inbox_chatting_box .chatting_content li.media.reply.first {
  margin: -15px 0;
}
.inbox_chatting_box .chatting_content li.media.reply .media-body p {
  border-radius: 3px;
  background-color: #eef1ff;
  color: #484848;
  max-width: 320px;
  padding: 10px 15px;
}
.message_container .message_input {
  background-color: #f9fafc;
  bottom: 30px;
  left: 30px;
  position: absolute;
  right: 30px;
  text-align: center;
}
.message_container .message_input form input.form-control {
  background-color: #f7f7f7;
  border-color: transparent;
  border-radius: 8px;
  height: 70px;
  width: 100%;
  padding-left: 30px;
}
.message_container .message_input form input.form-control::placeholder {
  font-size: 14px;
  font-family: 'Nunito';
  color: #484848;
  line-height: 1.714;
}
.message_container .message_input form input.form-control:focus {
  box-shadow: none;
  outline: none;
}
.message_container .message_input form button.btn {
  background-color: #ff5a5f;
  border: 1px solid #ffffff;
  border-radius: 8px;
  color: #ffffff;
  font-size: 16px;
  flex: 1;
  height: 50px;
  position: absolute;
  right: 20px;
  width: 190px;
}
.message_container .message_input form button.btn:focus {
  box-shadow: none;
  outline: none;
}
.message_container .message_input form button.btn span {
  font-size: 20px;
  padding-left: 10px;
}

/*== My Profile Page ==*/
.my_profile_select_box label {
  color: #221f1f;
}
.my_profile_select_box {
  margin-bottom: 20px;
}
.my_profile_select_box .dropdown.bootstrap-select {
  display: inline;
}
.my_profile_select_box .dropdown.bootstrap-select button.btn {
  background-color: transparent;
  border: 1px solid #dddddd;
  border-radius: 4px;
  color: #7f7f7f;
  height: 50px;
  line-height: 35px;
}
.my_profile_select_box .dropdown.bootstrap-select button.btn:focus {
  box-shadow: none;
  outline: none !important;
}
.my_profile_select_box .dropdown.bootstrap-select:focus {
  border: 1px solid #dddddd;
}
/*== DashBoard Pages ==*/
.ff_one {
  background-color: #ffffff;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(235, 235, 235);
  border-radius: 8px;
  height: 150px;
  margin-bottom: 30px;
  position: relative;
  padding: 20px 20px 20px 25px;
}
.ff_one .icon {
  border-radius: 50%;
  background-color: rgba(29, 41, 62, 0.102);
  float: right;
  height: 100px;
  line-height: 100px;
  text-align: center;
  width: 100px;
}
.ff_one .icon span {
  color: #1d293e;
  font-size: 36px;
}
.ff_one.style2 .icon span {
  color: #9499da;
}
.ff_one.style3 .icon span {
  color: #ff5a5f;
}
.ff_one.style4 .icon span {
  color: #ffbc7d;
}
.ff_one .detais {
  padding-top: 25px;
}
.ff_one .detais .timer {
  font-size: 36px;
  font-family: 'Nunito';
  color: #484848;
  line-height: 1.2;
}
.ff_one .detais p {
  font-size: 16px;
  font-family: 'Nunito';
  color: rgb(119, 119, 119);
  margin-bottom: 0;
}
.ff_one.style2 .icon {
  background-color: rgba(148, 153, 218, 0.149);
}
.ff_one.style3 .icon {
  background-color: rgba(255, 90, 95, 0.149);
}
.ff_one.style4 .icon {
  background-color: rgba(255, 188, 125, 0.149);
}
.application_statics {
  border-radius: 5px;
  background-color: #ffffff;
  -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  padding: 30px;
  position: relative;
}
.application_statics h4 {
  font-family: 'Nunito';
  color: #484848;
  font-weight: bold;
  line-height: 1.2;
}
.recent_job_activity {
  border-radius: 5px;
  background-color: #ffffff;
  -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  padding: 30px;
}
.recent_job_activity h4 {
  color: #484848;
  font-size: 18px;
  font-family: 'Nunito';
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 20px;
}
.recent_job_activity .grid {
  margin-bottom: 25px;
}
.recent_job_activity .grid ul.bb_none {
  border-bottom: none;
}
.recent_job_activity .grid ul li:first-child {
  border-radius: 50%;
  background-color: rgb(255, 232, 233);
  height: 45px;
  line-height: 45px;
  margin-right: 15px;
  text-align: center;
  width: 45px;
}
.recent_job_activity .grid ul li:first-child .icon {
  color: #ff5a5f;
  font-size: 20px;
}
.recent_job_activity .grid ul li p {
  font-size: 14px;
  font-family: 'Nunito';
  color: #484848;
  line-height: 1.714;
  margin-bottom: 0;
}
.dashboard_sidebar_menu {
  position: relative;
  z-index: 9999;
}
.sidebar-menu {
  background-color: #1d293e;
  bottom: 0;
  height: auto;
  left: 0;
  margin: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  position: fixed;
  top: 55px !important;
  width: 270px !important;
  z-index: 9;
}
.sidebar-menu::-webkit-scrollbar {
  border-radius: 6px;
  margin-top: 15px;
  margin-right: 10px;
  width: 6px;
  z-index: 3;
}
.sidebar-menu::-webkit-scrollbar * {
  background: transparent;
  border-radius: 6px;
}
.sidebar-menu::-webkit-scrollbar-track {
  background-color: #1d293e;
  border-radius: 6px;
}
.sidebar-menu::-webkit-scrollbar-thumb {
  background-color: #1d293e !important;
  border-radius: 6px;
}
.sidebar-menu li {
  position: relative;
  margin: 0;
  padding: 0;
}
.sidebar-menu li a {
  border-left: 2px solid transparent;
  color: rgb(138, 153, 179);
  display: block;
  font-size: 14px;
  font-family: 'Nunito';
  line-height: 3;
  padding: 0 15px;
}
.sidebar-menu li a .fa {
  width: 20px;
}
.sidebar-menu li a span {
  padding-left: 10px;
}
.sidebar-menu li:hover a,
.sidebar-menu li.active a {
  color: #ffffff;
  background-color: #172133;
  border-left-color: #ff5a5f;
}
.sidebar-menu li .treeview-menu {
  margin: 0;
  background-color: #172133;
}
.sidebar-menu li .label,
.sidebar-menu li .badge {
  margin-top: 3px;
  margin-right: 5px;
}
.sidebar-menu li.header {
  background-color: #24324a;
  color: #ffffff;
  font-family: 'Nunito';
  font-size: 22px !important;
  font-weight: bold;
  line-height: 1.2;
  padding: 10px 0 0px 30px !important;
  text-align: left;
  height: 65px;
}
.sidebar-menu li a .fa-angle-down {
  width: auto;
  height: auto;
  padding: 0;
  margin-right: 10px;
  margin-top: 18px;
}
.sidebar-menu li.active a .fa-angle-down {
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
.sidebar-menu li.active .treeview-menu {
  display: block;
}
.sidebar-menu a {
  color: #b8c7ce;
  text-decoration: none;
}
.sidebar-menu .treeview-menu {
  display: none;
  list-style: none;
  padding: 0;
  margin: 0;
}
.sidebar-menu .treeview-menu .treeview-menu {
  padding-left: 20px;
}
.sidebar-menu .treeview-menu li {
  margin: 0;
}
.sidebar-menu .treeview-menu li a {
  color: rgb(138, 153, 179);
  display: block;
  font-size: 14px;
  font-family: 'Nunito';
  line-height: 2.5;
  padding-left: 30px;
}
.sidebar-menu .treeview-menu li a .fa {
  width: 20px;
}
.sidebar-menu .treeview-menu li a i {
  font-size: 8px;
}
.sidebar-menu .treeview-menu li a .fa-angle-down,
.sidebar-menu .treeview-menu li a .fa-angle-up {
  width: auto;
}
.sidebar-menu .treeview-menu li.active a,
.sidebar-menu .treeview-menu li a:hover {
  color: #fff;
}
.sidebar-menu .title {
  font-size: 16px;
  font-family: 'Nunito';
  color: rgb(96, 112, 140);
  line-height: 1.5;
  margin-top: 30px;
  padding-left: 15px;
}
/*== sidebar about widget ==*/
.siderbar_contact_widget h4 {
  font-size: 20px;
  font-family: 'Nunito SemiBold';
  color: #484848;
  margin-bottom: 15px;
  line-height: 1.2;
}
.siderbar_contact_widget p {
  font-size: 15px;
  font-family: 'Open Sans';
  color: rgb(126, 126, 126);
  margin-bottom: 0;
  line-height: 2.667;
}
.siderbar_contact_widget .scw_social_icon li a {
  font-size: 16px;
  font-family: 'FontAwesome';
  color: rgb(126, 126, 126);
  line-height: 1.2;
}
/*== Pricing SLider ==*/
.cl_pricing_slider {
  position: relative;
  padding: 0 10px;
}
.cl_pricing_slider p {
  margin-bottom: 0;
}
.cl_pricing_slider div#slider-range {
  background-color: #dfdfdf;
  border-radius: 3px;
  height: 6px;
  margin-bottom: 25px;
  margin-top: 25px;
}
.cl_pricing_slider .ui-slider-range.ui-corner-all.ui-widget-header {
  background-color: #ff5a5f;
}
.cl_pricing_slider span.ui-slider-handle.ui-corner-all.ui-state-default {
  background-color: #fff;
  border: 2px solid #ff5a5f;
  border-radius: 50%;
  height: 18px;
  outline: none;
  top: -7px;
  width: 18px;
}
.cl_pricing_slider .sl_input {
  background-color: transparent;
  border: none;
  color: #221f1f;
  text-align: center;
}
.cl_pricing_slider .sl_input:focus {
  box-shadow: none;
  outline: none;
}
.cl_skill_checkbox .content.ui_kit_checkbox {
  border-radius: 6px;
  height: 250px;
  overflow: hidden scroll;
  text-align: left;
}
.cl_skill_checkbox .content.ui_kit_checkbox::-webkit-scrollbar {
  border-radius: 6px;
  margin-top: 15px;
  margin-right: 10px;
  width: 6px;
  z-index: 3;
}
.cl_skill_checkbox .content.ui_kit_checkbox::-webkit-scrollbar * {
  background: transparent;
  border-radius: 6px;
}
.cl_skill_checkbox .content.ui_kit_checkbox::-webkit-scrollbar-track {
  background-color: #dfdfdf;
  border-radius: 6px;
}
.cl_skill_checkbox .content.ui_kit_checkbox::-webkit-scrollbar-thumb {
  background-color: #a2a2a2 !important;
  border-radius: 6px;
}
/*== Profile Avatar ==*/
.wrap-custom-file {
  position: relative;
  display: inline-block;
  width: 260px;
  height: 260px;
  text-align: center;
}
.wrap-custom-file input[type='file'] {
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 2px;
  overflow: hidden;
  opacity: 0;
}
.wrap-custom-file label {
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  overflow: hidden;
  cursor: pointer;
  /* background-image: url(../images/team/upload_photo.jpg); */
  border-radius: 5px;
  -webkit-transition: -webkit-transform 0.4s;
  transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
  transition: transform 0.4s, -webkit-transform 0.4s;
}
.wrap-custom-file label span {
  border-radius: 8px;
  background-color: #ffffff;
  -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  -o-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  bottom: 20px;
  color: #484848;
  font-size: 14px;
  font-family: 'Nunito';
  line-height: 2.2;
  height: 50px;
  left: 20px;
  position: absolute;
  width: 160px;
}
.wrap-custom-file label i {
  color: #ff5a5f;
  display: inline-block;
  font-size: 20px;
  margin-right: 5px;
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
.wrap-custom-file label.file-ok {
  background-size: cover;
  background-position: center;
}
.wrap-custom-file label.file-ok span {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0.3rem;
  font-size: 1.1rem;
  color: #ffffff;
  background-color: rgba(36, 65, 231, 0.9);
}
.wrap-custom-file label.file-ok .fa {
  display: none;
}
.avatar-upload {
  position: relative;
}
.avatar-upload .avatar-edit {
  position: absolute;
  right: 0;
  top: 0;
  width: 27%;
}
.avatar-upload .avatar-edit input {
  display: none;
}
.avatar-upload .avatar-edit input + label {
  height: 50px;
  border-radius: 8px;
  background-color: rgb(29, 41, 62);
  cursor: pointer;
  font-size: 16px;
  font-family: 'Nunito';
  color: rgb(255, 255, 255);
  font-weight: bold;
  line-height: 30px;
  position: absolute;
  top: 0;
  width: 100%;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.avatar-upload .avatar-edit input + label:hover {
  background-color: #ffffff;
  border: 1px solid #ff5a5f;
}
.avatar-upload .avatar-edit input + label:hover:after {
  color: #ff5a5f;
}
.avatar-upload .avatar-edit input + label::after {
  content: 'Upload';
  color: #ffffff;
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
}
.avatar-upload .avatar-preview {
  height: 50px;
  left: 0;
  position: absolute;
  width: 70%;
}
.avatar-upload #imagePreview {
  background-color: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  height: 100%;
}
.portfolio_item {
  border-radius: 8px;
  background-color: rgb(247, 247, 247);
  height: 200px;
  margin-bottom: 30px;
  overflow: hidden;
  position: relative;
  width: 200px;
}
.portfolio_item img {
  height: 200px;
  width: 200px;
}
.portfolio_item .edu_stats_list {
  border-radius: 8px;
  background-color: rgb(255, 90, 95);
  cursor: pointer;
  height: 35px;
  line-height: 35px;
  position: absolute;
  right: 10px;
  text-align: center;
  top: 10px;
  width: 35px;
}
.portfolio_item .edu_stats_list span {
  color: rgb(255, 255, 255);
}
.resume_uploader .upload input[type='file'] {
  display: none;
}
.resume_uploader .upload-path {
  border: 1px solid #ebebeb;
  border-radius: 8px;
  flex: 1;
  height: 50px;
  margin-right: 20px;
  outline: none;
  padding-left: 20px;
  width: 70%;
}
.resume_uploader .upload {
  border-radius: 8px;
  border: 1px solid rgb(29, 41, 62);
  background-color: rgb(29, 41, 62);
  color: #ffffff;
  cursor: pointer;
  display: block;
  font-size: 16px;
  font-family: 'Nunito';
  height: 50px;
  line-height: 50px;
  margin-top: 0;
  outline: 0;
  position: relative;
  text-align: center;
  width: 200px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.resume_uploader .upload:hover {
  background-color: #ffffff;
  border: 1px solid #ff5a5f;
  color: #ff5a5f;
}
.resume_uploader .upload:active {
  background-color: #ffffff;
}
/*== UI Page Elements ==*/
.company_life input.amount {
  text-align: right;
}
.company_life input.amount2 {
  text-align: left;
}
.company_life input.amount,
.company_life input.amount2 {
  color: #221f1f;
  background-color: transparent;
  box-shadow: none;
  border: none;
  display: inline-block;
  margin-top: 30px;
  width: 40%;
}
.company_life
  .cl_pricing_slider
  span.ui-slider-handle.ui-corner-all.ui-state-default {
  top: -4px;
}
.company_life .ui-slider-horizontal {
  background-color: #dfdfdf !important;
  height: 6px;
}
.company_life .slider-range {
  width: 100%;
}
/*== Alpha v2 Page ==*/
.alpha-pag {
  background: #ffffff;
  margin-bottom: 35px;
  text-align: center;
}
.alpha-pag ul {
  margin-bottom: 0;
}
.alpha-pag ul.option-set li {
  margin-right: 40px;
}
.alpha-pag ul.option-set li:last-child {
  margin-right: 0;
}
.alpha-pag ul.option-set li a {
  padding: 0 0 5px;
}
.alpha-pag ul.option-set li a.selected {
  border-bottom: 2px solid #ff5a5f;
  color: #ff5a5f;
}
.alpha-pag li a {
  color: #7d7d7d;
  padding: 0 8px;
}
.alpha-pag li a:hover {
  color: #221f1f;
}
.emply-text-sec {
  float: left;
  width: 100%;
}
.emply-text {
  float: left;
  margin-bottom: 40px;
}
.emply-text > h4 {
  margin-bottom: 30px;
}
/*== Carrer Tips Home V3 ==*/
.blog_post_home2 {
  border-radius: 5px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  height: 450px;
  margin: 0 13px 45px;
  overflow: hidden;
  position: relative;
}
.blog_post_home2.one {
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  padding: 30px 25px;
}
.blog_post_home2.one .bph2_header:before {
  display: none;
}
.blog_post_home2 .bph2_header:before {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.blog_post_home2:hover {
  cursor: pointer;
}
.blog_post_home2:hover .bph2_header:before {
  background-color: rgba(36, 65, 231, 0.8);
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.blog_post_home2 .bph2_header img {
  height: 100%;
}
.blog_post_home2 .bph2_header .bph2_date_meta {
  border-radius: 5px;
  overflow: hidden;
  position: absolute;
  right: 25px;
  top: 30px;
  text-align: center;
}
.blog_post_home2 .bph2_header .bph2_date_meta:hover,
.blog_post_home2 .bph2_header .bph2_date_meta:hover span {
  background-color: #484848;
  color: #ffffff;
}
.blog_post_home2.one .details {
  bottom: 0;
  margin-top: 210px;
  padding: 0;
  position: relative;
}
.blog_post_home2.one .details h4 {
  color: #484848;
}
.blog_post_home2.one .details p {
  margin-bottom: 0;
}
.blog_post_home2.one .details .post_meta ul li a {
  color: #898989;
}
.blog_post_home2 .details {
  position: absolute;
  bottom: 50px;
  padding: 0 30px;
}
.blog_post_home2 .details h4 {
  color: #fafafa;
  font-family: 'Nunito';
  font-size: 20px;
  font-weight: normal;
  line-height: 1.5;
}
.blog_post_home2 .details .post_meta ul li a {
  color: #fafafa;
  font-size: 14px;
}
.blog_post_home2 .details .post_meta ul li a i {
  font-size: 18px;
  padding-right: 5px;
}
.read_more_home5 h4 {
  font-weight: bold;
}
/*== Testimonials Home V3 ==*/
.testimonial_content {
  background-color: #ffffff;
  border-radius: 4px;
  height: 300px;
  margin: 70px 100px;
  position: relative;
  padding: 60px 100px;
  text-align: center;
  -webkit-box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.05);
  -o-box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.05);
}
.testimonial_content .thumb {
  display: inline-block;
  margin-bottom: 40px;
  margin-top: -120px;
  position: relative;
}
.testimonial_content .thumb h4 {
  margin-bottom: 0;
}
.testimonial_content .thumb p {
  color: #ff5a5f;
  margin-bottom: 0;
}
.testimonial_content .thumb img {
  border-radius: 50%;
  margin-bottom: 20px;
}
.testimonial_content .details p {
  font-size: 15px;
  line-height: 28px;
  margin: 0 auto;
  max-width: 510px;
}
.t_icon.home3 {
  color: #1c327e;
  left: 130px;
  top: 0px;
}
/*== Contact Page ==*/
.form_grid {
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid #ebebeb;
  padding: 30px;
}
.form_grid h4 {
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 20px;
}
.form_grid p {
  font-size: 14px;
  line-height: 1.714;
  margin-bottom: 30px;
}
.form_grid .contact_form input {
  border: 1px solid #ebebeb;
  border-radius: 8px;
  background-color: #ffffff;
  color: #484848;
  font-size: 14px;
  height: 50px;
  line-height: 1.2;
  margin-bottom: 35px;
  padding-left: 20px;
}
.form_grid .contact_form textarea.form-control {
  background-color: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  font-size: 14px;
  font-family: 'Nunito';
  color: #484848;
  line-height: 1.2;
  margin-bottom: 25px;
  padding: 20px;
}
.form_grid .contact_form button {
  border-radius: 8px;
  background-color: #ffc107;
  -webkit-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  -moz-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  -o-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  font-size: 16px;
  font-family: 'Nunito';
  color: #000000;
  font-weight: bold;
  line-height: 1.2;
  height: 50px;
  width: 200px;
  margin-bottom: 15px;
}
.contact_localtion {
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  padding: 30px;
  position: relative;
}
.contact_localtion h4 {
  font-size: 18px;
  font-family: 'Nunito';
  color: #484848;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 20px;
}
.contact_localtion p {
  font-size: 14px;
  font-family: 'Nunito';
  color: #484848;
  line-height: 1.714;
  margin-bottom: 20px;
}
.contact_localtion .content_list h5 {
  font-size: 16px;
  font-family: 'Nunito';
  color: #484848;
  font-weight: bold;
  line-height: 1.5;
  margin-bottom: 0;
}
.contact_localtion .content_list p {
  margin-bottom: 30px;
}
.contact_form_social_area {
  margin-bottom: 0;
  position: relative;
}
.contact_form_social_area li {
  background-color: #f7f7f7;
  border-radius: 8px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  width: 45px;
}
.contact_form_social_area li:hover {
  cursor: pointer;
}
.contact_form_social_area li a {
  font-size: 14px;
  color: #484848;
  line-height: 1.2;
}

/*== Maximage_slider ==*/
.maximage-home .mc-image {
  height: 920px !important;
  position: relative !important;
}
.maximage-home .mc-image img {
  margin: 0 !important;
  width: 100% !important;
}
.maxtext {
  bottom: 45%;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  text-align: center;
  z-index: 9;
}
.maxtext h1 {
  color: #ffffff;
  font-size: 50px;
  font-family: 'Nunito';
  line-height: 1.2;
  text-transform: uppercase;
}
.maxtext p {
  color: #ffffff;
  font-family: 'Nunito';
  font-size: 18px;
  line-height: 1.2;
}
/*== Color Area ==*/
.img-whp {
  width: 100% !important;
}
.text-thm {
  color: #ff5a5f !important;
}
.hvr-text-thm:hover {
  color: #ff5a5f !important;
}
.text-thm2 {
  color: #ff787c !important;
}
.text-thm3 {
  color: #006c70 !important;
}
.text-thm4 {
  color: #1fbda6;
}
.text-thm5 {
  color: #192675;
}
.text-thm6 {
  color: #ff1053;
}
.color-white {
  color: #ffffff !important;
}
.color-lightgray {
  color: #d3d3d3;
}
.color-gray {
  color: gray;
}
.color-gray2 {
  color: #7e7e7e;
}
.color-green2 {
  color: #28b928;
}
.color-blue {
  color: #201b71;
}
.color-orose {
  color: #c75533;
}
.color-dark {
  color: #484848;
}
.color-black {
  color: #000;
}
.color-black2 {
  color: #484848;
}
.color-black11 {
  color: #111111 !important;
}
.color-black22 {
  color: #222222;
}
.color-black33 {
  color: #333333;
}
.color-black31 {
  color: #303030;
}
.color-black44 {
  color: #434343;
}
.color-black55 {
  color: #555555;
}
.color-black66 {
  color: #676767;
}
.color-primary {
  color: #337ab7;
}
.color-success {
  color: #5cb85c;
}
.color-info {
  color: #5bc0de;
}
.color-silver {
  color: #c0c0c0;
}
.color-warning {
  color: #f0ad4e;
}
.color-danger {
  color: #d9534f;
}
.color-green {
  color: #28b928;
}
.color-fb {
  color: #3b5998;
}
.color-golden {
  color: #febe42;
}
.color-red {
  color: #ff4b53;
}
.bgc-thm {
  background-color: #ff5a5f !important;
}
.bgc-thm2 {
  background-color: #ff787c !important;
}
.bgc-fb {
  background-color: #3b5998;
}
.color-twtr {
  color: #1da1f2;
}
.bgc-twtr {
  background-color: #1da1f2;
}
.color-inst {
  color: #d31a77;
}
.bgc-inst {
  background-color: #d31a77;
}
.color-lnkdn {
  color: #007bb6;
}
.bgc-lnkdn {
  background-color: #007bb6;
}
.color-pint {
  color: #cb2027;
}
.bgc-pint {
  background-color: #cb2027;
}
.color-skyp {
  color: #00aff0;
}
.bgc-skyp {
  background-color: #00aff0;
}
.color-skyp {
  color: #00aff0;
}
.bgc-skyp {
  background-color: #00aff0;
}
.color-drbl {
  color: #ea4c89;
}
.bgc-drbl {
  background-color: #ea4c89;
}
.color-behnc {
  color: #0b7bff;
}
.bgc-behnc {
  background-color: #0b7bff;
}
.color-utube {
  color: #ff0000;
}
.bgc-utube {
  background-color: #ff0000;
}
.color-gogle {
  color: #ea4335;
}
.bgc-gogle {
  background-color: #ea4335;
}
.bgc-darkblue {
  background-color: #112360;
}
.bgc-seagreen {
  background-color: #43d4b6;
}
.bgc-black11 {
  background-color: #111111;
}
.bgc-split {
  background-color: #181413 !important;
}
.bgc-primary {
  background-color: #337ab7;
}
.bgc-success {
  background-color: #5cb85c;
}
.bgc-info {
  background-color: #5bc0de;
}
.bgc-warning {
  background-color: #f0ad4e;
}
.bgc-danger {
  background-color: #d9534f;
}
.bgc-white {
  background-color: #ffffff !important;
}
.bgc-lightgray {
  background-color: #d3d3d3;
}
.bgc-snowshade {
  background-color: #f9f9f9;
}
.bgc-snowshade2 {
  background-color: #fafafa;
}
.bgc-golden {
  background-color: #febe42;
}
.color-whitef1 {
  color: #f1f1f1;
}
.color-whitef2 {
  color: #f2f2f2;
}
.color-whitef3 {
  color: #f3f3f3;
}
.color-whitef4 {
  color: #f4f4f4;
}
.color-whitef5 {
  color: #f5f5f5;
}
.color-whitef6 {
  color: #f6f6f6;
}
.color-whitef7 {
  color: #f7f7f7;
}
.color-whitef8 {
  color: #f8f8f8;
}
.color-whitef9 {
  color: #f9f9f9;
}
.color-whitef0 {
  color: #f0f0f0;
}
.bgc-f0 {
  background-color: #f0f0f0;
}
.bgc-f1 {
  background-color: #f1f1f1;
}
.bgc-f2 {
  background-color: #f2f2f2;
}
.bgc-f3 {
  background-color: #f3f3f3;
}
.bgc-f4 {
  background-color: #f4f4f4;
}
.bgc-f5 {
  background-color: #f5f5f5;
}
.bgc-f6 {
  background-color: #f6f6f6;
}
.bgc-f7 {
  background-color: #f7f7f7;
}
.bgc-f8 {
  background-color: #f8f8f8;
}
.bgc-f9 {
  background-color: #f9f9f9;
}
.bgc-fa {
  background-color: #fafafa !important;
}
.bgc-transparent {
  background-color: transparent !important;
}
.bgc-darkcyan {
  background-color: darkcyan;
}
.bgc-darkblue {
  background-color: #004274;
}
.bgc-green {
  background-color: #28b928;
}
.bdrrn {
  border: none;
}
.bdrs0 {
  border-radius: 0 !important;
}
.bdr1 {
  border: 1px solid #ebebeb;
}
.bdrs5 {
  border-radius: 5px;
}
.bdrs8 {
  border-radius: 8px;
}
.bdrs10 {
  border-radius: 10px;
}
.bdrs20 {
  border-radius: 20px;
}
.bdrs50 {
  border-radius: 50%;
}
.bxshd {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.hvr-bxshd:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.tdu {
  text-decoration: underline;
}
.tdu-hvr:hover {
  cursor: pointer;
  text-decoration: underline;
}
.all-body-content {
  background-color: #ffffff;
  margin-bottom: 760px;
  position: relative;
  z-index: 9;
}
.h125 {
  height: 125px;
}
.bb1 {
  border-bottom: 1px solid #ebebeb;
}
/*== Button Area ==*/
.btn {
  border-radius: 4px;
}
.btn.dbxshad {
  -webkit-box-shadow: 0px 1px 4px 0px rgba(36, 65, 231, 0.3);
  -moz-box-shadow: 0px 1px 4px 0px rgba(36, 65, 231, 0.3);
  -o-box-shadow: 0px 1px 4px 0px rgba(36, 65, 231, 0.3);
  box-shadow: 0px 1px 4px 0px rgba(36, 65, 231, 0.3);
}
.btn.circle {
  border-radius: 27px;
}
.btn.white {
  background-color: #ffffff;
  color: #ff5a5f;
}
.btn:hover,
.btn:active,
.btn:focus {
  box-shadow: none;
  outline: none;
}
.btn-thm {
  background-color: #ffc107;
  border: 2px solid #ffc107;
  border-radius: 5px;
  color: #ffffff;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.btn-thm.rounded {
  background-color: #ff5a5f;
  border-radius: 5px;
  color: #ffffff;
}

.btn-thm:hover,
.btn-thm:active,
.btn-thm:focus {
  background-color: #ffffff;
  border-color: #ff5a5f;
  color: #ff5a5f;
}

.btn-thm.white:hover,
.btn-thm.white:active,
.btn-thm.white:focus {
  background-color: #ff5a5f;
  border-color: #ff5a5f;
  color: #ffffff;
}
.btn-thm2 {
  background-color: #ff787c;
  border-color: #ff787c;
  color: #ffffff;
  border-radius: 0px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.btn-thm2:hover,
.btn-thm2:active,
.btn-thm2:focus {
  background-color: #ffffff;
  border-color: #ff787c;
  color: #ff787c;
}

.btn-thm3 {
  /* background-color: #ff1053; */
  border: 2px solid #ffc107;
  border: none;
  /* color: #ffffff; */
  border-radius: 0px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  background-color: #ffc107;
  -o-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  color: #000;
  border-radius: 10px;
  margin-left: 10px;
}

.btn-thm3:hover,
.btn-thm3:active,
.btn-thm3:focus {
  /* background-color: #ff1053; */
  border: 2px solid #ffc107;
  border: none;
  /* color: #ffffff; */
  border-radius: 0px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  background-color: #ffc107;
  -o-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  color: #000;
  border-radius: 10px;
  margin-left: 10px;
}

.btn-dark {
  background-color: #051925;
  border-color: #051925;
  color: #ffffff;
  border-radius: 0px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.btn-dark:hover,
.btn-dark:active,
.btn-dark:focus {
  background-color: #ffffff;
  border-color: #051925;
  color: #051925;
}
.btn-yellow {
  background-color: #f0c900;
  border-color: #f0c900;
  color: #333333;
  border-radius: 0px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.btn-yellow:hover,
.btn-yellow:active,
.btn-yellow:focus {
  color: #333333;
}

.btn-yellow:hover,
.btn-yellow:active,
.btn-yellow:focus {
  color: #ffffff;
}

.btn-white {
  background-color: #ffffff;
  border-color: #eeeeee;
  color: #303030;
  border-radius: 0px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.btn-white:hover,
.btn-white:active,
.btn-white:focus {
  color: #ff5a5f;
}
.btn-gray {
  background-color: #d3d3d3;
  border-color: #d3d3d3;
  color: #ffffff;
  border-radius: 0px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.btn-gray:hover,
.btn-gray:active,
.btn-gray:focus {
  background-color: #ff5a5f;
  color: #ffffff;
}
.btn-green {
  background-color: #28b928;
  border-color: #28b928;
  color: #ffffff;
  border-radius: 0px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.btn-green:hover,
.btn-green:active,
.btn-green:focus {
  color: #ffffff;
}
.btn-blue {
  background-color: #1c327e;
  border-color: #1c327e;
  color: #ffffff;
  border-radius: 0px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.btn-blue:hover,
.btn-blue:active,
.btn-blue:focus {
  background-color: #ff5a5f;
  border-color: #ff5a5f;
  color: #ffffff;
}
.btn-transparent {
  background-color: transparent;
  border-color: #ff5a5f;
  color: #ff5a5f;
  border-radius: 0px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.btn-transparent:hover,
.btn-transparent:active,
.btn-transparent:focus {
  background-color: #ff5a5f;
  color: #ffffff;
}
.btn-transparent2 {
  background-color: transparent;
  border-color: -webkit-linear-gradient(
    30deg,
    rgb(69, 104, 220) 0%,
    rgb(123, 105, 199) 50%,
    rgb(176, 106, 179) 100%
  );
  border-color: -o-linear-gradient(
    30deg,
    rgb(69, 104, 220) 0%,
    rgb(123, 105, 199) 50%,
    rgb(176, 106, 179) 100%
  );
  border-color: -ms-linear-gradient(
    30deg,
    rgb(69, 104, 220) 0%,
    rgb(123, 105, 199) 50%,
    rgb(176, 106, 179) 100%
  );
  border-color: -moz-linear-gradient(
    30deg,
    rgb(69, 104, 220) 0%,
    rgb(123, 105, 199) 50%,
    rgb(176, 106, 179) 100%
  );
  border-color: linear-gradient(
    60deg,
    rgb(69, 104, 220) 0%,
    rgb(123, 105, 199) 50%,
    rgb(176, 106, 179) 100%
  );
  color: #4568dc;
  border-radius: 0px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.btn-transparent2:hover,
.btn-transparent2:active,
.btn-transparent2:focus {
  background: -webkit-linear-gradient(
    30deg,
    rgb(69, 104, 220) 0%,
    rgb(123, 105, 199) 50%,
    rgb(176, 106, 179) 100%
  );
  background: -o-linear-gradient(
    30deg,
    rgb(69, 104, 220) 0%,
    rgb(123, 105, 199) 50%,
    rgb(176, 106, 179) 100%
  );
  background: -ms-linear-gradient(
    30deg,
    rgb(69, 104, 220) 0%,
    rgb(123, 105, 199) 50%,
    rgb(176, 106, 179) 100%
  );
  background: -moz-linear-gradient(
    30deg,
    rgb(69, 104, 220) 0%,
    rgb(123, 105, 199) 50%,
    rgb(176, 106, 179) 100%
  );
  background: linear-gradient(
    60deg,
    rgb(69, 104, 220) 0%,
    rgb(123, 105, 199) 50%,
    rgb(176, 106, 179) 100%
  );
  color: #ffffff;
}
.btn-default {
  font-size: 14px;
  padding: 8px 15px;
}
/*== Fonts Size, Font Weights, Height, Display & Position ==*/
.fz11 {
  font-size: 11px;
}
.fz12 {
  font-size: 12px;
}
.fz13 {
  font-size: 13px;
}
.fz14 {
  font-size: 14px;
}
.fz15 {
  font-size: 15px;
}
.fz16 {
  font-size: 16px;
}
.fz17 {
  font-size: 17px;
}
.fz18 {
  font-size: 18px;
}
.fz19 {
  font-size: 19px;
}
.fz20 {
  font-size: 20px;
}
.fz24 {
  font-size: 24px;
}
.fz26 {
  font-size: 26px;
}
.fz30 {
  font-size: 30px;
}
.fz40 {
  font-size: 40px;
}
.fz45 {
  font-size: 45px;
}
.fz48 {
  font-size: 48px;
}
.fz50 {
  font-size: 50px;
}
.fz55 {
  font-size: 55px;
}
.fz60 {
  font-size: 60px;
}
.fz72 {
  font-size: 72px;
}
.fz100 {
  font-size: 100px !important;
}
.lh30 {
  line-height: 30px;
}
.h05 {
  height: 5px !important;
}
.h10 {
  height: 10px;
}
.h20 {
  height: 20px;
}
.h25 {
  height: 25px;
}
.h30 {
  height: 30px;
}
.h35 {
  height: 35px;
}
.h40 {
  height: 40px;
}
.h45 {
  height: 45px;
}
.h50 {
  height: 50px;
}
.h55 {
  height: 55px;
}
.h60 {
  height: 60px;
}
.h65 {
  height: 65px;
}
.h70 {
  height: 70px;
}
.h75 {
  height: 75px;
}
.h80 {
  height: 80px;
}
.h85 {
  height: 85px;
}
.h90 {
  height: 90px;
}
.h95 {
  height: 95px;
}
.h100p {
  height: 100%;
}
.h100 {
  height: 100px;
}
.hf100 {
  height: 100%;
}
.h150 {
  height: 150px;
}
.h200 {
  height: 200px;
}
.h250 {
  height: 250px !important;
}
.h300 {
  height: 300px;
}
.h320 {
  height: 320px;
}
.h345 {
  height: 345px;
}
.h350 {
  height: 350px;
}
.h355 {
  height: 355px;
}
.h400 {
  height: 400px;
}
.h450 {
  height: 450px;
}
.h500 {
  height: 500px;
}
.h550 {
  height: 550px;
}
.h600 {
  height: 600px;
}
.h650 {
  height: 650px;
}
.h660 {
  height: 660px;
}
.h700 {
  height: 700px;
}
.h750 {
  height: 750px;
}
.h800 {
  height: 800px;
}
.h850 {
  height: 850px;
}
.h900 {
  height: 900px;
}
.h950 {
  height: 950px;
}
.h1000 {
  height: 1000px;
}
.wa {
  width: auto;
}
.w60 {
  width: 60px !important;
}
.w80 {
  width: 80px !important;
}
.w100 {
  width: 100% !important;
}
.maxw100 {
  max-width: 100%;
}
.maxw1600 {
  max-width: 1600px;
}
.fw300 {
  font-weight: 300 !important;
}
.fw400 {
  font-weight: 400;
}
.fw500 {
  font-weight: 500;
}
.fw600 {
  font-weight: 600;
}
.fw700 {
  font-weight: 700;
}
.fw800 {
  font-weight: 800;
}
.fw900 {
  font-weight: 900;
}
.fwb {
  font-weight: bold;
}
.db {
  display: block;
}

.dib {
  display: inline-block;
}

.dif {
  display: inline-flex;
}

.df {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
}

.dfr {
  display: flow-root;
}

.dn {
  display: none;
}

.ovh {
  overflow: hidden;
}

.ovv {
  overflow: visible;
}

.posa {
  position: absolute;
}

.posr {
  position: relative;
}

/*== Custome Margin Padding ==*/
.ulockd-pmz {
  margin: 0;
  padding: 0;
}

.p0 {
  padding: 0 !important;
}

.p1 {
  padding: 1;
}

.p10 {
  padding: 10px;
}

.p15 {
  padding: 15px;
}

.p20 {
  padding: 20px;
}

.p25 {
  padding: 25px;
}

.p30 {
  padding: 30px;
}

.p35 {
  padding: 35px;
}

.p40 {
  padding: 40px;
}

.p45 {
  padding: 45px;
}

.p50 {
  padding: 50px;
}

.p55 {
  padding: 55px;
}

.p60 {
  padding: 60px;
}

.p65 {
  padding: 65px;
}

.p70 {
  padding: 70px;
}

.p75 {
  padding: 75px;
}

.p80 {
  padding: 80px;
}

.pad10 {
  padding: 10% 5%;
}

.pad17 {
  padding-top: 17% !important;
}

.pt0 {
  padding-top: 0px !important;
}

.pt10 {
  padding-top: 10px !important;
}

.pt15 {
  padding-top: 15px;
}

.pt20 {
  padding-top: 20px;
}

.pt25 {
  padding-top: 25px;
}

.pt30 {
  padding-top: 30px !important;
}

.pt35 {
  padding-top: 35px;
}

.pt40 {
  padding-top: 40px;
}

.pt45 {
  padding-top: 45px;
}

.pt50 {
  padding-top: 50px;
}

.pt55 {
  padding-top: 55px;
}

.pt60 {
  padding-top: 60px;
}

.pt65 {
  padding-top: 65px;
}

.pt70 {
  padding-top: 70px;
}

.pt75 {
  padding-top: 75px;
}

.pt80 {
  padding-top: 80px;
}

.pt85 {
  padding-top: 85px;
}

.pt90 {
  padding-top: 90px;
}

.pt95 {
  padding-top: 95px;
}

.pt100 {
  padding-top: 100px;
}

.pt120 {
  padding-top: 120px;
}

.pt130 {
  padding-top: 130px;
}

.pt140 {
  padding-top: 140px;
}

.pt150 {
  padding-top: 150px;
}

.pt160 {
  padding-top: 160px;
}

.pt170 {
  padding-top: 170px;
}

.pt180 {
  padding-top: 180px;
}

.pt190 {
  padding-top: 190px;
}

.pt200 {
  padding-top: 200px;
}

.pt100 {
  padding-top: 100px;
}

.pt70 {
  padding-top: 70px;
}

.pt70 {
  padding-top: 70px;
}

.pt70 {
  padding-top: 70px;
}

.pr0 {
  padding-right: 0;
}

.pr1 {
  padding-right: 1px;
}

.pr5 {
  padding-right: 5px;
}

.pr10 {
  padding-right: 10px;
}

.pr15 {
  padding-right: 15px;
}

.pr20 {
  padding-right: 20px;
}

.pr25 {
  padding-right: 25px;
}

.pr30 {
  padding-right: 30px;
}

.pr35 {
  padding-right: 35px;
}

.pr40 {
  padding-right: 40px;
}

.pr45 {
  padding-right: 45px;
}

.pr50 {
  padding-right: 50px;
}

.pr55 {
  padding-right: 55px;
}

.pr60 {
  padding-right: 60px;
}

.pr65 {
  padding-right: 65px;
}

.pr70 {
  padding-right: 70px;
}

.pb0 {
  padding-bottom: 0px !important;
}

.pb15 {
  padding-bottom: 15px;
}

.pb5 {
  padding-bottom: 5px;
}

.pb10 {
  padding-bottom: 10px !important;
}

.pb15 {
  padding-bottom: 15px;
}

.pb20 {
  padding-bottom: 20px;
}

.pb25 {
  padding-bottom: 25px;
}

.pb30 {
  padding-bottom: 30px;
}

.pb35 {
  padding-bottom: 35px;
}

.pb40 {
  padding-bottom: 40px;
}

.pb45 {
  padding-bottom: 45px;
}

.pb50 {
  padding-bottom: 50px;
}

.pb55 {
  padding-bottom: 55px;
}

.pb60 {
  padding-bottom: 60px;
}

.pb65 {
  padding-bottom: 65px;
}

.pb70 {
  padding-bottom: 70px;
}

.pb75 {
  padding-bottom: 75px;
}

.pb80 {
  padding-bottom: 80px;
}

.pb85 {
  padding-bottom: 85px;
}

.pb90 {
  padding-bottom: 90px;
}

.pb95 {
  padding-bottom: 95px;
}

.pb100 {
  padding-bottom: 100px;
}

.pl0 {
  padding-left: 0 !important;
}

.pl1 {
  padding-left: 1px;
}

.pl5 {
  padding-left: 5px;
}

.pl10 {
  padding-left: 10px;
}

.pl15 {
  padding-left: 15px;
}

.pl20 {
  padding-left: 20px;
}

.pl25 {
  padding-left: 25px;
}

.pl30 {
  padding-left: 30px;
}

.pl35 {
  padding-left: 35px;
}

.pl40 {
  padding-left: 40px;
}

.pl45 {
  padding-left: 45px;
}

.pl50 {
  padding-left: 50px;
}

.pl55 {
  padding-left: 55px;
}

.pl60 {
  padding-left: 60px;
}

.pl65 {
  padding-left: 65px;
}

.pl70 {
  padding-left: 70px;
}

.pl75 {
  padding-left: 75px;
}

.pl80 {
  padding-left: 80px;
}

.pl85 {
  padding-left: 85px;
}

.pl90 {
  padding-left: 90px;
}

.pl95 {
  padding-left: 95px;
}

.pl100 {
  padding-left: 100px;
}

.prpl0 {
  padding-left: 0;
  padding-right: 0;
}
.prpl5 {
  padding-left: 5px;
  padding-right: 5px;
}

.prpl10 {
  padding-left: 10px;
  padding-right: 10px;
}

.prpl15 {
  padding-left: 15px;
  padding-right: 15px;
}

.prpl20 {
  padding-left: 20px;
  padding-right: 20px;
}

.prpl25 {
  padding-left: 25px;
  padding-right: 25px;
}

.prpl30 {
  padding-left: 30px;
  padding-right: 30px;
}

.prpl35 {
  padding-left: 35px;
  padding-right: 35px;
}

.prpl40 {
  padding-left: 40px;
  padding-right: 40px;
}

.prpl2 {
  padding-left: 2px;
  padding-right: 2px;
}

.ptpb50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.ptpb60 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.plpr0 {
  padding-left: 0px;
  padding-right: 0px;
}

.m0a {
  margin: 0 auto;
}

.mt-5 {
  margin-top: -5px;
}

.mt-10 {
  margin-top: -10px;
}

.mt-15 {
  margin-top: -15px;
}

.mt-20 {
  margin-top: -20px;
}

.mt-25 {
  margin-top: -25px;
}

.mt-30 {
  margin-top: -30px;
}

.mt-35 {
  margin-top: -35px;
}

.mt-40 {
  margin-top: -40px;
}

.mt-45 {
  margin-top: -45px;
}

.mt-50 {
  margin-top: -50px;
}

.mt-55 {
  margin-top: -55px;
}

.mt-60 {
  margin-top: -60px;
}

.mt-65 {
  margin-top: -65px;
}

.mt-70 {
  margin-top: -70px;
}

.mt-75 {
  margin-top: -75px;
}

.mt-80 {
  margin-top: -80px;
}

.mt-85 {
  margin-top: -85px;
}

.mt-90 {
  margin-top: -90px;
}

.mt-95 {
  margin-top: -95px;
}

.mt-100 {
  margin-top: -100px;
}

.mt-150 {
  margin-top: -150px;
}

.mt-150 {
  margin-top: -150px;
}

.mt-200 {
  margin-top: -200px;
}

.m0 {
  margin: 0;
}

.m5 {
  margin: 5px;
}

.m10 {
  margin: 10px !important;
}

.m15 {
  margin: 15px;
}

.m20 {
  margin: 20px;
}

.m25 {
  margin: 25px;
}

.m30 {
  margin: 30px;
}

.m35 {
  margin: 35px;
}

.m40 {
  margin: 40px;
}

.m45 {
  margin: 45px;
}

.m50 {
  margin: 50px;
}

.mt0 {
  margin-top: 0px !important;
}

.mt5 {
  margin-top: 5px;
}

.mt8 {
  margin-top: 8px;
}

.mt10 {
  margin-top: 10px;
}

.mt15 {
  margin-top: 15px;
}

.mt20 {
  margin-top: 20px;
}

.mt25 {
  margin-top: 25px !important;
}

.mt30 {
  margin-top: 30px;
}

.mt35 {
  margin-top: 35px;
}

.mt40 {
  margin-top: 40px;
}

.mt45 {
  margin-top: 45px;
}

.mt50 {
  margin-top: 50px;
}

.mt60 {
  margin-top: 60px;
}

.mt60 {
  margin-top: 60px;
}

.mt65 {
  margin-top: 65px;
}

.mt70 {
  margin-top: 70px;
}

.mt75 {
  margin-top: 75px;
}

.mt80 {
  margin-top: 80px;
}

.mt85 {
  margin-top: 85px;
}

.mt90 {
  margin-top: 90px;
}

.mt95 {
  margin-top: 95px;
}

.mt100 {
  margin-top: 100px;
}

.mt150 {
  margin-top: 150px;
}

.mt200 {
  margin-top: 200px;
}

.mt250 {
  margin-top: 250px !important;
}

.mt300 {
  margin-top: 3000px;
}

.mt-30 {
  margin-top: -30px;
}

.mr0 {
  margin-right: 0px;
}

.ulockd-mrgb35 {
  margin-right: 5px;
}

.mr10 {
  margin-right: 10px;
}

.mr15 {
  margin-right: 15px;
}

.mr20 {
  margin-right: 20px;
}

.mr25 {
  margin-right: 25px;
}

.mb0 {
  margin-bottom: 0px !important;
}

.mb5 {
  margin-bottom: 5px;
}

.mb10 {
  margin-bottom: 10px;
}

.mb15 {
  margin-bottom: 15px;
}

.mb20 {
  margin-bottom: 20px !important;
}

.mb25 {
  margin-bottom: 25px !important;
}

.mb30 {
  margin-bottom: 30px;
}

.mb35 {
  margin-bottom: 35px;
}

.mb40 {
  margin-bottom: 40px !important;
}

.mb45 {
  margin-bottom: 45px;
}

.mb50 {
  margin-bottom: 50px !important;
}

.mb55 {
  margin-bottom: 55px;
}

.mb60 {
  margin-bottom: 60px;
}

.mb65 {
  margin-bottom: 65px;
}

.mb70 {
  margin-bottom: 70px;
}

.mb75 {
  margin-bottom: 75px;
}

.mb80 {
  margin-bottom: 80px;
}

.mb85 {
  margin-bottom: 85px;
}

.mb90 {
  margin-bottom: 90px;
}

.mb95 {
  margin-bottom: 95px;
}

.mb100 {
  margin-bottom: 100px;
}

.ml0 {
  margin-left: 0px;
}

.ml5 {
  margin-left: 5px;
}

.ml10 {
  margin-left: 10px;
}

.ml15 {
  margin-left: 15px;
}

.ml20 {
  margin-left: 20px;
}

.ml25 {
  margin-left: 25px;
}

.ml30 {
  margin-left: 30px;
}

.ml35 {
  margin-left: 35px;
}

.ml40 {
  margin-left: 40px;
}

.ml45 {
  margin-left: 45px;
}

.ml50 {
  margin-left: 50px;
}

.ml55 {
  margin-left: 55px;
}

.ml60 {
  margin-left: 60px;
}

.zi1 {
  z-index: 1;
}
.zi9 {
  z-index: 9;
}
.zi-1 {
  z-index: -1;
}
.curp {
  cursor: pointer;
}
/*== BackGround Images ==*/
.bg-img2 {
  background-image: url('../images/background/2.jpg');
}
.bg-img3 {
  background-attachment: scroll;
  background-image: url('../images/background/3.jpg');
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 8px;
  height: 600px;
}
.bg-img4 {
  background-attachment: scroll;
  background-image: url('../images/background/4.jpg');
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 8px;
  height: 450px;
}
.bg-img5 {
  background-attachment: scroll;
  background-image: url('../images/background/5.jpg');
  background-position: center;
  background-repeat: no-repeat;
  height: 650px;
}
.bg-img6 {
  background-attachment: scroll;
  background-image: url('../images/background/8.jpg');
  background-position: center;
  background-repeat: no-repeat;
  height: 960px;
}
.bg-ptrn1 {
  background-attachment: scroll;
  background-image: url('../images/pattern/1.png');
  background-position: center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
}
.bg-ptrn2 {
  background-attachment: scroll;
  background-image: url('../images/pattern/2.png');
  background-position: center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
}
section {
  padding: 30px 0;
  position: relative;
}
.main-title {
  position: relative;
  margin-bottom: 50px;
}
.main-title h2 {
  color: #484848;
  font-family: 'Nunito';
  line-height: 1.2;
  margin-bottom: 10px;
  margin-top: 0;
}
.main-title h3 {
  display: inline-block;
  margin-bottom: 10px;
  margin-top: 0;
}
.main-title p {
  font-size: 16px;
  font-family: 'Nunito';
  color: rgb(119, 119, 119);
  line-height: 1.2;
  margin-bottom: 0;
}
.main-title p a {
  color: #484848;
  font-weight: bold;
  line-height: 1.5;
}
.preloader {
  background-color: #ffffff;
  background-image: url('../images/preloader.gif');
  background-position: center center;
  background-repeat: no-repeat;
  height: 100%;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 99999;
}

.scrollToHome:hover {
  color: #ffffff;
}
.scrollToHome {
  border-radius: 50%;
  background-color: #141e2f;
  bottom: 15px;
  color: #8a99b3 !important;
  display: none;
  font-size: 14px;
  height: 45px;
  line-height: 40px;
  padding: 3px 10px;
  position: fixed;
  right: 20px;
  text-align: center;
  width: 45px;
  z-index: 99999;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
a.scrollToHome.home7 {
  background-color: #ffffff;
  -webkit-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
  -moz-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
  -o-box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
  box-shadow: 0px 0px 50px 0px rgba(19, 19, 28, 0.12);
  color: #232733;
}
a.scrollToHome.home8 {
  right: 6%;
}
/*== BackGround Color White Overlay ==*/
.bgc-overlay-white3:before {
  background-color: rgba(255, 255, 255, 0.3);
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.bgc-overlay-white35:before {
  background-color: rgba(255, 255, 255, 0.35);
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.bgc-overlay-white4:before {
  background-color: rgba(255, 255, 255, 0.4);
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.bgc-overlay-white45:before {
  background-color: rgba(255, 255, 255, 0.45);
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.bgc-overlay-white5:before {
  background-color: rgba(255, 255, 255, 0.5);
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.bgc-overlay-white55:before {
  background-color: rgba(255, 255, 255, 0.55);
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.bgc-overlay-white6:before {
  background-color: rgba(255, 255, 255, 0.6);
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.bgc-overlay-white65:before {
  background-color: rgba(255, 255, 255, 0.65);
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.bgc-overlay-white7:before {
  background-color: rgba(255, 255, 255, 0.7);
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.bgc-overlay-white75:before {
  background-color: rgba(255, 255, 255, 0.75);
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.bgc-overlay-white8:before {
  background-color: rgba(255, 255, 255, 0.8);
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.bgc-overlay-white85:before {
  background-color: rgba(255, 255, 255, 0.85);
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.bgc-overlay-white9:before {
  background-color: rgba(255, 255, 255, 0.9);
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.bgc-overlay-white95:before {
  background-color: rgba(255, 255, 255, 0.95);
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

/*== BackGround Color Black Overlay ==*/
.bgc-overlay-black3:before {
  background-color: rgba(0, 0, 0, 0.3);
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.bgc-overlay-black35:before {
  background-color: rgba(0, 0, 0, 0.35);
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.bgc-overlay-black4:before {
  background-color: rgba(0, 0, 0, 0.4);
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.bgc-overlay-black45:before {
  background-color: rgba(0, 0, 0, 0.45);
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.bgc-overlay-black5:before {
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.bgc-overlay-black55:before {
  background-color: rgba(0, 0, 0, 0.55);
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.bgc-overlay-black6:before {
  background-color: rgba(0, 0, 0, 0.6);
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.bgc-overlay-black65:before {
  background-color: rgba(0, 0, 0, 0.65);
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.bgc-overlay-black7:before {
  background-color: rgba(0, 0, 0, 0.7);
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.bgc-overlay-black75:before {
  background-color: rgba(0, 0, 0, 0.75);
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.bgc-overlay-black8:before {
  background-color: rgba(0, 0, 0, 0.8);
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.bgc-overlay-black85:before {
  background-color: rgba(0, 0, 0, 0.85);
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.bgc-overlay-black9:before {
  background-color: rgba(0, 0, 0, 0.9);
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.bgc-overlay-black95:before {
  background-color: rgba(0, 0, 0, 0.95);
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
/*== Parallax Css ==*/
.parallax {
  background-attachment: scroll;
  background-position: 50% 0px;
  z-index: 1;
  position: relative;
}
.home-seven.parallax {
  z-index: 0;
}
/*== Divider Css ==*/
.divider {
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  overflow: hidden;
  padding: 130px 0;
  position: relative;
}
.divider:before {
  background-color: rgba(25, 38, 117, 0.8);
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.divider_home2 {
  background-position: center center;
  -webkit-background-size: cover;
  background-size: cover;
  overflow: hidden;
  padding: 130px 0;
  position: relative;
}
.divider_home1 {
  background-color: rgb(29, 41, 62);
}
.divider_home1:before {
  background-image: url('../images/background/2.jpg');
  content: '';
  height: 100%;
  left: 0;
  opacity: 0.5;
  position: absolute;
  top: 0;
  width: 100%;
}
.divider_home2:before {
  background-color: rgba(0, 0, 0, 0.8);
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.form_control {
  border-radius: 0;
  box-shadow: none;
  height: 45px;
}
.divider-one h1,
.divider-two h1 {
  font-size: 40px;
  line-height: 50px;
  margin-bottom: 50px;
}
.divider-two h1 {
  margin-bottom: 120px;
}
.divider-one .divider-btn {
  border: 2px solid #ffffff;
  border-radius: 25px;
  color: #ffffff;
  height: 50px;
  line-height: 35px;
  width: 215px;
}
.divider-one .divider-btn:hover {
  border: 2px solid #ff5a5f;
}
.divider-one p {
  margin-bottom: 15px;
}
.divider2 {
  padding-bottom: 110px;
  padding-top: 135px;
}
.divider2:before {
  background-image: url(../images/background/2.html);
  background-repeat: no-repeat;
  bottom: 0;
  content: '';
  left: 0;
  opacity: 0.2;
  position: absolute;
  top: 30px;
  width: 100%;
}
.divider-two {
  position: relative;
}
.divider-two h3 {
  font-size: 26px;
  font-weight: normal;
  margin-bottom: 50px;
}
.divider-two p {
  margin-bottom: 0;
}
#mc_embed_signup input {
  background-color: transparent;
  border-bottom: 1px solid #ffffff;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  color: #ffffff;
  font-weight: 300;
  height: 45px;
  width: 85%;
}
#mc_embed_signup input:focus {
  outline: none;
}
#mc_embed_signup input::placeholder {
  color: #ffffff;
}
#mc_embed_signup .mc-field-group {
  margin-bottom: 40px;
}
#mc_embed_signup .mailchimp_btn {
  background-color: transparent;
  border: 2px solid #ffffff;
  border-radius: 25px;
  color: #ffffff;
  height: 50px;
  width: 190px;
}
#mc_embed_signup .mailchimp_btn:hover {
  background-color: #ffffff;
  color: #192675;
}
.divider-two .wrapper {
  text-align: center;
}
div#countdown .col-xs-6.col-sm-3:first-child {
  margin-right: 40px;
  text-align: left;
  width: auto;
}
div#countdown .col-xs-6.col-sm-3 {
  display: inline-block;
}
.divider-two .time {
  color: #fff;
  font-size: 30px;
  line-height: normal;
}
.divider-two .label {
  color: #ffffff;
  display: block;
  font-size: 20px;
  text-transform: uppercase;
}
/*== Chart Js Css ==*/
canvas {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.chart-container {
  width: 100%;
}
.c_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
/*== Tags Bar Css ==*/
.selected_filter_widget {
  border: 1px solid rgb(237, 239, 247);
  display: inline-block;
  height: auto;
  margin-bottom: 30px;
  padding: 30px 30px 25px;
  position: relative;
  width: 100%;
}
.selected_filter_widget.style2 {
  border: 1px solid rgb(237, 239, 247);
}
.selected_filter_widget.style3 {
  border: none;
  padding: 0 15px;
}
.selected_filter_widget.style4 {
  background-color: #f9fafc;
  border-radius: 5px;
  border: none;
  padding: 35px;
}
.selected_filter_widget .tags-bar.style2 {
  background-color: transparent;
  border-radius: 0;
  padding: 0;
}
.selected_filter_widget.style2 .accordion .link {
  font-size: 20px;
  font-family: 'Nunito SemiBold';
  color: #484848;
  line-height: 1.2;
}
.tags-bar {
  background-color: #f0f0f0;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  float: left;
  padding: 10px;
  position: relative;
  width: 100%;
}
.tags-bar > span {
  background-color: #edeff7;
  color: #4f4f4f;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
  border-radius: 25px;
  font-size: 13px;
  height: 30px;
  line-height: 55px;
  margin-right: 5px;
  padding: 10px 20px;
  position: relative;
  width: 125px;
}
.tags-bar > span i {
  color: #383535;
  cursor: pointer;
  font-size: 12px;
  font-style: normal;
  height: 16px;
  padding-left: 10px;
}
.tags-bar .action-tags {
  float: right;
}
.tags-bar .action-tags a {
  color: #939393;
  font-size: 13px;
}
.tags-bar .action-tags a i {
  font-size: 16px;
}
#accordion .panel {
  border: 0;
  border-radius: 0;
  margin-top: 0px;
}
#accordion a {
  display: block;
  text-decoration: none;
}
#accordion .panel-heading a.collapsed:hover,
#accordion .panel-heading a.collapsed:focus {
  color: #484848;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}
#accordion .panel-heading a.collapsed:hover::before,
#accordion .panel-heading a.collapsed:focus::before {
  color: #484848;
}
#accordion .panel-heading {
  padding: 0;
}
#accordion .panel-heading a:not(.collapsed) {
  color: #484848;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}
/* arrow fontawesome icon to the right */
#accordion .panel-heading .accordion-toggle::before {
  font-family: 'FontAwesome';
  color: #484848;
  content: '\f107';
  float: right;
  font-weight: lighter;
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}
#accordion .panel-heading .accordion-toggle.collapsed::before {
  color: #444;
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}
.body_content.translated {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  -webkit-transform: translateX(380px);
  -ms-transform: translateX(380px);
  -o-transform: translateX(380px);
  transform: translateX(380px);
}
.sidebar_content_details {
  background-color: #ffffff;
  border: 1px solid #dddddd;
  bottom: 0;
  height: auto;
  left: 0;
  margin-left: 0;
  overflow-y: scroll;
  padding: 80px 0 0;
  position: fixed;
  top: 0;
  -webkit-transition: all 0.1s ease;
  -moz-transition: all 0.1s ease;
  -o-transition: all 0.1s ease;
  transition: all 0.1s ease;
  width: 360px;
  z-index: 9;
}
.sidebar_content_details::-webkit-scrollbar {
  margin-top: 15px;
  margin-right: -10px;
  width: 5px;
  z-index: 3;
}
.sidebar_content_details::-webkit-scrollbar * {
  background: transparent;
  margin-right: -10px;
}
.sidebar_content_details::-webkit-scrollbar-track {
  display: none;
}
.sidebar_content_details::-webkit-scrollbar-thumb {
  background-color: #dfdfdf !important;
  border-radius: 2.5px;
  margin-right: -10px;
}
.sidebar_content_details.is-full-width {
  left: 0;
  margin-left: -100%;
  position: fixed;
}
.sidebar_content_details.is-full-width.style2 {
  margin-left: 0;
}
.sidebar_content_details.style2 {
  border: 1px solid transparent;
  padding: 0;
  left: -376px;
}
.sidebar_content_details.style3 {
  padding: 5px 0 0;
  margin-left: -200%;
  z-index: 9999;
}
.sidebar_content_details.style3.sidebar_ml0 {
  margin-left: 0;
}
.sidebar_switch {
  position: relative;
}
#main .filteropen,
#main2 .filteropen2,
#main2 .filter_open_btn {
  background-color: #ff5a5f;
  border-radius: 8px;
  color: #ffffff;
  cursor: pointer;
  font-size: 16px;
  font-family: 'Nunito';
  font-weight: bold;
  height: 50px;
  left: auto;
  line-height: 50px;
  overflow: hidden;
  padding-left: 45px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 38px;
  width: 170px;
  z-index: 999;
  -webkit-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  -moz-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  -o-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
}
#main2 .filter_open_btn {
  top: 5px;
}
#open.flaticon-filter-results-button:before,
#open.flaticon-close:before,
#open2.flaticon-filter-results-button:before,
#open2.flaticon-close:before {
  background-color: #f53c41;
  left: 0;
  position: absolute;
  width: 48px;
}
#main .filteropen.style2,
#main2 .filteropen2.style2 {
  left: auto;
  position: relative;
  right: auto;
  top: auto;
}
.sidebar_switch.style #main .filteropen,
.sidebar_switch.style2 #main2 .filteropen2 {
  left: 55px;
  right: auto;
  top: 55px;
}
.listing_toogle_sidebar.sidenav {
  background-color: #fafafa;
  border: 1px solid #dddddd;
  border-top: none;
  height: 875px;
  left: 0;
  overflow-x: hidden;
  padding: 0 0 70px;
  position: fixed;
  top: 170px;
  width: 380px;
  z-index: 1;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  -webkit-transform: translate(-100%, -80px);
  -moz-transform: translate(-100%, -80px);
  -o-transform: translate(-100%, -80px);
  transform: translate(-100%, -80px);
}
.listing_toogle_sidebar.sidenav a {
  display: block;
  padding: 0;
  text-decoration: none;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.listing_toogle_sidebar.sidenav .closebtn {
  font-size: 36px;
  margin-left: 50px;
  position: absolute;
  top: 0;
  right: 25px;
}
.listing_toogle_sidebar.sidenav::-webkit-scrollbar {
  background: transparent;
  border-radius: 10px;
  padding-right: 10px;
  width: 8px;
}
.listing_toogle_sidebar.sidenav::-webkit-scrollbar-thumb {
  background-color: #ededed;
  border-radius: 3px;
  margin-right: 5px;
}
/* Shop Page Style*/
.sp_search_content {
  background-color: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  height: 62px;
  padding: 15px 30px;
  position: relative;
}
.search_result {
  display: inline-block;
}
.search_drpdwn {
  display: inline-block;
  float: right;
}
.search_drpdwn button.btn.dropdown-toggle.btn-light {
  background-color: transparent;
  border: none;
}
.search_drpdwn button.btn.dropdown-toggle.btn-light:hover,
.search_drpdwn button.btn.dropdown-toggle.btn-light:active,
.search_drpdwn button.btn.dropdown-toggle.btn-light:focus {
  border: none;
  box-shadow: none;
  outline: none !important;
}
.search_drpdwn .filter-option-inner-inner {
  color: #777777;
}
.shop_grid {
  background-color: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  cursor: pointer;
  margin-bottom: 30px;
  padding: 10px;
  position: relative;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.shop_grid .thumb {
  position: relative;
}
.shop_grid .thumb .tag {
  border-radius: 3px;
  background-color: #ff5a5f;
  font-size: 14px;
  font-family: 'Nunito';
  color: #fefefe;
  height: 25px;
  left: 10px;
  line-height: 25px;
  position: absolute;
  text-align: center;
  top: 10px;
  width: 70px;
}
.shop_grid .details {
  padding: 20px 10px 10px 10px;
  position: relative;
}
.shop_grid .details h4.item-tile {
  font-family: 'Nunito';
  color: #484848;
  font-weight: bold;
  line-height: 1.444;
  margin-bottom: 15px;
}
.shop_grid .details h4.item-tile .price {
  font-family: 'Nunito';
  color: #ff5a5f;
  font-weight: bold;
  float: right;
}
.shop_grid .details .add_to_cart {
  border-radius: 8px;
  -webkit-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  -moz-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  -o-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  font-size: 16px;
  font-family: 'Nunito';
  font-weight: bold;
  line-height: 1.2;
  height: 45px;
  width: 150px;
}
/* Shop Order Page Style */
.shop_order_box {
  border-style: solid;
  border-width: 2px;
  border-color: rgb(237, 239, 247);
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0);
  padding: 30px;
}
.shop_order_box .main_title {
  font-size: 20px;
  font-family: 'Nunito SemiBold';
  color: #484848;
  line-height: 1.2;
  margin-bottom: 20px;
  text-align: center;
}
.shop_order_box .order_list_raw {
  margin-bottom: 80px;
  margin-top: 60px;
  text-align: center;
}
.shop_order_box .order_list_raw ul {
  margin-bottom: 0;
}
.shop_order_box .order_list_raw ul li {
  margin-right: 60px;
  text-align: left;
}
.shop_order_box .order_list_raw ul li:last-child {
  margin-right: 0px;
}
.shop_order_box .order_list_raw ul li h4 {
  font-size: 18px;
  font-family: 'Nunito';
  color: #484848;
  line-height: 1.333;
  margin-bottom: 4px;
}
.shop_order_box .order_list_raw ul li p {
  font-size: 15px;
  font-family: 'Open Sans';
  color: rgb(126, 126, 126);
  line-height: 1.6;
}
.shop_order_box .order_details .title {
  font-size: 20px;
  font-family: 'Nunito SemiBold';
  color: #484848;
  line-height: 1.2;
  margin-bottom: 40px;
}
.shop_order_box .order_details .title2 {
  font-size: 20px;
  font-family: 'Nunito SemiBold';
  color: #484848;
  line-height: 1.2;
  margin-bottom: 20px;
}
.shop_order_box .order_details .od_content {
  margin-bottom: 60px;
}
.shop_order_box .order_details .od_content li {
  font-size: 15px;
  font-family: 'Open Sans';
  color: rgb(126, 126, 126);
  line-height: 1.6;
  margin-bottom: 25px;
}
.shop_order_box .order_details .od_content .tamount {
  font-size: 18px;
  font-family: 'Nunito SemiBold';
  color: rgb(255, 16, 83);
  line-height: 1.333;
}
/*== Shop Chaeckout Page Style ==*/
.checkout_form {
  position: relative;
}
.checkout_form.style2 {
  background-color: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  padding: 30px;
}
.checkout_form.style2 .heading p {
  font-size: 14px;
  font-family: 'Nunito';
  color: #484848;
  line-height: 1.2;
  margin-bottom: 15px;
}
.checkout_form .checkout_coupon .form1 {
  display: block;
  margin-bottom: 60px;
  padding-top: 10px;
  text-align: center;
}
.checkout_form .checkout_coupon form input.coupon_input {
  background-color: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  font-size: 14px;
  font-family: 'Nunito';
  color: #484848;
  line-height: 1.2;
  height: 52px;
  margin-right: 24px;
  width: 300px;
}
.checkout_form .checkout_coupon form label {
  font-size: 16px;
  font-family: 'Nunito';
  color: #484848;
  font-weight: bold;
  line-height: 1.75;
}
.checkout_form .checkout_coupon form input {
  background-color: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  font-size: 14px;
  font-family: 'Nunito';
  color: #484848;
  line-height: 1.2;
  height: 52px;
  margin-right: 24px;
}
.checkout_form .checkout_coupon form textarea {
  background-color: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  font-size: 14px;
  font-family: 'Nunito';
  color: #484848;
  line-height: 1.2;
  padding: 25px;
}
.checkout_form .checkout_coupon form button {
  border-radius: 8px;
  background-color: #3e4c66;
  color: #ffffff;
  -webkit-box-shadow: 0px 1px 4px 0px rgba(62, 76, 102, 0.3);
  -moz-box-shadow: 0px 1px 4px 0px rgba(62, 76, 102, 0.3);
  -o-box-shadow: 0px 1px 4px 0px rgba(62, 76, 102, 0.3);
  box-shadow: 0px 1px 4px 0px rgba(62, 76, 102, 0.3);
  font-size: 16px;
  font-family: 'Nunito';
  font-weight: bold;
  height: 50px;
  line-height: 1.2;
  margin-bottom: 0;
  width: 170px;
}
.checkout_form .checkout_coupon form button:hover {
  border: 1px solid #3e4c66;
  background-color: #ffffff;
  color: #3e4c66;
}
.checkout_form .checkout_coupon form button.btn2 {
  margin-right: 24px;
}
.checkout_form .checkout_coupon form button.btn3 {
  background-color: #ff5a5f;
  border-radius: 8px;
  color: #ffffff;
  -webkit-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  -moz-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  -o-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  font-size: 16px;
  font-family: 'Nunito';
  font-weight: bold;
  line-height: 1.2;
  height: 50px;
  position: absolute;
  right: 0;
  width: 170px;
}
.checkout_form .checkout_coupon form button.btn3:hover {
  border: 1px solid #ff5a5f;
  background-color: #ffffff;
  color: #ff5a5f;
}
.checkout_form
  .checkout_coupon
  .form2
  .my_profile_select_box
  .dropdown.bootstrap-select
  button.btn {
  width: 100%;
}
.checkout_form
  .checkout_coupon
  .form2
  .my_profile_select_box
  .dropdown-item.active,
.checkout_form
  .checkout_coupon
  .form2
  .my_profile_select_box
  .dropdown-item:active {
  background-color: transparent;
  color: #6f7074;
}
.checkout_form .checkout_coupon .form2 .form-group {
  margin-bottom: 30px;
}
.checkout_form .checkout_coupon .form2 label {
  font-size: 15px;
  font-family: 'Open Sans';
  color: rgb(79, 79, 79);
  line-height: 1.867;
}
.checkout_form .checkout_coupon .form2 .ai_title {
  font-size: 18px;
  font-family: 'Nunito';
  color: #484848;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 20px;
  margin-top: 10px;
}
.checkout_form .checkout_coupon .form2 p {
  font-size: 16px;
  font-family: 'Nunito';
  color: #484848;
  font-weight: bold;
  line-height: 1.75;
}
.order_sidebar_widget {
  border: 1px solid #ebebeb;
  border-radius: 8px;
  background-color: #ffffff;
  padding: 30px 30px 10px;
  position: relative;
}
.order_sidebar_widget.style2 {
  padding: 30px;
}
.order_sidebar_widget .title {
  color: #484848;
  font-size: 18px;
  font-family: 'Nunito';
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 40px;
}
.order_sidebar_widget ul li p {
  margin-bottom: 27px;
}
.order_sidebar_widget .subtitle p {
  color: #484848;
  font-size: 16px;
  font-family: 'Nunito';
  font-weight: bold;
}
.order_sidebar_widget .subtitle span,
.order_sidebar_widget .subtitle .totals {
  color: #ff5a5f;
  font-size: 16px;
  font-family: 'Nunito';
}
.payment_widget {
  border: 1px solid #ebebeb;
  border-radius: 8px;
  background-color: #ffffff;
  padding: 30px;
}
.payment_widget .bt_details {
  border-radius: 5px;
  background-color: #f7f7f7;
  -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  -o-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  margin-bottom: 15px;
  margin-top: 5px;
  padding: 25px;
}
.payment_widget .bt_details p {
  font-size: 14px;
  font-family: 'Nunito';
  color: #484848;
  line-height: 1.714;
  margin-bottom: 0;
}
.payment_widget_btn .btn-block {
  margin-top: 30px;
  width: 100%;
}
.payment_widget_btn .btn-block:hover {
  border: 1px solid #ff1053;
}
.cart_page_form thead {
  display: block;
}
.cart_page_form {
  border: 2px solid rgb(237, 239, 247);
  background-color: #ffffff;
  padding: 30px;
  margin-bottom: 30px;
}
.cart_page_form table.table.table-responsive {
  border-radius: 5px 5px 0 0;
  margin-bottom: 0;
}
.cart_page_form tr.carttable_row {
  background-color: #f7f7f7;
  border-radius: 8px;
  display: block;
  height: 60px;
}
.cart_page_form tr.carttable_row th:first-child {
  width: 47%;
}
.cart_page_form tr.carttable_row th:nth-child(2).cartm_title {
  width: 19%;
}
.cart_page_form tr.carttable_row th:nth-child(3).cartm_title {
  width: 15%;
}
.cart_page_form tr.carttable_row th:nth-child(4).cartm_title {
  width: 20%;
}
.cart_page_form tr.carttable_row th.cartm_title {
  background-color: #f7f7f7;
  border: none;
  color: #484848;
  font-size: 16px;
  font-family: 'Nunito';
  font-weight: bold;
  line-height: 2.2;
  padding-left: 20px;
}
.table_body .cart_list {
  margin-bottom: 0;
}
.cart_page_form .table_body td,
.cart_page_form .table_body th {
  border-bottom: 1px solid #ebebeb;
  border-top: none;
  padding: 30px 0 30px 0;
  vertical-align: middle;
}
.cart_page_form.style2 .table_body td,
.cart_page_form.style2 .table_body th {
  padding: 30px 31px;
  vertical-align: middle;
}
.cart_page_form.style2 .table_body th {
  width: 55%;
}
.cart_page_form.style2 .table_body td {
  width: 13%;
}
.cart_page_form.style3 .table_body td,
.cart_page_form.style3 .table_body th {
  border-bottom: 1px solid #dedede;
}
.cart_page_form.style3 {
  border: none;
}
.cart_page_form.style3 .table_body tr {
  border-left: 1px solid #dedede;
  border-right: 1px solid #dedede;
}
.cart_page_form.style3 .table_body td {
  width: 0%;
}
.cart_page_form.style3 .table_body th {
  width: 52%;
}
.order_key_status li:last-child span {
  margin-left: 54px;
}
.cart_page_form.style3 tr.borderless_table_row {
  border-left: none;
  border-right: none;
}
.cart_page_form.style3 tr.borderless_table_row th,
.cart_page_form.style3 tr.borderless_table_row td,
.cart_page_form.style3 tr.borderless_table_row.style2 th,
.cart_page_form.style3 tr.borderless_table_row.style2 td {
  border: none;
}
.cart_page_form tbody.table_body {
  display: inline-table;
  width: 100%;
}
.cart_page_form tbody.table_body th .cart_list .cart_title {
  font-size: 18px;
  font-family: 'Nunito';
  color: #484848;
  font-weight: bold;
  line-height: 1.444;
}
.cart_page_form tbody.table_body th .cart_list li {
  margin-right: 0;
  vertical-align: middle;
}
.cart_page_form tbody.table_body td .cart_count {
  border: none;
  border-radius: 8px;
  background-color: #efefef;
  font-size: 16px;
  font-family: 'Nunito';
  color: #484848;
  font-weight: bold;
  height: 50px;
  line-height: 1.2;
  width: 70px;
}

.cart_page_form tbody.table_body td .cart_count:focus {
  outline: none;
}
.order_key_status li {
  font-size: 18px;
  font-family: 'Nunito';
  color: rgb(10, 10, 10);
  line-height: 2.222;
}
.order_key_status li span {
  font-size: 15px;
  font-family: 'Open Sans';
  color: rgb(126, 126, 126);
  line-height: 2.667;
  margin-left: 75px;
}
/* Shop Single Page Style */
.single_product_grid {
  background-color: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  margin: 0;
  padding: 10px 0;
  position: relative;
}
.single_product_grid .sps_content .content {
  text-align: left;
}
.single_product_grid .sps_content {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
}
.single_product_grid .sps_content .content .shop_single_product_details {
  padding: 15px 10px 0 0;
}
.single_product_grid .sps_content .content .shop_single_product_details p {
  font-size: 14px;
  font-family: 'Nunito';
  color: #484848;
  line-height: 1.714;
}
.single_product_slider.owl-theme .owl-nav {
  display: none;
}
.single_product_slider.owl-theme .owl-dots {
  text-align: left;
}
.single_product_slider.owl-theme .owl-dots .owl-dot span {
  border-radius: 6px;
  height: 80px;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 10px;
  margin-top: 10px;
  width: 90px;
}
.single_product_slider.owl-theme .owl-dots .owl-dot:first-child span {
  /* background-image: url(../images/shop/ss1.png); */
  background-position: center center;
  background-size: cover;
}
.single_product_slider.owl-theme .owl-dots .owl-dot:nth-child(2) span {
  /* background-image: url(../images/shop/ss2.png); */
  background-position: center center;
  background-size: cover;
}
.single_product_slider.owl-theme .owl-dots .owl-dot:nth-child(3) span {
  /* background-image: url(../images/shop/ss3.png); */
  background-position: center center;
  background-size: cover;
}
.single_product {
  position: relative;
}
.single_product .product_popup {
  border-radius: 50%;
  background-color: #ffffff;
  height: 50px;
  position: absolute;
  right: 10px;
  text-align: center;
  top: 10px;
  width: 50px;
}
.single_product .product_popup span {
  color: #484848;
  font-size: 25px;
  line-height: 2;
}
.single_product .single_item .thumb img {
  height: 312px;
}
.shop_single_product_details h4 {
  color: #484848;
  font-size: 22px;
  font-family: 'Nunito';
  font-weight: bold;
  line-height: 1.182;
  margin-bottom: 15px;
}
.shop_single_product_details .cart_btns input {
  background-color: #efefef;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-family: 'Nunito';
  color: #484848;
  font-weight: bold;
  line-height: 1.2;
  height: 50px;
  text-align: center;
  width: 70px;
}
.shop_single_product_details .sspd_price {
  font-size: 18px;
  font-family: 'Nunito';
  color: #ff5a5f;
  font-weight: bold;
  line-height: 1.2;
}
.shop_single_product_details .cart_btns input:focus {
  outline: none;
}
.shop_single_product_details .cart_btns button.btn {
  background-color: #ff5a5f;
  -webkit-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  -moz-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  -o-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  border-radius: 8px;
  font-size: 16px;
  font-family: 'Nunito';
  font-weight: bold;
  line-height: 1.2;
  color: #ffffff;
  height: 50px;
  width: 170px;
}
.shop_single_product_details .cart_btns button.btn:hover {
  background-color: #ffffff;
  border: 1px solid #ff5a5f;
  color: #ff5a5f;
}
.shop_single_product_details .sspd_sku li a {
  font-size: 15px;
  font-family: 'Open Sans';
  color: rgb(126, 126, 126);
  line-height: 1.6;
}
.shop_single_product_details .sspd_social_icon li:first-child {
  font-size: 18px;
  font-family: 'Nunito';
  color: rgb(59, 59, 59);
  line-height: 1.333;
  padding-right: 10px;
}
.shop_single_product_details .sspd_social_icon li {
  padding-right: 20px;
}
.shop_single_product_details .sspd_social_icon li a {
  font-size: 16px;
  font-family: 'FontAwesome';
  color: #b6b9c7;
  line-height: 1.2;
}
.shop_single_product_details .sspd_social_icon li:hover a {
  color: rgb(72, 73, 77);
}
.product_single_content
  .mbp_pagination_comments
  .mbp_first.media
  .media-body
  p {
  font-size: 14px;
  font-family: 'Nunito';
  color: #484848;
  line-height: 1.857;
  margin-bottom: 0;
}
.product_single_content.style2 {
  border-style: solid;
  border-width: 2px;
  border-color: rgb(237, 239, 247);
  border-radius: 5px;
  background-color: rgba(42, 53, 59, 0);
}
.product_single_content
  .mbp_pagination_comments
  .mbp_first.media
  h4.sub_title
  ul
  li
  a {
  font-size: 13px;
  font-family: 'FontAwesome';
  color: #bcc52a;
  line-height: 1.2;
}
.product_single_content
  .mbp_pagination_comments
  .mbp_first.media
  h4.sub_title
  .sspd_review
  ul
  li {
  margin-right: 2px;
}
.cs_rwo_tabs.csv2 .nav-tabs {
  border-bottom: none;
}
.cs_rwo_tabs.csv2 .nav-tabs .nav-link {
  border: 1px solid transparent;
  font-size: 18px;
  font-family: 'Nunito';
  color: #484848;
  height: 64px;
  line-height: 2.5;
  text-align: center;
  width: 165px;
}
.cs_rwo_tabs.csv2 .nav-tabs .nav-link:hover {
  border: 1px solid transparent;
}
.cs_rwo_tabs.csv2 .nav-tabs .nav-link.active {
  background-color: #ffffff;
  -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  -o-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  position: relative;
}
.cs_rwo_tabs.csv2 .nav-tabs .nav-link.active:before {
  background-color: #ffffff;
  content: '';
  height: 18px;
  left: 0;
  margin-left: 75px;
  margin-top: 46px;
  position: absolute;
  right: 0;
  width: 18px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.shop_single_tab_content .nav-tabs {
  border-bottom: 1px solid #ebebeb;
}
.shop_single_tab_content .nav-tabs li.nav-item {
  padding: 15px 0 0 30px;
}
.shop_single_tab_content.style3 .nav-tabs {
  border-bottom: none;
}
.shop_single_tab_content.style3 .nav-tabs li.nav-item {
  padding: 0 0 0 30px;
}
.shop_single_tab_content.style3 .nav-tabs .nav-link {
  padding-top: 0;
}
.shop_single_tab_content.style3 .nav-tabs .nav-link {
  font-size: 16px;
  font-weight: 400;
}
.ls_barchart {
  max-height: 345px !important;
}
.shop_single_tab_content {
  background-color: #ffffff;
  border-bottom: 1px solid #ebebeb;
  border-radius: 8px;
}
.shop_single_tab_content .mbp_pagination_comments {
  margin-top: 0;
  position: relative;
}
ul.total_reivew_view {
  cursor: pointer;
  margin-bottom: 0;
  padding: 30px 30px 0;
  position: relative;
  width: 100%;
}
ul.total_reivew_view li.sub_titles {
  font-size: 18px;
  font-family: 'Nunito';
  color: #484848;
  font-weight: bold;
  line-height: 1.2;
  margin-right: 10px;
}
ul.total_reivew_view li.avrg_review {
  font-size: 14px;
  font-family: 'Nunito';
  color: #767676;
  line-height: 2.143;
}
ul.total_reivew_view li.write_review {
  font-size: 14px;
  font-family: 'Nunito';
  color: #ff5a5f;
  line-height: 2.57;
  position: absolute;
  right: 30px;
}
ul.total_reivew_view .star_list li {
  margin-right: 4px;
}
ul.total_reivew_view .star_list li a {
  font-size: 14px;
  font-family: 'FontAwesome';
  color: #bcc52a;
  line-height: 2.143;
}
ul.total_reivew_view .star_list li:last-child a {
  color: #e1e1e1;
}
.shop_single_tab_content .mbp_pagination_comments .mbp_first.media {
  padding: 30px 30px 20px;
}
.shop_single_tab_content
  .mbp_pagination_comments
  .mbp_first.media
  .media-body
  h4.sub_title {
  margin-bottom: 0;
}
.shop_single_tab_content .nav-tabs .nav-link {
  border: none;
  font-size: 18px;
  font-family: 'Nunito';
  color: #484848;
  font-weight: bold;
  line-height: 1.2;
  padding: 0.5rem 0;
}
.shop_single_tab_content .nav-tabs .nav-link.active {
  border-bottom: 1px solid #ff5a5f;
  color: #ff5a5f;
}
.mbp_comment_form.style2 {
  padding: 10px 30px 30px;
}
.bsp_reveiw_wrt {
  border: 1px solid rgb(235, 235, 235);
  background-color: #ffffff;
  border-radius: 8px;
  margin-bottom: 30px;
  padding: 30px;
}
.mbp_comment_form.style2 h4,
.bsp_reveiw_wrt h4 {
  font-size: 18px;
  font-family: 'Nunito';
  color: #484848;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 25px;
}
.mbp_comment_form.style2 ul {
  margin-bottom: 20px;
}
.mbp_comment_form.style2 .sspd_review .review_rating_para,
.review_star li p {
  font-size: 14px;
  font-family: 'Nunito';
  color: #484848;
  line-height: 1.714;
  padding-left: 10px;
}
.mbp_comment_form.style2 .sspd_review li,
.review_star li,
.review_star li .sspd_review ul li {
  margin-right: 0;
}
.mbp_comment_form.style2 .sspd_review li ul li a,
.review_star li ul li a {
  font-size: 14px;
  font-family: 'FontAwesome';
  color: #bcc52a;
  line-height: 2.143;
}
.mbp_comment_form.style2 .sspd_review li ul li:last-child a,
.review_star li ul li:last-child a {
  color: #e1e1e1;
}
.mbp_comment_form.style2 .comments_form,
.bsp_reveiw_wrt .comments_form {
  background-color: transparent;
  padding: 0;
}
.mbp_comment_form.style2 .comments_form .form-group input,
.bsp_reveiw_wrt .comments_form .form-group input {
  background-color: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  font-size: 14px;
  font-family: 'Nunito';
  color: #484848;
  line-height: 1.2;
  height: 52px;
  margin-bottom: 20px;
  padding-left: 20px;
}
.mbp_comment_form.style2 .comments_form .form-group textarea,
.bsp_reveiw_wrt .comments_form .form-group textarea {
  background-color: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  color: #484848;
  font-size: 14px;
  font-family: 'Nunito';
  line-height: 1.2;
  margin-bottom: 20px;
  padding: 20px;
}
.mbp_comment_form.style2 .comments_form button.btn,
.bsp_reveiw_wrt .comments_form button.btn {
  border-radius: 8px;
  background-color: #ff5a5f;
  -webkit-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  -moz-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  -o-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  color: #ffffff;
  font-size: 16px;
  font-family: 'Nunito';
  font-weight: bold;
  height: 50px;
  line-height: 35px;
  width: 200px;
}
.mbp_comment_form.style2 .comments_form button.btn:hover,
.mbp_comment_form.style2 .comments_form button.btn:focus,
.bsp_reveiw_wrt .comments_form button.btn:hover,
.bsp_reveiw_wrt .comments_form button.btn:focus {
  background-color: #ffffff;
  color: #ff5a5f;
  border: 1px solid #ff5a5f;
}
.custom_hr {
  position: relative;
}
.custom_hr:before {
  background-color: #ebebeb;
  content: '';
  height: 1px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
.custom_hr.style3:before {
  top: 30px;
}
.become_instructor_container {
  position: relative;
}
.become_instructor_container .titles {
  color: #484848;
  font-family: nunito;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.3;
  margin-bottom: 30px;
}
/* Google map Multiple marker with content box */
.home_two_map.style2 {
  position: inherit;
}
.map-canvas.half_style {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute !important;
  right: 0;
  top: 0;
  width: 100%;
}
.gm-style .gm-style-iw-c {
  background-color: #ffffff;
  -webkit-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
  -moz-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
  -o-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
  box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
  border-radius: 8px;
  top: 15px;
  max-height: 321px !important;
  max-width: 290px !important;
}
.gm-style .gm-style-iw-t::after {
  top: 15px;
}
button.gm-ui-hover-effect {
  background-color: #ffffff !important;
  border-radius: 5px;
  border: 0 !important;
  color: inherit;
  font-size: 30px;
  height: 35px;
  line-height: 30px;
  outline: none;
  opacity: 1;
  position: absolute !important;
  right: 10px !important;
  text-align: center;
  top: 15px !important;
  width: 35px;
}
button.gm-ui-hover-effect:hover,
button.gm-ui-hover-effect:focus {
  opacity: 1;
}
.gm-style-iw-d {
  height: auto;
  width: 290px;
}
.gm-style-iw-d img {
  margin-bottom: 15px;
  max-height: 175px;
  max-width: 270px;
  width: 100%;
}
.gm-style-iw-d h5 {
  font-size: 14px;
  font-family: 'Nunito';
  color: #ff5a5f;
  line-height: 1.2;
}
.gm-style-iw-d h4 {
  font-size: 16px;
  font-family: 'Nunito';
  color: #484848;
  font-weight: bold;
  line-height: 1.2;
}
.gm-style-iw-d p {
  font-size: 14px;
  font-family: 'Nunito';
  color: #484848;
  line-height: 1.2;
}
.gm-style-iw-d p span {
  margin-right: 10px;
}
/* New Pricing Range Smooth Slider */
#slider-range-value1,
#slider-range-value2 {
  color: #006c70;
  font-size: 16px;
  font-family: 'Nunito';
  font-weight: bold;
  line-height: 1.2;
}
#slider-range-value2 {
  float: right;
  margin-top: 15px;
}
.slider-labels {
  margin-top: 10px;
}
.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.noUi-target {
  position: relative;
}
.noUi-base {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}
.noUi-origin {
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
}
.noUi-handle {
  position: relative;
  z-index: 1;
}
.noUi-stacking .noUi-handle {
  z-index: 10;
}
.noUi-state-tap .noUi-origin {
  -webkit-transition: left 0.3s, top 0.3s;
  transition: left 0.3s, top 0.3s;
}
.noUi-state-drag * {
  cursor: inherit !important;
}
.noUi-base,
.noUi-handle {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.noUi-horizontal .noUi-handle {
  background-color: #ff5a5f;
  border: 10px solid #fff;
  border-radius: 50%;
  height: 11px;
  left: -7px;
  top: -13px;
  width: 11px;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(19, 19, 28, 0.2);
  -moz-box-shadow: 0px 0px 5px 0px rgba(19, 19, 28, 0.2);
  -o-box-shadow: 0px 0px 5px 0px rgba(19, 19, 28, 0.2);
  box-shadow: 0px 0px 5px 0px rgba(19, 19, 28, 0.2);
}
.noUi-background {
  background: #f7f7f7;
}
.noUi-connect {
  background: #ff5a5f;
  -webkit-transition: background 450ms;
  transition: background 450ms;
}
.noUi-draggable {
  cursor: w-resize;
}
.noUi-vertical .noUi-draggable {
  cursor: n-resize;
}
.noUi-handle {
  cursor: default;
  -webkit-box-sizing: content-box !important;
  -moz-box-sizing: content-box !important;
  box-sizing: content-box !important;
}
.noUi-handle:active {
  background-clip: padding-box;
  -webkit-background-clip: padding-box;
  border: 10px solid #fff;
  left: -14px;
  top: -13px;
}
/* Disabled state; */
[disabled].noUi-connect,
[disabled] .noUi-connect {
  background: #b8b8b8;
}
[disabled].noUi-origin,
[disabled] .noUi-handle {
  cursor: not-allowed;
}

/* =========================Custom Css By Tawhid========================= */

.home-four .banner-style-one .slide:before {
  background-image: -moz-linear-gradient(
    -36deg,
    rgb(62 62 165 / 0%) 100%,
    rgb(255 90 95 / 0%) 100%
  );
  background-image: -webkit-linear-gradient(
    -36deg,
    rgb(62 62 165 / 0%) 100%,
    rgb(255 90 95 / 0%) 100%
  );
  background-image: -ms-linear-gradient(
    -36deg,
    rgb(62 62 165 / 0%) 100%,
    rgb(255 90 95 / 0%) 100%
  );
}
header.header-nav.menu_style_home_one.style2 a.navbar_brand {
  margin-top: 10px;
  padding: 0px 0;
}

.logo1,
.logo2 {
  height: 40px;
}

.home_adv_srch_opt .nav-pills li.nav-item a.nav-link.active {
  background-color: #1166bb;
  border-color: #1166bb;
}
.home_adv_srch_opt .nav-pills li.nav-item a.nav-link.active:before {
  background-color: #1166bb;
}
.home1-advnc-search ul li .search_option_button button {
  background-color: #ffc107;
  -webkit-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  -moz-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  -o-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
  color: #000;
}
.feat_property .thumb .thmb_cntnt ul.tag li:last-child,
.feat_property.home8 ul.tag li:last-child,
.properti_city.home6 .thumb .thmb_cntnt ul.tag li:last-child,
.feat_property.list .dtls_headr ul.tag li:last-child {
  background-color: #f7b806;
  color: white;
}

.text-thm {
  color: #4f83b2 !important;
}

.propert_info {
  text-align: center;
  margin-top: 15px;
  border: 1px solid #efefef;
  padding: 7px;
}
.propert_info h5 {
  font-size: 12px;
  margin-bottom: 0;
  color: #000;
  margin-top: 5px;
  font-weight: 100;
}

.owl-carousel .owl-item .proprty_icon img {
  width: auto;
  max-width: 100%;
  vertical-align: middle;
  border-style: none;
  display: inline;
}
.rent_info {
  display: flex;
  margin-top: 15px;
  height: 47px;
  overflow: hidden;
}
.apart {
  background: #ffb900;
  z-index: 0;
  position: relative;
  padding: 11px 20px;
  width: 50%;
  color: #000;
  font-size: 16px;
  /* font-family: 'Poppins', sans-serif; */
}
.apart:after {
  content: '';
  background: #ffb900;
  z-index: -1;
  width: 40px;
  height: 100%;
  transform: skew(-30deg);
  position: absolute;
  top: 0px;
  right: -15px;
}
.sale {
  background: #2b2727;
  padding: 10px;
  text-align: center;
  color: #fff;
  width: 50%;
  font-size: 16px;
  font-weight: 300;
  /* font-family: 'Poppins', sans-serif; */
}
.proprty_icon .svg {
  max-width: 25px;
}

.listing-home-bg {
  height: 320px;
}

.agent-body {
  justify-content: center;
  display: flex;
  flex-flow: column;
  height: 80px;
}

.feat_property.home7.style4 .thumb.listview {
  margin: 10px;
  max-height: auto;
}

#myTAB .item {
  margin: 10px;
  width: 31%;
}

#myTAB .thumb:hover {
  z-index: 2;
  display: block;
  content: '';
  height: 100%;
  background: -webkit-linear-gradient(
    left,
    #484848 100%,
    rgb(255 255 255 / 0%) 100%
  );
}
#new .sidebar_feature_property_slider .feat_property .thumb {
  height: 320px;
}

/*  */
.col-12.sticky-ad-tabs-container.sticky-ad-tabs-container--top-second {
  width: 390px;
}
.col-12.sticky-ad-tabs-container.sticky-ad-tabs-container--top-first {
  width: 390px;
}

.leftMenuActived {
  color: #ffffff !important;
  background-color: #172133 !important;
  border-left-color: #ffc107 !important;
}
.form-select {
  background-color: #ffffff;
  border: 1px solid rgb(235, 235, 235);
  border-radius: 8px;
  height: 52px;
  padding-left: 20px;
  width: 250px;
}

.view_edit_delete_list {
  width: 120px;
}

.view_edit_delete_list li {
  background-color: rgb(247, 247, 247);
  border-radius: 8px;
  height: 30px !important;
  line-height: 30px !important;
  text-align: center;
  width: 30px !important;
  font-size: 12px;
}
.view_edit_delete_list li a span {
  color: #ff5a5f;
  font-size: 14px !important;
}
.admin-footer {
  position: absolute;
  width: 100%;
  height: 50px;
}
.distance-title {
  display: flex;
  align-items: center;
  justify-content: end;
}
.distance-input {
}
.img-box {
  width: 100px !important;
  height: 100px;
  border: 5px solid #e3e3e3;
  border-radius: 10px;
  margin-right: 20px;
  margin-bottom: 20px;
  object-fit: fill;
}

@media (max-width: 1399.98px) {
  .sidebar-menu {
    width: 200px !important;
  }
  .our-dashbord.dashbord .container-fluid {
    padding-left: 0%;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-lg {
    padding: 0 !important;
  }
  .modal-fullscreen-lg .modal-dialog {
    width: 100%;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg .modal-body {
    overflow-y: auto;
  }
}
.modal-fullscreen-xl {
  padding: 0 !important;
}
.modal-fullscreen-xl .modal-dialog {
  width: 100%;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen-xl .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen-xl .modal-body {
  overflow-y: auto;
}
.col-12.sticky-ad-tabs-container {
  transition: none !important;
}
.image-gallery-content .image-gallery-slide .image-gallery-image {
  width: 800px;
  height: 400px;
}
.mega-dropdown > .dropdown-content {
  top: 100px;
  z-index: 0;
}
/* .sticky-ad-tabs-container--top-second {
  top: 0px !important;
} */

.sticky-menu {
  position: fixed;
  z-index: 10;
  width: -webkit-fill-available;
}
.non-sticky-menu {
  position: relative;
}

.add-property-modal .modal-body {
  max-height: 600px;
  overflow-y: scroll;
}

.profile_photo {
  width: 40px;
  height: 40px;
}
.profile_photo_sqr {
  width: 40px;
  height: 40px;
}

@media (max-width: 764px) {
  .mobile_not {
    display: none;
  }
  .header.stylehome1 .dropdown__content .dropdown-item {
    display: contents;
  }
  .profile_photo {
    width: 30px;
    height: 30px;
  }
  #myTAB .item {
    margin: 10px 0px;
    width: 100%;
  }
  .home_adv_srch_opt.home4 {
    margin-top: 0px;
  }
  .ui_kit_tab .nav.nav-tabs {
    height: auto;
  }
  .ui_kit_tab .nav.nav-tabs .nav-link {
    height: 45px;

    width: 150px;
  }
  .home1-advnc-search ul li {
    width: 100%;
  }
  .home1-advnc-search ul li .search_option_button button.btn-thm3 {
    width: 150px;
  }
  .btn-thm3 {
    width: 150px;
  }
  .search_option_button {
    display: flex;
  }
  .inner_page_breadcrumb .breadcrumb_content {
    margin-top: 55px !important;
  }
  .home_adv_srch_opt .nav-pills li.nav-item a.nav-link {
    height: 40px;
    width: 80px;
    line-height: 20px;
  }
  .home_adv_srch_opt .nav-pills li.nav-item a.nav-link.active:before {
    height: 15px;
    margin-left: 5px;
    top: 30px;
    width: 15px;
    transform: rotate(45deg);
  }
}

.posr.color-white.ctText {
  color: #000;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 5px 10px;
  border-radius: 0.5rem;
  /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 1); */
}

.ctPadding {
  padding: 0 10px !important;
}

.star {
  color: red;
}

@media (min-width: 900px) {
  .stylehome1.mbl {
    display: none;
  }
}
