.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-button-next, .swiper-button-prev {
  position: absolute;
  top: var(--swiper-navigation-top-offset,50%);
  width: calc(var(--swiper-navigation-size)/ 44 * 35);
  height: var(--swiper-navigation-size);
  margin-top: calc(0px - (var(--swiper-navigation-size)/ 2));
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--swiper-navigation-color,var(--swiper-theme-color));
  /* background-color: #fff; */
  padding: 30px 15px;
  border-radius: 10px;
  color: #666;
}

/* (min-width:480px) (max-width:500px) */
@media only screen and (max-width: 500px) {
  .swiper-button-next, .swiper-button-prev {
    display: none;
  }
}