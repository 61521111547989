.thumb {
  height: 80px;
  max-width: 80px;
  margin: 0;
  position: relative;
}

img {
  /* opacity: 0.6; */
  object-fit: cover;
}