/*
  Flaticon icon font: Flaticon
  Creation date: 09/01/2020 08:18
*/
@font-face {
  font-family: "Flaticon";
  src: url("../fonts/Flaticon.eot");
  src: url("../fonts/Flaticond41d.eot?#iefix") format("embedded-opentype"),
       url("../fonts/Flaticon.woff2") format("woff2"),
       url("../fonts/Flaticon.woff") format("woff"),
       url("../fonts/Flaticon.ttf") format("truetype"),
       url("../fonts/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../fonts/Flaticon.svg#Flaticon") format("svg");
  }
}
[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {font-family: Flaticon;font-style: normal;}

.flaticon-calendar:before { content: "\f100"; }
.flaticon-percent:before { content: "\f101"; }
.flaticon-money-bag:before { content: "\f102"; }
.flaticon-close:before { content: "\f103"; }
.flaticon-filter-results-button:before { content: "\f104"; }
.flaticon-pin:before { content: "\f105"; }
.flaticon-street-view:before { content: "\f106"; }
.flaticon-photo-camera:before { content: "\f107"; }
.flaticon-share:before { content: "\f108"; }
.flaticon-printer:before { content: "\f109"; }
.flaticon-tick:before { content: "\f10a"; }
.flaticon-play:before { content: "\f10b"; }
.flaticon-college-graduation:before { content: "\f10c"; }
.flaticon-heartbeat:before { content: "\f10d"; }
.flaticon-front-of-bus:before { content: "\f10e"; }
.flaticon-pdf:before { content: "\f10f"; }
.flaticon-document:before { content: "\f110"; }
.flaticon-download:before { content: "\f111"; }
.flaticon-smartphone-call:before { content: "\f112"; }
.flaticon-telephone:before { content: "\f113"; }
.flaticon-black-back-closed-envelope-shape:before { content: "\f114"; }
.flaticon-layers:before { content: "\f115"; }
.flaticon-envelope:before { content: "\f116"; }
.flaticon-home:before { content: "\f117"; }
.flaticon-heart:before { content: "\f118"; }
.flaticon-invoice:before { content: "\f119"; }
.flaticon-magnifying-glass:before { content: "\f11a"; }
.flaticon-chat:before { content: "\f11b"; }
.flaticon-box:before { content: "\f11c"; }
.flaticon-user:before { content: "\f11d"; }
.flaticon-logout:before { content: "\f11e"; }
.flaticon-view:before { content: "\f11f"; }
.flaticon-edit:before { content: "\f120"; }
.flaticon-garbage:before { content: "\f121"; }
.flaticon-reply:before { content: "\f122"; }
.flaticon-left-arrow:before { content: "\f123"; }
.flaticon-transfer:before { content: "\f124"; }
.flaticon-zoom-in:before { content: "\f125"; }
.flaticon-drop-down-arrow:before { content: "\f126"; }
.flaticon-maps-and-flags:before { content: "\f127"; }
.flaticon-more:before { content: "\f128"; }
.flaticon-transfer-1:before { content: "\f129"; }
.flaticon-high-five:before { content: "\f12a"; }
.flaticon-home-1:before { content: "\f12b"; }
.flaticon-profit:before { content: "\f12c"; }
.flaticon-next:before { content: "\f12d"; }
.flaticon-arrows:before { content: "\f12e"; }
.flaticon-angle-arrow-down:before { content: "\f12f"; }
.flaticon-plus:before { content: "\f130"; }
.flaticon-mouse:before { content: "\f131"; }
.flaticon-mouse-1:before { content: "\f132"; }
.flaticon-back:before { content: "\f133"; }
.flaticon-download-1:before { content: "\f134"; }
.flaticon-upload:before { content: "\f135"; }
.flaticon-left-arrow-1:before { content: "\f136"; }
.flaticon-right-arrow:before { content: "\f137"; }
.flaticon-download-arrow:before { content: "\f138"; }
.flaticon-house:before { content: "\f139"; }
.flaticon-house-1:before { content: "\f13a"; }
.flaticon-house-2:before { content: "\f13b"; }
.flaticon-building:before { content: "\f13c"; }
.flaticon-placeholder:before { content: "\f13d"; }
.flaticon-play-button:before { content: "\f13e"; }
.flaticon-caret-down:before { content: "\f13f"; }
.flaticon-password:before { content: "\f140"; }
.flaticon-login:before { content: "\f141"; }
.flaticon-user-1:before { content: "\f142"; }
.flaticon-gear:before { content: "\e001"; }