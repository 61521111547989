.table > .loading_div {
  position: absolute;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.8);
  transition: all 0.3s ease;
  top: 50;
  bottom: 0;
  padding: 100px;
  display: flex;
  justify-content: center;
  align-content: center;
}
.loading {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #0095e8;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
