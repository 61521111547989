/*Pure Css MegaDropDown*/
.vertical-wrapper{
  position: relative;
}
.vertical-wrapper:before{
  bottom: -19px;
  height: 20px;
  background-color: transparent;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
}
.vertical-wrapper .title-vertical{
  background-color: #2441e7;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  padding: 13px 30px;
  line-height: normal;
  margin: 0;
  text-transform: uppercase;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.vertical-wrapper .title-vertical.home3{
  background-color: #ffffff;
  color: #0a0a0a;
  text-transform: capitalize;
}
.vertical-wrapper .title-vertical.home5{
  background-color: #0a0a0a;
  color: #ffffff;
}
.vertical-wrapper .title-vertical .show-down{
  font-size: 16px;
  float: right;
  margin: 1px 0 0 10px;
}
.vertical-wrapper .content-vertical{
  background-color: transparent;
  border-radius: 5px;
  left: 0;
  margin: 18px 0 0 0;
  min-width: 322px;
  padding: 0;
  pointer-events: none;
  position: absolute;
  top: 120%;
  visibility: hidden;
  width: 100% !important;
  z-index: 8;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}
.vertical-wrapper:hover .content-vertical{
  opacity: 1;
  pointer-events: auto;
  visibility: visible;
  top: 100%;
  filter: alpha(opacity=100);
  -webkit-backface-visibility: visible;
  -moz-backface-visibility: visible;
  backface-visibility: visible;
}
.vertical-wrapper .content-vertical .mega-vertical-menu{
  background-color: #191919;
  border: 0;
  border-radius: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
}
.vertical-wrapper .content-vertical .mega-vertical-menu:before{
  content: "";
  height: 0;
  left: 24px;
  position: absolute;
  top: -10px;
  width: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  border-bottom: 11px solid #191919;
}
.vertical-wrapper .content-vertical .mega-vertical-menu > li{
  background-color: transparent;
  border-bottom: 1px solid #2e2e2e;
  float: none;
  position: static;
}
.vertical-wrapper .content-vertical .mega-vertical-menu > li:first-child{
  border-top: 0;
}
.vertical-wrapper .content-vertical .mega-vertical-menu > li:before, 
.vertical-wrapper .content-vertical .mega-vertical-menu > li:after{
  content: " ";
  display: table;
}
.vertical-wrapper .content-vertical .mega-vertical-menu > li:after{
  clear: both;
}
.vertical-wrapper .content-vertical .mega-vertical-menu > li:first-child a{
  -webkit-border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -webkit-border-bottom-right-radius: 0px;
  -webkit-border-bottom-left-radius: 0px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-topright: 5px;
  -moz-border-radius-bottomright: 0px;
  -moz-border-radius-bottomleft: 0px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}
.vertical-wrapper .content-vertical .mega-vertical-menu > li > a{
  background-color: transparent;
  color: #a4a4a4;
  font-family: "Nunito", Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  float: left;
  padding: 13px 30px;
  position: relative;
  width: 100%;
  -webkit-transition: all 0.1s ease-in;
  -o-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
}
.vertical-wrapper .content-vertical .mega-vertical-menu > li > a:hover{
  color: #fff;
  background-color: #0a0a0a;
  padding-left: 36px;
}
.vertical-wrapper .content-vertical .mega-vertical-menu > li:hover > .dropdown-menu{
  visibility: visible;
  pointer-events: auto;
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-backface-visibility: visible;
  -moz-backface-visibility: visible;
  backface-visibility: visible;
}
.vertical-wrapper .content-vertical .mega-vertical-menu > li .dropdown-menu{
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  font-size: 15px;
  text-align: left;
  background-color: #fff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  -webkit-box-shadow: 0 3px 4px rgba(0, 0, 0, 0.175);
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.175);
  background-clip: padding-box;
}
.vertical-wrapper .content-vertical .mega-vertical-menu > li .dropdown-menu{
  display: block;
  pointer-events: none;
  top: -5px;
  padding: 20px 30px;
  border: 0;
  min-width: 300px;
  height: 100%;
  background-color: #0a0a0a;
  visibility: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  opacity: 0;
  filter: alpha(opacity=0);
  margin-left: -4px;
  right: auto;
  left: 100% !important;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-border-top-left-radius: 0px;
  -webkit-border-top-right-radius: 5px;
  -webkit-border-bottom-right-radius: 5px;
  -webkit-border-bottom-left-radius: 0px;
  -moz-border-radius-topleft: 0px;
  -moz-border-radius-topright: 5px;
  -moz-border-radius-bottomright: 5px;
  -moz-border-radius-bottomleft: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 0px;
}
.vertical-wrapper .content-vertical .mega-vertical-menu > li .dropdown-menu.vertical-megamenu{
  padding: 30px;
}
.vertical-wrapper .content-vertical .mega-vertical-menu > li > a .caret{
  top: 15px;
  margin: 0;
  border: 0;
  position: absolute;
  overflow: hidden;
  font-size: 8px;
  width: 20px;
  height: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  right: 23px;
}
.vertical-wrapper .content-vertical .mega-vertical-menu > li > a .caret:before{
  content: "\f104";
  font-family: "Flaticon";
}
.vertical-wrapper .content-vertical .mega-vertical-menu .dropdown-toggle:after{
  display: none;
}
.vertical-wrapper .content-vertical .mega-vertical-menu > li .dropdown-menu.vertical-megamenu .element-section-wrap{
  position: relative;
}
.element-container{
  max-width: 1320px;
}
.element-container{
  max-width: 1140px;
}
.element-container{
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: auto;
  margin-left: auto;
  position: relative;
}
.element-row{
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.element-wrapper{
  padding: 25px 15px 0px 30px;
}
.element-wrapper > .title-wrapper{
  padding: 0px 0px 15px 0px;
}
.element-wrapper .element-wrapper-title{
  color: #ffffff;
  font-family: "Nunito";
  font-size: 16px;
  font-weight: 400;
}
.element-wrapper .element-list-wrapper{
  padding: 0 0 15px 0;
  position: relative;
}
.vertical-wrapper .content-vertical .mega-vertical-menu > li .dropdown-menu li {
  border-bottom: 0;
  padding: 0;
}
.vertical-wrapper .content-vertical .mega-vertical-menu > li .dropdown-menu li > a{
  background-color: transparent;
  color: #a4a4a4;
  font-family: "Nunito", Arial, sans-serif;
  font-size: 16px;
  line-height: 40px;
}
.vertical-wrapper .content-vertical .mega-vertical-menu > li .dropdown-menu li > a:hover{
  color: #fff;
  -webkit-transform: translateX(10px);
  -ms-transform: translateX(10px);
  -o-transform: translateX(10px);
  transform: translateX(10px);
}
.widget-nav-menu .wn-menu li{
  line-height: 30px;
  margin: 0px;
}
.widget-nav-menu .wn-menu li a{
  font-size: 14px;
  color: inherit;
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.element-warapper-btn .element-wrapper-sub-title{
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: 400;
  fill: #ffffff;
  color: #ffffff;
  background-color: rgba(0,0,0,0);
  padding: 0px 0px 0px 0px;
}
.element-img-widget-wrapper{
  position: relative;
}
.element-img-widget-wrapper img{
  height: 400px;
  position: absolute;
  right: 0;
  left: 0;
}
/*  (max-width:1200px)+++ */
@media only screen and (max-width:1200px){
  .vertical-wrapper .content-vertical{
    left: -120px;
  }
  .vertical-wrapper .content-vertical .mega-vertical-menu::before{
    left: auto;
    right: 100px;
  }
}
@media only screen and (max-width: 1199.98px){
  .vertical-wrapper .content-vertical{
    left: -180px;
    min-width: 250px;
    text-align: left;
  }
  .vertical-wrapper .content-vertical.home5{
    left: -170px;
  }
  .vertical-wrapper .content-vertical .mega-vertical-menu::before{
    left: auto;
    right: 15px;
  }
}